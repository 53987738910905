<template>
    <div class="page-layout blank outletdetails">
        <div class="pb-10">
            <h2 class="headerblock">
                <div class="row">
                    <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                        <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasPrevious()"
                            :to="{ path: $routerHistory.previous().path }">
                            <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                    <i class="icons icon-arrow-left"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                        <router-link style="color: transparent!important;" :disabled="!$routerHistory.hasForward()"
                            :to="{ path: $routerHistory.next().path }">
                            <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                                    <i class="icons icon-arrow-right"></i>
                                </button>
                            </el-tooltip>
                        </router-link>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12"></div>
                </div>
            </h2>
            <div class="page-layout carded left-sidebar" style="overflow: auto !important;">
                <div class="page-content-wrapper">
                    <div class="page-content">
                        <el-container class="outlet-details-c-el-container" style="min-width: 768px;">
                            <el-aside class="contactSidebar">
                                <div class="header pt-4">
                                    <div class="contactInfo mt-8">
                                        <div class="profile-left text-center">
                                            <div class="profile-image">
                                                <my-upload field="img" @crop-success="onOutletPicCrop"
                                                    v-model="showOutletPic" :width="100" langType="en" :height="100"
                                                    :params="params" :headers="headers" img-format="png"></my-upload>
                                                <img :src="generalDetails.profile_pic || 'https://cdn.digicollect.com/cdn/posv2/company-logo.svg'"
                                                    style="width: 90px;" alt="Company Logo" />
                                            </div>
                                        </div>
                                        <div class="mb-4 mt-2 profile-button text-center">
                                            <a id="change_picture" class="btn btn-secondary btn-smm"
                                                @click="showOutletPic=!showOutletPic">Change Picture</a>
                                        </div>
                                        <span class="fw-600 fs-18 contactTitle"
                                            :title="outlet.name">{{outlet.name}}</span>
                                        <hr
                                            style="border-top:2px solid rgba(13, 132, 242, 0.45) !important;margin-top: 2.5rem!important;" />
                                        <div class="mt-4">
                                            <ul class="nav flex-column" style="padding-bottom: 30px;">

                                                <li class="nav-item pt-2">
                                                    <a class="nav-link verticalTab"
                                                        :class="{contactActiveTab : alertSettings}"
                                                        @click="alertSettings = true">Alerts</a>
                                                </li>
                                                <li class="nav-item pt-2">
                                                    <a class="nav-link verticalTab"
                                                        :class="{contactActiveTab : approvalPermission}"
                                                        @click="approvalPermission = true"
                                                        >Approval Permission</a>
                                                </li>
                                                <li class="nav-item pt-4">
                                                    <a class="nav-link verticalTab"
                                                        :class="{contactActiveTab : cashRegisterSettings}"
                                                        @click="cashRegisterSettings = true">Cash Registers &
                                                        Printers</a>
                                                </li>
                                                <li class="nav-item pt-4">
                                                    <a class="nav-link verticalTab"
                                                        :class="{contactActiveTab : generalSettings}"
                                                        @click="generalSettings = true">Contact Info</a>
                                                </li>
                                                <li class="nav-item pt-4">
                                                    <a class="nav-link verticalTab"
                                                        :class="{contactActiveTab : generatePin}"
                                                        @click="generatePin = true"
                                                        >Generate User Pin</a>
                                                </li>
                                                <li class="nav-item pt-4">
                                                    <a class="nav-link verticalTab"
                                                        :class="{contactActiveTab : otherSettings}"
                                                        @click="otherSettings = true">Other Settings</a>
                                                </li>
                                                <li class="nav-item pt-4">
                                                    <a class="nav-link verticalTab"
                                                        :class="{contactActiveTab : paymentSettings}"
                                                        @click="paymentSettings = true">Payments</a>
                                                </li>
                                                <!-- <li class="nav-item pt-4">
                                                    <a class="nav-link verticalTab" :class="{contactActiveTab : pictureSettings}" @click="pictureSettings = true">Pictures</a>
                                                </li> -->
                                                <li class="nav-item pt-4">
                                                    <a class="nav-link verticalTab"
                                                        :class="{contactActiveTab : salesData}"
                                                        @click="salesData = true">Sales Data</a>
                                                </li>
                                                <li class="nav-item pt-4">
                                                    <a class="nav-link verticalTab"
                                                        :class="{contactActiveTab : scmSettings}"
                                                        @click="scmSettings = true">Supply Chain</a>
                                                </li>
                                                <li class="nav-item pt-4">
                                                    <a class="nav-link verticalTab"
                                                        :class="{contactActiveTab : taxSettings}"
                                                        @click="taxSettings = true">Tax and Other Charges</a>
                                                </li>
                                                <li class="nav-item pt-4">
                                                    <a class="nav-link verticalTab"
                                                        :class="{contactActiveTab : voidCancelled}"
                                                        @click="voidCancelled = true">Void/Cancelled</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </el-aside>
                            <!-- <el-main class="contactTabs" style="background-color: #00448b;"> -->
                            <el-main class="contactTabs" style="background-color: #00448b;">
                                <div v-if="generalSettings" class="general-settings-tab-outlet">
                                    <div class="demo-content mediaSize" style="padding: 0 30px;">
                                        <h6 class="heading-details pt-4 pl-5">GENERAL DETAILS</h6>
                                        <div>
                                            <div class="d-flex mt-2 flex-wrap">
                                                <div class="col-lg-6 col-md-6 col-sm-12 pl-5">
                                                    <div class="form-group contactGroup">
                                                        <input id="outlet_name" class="inputContact"
                                                            v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                                                            name="outletName" type="text" value="Green View Cafe"
                                                            v-model="generalDetails.name" data-vv-scope="generalDetails"
                                                            :maxlength="editoutletname" required />
                                                        <span class="highlightContact"></span>
                                                        <span class="barContact"></span>
                                                        <label class="labelContact">
                                                            Outlet Name
                                                            <sup>★</sup>
                                                        </label>
                                                        <span class="text-danger fs-13"
                                                            v-show="errors.has('generalDetails.outletName') && ifGeneralDetailsSubmitted">{{errors.first('generalDetails.outletName')}}</span>
                                                        <span class="text-danger fs-13"
                                                            v-show="duplicateOutletName">Duplicate Outlet Name</span>
                                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                                            {{editoutletname - generalDetails.name.length}} /
                                                            {{editoutletname}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 pl-2">
                                                    <div class="form-group contactGroup pt-4">
                                                        <input id="website" class="inputContact" type="text"
                                                            name="outletWebsite" data-vv-scope="generalDetails"
                                                            v-validate="{required:true,regex:/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/}"
                                                            v-model="generalDetails.website" required
                                                            :maxlength="editwebsite" />
                                                        <span class="highlightContact"></span>
                                                        <span class="barContact"></span>
                                                        <label class="labelContact">
                                                            Website
                                                            <sup>★</sup>
                                                        </label>
                                                        <span class="text-danger fs-13"
                                                            v-show="errors.has('generalDetails.outletWebsite') && ifGeneralDetailsSubmitted">{{errors.first('generalDetails.outletWebsite')}}</span>
                                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                                            {{editwebsite - generalDetails.website.length}} /
                                                            {{editwebsite}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-wrap">
                                            <div class="col-lg-6 pl-5">
                                                <div class="form-group contactGroup">
                                                    <input id="email" class="inputContact" type="text"
                                                        v-validate="{required:true,regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,50})+$/}"
                                                        data-vv-scope="generalDetails" name="email"
                                                        v-model="generalDetails.email" :maxlength="editemail" />
                                                    <span class="highlightContact"></span>
                                                    <span class="barContact"></span>
                                                    <label class="labelContact">
                                                        Email
                                                        <sup>★</sup>
                                                    </label>
                                                    <span class="text-danger fs-13"
                                                        v-show="errors.has('generalDetails.email') && ifGeneralDetailsSubmitted">{{errors.first('generalDetails.email')}}</span>
                                                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                                        {{editemail - generalDetails.email.length}} / {{editemail}}</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 pl-2">
                                                <div class="form-group contactGroup">
                                                    <input id="tax_id" class="inputContact" type="text"
                                                        v-validate="'required'" data-vv-scope="generalDetails"
                                                        name="taxId" v-model="generalDetails.tax_id" required
                                                        :maxlength="edittaxID" />
                                                    <span class="highlightContact"></span>
                                                    <span class="barContact"></span>
                                                    <label class="labelContact">
                                                        Tax ID
                                                        <sup>★</sup>
                                                    </label>
                                                    <span class="text-danger fs-13"
                                                        v-show="errors.has('generalDetails.taxId') && ifGeneralDetailsSubmitted">{{errors.first('generalDetails.taxId')}}</span>
                                                    <p class="pull-right text-right text-grey font-600 fs-12 pt-1">
                                                        {{edittaxID - generalDetails.tax_id.length}} / {{edittaxID}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                                            style="display: -webkit-inline-box;background-color:#fff !important;">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                alt="Vertical Cash" />
                                            <div class="col-md-6">
                                                <label class="fs-14" style="color:#00448b;">Is Outlet?
                                                    <sup>★</sup></label><br>
                                                <div class="form-check form-check-inline">
                                                    <label class="form-check-label">
                                                        <input v-model="generalDetails.branch_type" type="radio"
                                                            class="form-check-input" value="headquarters"
                                                            v-validate="'required'" readonly name="branch_type" />
                                                        <span class="radio-icon"></span>
                                                        <span style="padding-left: 25px !important;padding-top: 2px;">Headquarters</span>
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <label class="form-check-label">
                                                        <input v-model="generalDetails.branch_type" type="radio"
                                                            class="form-check-input" value="branch"
                                                            v-validate="'required'" readonly name="branch_type" />
                                                        <span class="radio-icon"></span>
                                                        <span style="padding-left: 25px !important;padding-top: 2px;">Branch</span>
                                                    </label>
                                                </div>
                                                <span class="invalid-feedback-form text-danger fs-13"
                                                    v-show="errors.has('branch_type')">Branch Type is Required</span>
                                            </div>
                                            <div class="col-md-6" v-if="generalDetails.branch_type == 'branch'">
                                                <div class="form-group" style="margin-top:-23px !important;">
                                                    <label class="fs-14" style="color:#00448b;">Branch
                                                        <sup>★</sup></label>
                                                    <v-select label="name" index="_id"
                                                        class="select-width clearNone optionsHeight vSelectWidth"
                                                        placeholder="Select Branch" :filterable="true"
                                                        :options="branchs" data-vv-scope="generalDetails" required
                                                        v-model="generalDetails.branch_id" v-validate="'required'"
                                                        name="branch" id="select_branch"></v-select>
                                                    <span class="invalid-feedback-form text-danger fs-13"
                                                        v-show="errors.has('generalDetails.branch')">Branch Name is
                                                        Required</span>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Hybrid Mode -->
                                        <!-- <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                                            style="display: -webkit-inline-box;background-color:#fff !important;">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                alt="Vertical Cash" />
                                            <div class="col-md-6">
                                                <label class="fs-14" style="color:#00448b;">Select Outlet mode?
                                                    <sup>★</sup></label><br>
                                                <div class="form-check form-check-inline">
                                                    <label class="form-check-label">
                                                        <input v-model="generalDetails.outlet_mode" type="radio"
                                                            class="form-check-input" value="cloud"
                                                            v-validate="'required'" readonly name="outlet_mode" />
                                                        <span class="radio-icon"></span>
                                                        <span style="padding-left: 25px !important;padding-top: 2px;">Cloud</span>
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <label class="form-check-label">
                                                        <input v-model="generalDetails.outlet_mode" type="radio"
                                                            class="form-check-input" value="hybrid"
                                                            v-validate="'required'" readonly name="outlet_mode" />
                                                        <span class="radio-icon"></span>
                                                        <span style="padding-left: 25px !important;padding-top: 2px;">Hybrid</span>
                                                    </label>
                                                </div>
                                                <span class="invalid-feedback-form text-danger fs-13"
                                                    v-show="errors.has('outlet_mode')">Outlet mode is Required</span>
                                            </div>
                                        </div> -->


                                        <div class="col-lg-12 pl-5 pt-3" id="phone_numver">
                                            <label class="col-form-label text-secondary">
                                                Phone Number
                                                <sup>★</sup>
                                            </label>
                                            <vue-tel-input id="phone_number_vue"
                                                placeholder="Please enter your Phone Number"
                                                :preferredCountries="['th', 'in', 'us']" data-vv-scope="generalDetails"
                                                v-model="generalDetails.phone_number" @onInput="onPhoneNumberInput">
                                            </vue-tel-input>
                                            <span v-if="!generalDetails.phone_number == ''" class="text-danger fs-13"
                                                v-show="isPhoneNumberInvalid && ifGeneralDetailsSubmitted">Please Enter
                                                Valid Phone Number</span>
                                            <span class="text-danger fs-13"
                                                v-show="!generalDetails.phone_number && ifGeneralDetailsSubmitted">Phone
                                                Number is Required</span>
                                        </div>
                                        <h6 class="heading-details pb-4 pt-4 pl-5">LOCALE SETTINGS</h6>
                                        <div class="col-lg-12 pl-5" style="cursor: not-allowed;">
                                            <label class="text-secondary">
                                                Currency
                                            </label>
                                            <div class="borderBottom">
                                                <span class="font-600">{{generalDetails.currency.name}}
                                                    {{generalDetails.currency.symbol}}</span>
                                            </div>
                                        </div>
                                        <div class="pt-4">
                                            <h6 class="heading-details pl-5">Long Form Address</h6>
                                            <div class="pl-1 pt-5">
                                                <div class="col-md-12">
                                                    <div class="group-setup">
                                                        <input id="long_address_address1" class="digi-form" type="text"
                                                            name="street" v-validate="'required'"
                                                            data-vv-scope="generalDetails"
                                                            v-model="generalDetails.address.street" required
                                                            :maxlength="editstreet" />
                                                        <span class="highlight digi-highlight"></span>
                                                        <span class="bar digi-bar"></span>
                                                        <label class="digi-label-outlet">
                                                            Address 1
                                                            <sup>★</sup>
                                                        </label>
                                                        <span class="text-danger fs-13"
                                                            v-show="errors.has('generalDetails.street') && ifGeneralDetailsSubmitted">Address
                                                            1 Name is Required</span>
                                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                            style="margin-bottom: 0px!important;">
                                                            {{editstreet - generalDetails.address.street.length}} /
                                                            {{editstreet}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-8">
                                                    <div class="group-setup">
                                                        <input id="long_address_address2" class="digi-form" type="text"
                                                            name="streetnew" data-vv-scope="generalDetails"
                                                            v-model="generalDetails.address.street2" required
                                                            :maxlength="editstreetnew" />
                                                        <span class="highlight digi-highlight"></span>
                                                        <span class="bar digi-bar"></span>
                                                        <label class="digi-label-outlet">Address 2</label>
                                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                            style="margin-bottom: 0px!important;">
                                                            {{editstreetnew - generalDetails.address.street2.length}} /
                                                            {{editstreetnew}}</p>
                                                    </div>
                                                </div>
                                                <div class="form-group form-bottom mt-6">
                                                    <div class="d-flex flex-wrap pb-2">
                                                        <div class="col-lg-4 col-md-4 col-sm-12">
                                                            <div class="group-setup">
                                                                <input id="long_address_township"
                                                                    v-model="generalDetails.address.township"
                                                                    class="digi-form" type="text"
                                                                    :maxlength="edittownship" name="state" required />
                                                                <span class="highlight digi-highlight"></span>
                                                                <span class="bar digi-bar"></span>
                                                                <label class="digi-label-outlet">Township</label>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{edittownship - generalDetails.address.township.length}}
                                                                    / {{edittownship}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-12">
                                                            <div class="group-setup">
                                                                <input id="long_address_city" class="digi-form"
                                                                    type="text" name="city"
                                                                    v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                                                                    data-vv-scope="generalDetails"
                                                                    v-model="generalDetails.address.city" required
                                                                    :maxlength="editcity" />
                                                                <span class="highlight digi-highlight"></span>
                                                                <span class="bar digi-bar"></span>
                                                                <label class="digi-label-outlet">
                                                                    City
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="text-danger fs-13"
                                                                    v-show="errors.has('generalDetails.city') && ifGeneralDetailsSubmitted">{{errors.first('generalDetails.city')}}</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{editcity - generalDetails.address.city.length}} /
                                                                    {{editcity}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-12">
                                                            <div class="group-setup">
                                                                <input id="long_address_state" class="digi-form"
                                                                    type="text" name="state"
                                                                    v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                                                                    data-vv-scope="generalDetails"
                                                                    v-model="generalDetails.address.state" required
                                                                    :maxlength="editstate" />
                                                                <span class="highlight digi-highlight"></span>
                                                                <span class="bar digi-bar"></span>
                                                                <label class="digi-label-outlet">
                                                                    State
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="text-danger fs-13"
                                                                    v-show="errors.has('generalDetails.state') && ifGeneralDetailsSubmitted">{{errors.first('generalDetails.state')}}</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{editstate - generalDetails.address.state.length}}
                                                                    / {{editstate}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group form-top d-flex flex-wrap mt-3">
                                                    <div class="col-lg-4 col-md-4 col-sm-12">
                                                        <div class="group-setup">
                                                            <input id="long_address_zip_code" class="digi-form"
                                                                type="text" name="zipcode"
                                                                v-validate="'required|numeric'"
                                                                data-vv-scope="generalDetails"
                                                                v-model="generalDetails.address.zipcode" required
                                                                :maxlength="editzipcode" />
                                                            <span class="highlight digi-highlight"></span>
                                                            <span class="bar digi-bar"></span>
                                                            <label class="digi-label-outlet">
                                                                Zip Code
                                                                <sup>★</sup>
                                                            </label>
                                                            <span class="text-danger fs-13"
                                                                v-show="errors.has('generalDetails.zipcode') && ifGeneralDetailsSubmitted">{{errors.first('generalDetails.zipcode')}}</span>
                                                            <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                style="margin-bottom: 0px!important;">
                                                                {{editzipcode - generalDetails.address.zipcode.length}}
                                                                / {{editzipcode}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-8 col-md-8 col-sm-12">
                                                        <float-label fixed label="Select Country*">
                                                            <select id="long_address_country" class="form-control"
                                                                name="country" v-model="generalDetails.address.country"
                                                                data-vv-scope="generalDetails"
                                                                placeholder="Select Country"
                                                                style="max-width: 100% !important;margin-top: 3px!important;">
                                                                <option hidden value selected>Select Country</option>
                                                                <option value="Afghanistan">Afghanistan</option>
                                                                <option value="Åland Island">Åland Islands</option>
                                                                <option value="Albania">Albania</option>
                                                                <option value="Algeria">Algeria</option>
                                                                <option value="American Samoa">American Samoa</option>
                                                                <option value="Andorra">Andorra</option>
                                                                <option value="Angola">Angola</option>
                                                                <option value="Anguilla">Anguilla</option>
                                                                <option value="Antarctica">Antarctica</option>
                                                                <option value="Antigua and Barbuda">Antigua and Barbuda
                                                                </option>
                                                                <option value="Argentina">Argentina</option>
                                                                <option value="Armenia">Armenia</option>
                                                                <option value="Aruba">Aruba</option>
                                                                <option value="Australia">Australia</option>
                                                                <option value="Austria">Austria</option>
                                                                <option value="Azerbaijan">Azerbaijan</option>
                                                                <option value="Bahamas">Bahamas</option>
                                                                <option value="Bahrain">Bahrain</option>
                                                                <option value="Bangladesh">Bangladesh</option>
                                                                <option value="Barbados">Barbados</option>
                                                                <option value="Belarus">Belarus</option>
                                                                <option value="Belgium">Belgium</option>
                                                                <option value="Belize">Belize</option>
                                                                <option value="Benin">Benin</option>
                                                                <option value="Bermuda">Bermuda</option>
                                                                <option value="Bhutan">Bhutan</option>
                                                                <option value="Bolivia, Plurinational State of">Bolivia,
                                                                    Plurinational State of</option>
                                                                <option value="Bonaire, Sint Eustatius and Saba">
                                                                    Bonaire, Sint Eustatius and Saba</option>
                                                                <option value="Bosnia and Herzegovina">Bosnia and
                                                                    Herzegovina</option>
                                                                <option value="Botswana">Botswana</option>
                                                                <option value="Bouvet Island">Bouvet Island</option>
                                                                <option value="Brazil">Brazil</option>
                                                                <option value="British Indian Ocean Territory">British
                                                                    Indian Ocean Territory</option>
                                                                <option value="Brunei Darussalam">Brunei Darussalam
                                                                </option>
                                                                <option value="Bulgaria">Bulgaria</option>
                                                                <option value="Burkina Faso">Burkina Faso</option>
                                                                <option value="Burundi">Burundi</option>
                                                                <option value="Cambodia">Cambodia</option>
                                                                <option value="Cameroon">Cameroon</option>
                                                                <option value="Canada">Canada</option>
                                                                <option value="Cape Verde">Cape Verde</option>
                                                                <option value="Cayman Islands">Cayman Islands</option>
                                                                <option value="Central African Republic">Central African
                                                                    Republic</option>
                                                                <option value="Chad">Chad</option>
                                                                <option value="Chile">Chile</option>
                                                                <option value="China">China</option>
                                                                <option value="Christmas Island">Christmas Island
                                                                </option>
                                                                <option value="Cocos (Keeling) Islands">Cocos (Keeling)
                                                                    Islands</option>
                                                                <option value="Colombia">Colombia</option>
                                                                <option value="Comoros">Comoros</option>
                                                                <option value="Congo">Congo</option>
                                                                <option value="Congo, the Democratic Republic of the">
                                                                    Congo, the Democratic Republic of the</option>
                                                                <option value="Cook Islands">Cook Islands</option>
                                                                <option value="Costa Rica">Costa Rica</option>
                                                                <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                                                                <option value="Croatia">Croatia</option>
                                                                <option value="Cuba">Cuba</option>
                                                                <option value="Curaçao">Curaçao</option>
                                                                <option value="Cyprus">Cyprus</option>
                                                                <option value="Czech Republic">Czech Republic</option>
                                                                <option value="Denmark">Denmark</option>
                                                                <option value="Djibouti">Djibouti</option>
                                                                <option value="Dominica">Dominica</option>
                                                                <option value="Dominican Republic">Dominican Republic
                                                                </option>
                                                                <option value="Ecuador">Ecuador</option>
                                                                <option value="Egypt">Egypt</option>
                                                                <option value="El Salvador">El Salvador</option>
                                                                <option value="Equatorial Guinea">Equatorial Guinea
                                                                </option>
                                                                <option value="Eritrea">Eritrea</option>
                                                                <option value="Estonia">Estonia</option>
                                                                <option value="Ethiopia">Ethiopia</option>
                                                                <option value="Falkland Islands (Malvinas)">Falkland
                                                                    Islands (Malvinas)</option>
                                                                <option value="Faroe Islands">Faroe Islands</option>
                                                                <option value="Fiji">Fiji</option>
                                                                <option value="Finland">Finland</option>
                                                                <option value="France">France</option>
                                                                <option value="French Guiana">French Guiana</option>
                                                                <option value="French Polynesia">French Polynesia
                                                                </option>
                                                                <option value="French Southern Territories">French
                                                                    Southern Territories</option>
                                                                <option value="Gabon">Gabon</option>
                                                                <option value="Gambia">Gambia</option>
                                                                <option value="Georgia">Georgia</option>
                                                                <option value="Germany">Germany</option>
                                                                <option value="Ghana">Ghana</option>
                                                                <option value="Gibraltar">Gibraltar</option>
                                                                <option value="Greece">Greece</option>
                                                                <option value="Greenland">Greenland</option>
                                                                <option value="Grenada">Grenada</option>
                                                                <option value="Guadeloupe">Guadeloupe</option>
                                                                <option value="Guam">Guam</option>
                                                                <option value="Guatemala">Guatemala</option>
                                                                <option value="Guernsey">Guernsey</option>
                                                                <option value="Guinea">Guinea</option>
                                                                <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                                <option value="Guyana">Guyana</option>
                                                                <option value="Haiti">Haiti</option>
                                                                <option value="Heard Island and McDonald Islands">Heard
                                                                    Island and McDonald Islands</option>
                                                                <option value="Holy See (Vatican City State)">Holy See
                                                                    (Vatican City State)</option>
                                                                <option value="Honduras">Honduras</option>
                                                                <option value="Hong Kong">Hong Kong</option>
                                                                <option value="Hungary">Hungary</option>
                                                                <option value="Iceland">Iceland</option>
                                                                <option value="India">India</option>
                                                                <option value="Indonesia">Indonesia</option>
                                                                <option value="Iran, Islamic Republic of">Iran, Islamic
                                                                    Republic of</option>
                                                                <option value="Iraq">Iraq</option>
                                                                <option value="Ireland">Ireland</option>
                                                                <option value="Isle of Man">Isle of Man</option>
                                                                <option value="Israel">Israel</option>
                                                                <option value="Italy">Italy</option>
                                                                <option value="Jamaica">Jamaica</option>
                                                                <option value="Japan">Japan</option>
                                                                <option value="Jersey">Jersey</option>
                                                                <option value="Jordan">Jordan</option>
                                                                <option value="Kazakhstan">Kazakhstan</option>
                                                                <option value="Kenya">Kenya</option>
                                                                <option value="Kiribati">Kiribati</option>
                                                                <option value="Korea, Democratic People's Republic of">
                                                                    Korea, Democratic People's Republic of</option>
                                                                <option value="Korea, Republic of">Korea, Republic of
                                                                </option>
                                                                <option value="Kuwait">Kuwait</option>
                                                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                                <option value="Lao People's Democratic Republic">Lao
                                                                    People's Democratic Republic</option>
                                                                <option value="Latvia">Latvia</option>
                                                                <option value="Lebanon">Lebanon</option>
                                                                <option value="Lesotho">Lesotho</option>
                                                                <option value="Liberia">Liberia</option>
                                                                <option value="Libya">Libya</option>
                                                                <option value="Liechtenstein">Liechtenstein</option>
                                                                <option value="Lithuania">Lithuania</option>
                                                                <option value="Luxembourg">Luxembourg</option>
                                                                <option value="Macao">Macao</option>
                                                                <option
                                                                    value="Macedonia, the Former Yugoslav Republic of">
                                                                    Macedonia, the Former Yugoslav Republic of</option>
                                                                <option value="Madagascar">Madagascar</option>
                                                                <option value="Malawi">Malawi</option>
                                                                <option value="Malaysia">Malaysia</option>
                                                                <option value="Maldives">Maldives</option>
                                                                <option value="Mali">Mali</option>
                                                                <option value="Malta">Malta</option>
                                                                <option value="Marshall Islands">Marshall Islands
                                                                </option>
                                                                <option value="Martinique">Martinique</option>
                                                                <option value="Mauritania">Mauritania</option>
                                                                <option value="Mauritius">Mauritius</option>
                                                                <option value="Mayotte">Mayotte</option>
                                                                <option value="Mexico">Mexico</option>
                                                                <option value="Micronesia, Federated States of">
                                                                    Micronesia, Federated States of</option>
                                                                <option value="Moldova, Republic of">Moldova, Republic
                                                                    of</option>
                                                                <option value="Monaco">Monaco</option>
                                                                <option value="Mongolia">Mongolia</option>
                                                                <option value="Montenegro">Montenegro</option>
                                                                <option value="Montserrat">Montserrat</option>
                                                                <option value="Morocco">Morocco</option>
                                                                <option value="Mozambique">Mozambique</option>
                                                                <option value="Myanmar">Myanmar</option>
                                                                <option value="Namibia">Namibia</option>
                                                                <option value="Nauru">Nauru</option>
                                                                <option value="Nepal">Nepal</option>
                                                                <option value="Netherlands">Netherlands</option>
                                                                <option value="New Caledonia">New Caledonia</option>
                                                                <option value="New Zealand">New Zealand</option>
                                                                <option value="Nicaragua">Nicaragua</option>
                                                                <option value="Niger">Niger</option>
                                                                <option value="Nigeria">Nigeria</option>
                                                                <option value="Niue">Niue</option>
                                                                <option value="Norfolk Island">Norfolk Island</option>
                                                                <option value="Northern Mariana Islands">Northern
                                                                    Mariana Islands</option>
                                                                <option value="Norway">Norway</option>
                                                                <option value="Oman">Oman</option>
                                                                <option value="Pakistan">Pakistan</option>
                                                                <option value="Palau">Palau</option>
                                                                <option value="Palestine, State of">Palestine, State of
                                                                </option>
                                                                <option value="Panama">Panama</option>
                                                                <option value="Papua New Guinea">Papua New Guinea
                                                                </option>
                                                                <option value="Paraguay">Paraguay</option>
                                                                <option value="Peru">Peru</option>
                                                                <option value="Philippines">Philippines</option>
                                                                <option value="Pitcairn">Pitcairn</option>
                                                                <option value="Poland">Poland</option>
                                                                <option value="Portugal">Portugal</option>
                                                                <option value="Puerto Rico">Puerto Rico</option>
                                                                <option value="Qatar">Qatar</option>
                                                                <option value="Réunion">Réunion</option>
                                                                <option value="Romania">Romania</option>
                                                                <option value="Russian Federation">Russian Federation
                                                                </option>
                                                                <option value="Rwanda">Rwanda</option>
                                                                <option value="Saint Barthélemy">Saint Barthélemy
                                                                </option>
                                                                <option
                                                                    value="Saint Helena, Ascension and Tristan da Cunha">
                                                                    Saint Helena, Ascension and Tristan da Cunha
                                                                </option>
                                                                <option value="Saint Kitts and Nevis">Saint Kitts and
                                                                    Nevis</option>
                                                                <option value="Saint Lucia">Saint Lucia</option>
                                                                <option value="Saint Martin (French part)">Saint Martin
                                                                    (French part)</option>
                                                                <option value="Saint Pierre and Miquelon">Saint Pierre
                                                                    and Miquelon</option>
                                                                <option value="Saint Vincent and the Grenadines">Saint
                                                                    Vincent and the Grenadines</option>
                                                                <option value="Samoa">Samoa</option>
                                                                <option value="San Marino">San Marino</option>
                                                                <option value="Sao Tome and Principe">Sao Tome and
                                                                    Principe</option>
                                                                <option value="Saudi Arabia">Saudi Arabia</option>
                                                                <option value="Senegal">Senegal</option>
                                                                <option value="Serbia">Serbia</option>
                                                                <option value="Seychelles">Seychelles</option>
                                                                <option value="Sierra Leone">Sierra Leone</option>
                                                                <option value="Singapore">Singapore</option>
                                                                <option value="Sint Maarten (Dutch part)">Sint Maarten
                                                                    (Dutch part)</option>
                                                                <option value="Slovakia">Slovakia</option>
                                                                <option value="Slovenia">Slovenia</option>
                                                                <option value="Solomon Islands">Solomon Islands</option>
                                                                <option value="Somalia">Somalia</option>
                                                                <option value="South Africa">South Africa</option>
                                                                <option
                                                                    value="South Georgia and the South Sandwich Islands">
                                                                    South Georgia and the South Sandwich Islands
                                                                </option>
                                                                <option value="South Sudan">South Sudan</option>
                                                                <option value="Spain">Spain</option>
                                                                <option value="Sri Lanka">Sri Lanka</option>
                                                                <option value="Sudan">Sudan</option>
                                                                <option value="Suriname">Suriname</option>
                                                                <option value="Svalbard and Jan Mayen">Svalbard and Jan
                                                                    Mayen</option>
                                                                <option value="Swaziland">Swaziland</option>
                                                                <option value="Sweden">Sweden</option>
                                                                <option value="Switzerland">Switzerland</option>
                                                                <option value="Syrian Arab Republic">Syrian Arab
                                                                    Republic</option>
                                                                <option value="Taiwan, Province of China">Taiwan,
                                                                    Province of China</option>
                                                                <option value="Tajikistan">Tajikistan</option>
                                                                <option value="Tanzania, United Republic of">Tanzania,
                                                                    United Republic of</option>
                                                                <option value="Thailand">Thailand</option>
                                                                <option value="Timor-Leste">Timor-Leste</option>
                                                                <option value="Togo">Togo</option>
                                                                <option value="Tokelau">Tokelau</option>
                                                                <option value="Tonga">Tonga</option>
                                                                <option value="Trinidad and Tobago">Trinidad and Tobago
                                                                </option>
                                                                <option value="Tunisia">Tunisia</option>
                                                                <option value="Turkey">Turkey</option>
                                                                <option value="Turkmenistan">Turkmenistan</option>
                                                                <option value="Turks and Caicos Islands">Turks and
                                                                    Caicos Islands</option>
                                                                <option value="Tuvalu">Tuvalu</option>
                                                                <option value="Uganda">Uganda</option>
                                                                <option value="Ukraine">Ukraine</option>
                                                                <option value="United Arab Emirates">United Arab
                                                                    Emirates</option>
                                                                <option value="United Kingdom">United Kingdom</option>
                                                                <option value="United States">United States</option>
                                                                <option value="United States Minor Outlying Islands">
                                                                    United States Minor Outlying Islands</option>
                                                                <option value="Uruguay">Uruguay</option>
                                                                <option value="Uzbekistan">Uzbekistan</option>
                                                                <option value="Vanuatu">Vanuatu</option>
                                                                <option value="Venezuela, Bolivarian Republic of">
                                                                    Venezuela, Bolivarian Republic of</option>
                                                                <option value="Viet Nam">Viet Nam</option>
                                                                <option value="Virgin Islands, British">Virgin Islands,
                                                                    British</option>
                                                                <option value="Virgin Islands, U.S">Virgin Islands, U.S.
                                                                </option>
                                                                <option value="Wallis and Futuna">Wallis and Futuna
                                                                </option>
                                                                <option value="Western Sahara">Western Sahara</option>
                                                                <option value="Yemen">Yemen</option>
                                                                <option value="Zambia">Zambia</option>
                                                                <option value="Zimbabwe">Zimbabwe</option>
                                                            </select>
                                                        </float-label>
                                                        <span class="text-danger fs-13"
                                                            v-show="errors.has('generalDetails.country') && ifGeneralDetailsSubmitted">Country
                                                            Name is Required</span>
                                                    </div>
                                                </div>
                                                <div class="pt-4">
                                                    <h6 class="heading-details pl-5" id="copy_address_short">
                                                        Short Form Address
                                                        <span style="padding: 0 20px;color: #303031!important;">|</span>
                                                        <el-switch id="copy_address" v-model="cop_address_model"
                                                            @change="copy_address_long_to_short()"
                                                            inactive-text="Copy from Long Form Address"></el-switch>
                                                    </h6>
                                                    <div class="pl-1 pt-4">
                                                        <div class="col-md-12 mt-1">
                                                            <div class="group-setup">
                                                                <input id="short_address_address1" class="digi-form"
                                                                    type="text" name="shortstreet"
                                                                    v-validate="'required'"
                                                                    data-vv-scope="generalDetails"
                                                                    v-model="generalDetails.short_address.street"
                                                                    required :maxlength="editstreetshort" />
                                                                <span class="highlight digi-highlight"></span>
                                                                <span class="bar digi-bar"></span>
                                                                <label class="digi-label-outlet">
                                                                    Address 1
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="text-danger fs-13"
                                                                    v-show="errors.has('generalDetails.shortstreet') && ifGeneralDetailsSubmitted">Address
                                                                    1 Name is Required</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{editstreetshort - generalDetails.short_address.street.length}}
                                                                    / {{editstreetshort}}</p>
                                                            </div>
                                                        </div>

                                                        <div class="form-group form-bottom mt-4">
                                                            <div class="d-flex flex-wrap pb-2">
                                                                <div class="col-lg-6 col-md-6 col-sm-12">
                                                                    <div class="group-setup">
                                                                        <input id="short_address_city" class="digi-form"
                                                                            type="text" name="shortcity"
                                                                            v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                                                                            data-vv-scope="generalDetails"
                                                                            v-model="generalDetails.short_address.city"
                                                                            required :maxlength="editcityshort" />
                                                                        <span class="highlight digi-highlight"></span>
                                                                        <span class="bar digi-bar"></span>
                                                                        <label class="digi-label-outlet">
                                                                            City
                                                                            <sup>★</sup>
                                                                        </label>
                                                                        <span class="text-danger fs-13"
                                                                            v-show="errors.has('generalDetails.shortcity') && ifGeneralDetailsSubmitted">{{errors.first('generalDetails.shortcity')}}</span>
                                                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                            style="margin-bottom: 0px!important;">
                                                                            {{editcityshort - generalDetails.short_address.city.length}}
                                                                            / {{editcityshort}}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 col-md-6 col-sm-12">
                                                                    <div class="group-setup">
                                                                        <input class="digi-form" type="text"
                                                                            name="shortstate"
                                                                            v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                                                                            data-vv-scope="generalDetails"
                                                                            v-model="generalDetails.short_address.state"
                                                                            required :maxlength="editstateshort" />
                                                                        <span class="highlight digi-highlight"></span>
                                                                        <span class="bar digi-bar"></span>
                                                                        <label class="digi-label-outlet">
                                                                            State
                                                                            <sup>★</sup>
                                                                        </label>
                                                                        <span class="text-danger fs-13"
                                                                            v-show="errors.has('generalDetails.shortstate') && ifGeneralDetailsSubmitted">{{errors.first('generalDetails.shortstate')}}</span>
                                                                        <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                            style="margin-bottom: 0px!important;">
                                                                            {{editstateshort - generalDetails.short_address.state.length}}
                                                                            / {{editstateshort}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group form-top d-flex flex-wrap">
                                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                                <div class="group-setup">
                                                                    <input class="digi-form" type="text"
                                                                        name="shortzipcode"
                                                                        v-validate="'required|numeric'"
                                                                        data-vv-scope="generalDetails"
                                                                        v-model="generalDetails.short_address.zipcode"
                                                                        required :maxlength="editzipcodeshort" />
                                                                    <span class="highlight digi-highlight"></span>
                                                                    <span class="bar digi-bar"></span>
                                                                    <label class="digi-label-outlet">
                                                                        Zip Code
                                                                        <sup>★</sup>
                                                                    </label>
                                                                    <span class="text-danger fs-13"
                                                                        v-show="errors.has('generalDetails.shortzipcode') && ifGeneralDetailsSubmitted">{{errors.first('generalDetails.shortzipcode')}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6 col-sm-12 handlecountry">
                                                                <float-label fixed label="Select Country*">
                                                                    <select class="form-control" name="country"
                                                                        v-model="generalDetails.short_address.country"
                                                                        data-vv-scope="generalDetails"
                                                                        placeholder="Select Country"
                                                                        style="max-width: 100% !important;">
                                                                        <option hidden value selected>Select Country
                                                                        </option>
                                                                        <option value="Afghanistan">Afghanistan</option>
                                                                        <option value="Åland Island">Åland Islands
                                                                        </option>
                                                                        <option value="Albania">Albania</option>
                                                                        <option value="Algeria">Algeria</option>
                                                                        <option value="American Samoa">American Samoa
                                                                        </option>
                                                                        <option value="Andorra">Andorra</option>
                                                                        <option value="Angola">Angola</option>
                                                                        <option value="Anguilla">Anguilla</option>
                                                                        <option value="Antarctica">Antarctica</option>
                                                                        <option value="Antigua and Barbuda">Antigua and
                                                                            Barbuda</option>
                                                                        <option value="Argentina">Argentina</option>
                                                                        <option value="Armenia">Armenia</option>
                                                                        <option value="Aruba">Aruba</option>
                                                                        <option value="Australia">Australia</option>
                                                                        <option value="Austria">Austria</option>
                                                                        <option value="Azerbaijan">Azerbaijan</option>
                                                                        <option value="Bahamas">Bahamas</option>
                                                                        <option value="Bahrain">Bahrain</option>
                                                                        <option value="Bangladesh">Bangladesh</option>
                                                                        <option value="Barbados">Barbados</option>
                                                                        <option value="Belarus">Belarus</option>
                                                                        <option value="Belgium">Belgium</option>
                                                                        <option value="Belize">Belize</option>
                                                                        <option value="Benin">Benin</option>
                                                                        <option value="Bermuda">Bermuda</option>
                                                                        <option value="Bhutan">Bhutan</option>
                                                                        <option value="Bolivia, Plurinational State of">
                                                                            Bolivia, Plurinational State of</option>
                                                                        <option
                                                                            value="Bonaire, Sint Eustatius and Saba">
                                                                            Bonaire, Sint Eustatius and Saba</option>
                                                                        <option value="Bosnia and Herzegovina">Bosnia
                                                                            and Herzegovina</option>
                                                                        <option value="Botswana">Botswana</option>
                                                                        <option value="Bouvet Island">Bouvet Island
                                                                        </option>
                                                                        <option value="Brazil">Brazil</option>
                                                                        <option value="British Indian Ocean Territory">
                                                                            British Indian Ocean Territory</option>
                                                                        <option value="Brunei Darussalam">Brunei
                                                                            Darussalam</option>
                                                                        <option value="Bulgaria">Bulgaria</option>
                                                                        <option value="Burkina Faso">Burkina Faso
                                                                        </option>
                                                                        <option value="Burundi">Burundi</option>
                                                                        <option value="Cambodia">Cambodia</option>
                                                                        <option value="Cameroon">Cameroon</option>
                                                                        <option value="Canada">Canada</option>
                                                                        <option value="Cape Verde">Cape Verde</option>
                                                                        <option value="Cayman Islands">Cayman Islands
                                                                        </option>
                                                                        <option value="Central African Republic">Central
                                                                            African Republic</option>
                                                                        <option value="Chad">Chad</option>
                                                                        <option value="Chile">Chile</option>
                                                                        <option value="China">China</option>
                                                                        <option value="Christmas Island">Christmas
                                                                            Island</option>
                                                                        <option value="Cocos (Keeling) Islands">Cocos
                                                                            (Keeling) Islands</option>
                                                                        <option value="Colombia">Colombia</option>
                                                                        <option value="Comoros">Comoros</option>
                                                                        <option value="Congo">Congo</option>
                                                                        <option
                                                                            value="Congo, the Democratic Republic of the">
                                                                            Congo, the Democratic Republic of the
                                                                        </option>
                                                                        <option value="Cook Islands">Cook Islands
                                                                        </option>
                                                                        <option value="Costa Rica">Costa Rica</option>
                                                                        <option value="Côte d'Ivoire">Côte d'Ivoire
                                                                        </option>
                                                                        <option value="Croatia">Croatia</option>
                                                                        <option value="Cuba">Cuba</option>
                                                                        <option value="Curaçao">Curaçao</option>
                                                                        <option value="Cyprus">Cyprus</option>
                                                                        <option value="Czech Republic">Czech Republic
                                                                        </option>
                                                                        <option value="Denmark">Denmark</option>
                                                                        <option value="Djibouti">Djibouti</option>
                                                                        <option value="Dominica">Dominica</option>
                                                                        <option value="Dominican Republic">Dominican
                                                                            Republic</option>
                                                                        <option value="Ecuador">Ecuador</option>
                                                                        <option value="Egypt">Egypt</option>
                                                                        <option value="El Salvador">El Salvador</option>
                                                                        <option value="Equatorial Guinea">Equatorial
                                                                            Guinea</option>
                                                                        <option value="Eritrea">Eritrea</option>
                                                                        <option value="Estonia">Estonia</option>
                                                                        <option value="Ethiopia">Ethiopia</option>
                                                                        <option value="Falkland Islands (Malvinas)">
                                                                            Falkland Islands (Malvinas)</option>
                                                                        <option value="Faroe Islands">Faroe Islands
                                                                        </option>
                                                                        <option value="Fiji">Fiji</option>
                                                                        <option value="Finland">Finland</option>
                                                                        <option value="France">France</option>
                                                                        <option value="French Guiana">French Guiana
                                                                        </option>
                                                                        <option value="French Polynesia">French
                                                                            Polynesia</option>
                                                                        <option value="French Southern Territories">
                                                                            French Southern Territories</option>
                                                                        <option value="Gabon">Gabon</option>
                                                                        <option value="Gambia">Gambia</option>
                                                                        <option value="Georgia">Georgia</option>
                                                                        <option value="Germany">Germany</option>
                                                                        <option value="Ghana">Ghana</option>
                                                                        <option value="Gibraltar">Gibraltar</option>
                                                                        <option value="Greece">Greece</option>
                                                                        <option value="Greenland">Greenland</option>
                                                                        <option value="Grenada">Grenada</option>
                                                                        <option value="Guadeloupe">Guadeloupe</option>
                                                                        <option value="Guam">Guam</option>
                                                                        <option value="Guatemala">Guatemala</option>
                                                                        <option value="Guernsey">Guernsey</option>
                                                                        <option value="Guinea">Guinea</option>
                                                                        <option value="Guinea-Bissau">Guinea-Bissau
                                                                        </option>
                                                                        <option value="Guyana">Guyana</option>
                                                                        <option value="Haiti">Haiti</option>
                                                                        <option
                                                                            value="Heard Island and McDonald Islands">
                                                                            Heard Island and McDonald Islands</option>
                                                                        <option value="Holy See (Vatican City State)">
                                                                            Holy See (Vatican City State)</option>
                                                                        <option value="Honduras">Honduras</option>
                                                                        <option value="Hong Kong">Hong Kong</option>
                                                                        <option value="Hungary">Hungary</option>
                                                                        <option value="Iceland">Iceland</option>
                                                                        <option value="India">India</option>
                                                                        <option value="Indonesia">Indonesia</option>
                                                                        <option value="Iran, Islamic Republic of">Iran,
                                                                            Islamic Republic of</option>
                                                                        <option value="Iraq">Iraq</option>
                                                                        <option value="Ireland">Ireland</option>
                                                                        <option value="Isle of Man">Isle of Man</option>
                                                                        <option value="Israel">Israel</option>
                                                                        <option value="Italy">Italy</option>
                                                                        <option value="Jamaica">Jamaica</option>
                                                                        <option value="Japan">Japan</option>
                                                                        <option value="Jersey">Jersey</option>
                                                                        <option value="Jordan">Jordan</option>
                                                                        <option value="Kazakhstan">Kazakhstan</option>
                                                                        <option value="Kenya">Kenya</option>
                                                                        <option value="Kiribati">Kiribati</option>
                                                                        <option
                                                                            value="Korea, Democratic People's Republic of">
                                                                            Korea, Democratic People's Republic of
                                                                        </option>
                                                                        <option value="Korea, Republic of">Korea,
                                                                            Republic of</option>
                                                                        <option value="Kuwait">Kuwait</option>
                                                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                                        <option
                                                                            value="Lao People's Democratic Republic">Lao
                                                                            People's Democratic Republic</option>
                                                                        <option value="Latvia">Latvia</option>
                                                                        <option value="Lebanon">Lebanon</option>
                                                                        <option value="Lesotho">Lesotho</option>
                                                                        <option value="Liberia">Liberia</option>
                                                                        <option value="Libya">Libya</option>
                                                                        <option value="Liechtenstein">Liechtenstein
                                                                        </option>
                                                                        <option value="Lithuania">Lithuania</option>
                                                                        <option value="Luxembourg">Luxembourg</option>
                                                                        <option value="Macao">Macao</option>
                                                                        <option
                                                                            value="Macedonia, the Former Yugoslav Republic of">
                                                                            Macedonia, the Former Yugoslav Republic of
                                                                        </option>
                                                                        <option value="Madagascar">Madagascar</option>
                                                                        <option value="Malawi">Malawi</option>
                                                                        <option value="Malaysia">Malaysia</option>
                                                                        <option value="Maldives">Maldives</option>
                                                                        <option value="Mali">Mali</option>
                                                                        <option value="Malta">Malta</option>
                                                                        <option value="Marshall Islands">Marshall
                                                                            Islands</option>
                                                                        <option value="Martinique">Martinique</option>
                                                                        <option value="Mauritania">Mauritania</option>
                                                                        <option value="Mauritius">Mauritius</option>
                                                                        <option value="Mayotte">Mayotte</option>
                                                                        <option value="Mexico">Mexico</option>
                                                                        <option value="Micronesia, Federated States of">
                                                                            Micronesia, Federated States of</option>
                                                                        <option value="Moldova, Republic of">Moldova,
                                                                            Republic of</option>
                                                                        <option value="Monaco">Monaco</option>
                                                                        <option value="Mongolia">Mongolia</option>
                                                                        <option value="Montenegro">Montenegro</option>
                                                                        <option value="Montserrat">Montserrat</option>
                                                                        <option value="Morocco">Morocco</option>
                                                                        <option value="Mozambique">Mozambique</option>
                                                                        <option value="Myanmar">Myanmar</option>
                                                                        <option value="Namibia">Namibia</option>
                                                                        <option value="Nauru">Nauru</option>
                                                                        <option value="Nepal">Nepal</option>
                                                                        <option value="Netherlands">Netherlands</option>
                                                                        <option value="New Caledonia">New Caledonia
                                                                        </option>
                                                                        <option value="New Zealand">New Zealand</option>
                                                                        <option value="Nicaragua">Nicaragua</option>
                                                                        <option value="Niger">Niger</option>
                                                                        <option value="Nigeria">Nigeria</option>
                                                                        <option value="Niue">Niue</option>
                                                                        <option value="Norfolk Island">Norfolk Island
                                                                        </option>
                                                                        <option value="Northern Mariana Islands">
                                                                            Northern Mariana Islands</option>
                                                                        <option value="Norway">Norway</option>
                                                                        <option value="Oman">Oman</option>
                                                                        <option value="Pakistan">Pakistan</option>
                                                                        <option value="Palau">Palau</option>
                                                                        <option value="Palestine, State of">Palestine,
                                                                            State of</option>
                                                                        <option value="Panama">Panama</option>
                                                                        <option value="Papua New Guinea">Papua New
                                                                            Guinea</option>
                                                                        <option value="Paraguay">Paraguay</option>
                                                                        <option value="Peru">Peru</option>
                                                                        <option value="Philippines">Philippines</option>
                                                                        <option value="Pitcairn">Pitcairn</option>
                                                                        <option value="Poland">Poland</option>
                                                                        <option value="Portugal">Portugal</option>
                                                                        <option value="Puerto Rico">Puerto Rico</option>
                                                                        <option value="Qatar">Qatar</option>
                                                                        <option value="Réunion">Réunion</option>
                                                                        <option value="Romania">Romania</option>
                                                                        <option value="Russian Federation">Russian
                                                                            Federation</option>
                                                                        <option value="Rwanda">Rwanda</option>
                                                                        <option value="Saint Barthélemy">Saint
                                                                            Barthélemy</option>
                                                                        <option
                                                                            value="Saint Helena, Ascension and Tristan da Cunha">
                                                                            Saint Helena, Ascension and Tristan da Cunha
                                                                        </option>
                                                                        <option value="Saint Kitts and Nevis">Saint
                                                                            Kitts and Nevis</option>
                                                                        <option value="Saint Lucia">Saint Lucia</option>
                                                                        <option value="Saint Martin (French part)">Saint
                                                                            Martin (French part)</option>
                                                                        <option value="Saint Pierre and Miquelon">Saint
                                                                            Pierre and Miquelon</option>
                                                                        <option
                                                                            value="Saint Vincent and the Grenadines">
                                                                            Saint Vincent and the Grenadines</option>
                                                                        <option value="Samoa">Samoa</option>
                                                                        <option value="San Marino">San Marino</option>
                                                                        <option value="Sao Tome and Principe">Sao Tome
                                                                            and Principe</option>
                                                                        <option value="Saudi Arabia">Saudi Arabia
                                                                        </option>
                                                                        <option value="Senegal">Senegal</option>
                                                                        <option value="Serbia">Serbia</option>
                                                                        <option value="Seychelles">Seychelles</option>
                                                                        <option value="Sierra Leone">Sierra Leone
                                                                        </option>
                                                                        <option value="Singapore">Singapore</option>
                                                                        <option value="Sint Maarten (Dutch part)">Sint
                                                                            Maarten (Dutch part)</option>
                                                                        <option value="Slovakia">Slovakia</option>
                                                                        <option value="Slovenia">Slovenia</option>
                                                                        <option value="Solomon Islands">Solomon Islands
                                                                        </option>
                                                                        <option value="Somalia">Somalia</option>
                                                                        <option value="South Africa">South Africa
                                                                        </option>
                                                                        <option
                                                                            value="South Georgia and the South Sandwich Islands">
                                                                            South Georgia and the South Sandwich Islands
                                                                        </option>
                                                                        <option value="South Sudan">South Sudan</option>
                                                                        <option value="Spain">Spain</option>
                                                                        <option value="Sri Lanka">Sri Lanka</option>
                                                                        <option value="Sudan">Sudan</option>
                                                                        <option value="Suriname">Suriname</option>
                                                                        <option value="Svalbard and Jan Mayen">Svalbard
                                                                            and Jan Mayen</option>
                                                                        <option value="Swaziland">Swaziland</option>
                                                                        <option value="Sweden">Sweden</option>
                                                                        <option value="Switzerland">Switzerland</option>
                                                                        <option value="Syrian Arab Republic">Syrian Arab
                                                                            Republic</option>
                                                                        <option value="Taiwan, Province of China">
                                                                            Taiwan, Province of China</option>
                                                                        <option value="Tajikistan">Tajikistan</option>
                                                                        <option value="Tanzania, United Republic of">
                                                                            Tanzania, United Republic of</option>
                                                                        <option value="Thailand">Thailand</option>
                                                                        <option value="Timor-Leste">Timor-Leste</option>
                                                                        <option value="Togo">Togo</option>
                                                                        <option value="Tokelau">Tokelau</option>
                                                                        <option value="Tonga">Tonga</option>
                                                                        <option value="Trinidad and Tobago">Trinidad and
                                                                            Tobago</option>
                                                                        <option value="Tunisia">Tunisia</option>
                                                                        <option value="Turkey">Turkey</option>
                                                                        <option value="Turkmenistan">Turkmenistan
                                                                        </option>
                                                                        <option value="Turks and Caicos Islands">Turks
                                                                            and Caicos Islands</option>
                                                                        <option value="Tuvalu">Tuvalu</option>
                                                                        <option value="Uganda">Uganda</option>
                                                                        <option value="Ukraine">Ukraine</option>
                                                                        <option value="United Arab Emirates">United Arab
                                                                            Emirates</option>
                                                                        <option value="United Kingdom">United Kingdom
                                                                        </option>
                                                                        <option value="United States">United States
                                                                        </option>
                                                                        <option
                                                                            value="United States Minor Outlying Islands">
                                                                            United States Minor Outlying Islands
                                                                        </option>
                                                                        <option value="Uruguay">Uruguay</option>
                                                                        <option value="Uzbekistan">Uzbekistan</option>
                                                                        <option value="Vanuatu">Vanuatu</option>
                                                                        <option
                                                                            value="Venezuela, Bolivarian Republic of">
                                                                            Venezuela, Bolivarian Republic of</option>
                                                                        <option value="Viet Nam">Viet Nam</option>
                                                                        <option value="Virgin Islands, British">Virgin
                                                                            Islands, British</option>
                                                                        <option value="Virgin Islands, U.S">Virgin
                                                                            Islands, U.S.</option>
                                                                        <option value="Wallis and Futuna">Wallis and
                                                                            Futuna</option>
                                                                        <option value="Western Sahara">Western Sahara
                                                                        </option>
                                                                        <option value="Yemen">Yemen</option>
                                                                        <option value="Zambia">Zambia</option>
                                                                        <option value="Zimbabwe">Zimbabwe</option>
                                                                    </select>
                                                                </float-label>
                                                                <span class="text-danger fs-13"
                                                                    v-show="errors.has('generalDetails.country') && ifGeneralDetailsSubmitted">Country
                                                                    Name is Required</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="pb-5 text-center pt-5">
                                                    <button class="btn btn-link btn-smm"
                                                        style="text-transform: uppercase !important;font-size: 12px !important;border: 1px solid #00448b !important;"
                                                        @click="setGeneralDetails()" :disabled="settings_save_api_call">RESET</button>
                                                    <button type="submit" class="btn btn-secondary btn-smm btn-1 ml-5"
                                                        @click="saveEditedOutlet('generalDetails', outlet, generalDetails)" :disabled="settings_save_api_call">SAVE
                                                            <half-circle-spinner :animation-duration="1000" :size="20" v-if="settings_save_api_call" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="alertSettings" class="m-5">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <h6 style="color:#fff!important;letter-spacing: 0.3px;font-weight: bold;">
                                                Alert Settings</h6>
                                        </div>
                                    </div>
                                    <div class="d-flex mt-3 res-settings flex-wrap">
                                        <div class="col-lg-12 col-md-12 col-sm-12 send-report-div">
                                            <div class="form-group contactGroup">
                                                <label class="fs-14" style="color:#00448b;">Distribution Email List
                                                    :</label>
                                                <vue-tags-input v-model="report_email" name="tags" id="tags"
                                                    :tags="otherDetails.report_emails"
                                                    @tags-changed="alertEmailsChanges"
                                                    placeholder="Add Email & Press Enter" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pb-5 text-center mt-10">
                                        <button class="btn btn-link btn-smm" :disabled="settings_save_api_call" @click="setOtherDetails()"
                                            style="text-transform: uppercase !important;font-size: 12px !important;color:#fff!important;border:1px solid #fff!important;">RESET</button>
                                        <button style="background-color:#fff!important;color:#00448b!important;"
                                            type="submit" @click="saveOtherDetailsOutlet(outlet, otherDetails)" :disabled="settings_save_api_call"
                                            class="btn btn-secondary btn-smm btn-2 ml-5">SAVE
                                                <half-circle-spinner :animation-duration="1000" :size="20" v-if="settings_save_api_call" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                            </button>
                                    </div>
                                </div>
                                <div v-if="approvalPermission" class="pt-5">
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <span class="pl-4 font-weight-bold text-white"
                                                style="font-size:16px;">Waiter Access</span>
                                            <el-switch class="ml-2 approvalswitch" v-model="waiter_access" @change="toggleAccess"></el-switch>
                                        </div>
                                        <div class="d-flex mr-4">
                                            <span class="pl-4 font-weight-bold text-white"
                                                style="font-size:16px;">Approval Permission For Waiters</span>
                                            <el-switch class="ml-2 approvalswitch" :disabled="waiter_access == false" v-model="waiter_approval_permission" @change="toggleApproval"></el-switch>
                                        </div>
                                    </div>
                                    <div class="col-md-12" style="padding-top: 8px;">
                                        <div class="table-row table-row-new">
                                            <table class="table table-cash-outlet">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center" style="border-right: 1px solid #a8adb4;">Permissions</th>
                                                        <!-- <th class="text-center">Add Discount</th> -->
                                                        <th class="text-center" style="border-right: 1px solid #a8adb4;">Discount</th>
                                                        <!-- <th class="hidden-sm-down text-center">Delete Order</th> -->
                                                        <!-- <th class=" text-center">Remove Discount</th> -->
                                                        <th class="text-center">Remove Item</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="ajaxCallInProgress">
                                                    <tr>
                                                        <td colspan="4" class="text-center justify-content-center">
                                                            <div class="digi-table-loader">
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                                <div></div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-if="waiters_list.length!==0 && !ajaxCallInProgress">
                                                    <tr>
                                                        <td style="padding: 0 !important; border-right: 1px solid #a8adb4;" class="text-center">
                                                            <table class="table table-cash-outlet">
                                                                <tbody>
                                                                    <tr >
                                                                        <td style="padding: 0.6rem 1.2rem!important;" class="text-center">
                                                                        </td>
                                                                        <td style="padding: 0.6rem 1.2rem!important;" class="text-center">
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td style="padding: 0 !important; border-right: 1px solid #a8adb4;" class="text-center">
                                                            <table class="table table-cash-outlet">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style="padding: 0.6rem 1.2rem!important; color: #00448b;" class="text-center">
                                                                            Access
                                                                        </td>
                                                                        <td style="padding: 0.6rem 1.2rem!important; color: #00448b;" class="text-center">
                                                                            Approval
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            
                                                            <!-- <el-checkbox class="checkbox-inverse mt-2" v-model="ap.approval_permissions.change_discount" :checked="ap.approval_permissions.change_discount == true" @change="updatePermission($event, ap, 'change_discount')"></el-checkbox> -->
                                                        </td>
                                                        <td style="padding: 0 !important;" class="text-center">
                                                            <table class="table table-cash-outlet">
                                                                <tbody>
                                                                    <tr >
                                                                        <td style="padding: 0.6rem 1.2rem!important; color: #00448b;" class="text-center">
                                                                            Access
                                                                        </td>
                                                                        <td style="padding: 0.6rem 1.2rem!important; color: #00448b;" class="text-center">
                                                                            Approval
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <!-- <el-checkbox class="checkbox-inverse mt-2" v-model="ap.approval_permissions.remove_item" :checked="ap.approval_permissions.remove_item == true" @change="updatePermission($event, ap, 'remove_item')"></el-checkbox> -->
                                                        </td>
                                                    </tr>
                                                    <tr v-for="(ap, i) in waiters_list" :key="i">
                                                        <td style="padding: 0 !important; border-right: 1px solid #a8adb4;" class="text-center">
                                                            <table class="table table-cash-outlet">
                                                                <tbody>
                                                                    <tr >
                                                                        <td style="padding: 0.6rem 1.2rem!important;" class="text-center">
                                                                            {{ap.full_name}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td style="padding: 0 !important; border-right: 1px solid #a8adb4;" class="text-center">
                                                            <table class="table table-cash-outlet">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style="padding: 0.6rem 1.2rem!important;" class="text-center">
                                                                            <el-checkbox v-model="ap.approval_permissions.access_change_discount" :class="waiter_access == false ? 'disable-check' : ''"  class="checkbox-inverse mt-2" ></el-checkbox>
                                                                            <!-- @change="ChangeApprovalAccess('access_change_discount',ap.user_id, ap.approval_permissions.access_change_discount)" -->
                                                                        </td>
                                                                        <td style="padding: 0.6rem 1.2rem!important;" class="text-center">
                                                                            <el-checkbox v-model="ap.approval_permissions.change_discount" :class="waiter_approval_permission == false ? 'disable-check' : ''" class="checkbox-inverse mt-2" ></el-checkbox>
                                                                            <!-- @change="ChangeApprovalAccess('change_discount',ap.user_id, ap.approval_permissions.change_discount)"  -->
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            
                                                            <!-- <el-checkbox class="checkbox-inverse mt-2" v-model="ap.approval_permissions.change_discount" :checked="ap.approval_permissions.change_discount == true" @change="updatePermission($event, ap, 'change_discount')"></el-checkbox> -->
                                                        </td>
                                                        <td style="padding: 0 !important;" class="text-center">
                                                            <table class="table table-cash-outlet">
                                                                <tbody>
                                                                    <tr >
                                                                        <td style="padding: 0.6rem 1.2rem!important;" class="text-center">
                                                                            <el-checkbox v-model="ap.approval_permissions.access_remove_item" :class="waiter_access == false ? 'disable-check' : ''"  class="checkbox-inverse mt-2" ></el-checkbox>
                                                                            <!-- @change="ChangeApprovalAccess('access_remove_item',ap.user_id, ap.approval_permissions.access_remove_item)" -->
                                                                        </td>
                                                                        <td style="padding: 0.6rem 1.2rem!important;" class="text-center">
                                                                            <el-checkbox v-model="ap.approval_permissions.remove_item" :class="waiter_approval_permission == false ? 'disable-check' : ''"   class="checkbox-inverse mt-2" ></el-checkbox>
                                                                            <!-- @change="ChangeApprovalAccess('remove_item',ap.user_id, ap.approval_permissions.remove_item)" -->
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <!-- <el-checkbox class="checkbox-inverse mt-2" v-model="ap.approval_permissions.remove_item" :checked="ap.approval_permissions.remove_item == true" @change="updatePermission($event, ap, 'remove_item')"></el-checkbox> -->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-if="!ajaxCallInProgress && waiters_list.length == 0">
                                                    <tr>
                                                        <td style="padding: 0.6rem 1.2rem!important;font-weight:600;" colspan="7"
                                                            class="text-center">No Waiters Added</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="list-title mr-4 text-center mt-8" style="font-weight: 500;">
                                        <button class="btn btn-secondary btn-smm btnWhite" @click="selectPermissions" :disabled="waiter_permission_api_call">Save
                                            <half-circle-spinner :animation-duration="1000" :size="20" v-if="waiter_permission_api_call" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                        </button>
                                    </div>
                                </div>
                                <div v-if="generatePin" class="pt-5">
                                    <div v-if="pin_config == false">
                                        <div class="d-flex justify-content-between">
                                            <div class="d-flex">
                                                <span class="pl-4 font-weight-bold text-white hidden-xs-down"
                                                    style="font-size:16px;">Generate User Pin</span>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-center align-items-center" style="padding-top: 80px">
                                            <button class="btn btn-success text-white" @click="generateAllUserPins()" :disabled="generate_user_pin_api_inprogress">Generate pin for all users
                                                <half-circle-spinner :animation-duration="1000" :size="20" v-if="generate_user_pin_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                            </button>
                                        </div>
                                    </div>
                                    <div v-if="pin_config == true" class="">
                                        <div class="d-flex justify-content-between">
                                            <div class="d-flex">
                                                <span class="pl-4 font-weight-bold text-white hidden-xs-down"
                                                    style="font-size:16px;">User login via Pin</span>
                                                <el-switch @change="enablePinConfig()" :checked="pin_config == true" v-model="pin_config" class="ml-2 approvalswitch"></el-switch>
                                            </div>
                                        </div>
                                        <div class="col-md-12" style="padding-top: 8px;">
                                            <div class="table-row" id="lazy-load-approval2" @scroll="scrollEnd">
                                                <table class="table table-cash-outlet">
                                                    <thead>
                                                        <tr>
                                                            <th class="text-center w-25">User Name</th>
                                                            <th class="w-25 text-center">Pin</th>
                                                            <th class="w-25 text-center"></th>
                                                            <th class="w-25 text-center"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="ajaxCallInProgress">
                                                        <tr>
                                                            <td colspan="4" class="text-center justify-content-center">
                                                                <div class="digi-table-loader">
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-if="employees_list.length!==0 && !ajaxCallInProgress">
                                                        <tr v-for="(emp, index) in employees_list" :key="index">
                                                            <td style="padding: 0.6rem 1.2rem!important;" class="text-center">{{emp.full_name || '-'}}</td>
                                                            <td style="padding: 0.6rem 1.2rem!important;" class="text-center">
                                                                {{emp.pin || '-'}}
                                                            </td>
                                                            <td style="padding: 0.6rem 1.2rem!important;" class="text-center">
                                                                <button class="btn btn-success text-white" @click="pinRegeneration(emp.user_id)" :disabled="generate_user_pin_api_inprogress">Re-Generate Pin
                                                                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="generate_user_pin_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                                                </button>
                                                            </td>
                                                            <td style="padding: 0.6rem 1.2rem!important;" class="text-center">
                                                                <i v-if="emp.pin == null" class="icon icon-trash text-secondary pointer"></i>
                                                                <i v-else @click="deleteUserPin(emp.user_id)" class="icon icon-trash text-secondary pointer"></i>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody>
                                                        <tr v-if="!ajaxCallInProgress && employees_list.length == 0">
                                                            <td style="padding: 0.6rem 1.2rem!important;font-weight:600;" colspan="7"
                                                                class="text-center">No User Pin Added</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="notification-spinner" id="spinner" style="height: 20px;">
                                                    <div v-if="isLoadingPin" class="col-lg-12 col-md-12 col-sm-12 text-center">
                                                        <div class="digi-table-loader">
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="taxSettings" class="pt-5">
                                    <div class="d-flex justify-content-between">
                                        <span class="ml-4 font-weight-bold text-white hidden-xs-down"
                                            style="font-size:16px;">Tax Rules</span>
                                        <div class="list-title pull-right mr-4" style="font-weight: 500;">
                                            <a class="btn btn-secondary btn-smm btnWhite" @click="showTax">+ Add New Tax
                                                Rule</a>
                                        </div>
                                    </div>
                                    <div class="page-layout carded tax-tab-outlet"
                                        style="margin: 15px;border: 1px solid #a8adb4;border-radius: 16px !important;">
                                        <div class="demo-content">
                                            <table class="table table-tax-other">
                                                <thead>
                                                    <tr>
                                                        <th style="white-space:nowrap">NAME</th>
                                                        <th style="white-space:nowrap">CODE</th>
                                                        <th style="white-space:nowrap">RATE ( % )</th>
                                                        <th>Admin</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="tax_rules.length==0">
                                                        <td class="text-center" colspan="4">
                                                            <strong>No Tax Rules</strong>
                                                        </td>
                                                    </tr>
                                                    <tr v-else v-for="tax_rule in tax_rules" :key="tax_rule._id">
                                                        <td
                                                            style="max-width: 120px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;">
                                                            {{tax_rule.name}}</td>
                                                        <td style="padding:0 14px !important;">{{tax_rule.code}}</td>
                                                        <td style="padding:0 14px !important;">{{tax_rule.rate}}</td>
                                                        <td style="padding:0 14px !important;white-space:nowrap">
                                                            <el-tooltip class="item" effect="dark" content="Edit"
                                                                placement="top">
                                                                <a class="btn btn-icon companyactions actionicons"
                                                                    style="padding: 0.4rem .2rem;"
                                                                    @click="editTax(tax_rule)">
                                                                    <i class="icon icon-pencil"></i>
                                                                </a>
                                                            </el-tooltip>
                                                            <el-tooltip class="item" effect="dark" content="Delete"
                                                                placement="top">
                                                                <a class="btn btn-icon actionicons"
                                                                    style="padding: 0.4rem .2rem;"
                                                                    @click="deleteTaxRule(tax_rule._id)">
                                                                    <i class="icon icon-delete"></i>
                                                                </a>
                                                            </el-tooltip>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between" style="height: 32px;">
                                        <span class="ml-4 font-weight-bold text-white hidden-xs-down"
                                            style="font-size:16px;">Other Charges</span>
                                        <div class="list-title pull-right mr-4 mb-3" @click="createCharges()"
                                            style="font-weight: 500;">
                                            <a class="btn btn-secondary btn-smm btnWhite">+ Add New Other Charges</a>
                                        </div>
                                    </div>
                                    <div class="page-layout carded tax-tab-outlet"
                                        style="margin: 15px;border: 1px solid #a8adb4;border-radius: 16px !important;">
                                        <div class="demo-content">
                                            <table class="table table-tax-other">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th class="hidden-sm-down whiteSpace">ORDER TYPE</th>
                                                        <th class="hidden-md-down">CODE</th>
                                                        <th class="whiteSpace">RATE ( % )</th>
                                                        <th class="whiteSpace">Value (
                                                            {{generalDetails.currency.symbol}} )</th>
                                                        <th>Admin</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="other_charges.length==0">
                                                        <td class="text-center" colspan="6">
                                                            <strong>No Other Charges</strong>
                                                        </td>
                                                    </tr>
                                                    <tr v-else v-for="(charges, index) in other_charges" :key="index">
                                                        <td class="textCaps"
                                                            style="width: 120px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;">
                                                            {{charges.name}}</td>
                                                        <td class="textCaps hidden-sm-down whiteSpace"
                                                            style="padding: 0 10px!important;">
                                                            {{charges.service_type.dine_in + charges.service_type.deliveries + charges.service_type.online + charges.service_type.take_away}}
                                                            Order Type
                                                        </td>
                                                        <td class="textCaps hidden-md-down"
                                                            style="padding: 0 10px!important;">{{charges.code}}</td>
                                                        <td style="padding: 0 10px!important;"
                                                            v-if="charges.type == 'PERCENTAGE'">{{charges.tax_rate}}
                                                        </td>
                                                        <td style="padding: 0 10px!important;" v-else>-</td>
                                                        <td style="padding: 0 10px!important;"
                                                            v-if="charges.type == 'CUSTOM'">{{charges.tax_rate}}</td>
                                                        <td style="padding: 0 10px!important;" v-else>-</td>

                                                        <td style="padding: 0 10px!important;">
                                                            <el-tooltip class="item" effect="dark" content="Edit"
                                                                placement="top">
                                                                <a class="btn btn-icon companyactions actionicons"
                                                                    style="padding: 0.4rem .2rem;"
                                                                    @click="editCharges(index)">
                                                                    <i class="icon icon-pencil"></i>
                                                                </a>
                                                            </el-tooltip>
                                                            <el-tooltip class="item" effect="dark" content="Delete"
                                                                placement="top">
                                                                <a class="btn btn-icon actionicons"
                                                                    style="padding: 0.4rem .2rem;"
                                                                    @click="deleteOtherCharge(charges._id)">
                                                                    <i class="icon icon-delete"></i>
                                                                </a>
                                                            </el-tooltip>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <modal name="create-tax" transition="nice-modal-fade" class="final-modal popup-modal-all-width-height-height-500">
                                        <div class="v-modal-content">
                                            <div class="v-modal-header">
                                                <span class="v-modal-dialog-title v-modal-title">ADD NEW TAX RULE</span>
                                            </div>
                                            <div class="general-section" style="padding: 15px 0px;">
                                            <div class="v-modal-body"
                                                style="padding: 15px 0;">
                                                <div class="">
                                                    <div class="row pl-5 pr-5">
                                                        <div class="col-lg-12">
                                                            <div class="form-group contactGroup">
                                                                <input class="inputContact" type="text"
                                                                    value="Service Tax" required
                                                                    @input="duplicatetaxrule=false"
                                                                    v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                                                                    v-model="newTaxRule.name" name="ruletaxname"
                                                                    data-vv-scope="newTaxRule"
                                                                    :maxlength="taxrulename" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Name
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('newTaxRule.ruletaxname') && ifnewTaxRuleSubmitted"
                                                                    style="display:contents;">{{errors.first('newTaxRule.ruletaxname')}}</span>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="duplicatetaxrule">Tax Name Already
                                                                    Exists</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{taxrulename - newTaxRule.name.length}} /
                                                                    {{taxrulename}}</p>
                                                            </div>
                                                            <div class="form-group contactGroup">
                                                                <input class="inputContact" type="text"
                                                                    @input="duplicateTaxCode=false"
                                                                    v-model="newTaxRule.code" required
                                                                    v-validate="{required:true,regex:/^[a-zA-Z0-9]+$/}"
                                                                    name="ruletaxcode" data-vv-scope="newTaxRule"
                                                                    :maxlength="taxrulecode" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Code
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('newTaxRule.ruletaxcode') && ifnewTaxRuleSubmitted"
                                                                    style="display:contents;">{{errors.first('newTaxRule.ruletaxcode')}}</span>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="duplicateTaxCode">Duplicate Tax Code</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{taxrulecode - newTaxRule.code.length}} /
                                                                    {{taxrulecode}}</p>
                                                            </div>
                                                            <div class="form-group contactGroup">
                                                                <input class="inputContact" type="text" required
                                                                    v-model="newTaxRule.rate"
                                                                    v-validate="{required:true,regex:/^[0-9]\d{0,9}(\.\d{1,3})?%?$/}"
                                                                    name="rate" data-vv-scope="newTaxRule"
                                                                    :maxlength="taxrulerate" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Rate (%)
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('newTaxRule.rate') && ifnewTaxRuleSubmitted"
                                                                    style="display:contents;">{{errors.first('newTaxRule.rate')}}</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{taxrulerate - newTaxRule.rate.length}} /
                                                                    {{taxrulerate}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="form-group contactGroup other-charges-checkbox pl-5 pr-5">
                                                        <label style="color:#00448b!important;" class="pb-3">
                                                            Apply Other Charges to Order Type
                                                            <span class="ml-1">*</span>
                                                        </label>
                                                        <div class="d-flex">
                                                            <el-checkbox v-model="newTaxRule.service_type.dine_in">Dine
                                                                In</el-checkbox>
                                                            <el-checkbox v-model="newTaxRule.service_type.take_away">
                                                                Take Away</el-checkbox>
                                                            <el-checkbox v-model="newTaxRule.service_type.deliveries"
                                                                @change="clearTaxAddValues('Deliveries')">Deliveries
                                                            </el-checkbox>
                                                            <el-checkbox v-model="newTaxRule.service_type.online"
                                                                @change="clearTaxAddValues('Online')">Online
                                                            </el-checkbox>
                                                        </div>
                                                        <span v-if="validServiceTypeTaxAdd"
                                                            class="invalid-feedback-form text-danger fs-13">Order Type
                                                            is Required</span>
                                                    </div>
                                                    <div class="row mt-5 pl-5 pr-5">
                                                        <div class="col-lg-6 col-ms-6 col-sm-12"
                                                            v-if="newTaxRule.service_type.deliveries">
                                                            <multiselect v-model="newTaxRule.company_deliveries"
                                                                data-vv-scope="newTaxRule"
                                                                class="digi-multiselect category-multiple-addon"
                                                                id="select_order_type"
                                                                placeholder="Select Delivery Companies"
                                                                :options="delivery_company_list" label="name"
                                                                :multiple="true" :searchable="true"
                                                                :internal-search="false" :clear-on-select="false"
                                                                :close-on-select="false" :options-limit="40"
                                                                :max-height="200" :show-no-results="true"
                                                                :hide-selected="false" name="tax_order_type"
                                                                :limit-text="limitText" @remove="removecreateTax"
                                                                :blockKeys="['Delete']" v-validate="'required'"
                                                                track-by="_id" @open="getDeliveryData('Deliveries')">
                                                                <template slot="tag" slot-scope="{ option }">
                                                                    <span class="custom__tag">
                                                                        <span>{{ option.name }}</span>
                                                                        <span class="custom__remove"
                                                                            @click="removecreateTax(option)">
                                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg"
                                                                                style="margin-top: -4px;" />
                                                                        </span>
                                                                    </span>
                                                                </template>
                                                            </multiselect>
                                                            <span class="invalid-feedback-form text-danger fs-13"
                                                                v-show="errors.has('newTaxRule.tax_order_type')">Please
                                                                Select Delivery Company</span>
                                                        </div>
                                                        <div class="col-lg-6 col-ms-6 col-sm-12"
                                                            v-if="newTaxRule.service_type.online">
                                                            <multiselect v-model="newTaxRule.company_online"
                                                                class="digi-multiselect category-multiple-addon"
                                                                id="select_order_type" data-vv-scope="newTaxRule"
                                                                placeholder="Select Online Delivery Companies"
                                                                :options="delivery_company_list" label="name"
                                                                :multiple="true" :searchable="true"
                                                                :internal-search="false" :clear-on-select="false"
                                                                :close-on-select="false" :options-limit="40"
                                                                :max-height="200" :show-no-results="true"
                                                                :hide-selected="false" name="tax_online_co"
                                                                :limit-text="limitText" @remove="removeTaxOnline"
                                                                :blockKeys="['Delete']" v-validate="'required'"
                                                                track-by="_id" @open="getDeliveryData('Online')">
                                                                <template slot="tag" slot-scope="{ option }">
                                                                    <span class="custom__tag">
                                                                        <span>{{ option.name }}</span>
                                                                        <span class="custom__remove"
                                                                            @click="removeTaxOnline(option)">
                                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg"
                                                                                style="margin-top: -4px;" />
                                                                        </span>
                                                                    </span>
                                                                </template>
                                                            </multiselect>
                                                            <span class="invalid-feedback-form text-danger fs-13"
                                                                v-show="errors.has('newTaxRule.tax_online_co')">Please
                                                                Select Online Company</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="v-modal-dialog-actions modal-bg" style="border-radius: 0px 0px 15px 15px;">
                                                <button class="btn btn-link btn-smm" :disabled="add_new_tax_rule_api_inprogress"
                                                    style="text-transform: uppercase !important;font-size: 12px !important;"
                                                    @click="hideTax()">CANCEL</button>
                                                <button type="submit" class="btn btn-secondary btn-smm btn-3 ml-5"
                                                    @click="addTaxRule(newTaxRule)" :disabled="add_new_tax_rule_api_inprogress">SAVE
                                                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="add_new_tax_rule_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                                </button>
                                            </div>
                                        </div>
                                    </modal>
                                    <modal name="edit-tax" transition="nice-modal-fade" class="final-modal popup-modal-all-width-height-height-500">
                                        <div class="v-modal-content">
                                            <div class="v-modal-header">
                                                <span class="v-modal-dialog-title v-modal-title">EDIT TAX RULE</span>
                                            </div>
                                            <div class="general-section" style="padding: 15px 0px;">
                                            <div class="v-modal-body"
                                                style="padding: 15px 0;">
                                                <div class="">
                                                    <div class="row pl-5 pr-5">
                                                        <div class="col-lg-12">
                                                            <div class="form-group contactGroup">
                                                                <input class="inputContact" type="text" id="taxName"
                                                                    required @input="editduplicatetaxrule=false"
                                                                    v-model="editTaxRule.name" name="edit_tax_name"
                                                                    data-vv-scope="editTaxRule"
                                                                    v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                                                                    :maxlength="edittaxrulename" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Name
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    style="display:contents;"
                                                                    v-show="errors.has('editTaxRule.edit_tax_name') && ifEditTaxRuleSubmitted">{{errors.first('editTaxRule.edit_tax_name')}}</span>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="editduplicatetaxrule">Name Already
                                                                    Exists</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{edittaxrulename - editTaxRule.name.length}} / 30
                                                                </p>
                                                            </div>
                                                            <div class="form-group contactGroup mt-2">
                                                                <input class="inputContact" id="taxCode" type="text"
                                                                    required @input="editDuplicateTaxCode=false"
                                                                    v-model="editTaxRule.code" name="edit_tax_code"
                                                                    v-validate="{required:true,regex:/^[a-zA-Z0-9]+$/}"
                                                                    data-vv-scope="editTaxRule"
                                                                    :maxlength="edittaxrulecode" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Code
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    style="display:contents;"
                                                                    v-show="errors.has('editTaxRule.edit_tax_code') && ifEditTaxRuleSubmitted">{{errors.first('editTaxRule.edit_tax_code')}}</span>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="editDuplicateTaxCode">Duplicate Tax
                                                                    Code</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{edittaxrulecode - editTaxRule.code.length}} /
                                                                    {{edittaxrulecode}}</p>
                                                            </div>
                                                            <div class="form-group contactGroup mt-2">
                                                                <input class="inputContact" type="text" required
                                                                    v-model="editTaxRule.rate" name="edit_tax_rate"
                                                                    v-validate="{required:true,regex:/^[0-9]\d{0,9}(\.\d{1,3})?%?$/}"
                                                                    data-vv-scope="editTaxRule"
                                                                    :maxlength="edittaxrulerate" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact taxLabel">
                                                                    Rate ( % )
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    style="display:contents;"
                                                                    v-show="errors.has('editTaxRule.edit_tax_rate') && ifEditTaxRuleSubmitted">{{errors.first('editTaxRule.edit_tax_rate')}}</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{edittaxrulerate - editTaxRule.rate.toString().length}}
                                                                    / {{edittaxrulerate}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="form-group contactGroup other-charges-checkbox pl-5 pr-5">
                                                        <label style="color:#00448b!important;" class="pb-3">
                                                            Apply Other Charges to Order Type
                                                            <span class="ml-1">*</span>
                                                        </label>
                                                        <div class="d-flex">
                                                            <el-checkbox v-model="editTaxRule.service_type.dine_in">Dine
                                                                In</el-checkbox>
                                                            <el-checkbox v-model="editTaxRule.service_type.take_away">
                                                                Take Away</el-checkbox>
                                                            <el-checkbox v-model="editTaxRule.service_type.deliveries"
                                                                @change="clearTaxEditValues('Deliveries')">Deliveries
                                                            </el-checkbox>
                                                            <el-checkbox v-model="editTaxRule.service_type.online"
                                                                @change="clearTaxEditValues('Online')">Online
                                                            </el-checkbox>
                                                        </div>
                                                        <span v-if="validServiceTypeTaxEdit"
                                                            class="invalid-feedback-form text-danger fs-13">Order Type
                                                            is Required</span>
                                                    </div>
                                                    <div class="row mt-5 pl-5 pr-5">
                                                        <div class="col-lg-6 col-ms-6 col-sm-12"
                                                            v-if="editTaxRule.service_type.deliveries">
                                                            <multiselect v-model="editTaxRule.company_deliveries"
                                                                data-vv-scope="editTaxRule"
                                                                class="digi-multiselect category-multiple-addon"
                                                                id="select_order_type"
                                                                placeholder="Select Delivery Companies"
                                                                :options="delivery_company_list" label="name"
                                                                :multiple="true" :searchable="true"
                                                                :internal-search="false" :clear-on-select="false"
                                                                :close-on-select="false" :options-limit="40"
                                                                :max-height="200" :show-no-results="true"
                                                                :hide-selected="false" name="tax_order_type"
                                                                :limit-text="limitText" @remove="removeeditTax"
                                                                :blockKeys="['Delete']" v-validate="'required'"
                                                                track-by="_id" @open="getDeliveryData('Deliveries')">
                                                                <template slot="tag" slot-scope="{ option }">
                                                                    <span class="custom__tag">
                                                                        <span>{{ option.name }}</span>
                                                                        <span class="custom__remove"
                                                                            @click="removeeditTax(option)">
                                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg"
                                                                                style="margin-top: -4px;" />
                                                                        </span>
                                                                    </span>
                                                                </template>
                                                            </multiselect>
                                                            <span class="invalid-feedback-form text-danger fs-13"
                                                                v-show="errors.has('editTaxRule.tax_order_type')">Please
                                                                Select Delivery Company</span>
                                                        </div>
                                                        <div class="col-lg-6 col-ms-6 col-sm-12"
                                                            v-if="editTaxRule.service_type.online">
                                                            <multiselect v-model="editTaxRule.company_online"
                                                                class="digi-multiselect category-multiple-addon"
                                                                id="select_order_type" data-vv-scope="editTaxRule"
                                                                placeholder="Select Online Delivery Companies"
                                                                :options="delivery_company_list" label="name"
                                                                :multiple="true" :searchable="true"
                                                                :internal-search="false" :clear-on-select="false"
                                                                :close-on-select="false" :options-limit="40"
                                                                :max-height="200" :show-no-results="true"
                                                                :hide-selected="false" name="tax_online_co"
                                                                :limit-text="limitText" @remove="removeTaxEditOnline"
                                                                :blockKeys="['Delete']" v-validate="'required'"
                                                                track-by="_id" @open="getDeliveryData('Online')">
                                                                <template slot="tag" slot-scope="{ option }">
                                                                    <span class="custom__tag">
                                                                        <span>{{ option.name }}</span>
                                                                        <span class="custom__remove"
                                                                            @click="removeTaxEditOnline(option)">
                                                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg"
                                                                                style="margin-top: -4px;" />
                                                                        </span>
                                                                    </span>
                                                                </template>
                                                            </multiselect>
                                                            <span class="invalid-feedback-form text-danger fs-13"
                                                                v-show="errors.has('editTaxRule.tax_online_co')">Please
                                                                Select Online Company</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="v-modal-dialog-actions modal-bg" style="border-radius: 0px 0px 15px 15px;">
                                                <button class="btn btn-link btn-smm" :disabled="add_new_tax_rule_api_inprogress"
                                                    style="text-transform: uppercase !important;font-size: 12px !important;"
                                                    @click="hideEditTax()">CANCEL</button>
                                                <button type="submit" class="btn btn-secondary btn-smm btn-4 ml-5"
                                                    @click="updateTaxRule(editTaxRule)" :disabled="add_new_tax_rule_api_inprogress">SAVE
                                                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="add_new_tax_rule_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                                </button>
                                            </div>
                                        </div>
                                    </modal>
                                    <modal name="create-charges" transition="nice-modal-fade" class="final-modal popup-modal-all-width-height-height-500">
                                        <div class="v-modal-content">
                                            <div class="v-modal-header">
                                                <span class="v-modal-dialog-title v-modal-title">ADD NEW OTHER
                                                    CHARGES</span>
                                            </div>
                                            <div class="general-section" style="padding: 15px 0px;">
                                            <div class="v-modal-body"
                                                style="padding: 15px 0;">
                                                <div class="">
                                                    <div class="row pl-5 pr-5">
                                                        <div class="col-lg-12">
                                                            <div class="form-group contactGroup">
                                                                <input @input="nameExists = taxNameExists = false"
                                                                    name="charge_name" class="inputContact" type="text"
                                                                    v-model="name" required
                                                                    v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                                                                    :maxlength="chargesname" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Name
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('charge_name')"
                                                                    style="display:contents;">{{errors.first('charge_name')}}</span>
                                                                <div v-show="nameExists && name.trim()!==''"
                                                                    class="text-danger fs-13">Name Already Exists</div>
                                                                <div v-show="taxNameExists && name.trim()!==''"
                                                                    class="text-danger fs-13">Name Already Exists in
                                                                    Taxes</div>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{chargesname - name.length}} / {{chargesname}}</p>
                                                            </div>
                                                            <div class="form-group contactGroup">
                                                                <input @input="codeExists = taxCodeExists = false"
                                                                    name="charge_code" class="inputContact" type="text"
                                                                    v-model="code" required
                                                                    v-validate="{required:true,regex:/^[a-zA-Z0-9]+$/}"
                                                                    :maxlength="chargescode" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Code
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('charge_code')"
                                                                    style="display:contents;">{{errors.first('charge_code')}}</span>
                                                                <div v-show="codeExists && code.trim()!==''"
                                                                    class="text-danger fs-13">Code Already Exists</div>
                                                                <div v-show="taxCodeExists && code.trim()!==''"
                                                                    class="text-danger fs-13">Code Already Exists in
                                                                    Taxes</div>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{chargescode - code.length}} / {{chargescode}}</p>
                                                            </div>
                                                            <div class="pt-4">
                                                                <label class="text-secondary pr-2 fw-600 pb-1">Choose
                                                                    Type :</label>
                                                                <br />
                                                                <div class="form-check form-check-inline">
                                                                    <label class="form-check-label">
                                                                        <input v-model="type" type="radio"
                                                                            class="form-check-input" name="taxPer"
                                                                            id="optionsRadios1" value="PERCENTAGE" />
                                                                        <span class="radio-icon"></span>
                                                                        <span style="padding-left: 20px !important;">Percentage</span>
                                                                    </label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <label class="form-check-label">
                                                                        <input v-model="type" type="radio"
                                                                            class="form-check-input" name="taxPer"
                                                                            id="optionsRadios2" value="CUSTOM" />
                                                                        <span class="radio-icon"></span>
                                                                        <span style="padding-left: 20px !important;">Custom</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group contactGroup"
                                                                v-if="type == 'PERCENTAGE'">
                                                                <input class="inputContact" v-model="tax_rate"
                                                                    type="text" value="4%" required name="tax_rate"
                                                                    v-validate="{required:true,regex:/^[0-9]\d{0,9}(\.\d{1,3})?%?$/}"
                                                                    :maxlength="chargesrate" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Rate(%)
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('tax_rate')"
                                                                    style="display:contents;">{{errors.first('tax_rate')}}</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{chargesrate - tax_rate.length}} / {{chargesrate}}
                                                                </p>
                                                            </div>
                                                            <div class="form-group contactGroup"
                                                                v-if="type == 'CUSTOM'">
                                                                <input class="inputContact" v-model="tax_rate"
                                                                    type="text" value="4%" required
                                                                    name="custome_name_value"
                                                                    v-validate="{required:true,regex:/^[0-9]\d{0,9}(\.\d{1,3})?%?$/}"
                                                                    :maxlength="custom_value_length" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Value ($)
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('custome_name_value')"
                                                                    style="display:contents;">{{errors.first('custome_name_value')}}</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{custom_value_length - custom_value.length}} /
                                                                    {{custom_value_length}}</p>
                                                            </div>
                                                            <div class="form-group contactGroup other-charges-checkbox"
                                                                v-if="type == 'PERCENTAGE'">
                                                                <el-checkbox v-model="discount_included">Include
                                                                    Discount in Other Charges</el-checkbox>
                                                            </div>
                                                            <div class="form-group contactGroup other-charges-checkbox">
                                                                <label style="color:#00448b!important;" class="pb-3">
                                                                    Apply Other Charges to Order Type
                                                                    <span class="ml-1">*</span>
                                                                </label>
                                                                <div class="d-flex">
                                                                    <el-checkbox v-model="service_type.dine_in">Dine In
                                                                    </el-checkbox>
                                                                    <el-checkbox v-model="service_type.take_away">Take
                                                                        Away</el-checkbox>
                                                                    <el-checkbox v-model="service_type.deliveries"
                                                                        @change="clearValues('Deliveries')">Deliveries
                                                                    </el-checkbox>
                                                                    <el-checkbox v-model="service_type.online"
                                                                        @change="clearValues('Online')">Online
                                                                    </el-checkbox>
                                                                </div>
                                                                <span v-if="validServiceType"
                                                                    class="invalid-feedback-form text-danger fs-13">Order
                                                                    Type is Required</span>
                                                            </div>
                                                            <div class="row mt-5">
                                                                <div class="col-lg-6 col-ms-6 col-sm-12"
                                                                    v-if="service_type.deliveries">
                                                                    <multiselect v-model="company_deliveries"
                                                                        class="digi-multiselect category-multiple-addon"
                                                                        id="select_order_type"
                                                                        placeholder="Select Delivery Companies"
                                                                        :options="delivery_company_list" label="name"
                                                                        :multiple="true" :searchable="true"
                                                                        :internal-search="false"
                                                                        :clear-on-select="false"
                                                                        :close-on-select="false" :options-limit="40"
                                                                        :max-height="200" :show-no-results="true"
                                                                        :hide-selected="false" name="order_type"
                                                                        :limit-text="limitText" @remove="removecreate"
                                                                        :blockKeys="['Delete']" v-validate="'required'"
                                                                        track-by="_id"
                                                                        @open="getDeliveryData('Deliveries')">
                                                                        <template slot="tag" slot-scope="{ option }">
                                                                            <span class="custom__tag">
                                                                                <span>{{ option.name }}</span>
                                                                                <span class="custom__remove"
                                                                                    @click="removecreate(option)">
                                                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg"
                                                                                        style="margin-top: -4px;" />
                                                                                </span>
                                                                            </span>
                                                                        </template>
                                                                    </multiselect>
                                                                    <span
                                                                        class="invalid-feedback-form text-danger fs-13"
                                                                        v-show="errors.has('order_type')">Please Select
                                                                        Delivery Company</span>
                                                                </div>
                                                                <div class="col-lg-6 col-ms-6 col-sm-12"
                                                                    v-if="service_type.online">
                                                                    <multiselect v-model="company_online"
                                                                        class="digi-multiselect category-multiple-addon"
                                                                        id="select_order_type"
                                                                        placeholder="Select Online Delivery Companies"
                                                                        :options="delivery_company_list" label="name"
                                                                        :multiple="true" :searchable="true"
                                                                        :internal-search="false"
                                                                        :clear-on-select="false"
                                                                        :close-on-select="false" :options-limit="40"
                                                                        :max-height="200" :show-no-results="true"
                                                                        :hide-selected="false" name="online_co"
                                                                        :limit-text="limitText" @remove="removeOnline"
                                                                        :blockKeys="['Delete']" v-validate="'required'"
                                                                        track-by="_id"
                                                                        @open="getDeliveryData('Online')">
                                                                        <template slot="tag" slot-scope="{ option }">
                                                                            <span class="custom__tag">
                                                                                <span>{{ option.name }}</span>
                                                                                <span class="custom__remove"
                                                                                    @click="removeOnline(option)">
                                                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg"
                                                                                        style="margin-top: -4px;" />
                                                                                </span>
                                                                            </span>
                                                                        </template>
                                                                    </multiselect>
                                                                    <span
                                                                        class="invalid-feedback-form text-danger fs-13"
                                                                        v-show="errors.has('online_co')">Please Select
                                                                        Online Company</span>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <span class="font-weight-bold text-secondary mr-2">Is
                                                                    this Charge Applicable on Other Charges?</span>
                                                                <el-switch active-text="Yes" inactive-text="No"
                                                                    v-model="is_charge_applied_on_other_charge"
                                                                    @change="is_applied_on = ''"></el-switch>
                                                            </div>
                                                            <div class="form-group contactGroup"
                                                                v-if="is_charge_applied_on_other_charge">
                                                                <label style="color:#00448b!important;"
                                                                    class="pb-3">Select Other Charge<span
                                                                        class="ml-1">*</span></label>
                                                                <multiselect v-model="is_applied_on"
                                                                    class="digi-multiselect" id="select_applied_on"
                                                                    label="name" placeholder="Select Other Charge"
                                                                    :options="allServiceType" :multiple="false"
                                                                    :searchable="true" :internal-search="false"
                                                                    :clear-on-select="false" :close-on-select="true"
                                                                    :options-limit="40" :max-height="200"
                                                                    :show-no-results="true" :hide-selected="false"
                                                                    name="applied_on" v-validate="'required'"
                                                                    @open="getServiceType()"></multiselect>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('applied_on')">Please Select
                                                                    Other Charge</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="v-modal-dialog-actions modal-bg text-center">
                                                <button class="btn btn-link btn-smm" :disabled="save_other_charges_api_inprogress"
                                                    style="text-transform: uppercase !important;font-size: 12px !important;"
                                                    @click="hideCharges()">CANCEL</button>
                                                <button type="submit" class="btn btn-secondary btn-smm btn-5 ml-5" :disabled="save_other_charges_api_inprogress"
                                                    @click="saveCharges(name, code, tax_rate, service_type, discount_included, type,is_charge_applied_on_other_charge,is_applied_on,company_deliveries,company_online)">SAVE
                                                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="save_other_charges_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                                </button>
                                            </div>
                                        </div>
                                    </modal>
                                    <modal name="edit-charges" transition="nice-modal-fade" class="final-modal popup-modal-all-width-height-height-500">
                                        <div class="v-modal-content">
                                            <div class="v-modal-header">
                                                <span class="v-modal-dialog-title v-modal-title">EDIT OTHER
                                                    CHARGES</span>
                                            </div>
                                            <div class="general-section" style="padding: 15px 0px;">
                                            <div class="v-modal-body"
                                                style="padding: 15px 0;">
                                                <div class="">
                                                    <div class="row pl-5 pr-5">
                                                        <div class="col-lg-12">
                                                            <div class="form-group contactGroup">
                                                                <input @input="editNameExists = false"
                                                                    class="inputContact" v-model="edit_name" type="text"
                                                                    required value="service charges" name="charge_name"
                                                                    v-validate="{required:true,regex:/^[a-zA-Z0-9_ ]+$/}"
                                                                    :maxlength="editchargesname" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Name
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('charge_name')"
                                                                    style="display:contents;">{{errors.first('charge_name')}}</span>
                                                                <div v-show="editNameExists && edit_name.trim()!==''"
                                                                    class="text-danger fs-13">Name Already Exists</div>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{editchargesname - edit_name.length}} /
                                                                    {{editchargesname}}</p>
                                                            </div>
                                                            <div class="form-group contactGroup">
                                                                <input @input="editCodeExists = false"
                                                                    class="inputContact" v-model="edit_code" type="text"
                                                                    required name="charge_code"
                                                                    v-validate="{required:true,regex:/^[a-zA-Z0-9]+$/}"
                                                                    :maxlength="editchargescode" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Code
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('charge_code')"
                                                                    style="display:contents;">{{errors.first('charge_code')}}</span>
                                                                <div v-show="editCodeExists && edit_code.trim()!==''"
                                                                    class="text-danger fs-13">Code Already Exists</div>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{editchargescode - edit_code.length}} /
                                                                    {{editchargescode}}</p>
                                                            </div>
                                                            <div class="pt-4">
                                                                <label class="text-secondary pr-2 fw-600 pb-1">Choose
                                                                    Type :</label>
                                                                <br />
                                                                <div class="form-check form-check-inline">
                                                                    <label class="form-check-label">
                                                                        <input v-model="edit_type" type="radio"
                                                                            class="form-check-input" name="taxPer"
                                                                            id="optionsRadios1" value="PERCENTAGE" />
                                                                        <span class="radio-icon"></span>
                                                                        <span style="padding-left: 20px !important;">Percentage</span>
                                                                    </label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <label class="form-check-label">
                                                                        <input v-model="edit_type" type="radio"
                                                                            class="form-check-input" name="taxPer"
                                                                            id="optionsRadios2" value="CUSTOM" />
                                                                        <span class="radio-icon"></span>
                                                                        <span style="padding-left: 20px !important;">Custom</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="form-group contactGroup"
                                                                v-if="edit_type == 'PERCENTAGE'">
                                                                <input class="inputContact" v-model="edit_tax_rate"
                                                                    type="text" required value="4%" name="edit_tax_rate"
                                                                    v-validate="{required:true,regex:/^[0-9]\d{0,9}(\.\d{1,3})?%?$/}"
                                                                    :maxlength="editchargesrate" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Tax Rate(%)
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('edit_tax_rate')"
                                                                    style="display:contents;">{{errors.first('edit_tax_rate')}}</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{editchargesrate - edit_tax_rate.toString().length}}
                                                                    / {{editchargesrate}}</p>
                                                            </div>
                                                            <div class="form-group contactGroup"
                                                                v-if="edit_type == 'CUSTOM'">
                                                                <input class="inputContact" v-model="edit_tax_rate"
                                                                    type="text" value="4%" required
                                                                    name="custome_name_value_edit"
                                                                    v-validate="{required:true,regex:/^[0-9]\d{0,9}(\.\d{1,3})?%?$/}"
                                                                    :maxlength="custom_value_length" />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Value ($)
                                                                    <sup>★</sup>
                                                                </label>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('custome_name_value_edit')"
                                                                    style="display:contents;">{{errors.first('custome_name_value_edit')}}</span>
                                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1"
                                                                    style="margin-bottom: 0px!important;">
                                                                    {{custom_value_length - custom_value.length}} /
                                                                    {{custom_value_length}}</p>
                                                            </div>
                                                            <div class="form-group contactGroup other-charges-checkbox"
                                                                v-if="edit_type == 'PERCENTAGE'">
                                                                <el-checkbox v-model="edit_discount_included">Include
                                                                    Discount in Other Charges</el-checkbox>
                                                            </div>
                                                            <div class="form-group contactGroup other-charges-checkbox">
                                                                <label style="color:#00448b!important;" class="pb-3">
                                                                    Apply Other Charges to Order Type
                                                                    <span class="ml-1">*</span>
                                                                </label>
                                                                <div class="d-flex">
                                                                    <el-checkbox v-model="edit_service_type.dine_in">
                                                                        Dine In</el-checkbox>
                                                                    <el-checkbox v-model="edit_service_type.take_away">
                                                                        Take Away</el-checkbox>
                                                                    <el-checkbox v-model="edit_service_type.deliveries"
                                                                        @change="clearValues('Deliveries')">Deliveries
                                                                    </el-checkbox>
                                                                    <el-checkbox v-model="edit_service_type.online"
                                                                        @change="clearValues('Online')">Online
                                                                    </el-checkbox>
                                                                </div>
                                                                <span v-if="validServiceType"
                                                                    class="invalid-feedback-form text-danger fs-13">Order
                                                                    Type is Required</span>
                                                            </div>
                                                            <div class="row mt-5">
                                                                <div class="col-lg-6 col-ms-6 col-sm-12"
                                                                    v-if="edit_service_type.deliveries">
                                                                    <multiselect v-model="edit_company_deliveries"
                                                                        class="digi-multiselect category-multiple-addon"
                                                                        id="select_order_type"
                                                                        placeholder="Select Delivery Companies"
                                                                        :options="delivery_company_list" label="name"
                                                                        :multiple="true" :searchable="true"
                                                                        :internal-search="false"
                                                                        :clear-on-select="false"
                                                                        :close-on-select="false" :options-limit="40"
                                                                        :max-height="200" :show-no-results="true"
                                                                        :hide-selected="false" name="order_type"
                                                                        :limit-text="limitText"
                                                                        @remove="removecreateedit"
                                                                        :blockKeys="['Delete']" v-validate="'required'"
                                                                        track-by="_id"
                                                                        @open="getDeliveryData('Deliveries')">
                                                                        <template slot="tag" slot-scope="{ option }">
                                                                            <span class="custom__tag">
                                                                                <span>{{ option.name }}</span>
                                                                                <span class="custom__remove"
                                                                                    @click="removecreateedit(option)">
                                                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg"
                                                                                        style="margin-top: -4px;" />
                                                                                </span>
                                                                            </span>
                                                                        </template>
                                                                    </multiselect>
                                                                    <span
                                                                        class="invalid-feedback-form text-danger fs-13"
                                                                        v-show="errors.has('order_type')">Please Select
                                                                        Delivery Company</span>
                                                                </div>
                                                                <div class="col-lg-6 col-ms-6 col-sm-12"
                                                                    v-if="edit_service_type.online">
                                                                    <multiselect v-model="edit_company_online"
                                                                        class="digi-multiselect category-multiple-addon"
                                                                        id="select_order_type"
                                                                        placeholder="Select Online Delivery Companies"
                                                                        :options="delivery_company_list" label="name"
                                                                        :multiple="true" :searchable="true"
                                                                        :internal-search="false"
                                                                        :clear-on-select="false"
                                                                        :close-on-select="false" :options-limit="40"
                                                                        :max-height="200" :show-no-results="true"
                                                                        :hide-selected="false" name="online_co"
                                                                        :limit-text="limitText"
                                                                        @remove="removeOnlineedit"
                                                                        :blockKeys="['Delete']" v-validate="'required'"
                                                                        track-by="_id"
                                                                        @open="getDeliveryData('Online')">
                                                                        <template slot="tag" slot-scope="{ option }">
                                                                            <span class="custom__tag">
                                                                                <span>{{ option.name }}</span>
                                                                                <span class="custom__remove"
                                                                                    @click="removeOnlineedit(option)">
                                                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/cancel.svg"
                                                                                        style="margin-top: -4px;" />
                                                                                </span>
                                                                            </span>
                                                                        </template>
                                                                    </multiselect>
                                                                    <span
                                                                        class="invalid-feedback-form text-danger fs-13"
                                                                        v-show="errors.has('online_co')">Please Select
                                                                        Online Company</span>
                                                                </div>
                                                            </div>
                                                            <div class="form-group">
                                                                <span class="font-weight-bold text-secondary mr-2">Is
                                                                    this Charge Applicable on Other Charges?</span>
                                                                <el-switch active-text="Yes" inactive-text="No"
                                                                    v-model="edit_is_charge_applied_on_other_charge"
                                                                    @change="edit_is_applied_on = ''"></el-switch>
                                                            </div>
                                                            <div class="form-group contactGroup" id="scrolling"
                                                                v-if="edit_is_charge_applied_on_other_charge">
                                                                <label style="color:#00448b!important;"
                                                                    class="pb-3">Select Other Charge<span
                                                                        class="ml-1">*</span></label>
                                                                <multiselect v-model="edit_is_applied_on"
                                                                    class="digi-multiselect" id="select_applied_on"
                                                                    label="name" placeholder="Select Other Charge"
                                                                    :options="allServiceType" :multiple="false"
                                                                    :searchable="true" :internal-search="false"
                                                                    :clear-on-select="false" :close-on-select="true"
                                                                    :options-limit="40" :max-height="200"
                                                                    :show-no-results="true" :hide-selected="false"
                                                                    name="edit_applied_on" v-validate="'required'"
                                                                    @open="getServiceTypeEdit()"></multiselect>
                                                                <span class="invalid-feedback-form text-danger fs-13"
                                                                    v-show="errors.has('edit_applied_on')">Please Select
                                                                    Other Charge</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="v-modal-dialog-actions modal-bg text-center">
                                                <button class="btn btn-link btn-smm" :disabled="save_other_charges_api_inprogress"
                                                    style="text-transform: uppercase !important;font-size: 12px !important;"
                                                    @click="hideEditCharges()">CANCEL</button>
                                                <button type="submit" class="btn btn-secondary btn-smm btn-6 ml-5" :disabled="save_other_charges_api_inprogress"
                                                    @click="saveEditCharges(edit_name, edit_code, edit_tax_rate, edit_id, edit_service_type, edit_discount_included,edit_type,edit_is_charge_applied_on_other_charge,edit_is_applied_on,edit_company_deliveries,edit_company_online)">SAVE
                                                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="save_other_charges_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                                </button>
                                            </div>
                                        </div>
                                    </modal>
                                </div>
                                <div v-if="otherSettings" class="mediaSize other-settings-tab-outlet"
                                    style="padding-top:20px;">
                                    <ul class="nav nav-tabs nav-site" id="myTab" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" data-toggle="tab" href="#table-settings"
                                                role="tab" aria-controls="table-settings" aria-expanded="true">Table
                                                Settings</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-toggle="tab" href="#currency-setting" role="tab"
                                                aria-controls="currency-setting">Currency Setting</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-toggle="tab" href="#other-settings-tab" role="tab"
                                                aria-controls="other-settings-tab">Operation Hours</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-toggle="tab" href="#outlet-configuration"
                                                role="tab" aria-controls="outlet-configuration">Outlet Configuration</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content pl-5 pr-5 mt-6">
                                        <div role="tabpanel" class="tab-pane fade show active other-table-content"
                                            id="table-settings" aria-labelledby="table-settings">
                                            <p class="text-secondary font-600">Table Layout Settings</p>
                                            <div class="form-group contactGroup">
                                                <label style="font-size: 14px; color: #00448b;">
                                                    Please Enter The Number of Tables
                                                    <sup>★</sup>
                                                </label>
                                                <input class="inputContact" type="text" v-validate="'required|numeric'"
                                                    v-model="otherDetails.no_of_tables" name="Noof_tables"
                                                    data-vv-scope="tableSettings" required
                                                    style="padding: 1px 10px 2px 0!important;" />
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <span class="text-danger fs-13"
                                                    v-show="errors.has('otherDetails.Noof_tables')">Enter Valid Number
                                                    of Tables</span>
                                            </div>
                                            <div class="form-group contactGroup">
                                                <input class="inputContact" type="text"
                                                    v-validate="'required|alpha_num'" data-vv-scope="tableSettings"
                                                    name="sequence" :maxlength="prefixlength"
                                                    v-model="otherDetails.table_prefix" required />
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">
                                                    Please Enter The Table Number Prefix (For Ex. T1, T2 or FF01)
                                                    <sup>★</sup>
                                                </label>
                                                <span class="text-danger fs-13"
                                                    v-show="errors.has('otherDetails.sequence')">{{errors.first("otherDetails.sequence")}}</span>
                                                <p class="pull-right text-right text-grey font-600 fs-13">
                                                    {{prefixlength - otherDetails.table_prefix.length}} /
                                                    {{prefixlength}}</p>
                                            </div>
                                            <div class="pb-5 text-center mt-7">
                                                <button type="submit" class="btn btn-secondary btn-smm" @click="regenerate()">RE - GENERATE</button>
                                            </div>
                                            <div class="table-layout-to-change">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <p class="text-secondary font-600">
                                                        Table layout
                                                        <span class="text-danger pl-3">Click on Table to Rename</span>
                                                    </p>
                                                    <button type="submit" class="btn btn-secondary btn-smm" @click="showQrCode()">PRINT ALL</button>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center mt-3 pb-2">
                                                    <el-checkbox @change="showAssignTableWaiter($event)" v-model="assign_table_to_waiter">Assign Tables to Waiters</el-checkbox>
                                                    <button type="submit" @click="showAssignWaiterPopup" class="btn btn-secondary btn-smm" :disabled="selected_tables.length < 1" >Assign Waiter</button>
                                                </div>
                                                <div class="generate-table-div d-flex flex-wrap" style="max-height: 418px;overflow: auto;">
                                                    <span class="occupiedTable-regenerate position-relative" :class="{'occupiedTable-regenerate-selected':table.is_selected, 'occupiedTable-regenerate-waiter-assigned':table.assigned_to}"  v-for="(table, index) in tables" :key="index">
                                                        <el-dropdown trigger="click" class="text-right position-absolute" style="right: 0; top: 5px;">
                                                            <span class="text-right pointer">
                                                                <i class="icon icon-dots-vertical text-white"></i>
                                                            </span>
                                                            <el-dropdown-menu slot="dropdown">
                                                                <el-dropdown-item @click.native="showEditTable(index)">
                                                                    <span>Rename</span>
                                                                </el-dropdown-item>
                                                                <el-dropdown-item @click.native="viewQRCode(table)">
                                                                    <span>View</span>
                                                                </el-dropdown-item>
                                                                <el-dropdown-item v-if="table.assigned_to" @click.native="unAssignWaiter(table)">
                                                                    <span>Unassign Waiter</span>
                                                                </el-dropdown-item>
                                                            </el-dropdown-menu>
                                                        </el-dropdown>
                                                        <span class="d-flex align-items-center justify-content-center flex-column" @click="selectTableForWaiterSelection(table, index)">
                                                            {{table.table_id}}
                                                            <span class="d-block" v-if="table.assigned_to">
                                                                {{table.assigned_to.full_name}}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                                <modal name="rename-table" transition="nice-modal-fade" class="final-modal">
                                                    <div class="v-modal-content">
                                                        <div class="v-modal-header">
                                                            <span class="v-modal-dialog-title v-modal-title">RENAME
                                                                TABLE {{editTable.previous_table_id}}</span>
                                                        </div>
                                                        <div class="v-modal-body">
                                                            <div class="form-group contactGroup"
                                                                style="margin-top: -15px;">
                                                                <input @input="tableNameUpdate" class="inputContact"
                                                                    type="text" name="categoryName"
                                                                    v-model="table_update_name" :maxlength="max"
                                                                    autofocus required />
                                                                <span class="highlightContact"></span>
                                                                <span class="barContact"></span>
                                                                <label class="labelContact">
                                                                    Rename {{editTable.previous_table_id}} To
                                                                    <sup>★</sup>
                                                                </label>
                                                                <div v-show="stopEditing" class="text-danger">Please
                                                                    Enter Table Name</div>
                                                            </div>
                                                            <p class="pull-right text-right text-grey font-600">
                                                                {{max - editTable.table_id.length}} / {{max}}</p>
                                                        </div>
                                                        <div class="v-modal-footer modal-bg text-center pt-5 pb-5">
                                                            <a class="btn btn-white btn-smm btn-no-border-blue text-uppercase ml-1"
                                                                @click="hideEditTable">CANCEL</a>
                                                            <a v-if="editTable.table_id != ''"
                                                                class="btn btn-secondary btn-smm text-uppercase btn-7"
                                                                @click="saveEditTable">SAVE</a>
                                                            <a v-else disabled
                                                                class="btn btn-secondary btn-smm text-uppercase disabled">SAVE</a>
                                                        </div>
                                                    </div>
                                                </modal>
                                                <modal name="assign-table-to-waiter" transition="nice-modal-fade" class="final-modal addon-edit-popup">
                                                    <div class="v-modal-content">
                                                        <div class="general-section pt-2 pl-0 pr-0 pb-1 m-4">
                                                            <div class="v-modal-body pt-0 pb-0" style="overflow-y: auto !important;max-height: 355px; background-color: transparent !important;">
                                                                <div class="d-form">
                                                                    <div class="mt-3">
                                                                        <div class="text-center">
                                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/parrot.svg" width="100" />
                                                                        </div>
                                                                        <h2 class="mt-3 text-center" style="color: #303031;font-size: 30px;">Assign Tables!</h2>
                                                                        <p class="text-center" style="color: #707070;max-width: 400px;margin: 0 auto;font-size: 20px;">Select checkbox will allow to assign tables to waiters, would like to continue?</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="v-modal-dialog-actions mr-2 pt-0 mt-4">
                                                                <div>
                                                                    <button @click="yesNoAssignWaiter('no')" class="btn btn-smm btn-new-outline-primary">
                                                                        No
                                                                    </button>
                                                                    <button @click="yesNoAssignWaiter('yes')" class="btn btn-smm btn-secondary ml-4">
                                                                        Yes
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </modal>
                                                <modal name="unassign-table-to-waiter" transition="nice-modal-fade" class="final-modal addon-edit-popup">
                                                    <div class="v-modal-content">
                                                        <div class="general-section pt-2 pl-0 pr-0 pb-1 m-4">
                                                            <div class="v-modal-body pt-0 pb-0" style="overflow-y: auto !important;max-height: 355px; background-color: transparent !important;">
                                                                <div class="d-form">
                                                                    <div class="mt-3">
                                                                        <div class="text-center">
                                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/snake.svg" width="100" />
                                                                        </div>
                                                                        <h2 class="mt-3 text-center" style="color: #303031;font-size: 30px;">Alert!</h2>
                                                                        <p class="text-center" style="color: #707070;max-width: 400px;margin: 0 auto;font-size: 20px;">Deselect checkbox will remove waiters of the
                                                                            assigned table, would like to continue?</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="v-modal-dialog-actions mr-2 pt-0 mt-4">
                                                                <div>
                                                                    <button @click="yesNoUnAssignWaiter('no')" class="btn btn-smm btn-new-outline-primary">
                                                                        No
                                                                    </button>
                                                                    <button @click="yesNoUnAssignWaiter('yes')" class="btn btn-smm btn-secondary ml-4">
                                                                        Yes
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </modal>
                                                <modal v-if="assing_tables_to_waiter_popup" name="assign-waiter-to-tables" transition="nice-modal-fade" class="final-modal assign-waiter-to-tables-popup">
                                                    <div class="v-modal-content">
                                                        <div class="v-modal-header">
                                                            <span class="v-modal-dialog-title v-modal-title">Assign Table</span>
                                                            <button type="button" aria-label="Close" style="background-color: transparent;" @click="hideAssignWaiterPopup" >
                                                                <span aria-hidden="true">
                                                                    <i class="icon icon-close text-white"></i>
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div class="general-section p-4" style="background-color: #eff9fd;">
                                                            <div class="v-modal-body pt-0 pb-0" style="overflow-y: scroll !important;max-height: 355px; background-color: transparent !important;">
                                                                <div class="d-form" style="border: solid 0.5px #206ad3;border-radius: 5px;padding: 15px; background-color: #fff;">
                                                                    <div class="mt-3">
                                                                        <div>
                                                                            <div class="form-group contactGroup" style="margin-top: -25px;">
                                                                                <label class="text-blue font-600" style="font-size: 14px;">
                                                                                    Table
                                                                                </label>
                                                                                <div class="d-flex flex-wrap align-items-center" style="border-bottom: 1px solid #a9aaad !important;">
                                                                                    <div v-for="(selected_table, selected_table_index) in selected_tables" :key="selected_table_index+'selected_table_index'">
                                                                                        {{selected_table.table_name}}<span v-if="selected_tables.length>1 && (selected_table_index+1 < selected_tables.length)">;</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="mt-4">
                                                                            <div class="form-group contactGroup" style="margin-top: -25px;">
                                                                                <label class="text-blue font-600" style="font-size: 14px;">
                                                                                    Assign to Waiter :
                                                                                </label>
                                                                                <multiselect class="digi-multiselect" :options="waiter_options" v-model="selected_waiter"
                                                                                    :searchable="true" :close-on-select="true" :show-labels="false" 
                                                                                    :loading="isLoadingWaiters" @open="fetchWaiters" @search-change="searchWaiters" placeholder="Select Waiter" 
                                                                                    v-validate="'required'" label="full_name" name="assignwaiter">
                                                                                    <template slot="afterList">
                                                                                        <div v-observe-visibility="reachedEndOfListWaiters" />
                                                                                    </template>
                                                                                    <template slot="noOptions">
                                                                                        <div class="text-secondary text-center fw-600">Waiters Not
                                                                                            Found</div>
                                                                                    </template>
                                                                                    <template slot="noResult">
                                                                                        <div class="text-secondary text-center fw-600">No Results Found
                                                                                        </div>
                                                                                    </template>
                                                                                </multiselect>
                                                                                <span class="invalid-feedback-form text-danger" v-show="errors.has('assignwaiter')">Please Select Waiter</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="v-modal-dialog-actions mr-2 pt-0 mt-4">
                                                                <div>
                                                                    <button class="btn btn-danger" @click="hideAssignWaiterPopup" :disabled="waiter_selection_save_api_inprogress" style="border-radius: 5px !important;"> Cancel</button>
                                                                    <button class="btn btn-success ml-5" @click="saveWaiterSelection" :disabled="waiter_selection_save_api_inprogress" style="color: #fff !important;border-radius: 5px !important;"> Save
                                                                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="waiter_selection_save_api_inprogress" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </modal>
                                            </div>
                                        </div>
                                        <div role="tabpanel" class="tab-pane fade other-settings-other"
                                            id="currency-setting" aria-labelledby="currency-setting">
                                            <div class="row res-settings w-100 ml-0"
                                                style="display: -webkit-inline-box;">
                                                <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex  flex-wrap">
                                                    <div class="form-group contactGroup w-100 pb-5">
                                                        <label class="text-secondary pr-2 fw-600 pb-1">Currency
                                                            Setting</label>
                                                        <label class="text-secondary">
                                                            Currency
                                                        </label>
                                                        <div class="borderBottom">
                                                            <span class="font-600">{{generalDetails.currency.name}}
                                                                {{generalDetails.currency.symbol}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="row res-settings w-100 ml-0 pb-5" style="display: -webkit-inline-box;">
                                                <div class="col-lg-12 col-md-12 col-sm-12">
                                                    <div class="form-group contactGroup w-100 pb-3">
                                                        <label class="text-secondary pr-2 fw-600 pb-1">Precision Settings :</label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 d-flex flex-wrap">
                                                    <label class="text-secondary">
                                                        Decimal Points
                                                    </label>
                                                    <el-switch class="pl-5" @change="UpdateDecimal()" v-model="generalDetails.is_decimal_points_allowed"></el-switch>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 d-flex flex-nowrap align-items-center" v-if="generalDetails.is_decimal_points_allowed">
                                                    <label class="text-secondary">
                                                        No. Of Decimal Points:
                                                    </label>
                                                    <input class="ml-5" id="preventkeys"  @blur="checkDp()" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="4" :readonly="!generalDetails.is_decimal_points_allowed" style="width:200px !important;padding: 5px 10px !important;border-radius: 8px !important;" type="number" v-on:keypress="isNumber($event)" v-model.number="generalDetails.no_of_decimal_points"/>
                                                </div>
                                            </div> -->
                                            <div class="row mt-5">
                                                <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings pl-4"
                                                    style="display: -webkit-inline-box;">
                                                    <div class="col-lg-6">
                                                        <label class="text-secondary pr-2 fw-600 pb-1">Roundoff Settings
                                                            :</label><br>
                                                        <div class="form-check form-check-inline">
                                                            <label class="form-check-label">
                                                                <input v-model="generalDetails.round_off_settings"
                                                                    type="radio" class="form-check-input"
                                                                    value="neutral" />
                                                                <span class="radio-icon"></span>
                                                                <span class="ml-6" style="margin-top: 3px;">Neutral</span>
                                                            </label>
                                                        </div>
                                                        <!-- <div class="form-check form-check-inline">
                                                            <label class="form-check-label">
                                                                <input
                                                                    v-model="generalDetails.round_off_settings"
                                                                    type="radio"
                                                                    class="form-check-input"
                                                                    value="round_down"
                                                                />
                                                                <span class="radio-icon"></span>
                                                                <span>Round Down</span>
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <label class="form-check-label">
                                                            <input
                                                                v-model="generalDetails.round_off_settings"
                                                                type="radio"
                                                                class="form-check-input"
                                                                value="round_up"
                                                            />
                                                            <span class="radio-icon"></span>
                                                            <span>Round Up</span>
                                                            </label>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pb-5 text-center pt-5">
                                                <button class="btn btn-secondary btn-smm"
                                                    style="text-transform: uppercase !important;font-size: 12px !important;background: #fff !important;color: #00448b !important;"
                                                    @click="setGeneralDetails()" :disabled="settings_save_api_call">RESET</button>
                                                <button type="submit" class="btn btn-secondary btn-smm ml-5 btn-8"
                                                    style="text-transform: uppercase !important;font-size: 12px !important;background: #fff !important;color: #00448b !important;"
                                                    @click="saveEditedOutlet('generalDetails', outlet, generalDetails)" :disabled="settings_save_api_call">SAVE
                                                    <half-circle-spinner :animation-duration="1000" :size="20" v-if="settings_save_api_call" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                                </button>
                                            </div>
                                        </div>
                                        <div role="tabpanel" class="tab-pane fade other-settings-other"
                                            id="other-settings-tab" aria-labelledby="other-settings-tab">
                                            <div class="col-lg-12 col-md-12 col-sm-12 business-div">
                                                <div class="form-group contactGroup">
                                                    <label class="fs-14" style="color:#00448b;">Business Day Start
                                                        Time</label>
                                                    <vue-timepicker drop-direction="down" class="oraganization-time"
                                                        v-model="otherDetails.business_day_start_time" format="hh:mm A"
                                                        close-on-complete></vue-timepicker>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-4 pt-2"
                                                style="border-radius: 10px;padding-bottom: 30px;box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16)!important; background-color: #dee8f2!important;">
                                                <h6 class="heading-details pb-1 pt-2">
                                                    OPENING HOURS
                                                    <span class="text-danger fs-13"
                                                        v-show="noStartEndTime && ifOtherDetailsSubmitted"
                                                        style="font-size:15px;">(Please Fill Start-Time/End-Time For
                                                        Open Days)</span>
                                                </h6>
                                                <div class="contact-item row align-items-center multiple-heading py-sm-4 pl-5 pr-3"
                                                    style="background-color:#00448b!important;">
                                                    <div class="col-lg-2 col-md-2 col-sm-2 multiple-heading-div">Day
                                                    </div>
                                                    <div class="col-lg-2 col-md-2 col-sm-2 multiple-heading-div">
                                                        Open/Close</div>
                                                    <div
                                                        class="col-lg-4 col-md-4 col-sm-4 multiple-heading-div text-center openingHours">
                                                        Opening Hours</div>
                                                    <div class="col-lg-4 col-md-4 col-sm-4 multiple-heading-div text-center closingHours"
                                                        style="text-align: left!important;">Closing Hours</div>
                                                </div>
                                                <div class="add-member-modal pl-5 pr-3">
                                                    <!-- Modified -->
                                                    <div class="contact-item row align-items-center py-sm-4">
                                                        <div
                                                            class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                                            Monday</div>
                                                        <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                                            <el-switch
                                                                v-model="otherDetails.opening_hours.monday.status"
                                                                @change="clearOpeningHours(otherDetails.opening_hours.monday.status,'monday')">
                                                            </el-switch>
                                                        </div>
                                                        <!-- <div
                                                            class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <el-time-picker class="mr-3"
                                                                v-model="otherDetails.opening_hours.tuesday.start"
                                                                placeholder="Select Start Time"
                                                                :disabled="!otherDetails.opening_hours.tuesday.status">
                                                            </el-time-picker>
                                                            <el-time-picker
                                                                v-model="otherDetails.opening_hours.tuesday.end"
                                                                placeholder="Select End Time"
                                                                :disabled="!otherDetails.opening_hours.tuesday.status">
                                                            </el-time-picker>
                                                        </div> -->
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.monday.start" :disabled="!otherDetails.opening_hours.monday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.monday.start_meridian"  class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above meridian_dropdown" style="width:80px !important" :options="meridian_options" placeholder="" :disabled="!otherDetails.opening_hours.monday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-start outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.monday.end" :disabled="!otherDetails.opening_hours.monday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.monday.end_meridian"  class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.monday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="contact-item row align-items-center py-sm-4">
                                                        <div
                                                            class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                                            Tuesday</div>
                                                        <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                                            <el-switch
                                                                v-model="otherDetails.opening_hours.tuesday.status"
                                                                @change="clearOpeningHours(otherDetails.opening_hours.tuesday.status,'tuesday')">
                                                            </el-switch>
                                                        </div>
                                                        <!-- <div
                                                            class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <el-time-picker class="mr-3"
                                                                v-model="otherDetails.opening_hours.tuesday.start"
                                                                placeholder="Select Start Time"
                                                                :disabled="!otherDetails.opening_hours.tuesday.status">
                                                            </el-time-picker>
                                                            <el-time-picker
                                                                v-model="otherDetails.opening_hours.tuesday.end"
                                                                placeholder="Select End Time"
                                                                :disabled="!otherDetails.opening_hours.tuesday.status">
                                                            </el-time-picker>
                                                        </div> -->
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.tuesday.start" :disabled="!otherDetails.opening_hours.tuesday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.tuesday.start_meridian" class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.tuesday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-start outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.tuesday.end" :disabled="!otherDetails.opening_hours.tuesday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <v-select v-model="otherDetails.opening_hours.tuesday.meridian_end" class="filterName meridian" :options="meridian_options" :disabled="!otherDetails.opening_hours.tuesday.status"></v-select> -->
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.tuesday.end_meridian" class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.tuesday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="contact-item row align-items-center py-sm-4">
                                                        <div
                                                            class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                                            Wednesday</div>
                                                        <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                                            <el-switch
                                                                v-model="otherDetails.opening_hours.wednesday.status"
                                                                @change="clearOpeningHours(otherDetails.opening_hours.wednesday.status,'wednesday')">
                                                            </el-switch>
                                                        </div>
                                                        <!-- <div
                                                            class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <el-time-picker class="mr-3"
                                                                v-model="otherDetails.opening_hours.wednesday.start"
                                                                placeholder="Select Start Time"
                                                                :disabled="!otherDetails.opening_hours.wednesday.status">
                                                            </el-time-picker>
                                                            <el-time-picker
                                                                v-model="otherDetails.opening_hours.wednesday.end"
                                                                placeholder="Select End Time"
                                                                :disabled="!otherDetails.opening_hours.wednesday.status">
                                                            </el-time-picker>
                                                        </div> -->
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.wednesday.start" :disabled="!otherDetails.opening_hours.wednesday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.wednesday.start_meridian" class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.wednesday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-start outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.wednesday.end" :disabled="!otherDetails.opening_hours.wednesday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <v-select v-model="otherDetails.opening_hours.wednesday.meridian_end" class="filterName meridian" :options="meridian_options" :disabled="!otherDetails.opening_hours.wednesday.status"></v-select> -->
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.tuesday.end_meridian" class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.tuesday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="contact-item row align-items-center py-sm-4">
                                                        <div
                                                            class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                                            Thursday</div>
                                                        <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                                            <el-switch
                                                                v-model="otherDetails.opening_hours.thursday.status"
                                                                @change="clearOpeningHours(otherDetails.opening_hours.thursday.status,'thursday')">
                                                            </el-switch>
                                                        </div>
                                                        <!-- <div
                                                            class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <el-time-picker class="mr-3"
                                                                v-model="otherDetails.opening_hours.thursday.start"
                                                                placeholder="Select Start Time"
                                                                :disabled="!otherDetails.opening_hours.thursday.status">
                                                            </el-time-picker>
                                                            <el-time-picker
                                                                v-model="otherDetails.opening_hours.thursday.end"
                                                                placeholder="Select End Time"
                                                                :disabled="!otherDetails.opening_hours.thursday.status">
                                                            </el-time-picker>
                                                        </div> -->
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.thursday.start" :disabled="!otherDetails.opening_hours.thursday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.thursday.start_meridian" class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.thursday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-start outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.thursday.end" :disabled="!otherDetails.opening_hours.thursday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <v-select v-model="otherDetails.opening_hours.thursday.meridian_end" class="filterName meridian" :options="meridian_options" :disabled="!otherDetails.opening_hours.thursday.status"></v-select> -->
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.thursday.end_meridian" class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.thursday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="contact-item row align-items-center py-sm-4">
                                                        <div
                                                            class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                                            Friday</div>
                                                        <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                                            <el-switch
                                                                v-model="otherDetails.opening_hours.friday.status"
                                                                @change="clearOpeningHours(otherDetails.opening_hours.friday.status,'friday')">
                                                            </el-switch>
                                                        </div>
                                                        <!-- <div
                                                            class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <el-time-picker class="mr-3"
                                                                v-model="otherDetails.opening_hours.friday.start"
                                                                placeholder="Select Start Time"
                                                                :disabled="!otherDetails.opening_hours.friday.status">
                                                            </el-time-picker>
                                                            <el-time-picker
                                                                v-model="otherDetails.opening_hours.friday.end"
                                                                placeholder="Select End Time"
                                                                :disabled="!otherDetails.opening_hours.friday.status">
                                                            </el-time-picker>
                                                        </div> -->
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.friday.start" :disabled="!otherDetails.opening_hours.friday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.friday.start_meridian" class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.friday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-start outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.friday.end" :disabled="!otherDetails.opening_hours.friday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <v-select v-model="otherDetails.opening_hours.friday.meridian_end" class="filterName meridian" :options="meridian_options" :disabled="!otherDetails.opening_hours.friday.status"></v-select> -->
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.friday.end_meridian" class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.friday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="contact-item row align-items-center py-sm-4">
                                                        <div
                                                            class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                                            Saturday</div>
                                                        <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                                            <el-switch
                                                                v-model="otherDetails.opening_hours.saturday.status"
                                                                @change="clearOpeningHours(otherDetails.opening_hours.saturday.status,'saturday')">
                                                            </el-switch>
                                                        </div>
                                                        <!-- <div
                                                            class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <el-time-picker class="mr-3"
                                                                v-model="otherDetails.opening_hours.saturday.start"
                                                                placeholder="Select Start Time"
                                                                :disabled="!otherDetails.opening_hours.saturday.status">
                                                            </el-time-picker>
                                                            <el-time-picker
                                                                v-model="otherDetails.opening_hours.saturday.end"
                                                                placeholder="Select End Time"
                                                                :disabled="!otherDetails.opening_hours.saturday.status">
                                                            </el-time-picker>
                                                        </div> -->
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.saturday.start" :disabled="!otherDetails.opening_hours.saturday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.saturday.start_meridian" class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.saturday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-start outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.saturday.end" :disabled="!otherDetails.opening_hours.saturday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <v-select v-model="otherDetails.opening_hours.saturday.meridian_end" class="filterName meridian" :options="meridian_options" :disabled="!otherDetails.opening_hours.saturday.status"></v-select> -->
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.saturday.end_meridian" class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.saturday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="contact-item row align-items-center py-sm-4">
                                                        <div
                                                            class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                                            Sunday</div>
                                                        <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate">
                                                            <el-switch
                                                                v-model="otherDetails.opening_hours.sunday.status"
                                                                @change="clearOpeningHours(otherDetails.opening_hours.sunday.status,'sunday')">
                                                            </el-switch>
                                                        </div>
                                                        <!-- <div
                                                            class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <el-time-picker class="mr-3"
                                                                v-model="otherDetails.opening_hours.sunday.start"
                                                                placeholder="Select Start Time"
                                                                :disabled="!otherDetails.opening_hours.sunday.status">
                                                            </el-time-picker>
                                                            <el-time-picker
                                                                v-model="otherDetails.opening_hours.sunday.end"
                                                                placeholder="Select End Time"
                                                                :disabled="!otherDetails.opening_hours.sunday.status">
                                                            </el-time-picker>
                                                        </div> -->
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.sunday.start" :disabled="!otherDetails.opening_hours.sunday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.sunday.start_meridian" class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.sunday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4 col-md-4 col-sm-4 time-pick d-flex justify-content-start outletnewtimepicker text-center">
                                                            <div class="d-flex">
                                                                <vue-timepicker class="filterName mr-1" v-model="otherDetails.opening_hours.sunday.end" :disabled="!otherDetails.opening_hours.sunday.status" format="hh:mm A"></vue-timepicker>
                                                                <!-- <multiselect
                                                                    v-model="otherDetails.opening_hours.sunday.end_meridian" class="multiselect digi-multiselect approval-manager filter-multiple mt-1 multiselect--above" style="width:80px !important" placeholder="" :options="meridian_options" :disabled="!otherDetails.opening_hours.sunday.status"
                                                                ></multiselect> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 mt-4 pt-2"
                                                style="border-radius: 10px;padding-bottom: 5px; box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16)!important;background-color: #dee8f2!important;">
                                                <h6 class="heading-details pb-1 pt-2 fs-16">
                                                    Default Timings
                                                    <el-tooltip content="This Timing are noted for Generating Report"
                                                        placement="top">
                                                        <img
                                                            src="https://cdn.digicollect.com/cdn/pos/images-new-theme/info_checkout.svg" />
                                                    </el-tooltip>
                                                </h6>
                                                <div class="contact-item row align-items-center multiple-heading py-sm-4 pl-5 pr-3"
                                                    style="background-color:#00448b!important;">
                                                    <div class="col-lg-2 col-md-2 col-sm-2 multiple-heading-div">Meals
                                                    </div>
                                                    <div class="col-lg-2 col-md-2 col-sm-2 multiple-heading-div"></div>
                                                    <div
                                                        class="col-lg-4 col-md-4 col-sm-4 multiple-heading-div text-left openingHours">
                                                        Starting Time</div>
                                                    <div class="col-lg-4 col-md-4 col-sm-4 multiple-heading-div text-left closingHours"
                                                        >Ending Time</div>
                                                </div>
                                                <div class="contact-item row align-items-center py-sm-4">
                                                    <div
                                                        class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                                        Breakfast *</div>
                                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate"></div>
                                                    <div
                                                        class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                        <vue-timepicker v-validate="'required'" name="starting_time" class="filterName mr-1" v-model="otherDetails.default_timings.breakfast.start_time" format="hh:mm A"></vue-timepicker>
                                                        <vue-timepicker v-validate="'required'" name="ending_time" class="filterName mr-1" v-model="otherDetails.default_timings.breakfast.end_time" format="hh:mm A"></vue-timepicker>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3 email text-truncate"></div>
                                                    <div
                                                        class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex outletnewtimepicker ">
                                                        <span
                                                            class="invalid-feedback-form text-danger mt-0 text-right d-block "
                                                            v-show="errors.has('starting_time')">{{ errors.first("starting_time") }}</span>
                                                        <span
                                                            class="invalid-feedback-form text-danger mt-0 text-right d-block "
                                                            v-show="errors.has('ending_time')">{{ errors.first("ending_time") }}</span>
                                                    </div>
                                                </div>
                                                <div class="contact-item row align-items-center py-sm-4">
                                                    <div
                                                        class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                                        Lunch *</div>
                                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate"></div>
                                                    <div
                                                        class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                        <vue-timepicker v-validate="'required'" name="starting_time_lunch" class="filterName mr-1" v-model="otherDetails.default_timings.lunch.start_time" format="hh:mm A"></vue-timepicker>
                                                        <vue-timepicker v-validate="'required'" name="ending_time_lunch" class="filterName mr-1" v-model="otherDetails.default_timings.lunch.end_time" format="hh:mm A"></vue-timepicker>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3 email text-truncate"></div>
                                                    <div
                                                        class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex outletnewtimepicker ">
                                                        <span
                                                            class="invalid-feedback-form text-danger mt-0 text-right d-block "
                                                            v-show="errors.has('starting_time_lunch')">{{ errors.first("starting_time_lunch") }}</span>
                                                        <span
                                                            class="invalid-feedback-form text-danger mt-0 text-right d-block "
                                                            v-show="errors.has('ending_time_lunch')">{{ errors.first("ending_time_lunch") }}</span>
                                                    </div>
                                                </div>
                                                <div class="contact-item row align-items-center py-sm-4 mb-5">
                                                    <div
                                                        class="col-lg-2 col-md-2 col-sm-2 text-truncate font-weight-bold product-name">
                                                        Dinner *</div>
                                                    <div class="col-lg-2 col-md-2 col-sm-2 email text-truncate"></div>
                                                    <div
                                                        class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex justify-content-center outletnewtimepicker text-center">
                                                        <vue-timepicker v-validate="'required'" name="starting_time_dinner" class="filterName mr-1" v-model="otherDetails.default_timings.dinner.start_time" format="hh:mm A"></vue-timepicker>
                                                        <vue-timepicker v-validate="'required'" name="ending_time_dinner" class="filterName mr-1" v-model="otherDetails.default_timings.dinner.end_time" format="hh:mm A"></vue-timepicker>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3 email text-truncate"></div>
                                                    <div
                                                        class="col-lg-8 col-md-8 col-sm-8 time-pick d-flex outletnewtimepicker ">
                                                        <span
                                                            class="invalid-feedback-form text-danger mt-0 text-right d-block "
                                                            v-show="errors.has('starting_time_dinner')">{{ errors.first("starting_time_dinner") }}</span>
                                                        <span
                                                            class="invalid-feedback-form text-danger mt-0 text-right d-block "
                                                            v-show="errors.has('ending_time_dinner')">{{ errors.first("ending_time_dinner") }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pb-5 text-center mt-10">
                                                <button class="btn btn-link btn-smm"
                                                    style="text-transform: uppercase !important;font-size: 12px !important;color:#fff!important;border:1px solid #fff!important;"
                                                    @click="setOtherDetails()" :disabled="settings_save_api_call">RESET</button>
                                                <button style="background-color:#fff!important;color:#00448b!important;"
                                                    type="submit" class="btn btn-secondary btn-smm btn-9 ml-5"
                                                    @click="saveOtherDetailsOutletConfirm(outlet, otherDetails)" :disabled="settings_save_api_call">SAVE
                                                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="settings_save_api_call" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                                    </button>
                                            </div>
                                        </div>
                                        <div role="tabpanel" class="tab-pane fade other-settings-other"
                                            id="outlet-configuration" aria-labelledby="outlet-configuration">
                                            <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings"
                                                style="display: -webkit-inline-box;">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                    alt="Vertical Cash" />
                                                <div class="col-lg-6">
                                                    <label class="text-secondary pr-2 fw-600 pb-1">Order Placement Type
                                                        :</label>
                                                    <br />
                                                    <el-checkbox v-model="otherDetails.enable_fast_checkout">Enable Fast
                                                        Checkout</el-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings flex-wrap"
                                                style="display: -webkit-inline-box;">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                    alt="Vertical Cash" class="displayHidden" />
                                                <div class="col-lg-6 pb-1">
                                                    <label class="text-secondary pr-2 fw-600 pb-1">Include Discount in
                                                        :</label>
                                                    <br />
                                                    <el-checkbox v-model="otherDetails.additional_charge_with_discount">
                                                        Additional Charges</el-checkbox>
                                                </div>
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                    alt="Vertical Cash" class="displayHidden" />
                                                <div style="padding-left:15px;">
                                                    <label class="text-secondary pr-2 fw-600 pb-1">Double Discount
                                                        :</label>
                                                    <br />
                                                    <el-checkbox v-model="otherDetails.double_discount">Allow Double
                                                        Discount</el-checkbox>
                                                </div>
                                            </div>
                                            <div v-if="otherDetails.hasOwnProperty('courses_settings')" class="col-lg-12 col-md-12 col-sm-12 pt-3 res-settings">
                                                <label class="text-secondary pr-2 fw-600 pb-1">Courses Settings
                                                    :</label>
                                                <br />
                                                <div class="col-lg-12 row">
                                                    <div class="form-group contactGroup"
                                                        style="width: 100%;margin-top: -3px!important;">
                                                        <input name="product_name" class="inputContact" type="number"
                                                            v-model.number="otherDetails.courses_settings.no_of_courses"
                                                            @blur="checkCourseSettings" required maxlength="5"/>
                                                        <span class="highlightContact"></span>
                                                        <span class="barContact"></span>
                                                        <label class="labelContact">
                                                            Enter the Number of Courses
                                                            <sup>★</sup> (Enter value from 1 to 5)
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 row pt-3 pb-2 no-gutters">
                                                    <div class="col-lg-6">
                                                        <label class="text-secondary pr-2 fw-600 pb-1">Course Management
                                                            Configuration :</label>
                                                        <br />
                                                        <el-checkbox
                                                            v-model="otherDetails.courses_settings.default_deliver_together">
                                                            Default Deliver Together</el-checkbox>
                                                        <el-checkbox
                                                            v-model="otherDetails.courses_settings.enable_courses_firing">
                                                            Enable Course Firing</el-checkbox>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <label class="text-secondary pr-2 fw-600 pb-1">Default Display
                                                            Status For :</label>
                                                        <br />
                                                        <el-checkbox
                                                            v-model="otherDetails.courses_settings.default_display_status_for_courses">
                                                            Courses</el-checkbox>
                                                        <el-checkbox
                                                            v-model="otherDetails.courses_settings.default_display_status_for_items">
                                                            Items</el-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 pt-3 res-settings"
                                                >
                                                <div class="d-flex flex-wrap" style="display: -webkit-inline-box;">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                        alt="Vertical Cash" class="displayHidden" />
                                                    <div class="col-lg-6 pb-1">
                                                        <label class="text-secondary pr-2 fw-600 pb-1">Checkout screen :</label>
                                                        <br />
                                                        <el-checkbox @change="hideRowWithValueZero($event)" v-model="hide_row_with_value_zero">
                                                            Hide rows with a value of 0</el-checkbox>
                                                    </div>
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                        alt="Vertical Cash" class="displayHidden" />
                                                    <div style="padding-left:15px;">
                                                        <label class="text-secondary pr-2 fw-600 pb-1">Place Order</label>
                                                        <br />
                                                        <el-checkbox @change="showPlaceOrderInCheckout($event)" v-model="show_place_order_in_checkout">
                                                            Show place order option when enable</el-checkbox>
                                                    </div>
                                                </div>
                                                <div class="d-flex flex-wrap" style="display: -webkit-inline-box;">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                        alt="Vertical Cash" class="displayHidden" />
                                                    <div class="col-lg-6 pb-1">
                                                        <label class="text-secondary pr-2 fw-600 pb-1">Promotion and
                                                            Entertainment :</label>
                                                        <br />
                                                        <el-checkbox v-model="otherDetails.show_promotion_when_value_zero">
                                                            Show Promotion/Entertainment when value is Zero</el-checkbox>
                                                    </div>
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                        alt="Vertical Cash" class="displayHidden" />
                                                    <div style="padding-left:15px;">
                                                        <label class="text-secondary pr-2 fw-600 pb-1">Loyalty :</label>
                                                        <br />
                                                        <el-checkbox v-model="otherDetails.show_loyalty_when_value_zero">
                                                            Show Loyalty when value is Zero</el-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings"
                                                style="display: -webkit-inline-box;">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                    alt="Vertical Cash" class="displayHidden" />
                                                <div class="col-lg-12 pb-1">
                                                    <label class="text-secondary pr-2 fw-600 pb-1">Auto Logout :
                                                        <el-switch class="ml-2 approvalswitch" v-model="auto_logout"></el-switch>
                                                    </label>
                                                    <br />
                                                    <div class="d-flex align-items-end text-secondary fw-600">
                                                        Automatic logout when inactive for 
                                                        <input min="0" type="number" :readonly="!auto_logout" v-model="auto_logout_mins" class="mx-2" style="width: 50px;border-top: 0; border-left: 0; border-right: 0; border-bottom: solid 1px #00448b; background-color: transparent; outline: 0 none;" />
                                                        mins
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings"
                                                style="display: -webkit-inline-box;">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                    alt="Vertical Cash" class="displayHidden" />
                                                <div class="col-lg-12 pb-1 pt-3">
                                                    <label class="text-secondary pr-2 fw-600">Order Settings :</label>
                                                    <br />
                                                    <div class="row flex-wrap m-0">
                                                        <div class="d-flex align-items-end text-secondary fw-600 mr-8">
                                                            <span>Auto Prepare Item when Placed</span>
                                                            <el-switch class="ml-2 approvalswitch" @change="autoPrepareChange($event)" v-model="auto_prepare_when_placed">                                                        </el-switch>
                                                        </div>
                                                        <div class="d-flex align-items-end text-secondary fw-600">
                                                            <span>Auto Deliver Item when Placed</span>
                                                            <el-switch class="ml-2 approvalswitch" :disabled="!auto_prepare_when_placed" v-model="auto_deliver_when_placed">
                                                            </el-switch>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings"
                                                style="display: -webkit-inline-box;">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                    alt="Vertical Cash" class="displayHidden" />
                                                <div class="col-lg-12 pb-1 pt-3">
                                                    <label class="text-secondary pr-2 fw-600">Order Settings :</label>
                                                    <br />
                                                    <div class="row flex-wrap m-0">
                                                        <div class="d-flex align-items-end text-secondary fw-600 mr-8">
                                                            <span>Upcharge for Credit Card usage</span>
                                                            <el-switch class="ml-2 approvalswitch" @change="upchargeForCreditCardUsage($event)" v-model="upcharge_for_credit_card_usage"></el-switch>
                                                        </div>
                                                        <div class="d-flex align-items-end text-secondary fw-600">
                                                            <span>Credit Card Charges (%) : </span>
                                                            <input min="0" type="number" :readonly="!upcharge_for_credit_card_usage" @change="creditCardPercentage($event)" data-vv-as="Credit Card Charge" v-validate="'min:0'" name="credit_card_charges_percentage" v-model="credit_card_charges_percentage" class="mx-2" style="width: 50px;border-top: 0; border-left: 0; border-right: 0; border-bottom: solid 1px #00448b; background-color: transparent; outline: 0 none;" />
                                                        </div>
                                                        <span class="text-danger fs-13" style="display:inline;"
                                                            v-show="errors.has('credit_card_charges_percentage')">{{errors.first('credit_card_charges_percentage')}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings"
                                                style="display: -webkit-inline-box;">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                    alt="Vertical Cash" class="displayHidden" />
                                                <div class="col-lg-12 pb-1 pt-3">
                                                    <label class="text-secondary pr-2 fw-600">Hybrid Settings :</label>
                                                    <br />
                                                    <div class="row flex-wrap m-0">
                                                        <div class="d-flex align-items-end text-secondary fw-600 mr-8">
                                                            <span>Configure to Hybrid mode</span>
                                                            <el-switch class="ml-2 approvalswitch" @change="hybridModeChange($event)" v-model="is_outlet_hybrid">                                                        </el-switch>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings"
                                                style="display: -webkit-inline-box;">
                                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                                    alt="Vertical Cash" class="displayHidden" />
                                                <div class="col-lg-12 pb-1 pt-3">
                                                    <label class="text-secondary pr-2 fw-600">Hybrid Settings :</label>
                                                    <br />
                                                    <label class="text-secondary pr-2 fw-600 pb-1 pt-2">Configure to Hybrid mode :
                                                        <el-switch class="ml-2 approvalswitch" v-model="hybrid_mode"></el-switch>
                                                    </label>
                                                </div>
                                            </div> -->
                                            <div class="pb-5 text-center mt-10">
                                                <button class="btn btn-link btn-smm"
                                                    style="text-transform: uppercase !important;font-size: 12px !important;color:#fff!important;border:1px solid #fff!important;"
                                                    @click="setOtherDetails()" :disabled="settings_save_api_call">RESET</button>
                                                <button style="background-color:#fff!important;color:#00448b!important;"
                                                    type="submit" class="btn btn-secondary btn-smm btn-10 ml-5"
                                                    :disabled="is_credit_card_charges_percentage_less_than_zero || settings_save_api_call" @click="saveOtherDetailsOutletConfirm(outlet, otherDetails)">SAVE
                                                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="settings_save_api_call" color="#00448b" style="position: absolute;top:6px;right:2px" />
                                                    </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="manageSettings" style="padding:2px;">
                                    <div class="list-title pointer" style="font-weight: 500;padding: 20px 10px;"
                                        v-show="outlet.outlet_type === 'pop-up'">
                                        <a class="existingVendors" :class="{ activeClass : isExisting === true }"
                                            @click="existingVendorsTab()">Existing Vendors</a>
                                        <a class="newReq" :class="{activeClass: isNewReq === true}"
                                            @click="newRequestsTab()">
                                            New Requests
                                        </a>
                                    </div>
                                    <div class="ev" v-if="outlet.outlet_type === 'pop-up' && isExisting">
                                        <div class="demo-content">
                                            <table class="table table-striped table-outlet-details">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>PHONE</th>
                                                        <th>EMAIL</th>
                                                        <th>WEBSITE</th>
                                                        <!-- <th>ACTIVE</th> -->
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="existing_vendor in existing_vendors"
                                                        :key="existing_vendor._id">
                                                        <td>{{existing_vendor.name}} ( {{existing_vendor.account_name}}
                                                            )</td>
                                                        <td>{{existing_vendor.phone_number}}</td>
                                                        <td>{{existing_vendor.email}}</td>
                                                        <td>
                                                            <a class="hotel-website"
                                                                target="_blank">{{existing_vendor.website}}</a>
                                                        </td>
                                                        <!-- <td><el-switch v-model="existing_vendor.is_active"></el-switch></td> -->
                                                        <td>
                                                            <a class="btn btn-secondary btn-smm"
                                                                @click="unregisterVendorForPopupStore(existing_vendor)">Unregister</a>
                                                        </td>
                                                    </tr>
                                                    <!-- <tr v-if="!existing_vendors.length">
                                            <td class="text-center" colspan="6">Vendors not available</td>
                                        </tr>-->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="nr" v-if="outlet.outlet_type === 'pop-up' && isNewReq">
                                        <div class="demo-content">
                                            <table class="table table-striped table-outlet-details">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>PHONE</th>
                                                        <th>EMAIL</th>
                                                        <th>WEBSITE</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="new_vendor_request in new_vendor_requests"
                                                        :key="new_vendor_request._id">
                                                        <td>{{new_vendor_request.vendor_name}} (
                                                            {{new_vendor_request.account_name}} )</td>
                                                        <td>{{new_vendor_request.phone}}</td>
                                                        <td>{{new_vendor_request.email}}</td>
                                                        <td>
                                                            <a class="hotel-website"
                                                                target="_blank">{{new_vendor_request.website}}</a>
                                                        </td>
                                                        <td>
                                                            <a class="decline"
                                                                @click="rejectVendorRequest(new_vendor_request)">Decline</a>
                                                            <a class="btn btn-secondary btn-smm"
                                                                @click="acceptVendorRequest(new_vendor_request)">Accept</a>
                                                        </td>
                                                    </tr>
                                                    <!-- <tr v-if="!new_vendor_requests.length">
                                            <td class="text-center" colspan="6">
                                            <strong>Vendor Requests not Available</strong>
                                            </td>
                                        </tr>-->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="vendor-div"
                                        v-if="outlet.outlet_type === 'vendor' && !outlet.details.popup_store">
                                        <span class="popAvailable">Available Pop-ups</span>
                                        <div class="demo-content">
                                            <table class="table table-striped table-outlet-details">
                                                <thead>
                                                    <tr>
                                                        <th>NAME</th>
                                                        <th>PHONE</th>
                                                        <th>EMAIL</th>
                                                        <th>WEBSITE</th>
                                                        <th>REGISTER</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="popup_store in popup_stores" :key="popup_store._id">
                                                        <td>{{popup_store.name}} ( {{popup_store.account_name}} )</td>
                                                        <td>{{popup_store.phone_number}}</td>
                                                        <td>{{popup_store.email}}</td>
                                                        <td>
                                                            <a class="hotel-website"
                                                                target="_blank">{{popup_store.website}}</a>
                                                        </td>
                                                        <td>
                                                            <a class="btn btn-secondary btn-smm"
                                                                @click="sendRequesttoPopupStore(popup_store._id, popup_store.account_id)">Send
                                                                Request</a>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="!popup_stores">
                                                        <td colspan="5">No pop-up stores Available</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="currentPopup ml-6"
                                        v-if="outlet.outlet_type === 'vendor' && outlet.details.popup_store">
                                        <span class="popAvailable" v-if="outlet.details.is_accepted">Current Pop-up
                                            Detail</span>
                                        <span class="popAvailable" v-if="!outlet.details.is_accepted">Available
                                            Pop-up</span>
                                        <hr />
                                        <div class="row">
                                            <div class="col-4 settings-cols">
                                                <div class="pt-4 pb-4 align-items-center" style="display: -webkit-box;">
                                                    <div class="col-auto text-bold" style="display: -webkit-box;">NAME :
                                                    </div>
                                                    <div class="col font-weight-500 text-muted"
                                                        style="padding-left: 30px;">{{popup_details.name}}</div>
                                                </div>
                                                <div class="pt-4 pb-4 align-items-center" style="display: -webkit-box;">
                                                    <div class="col-auto text-bold">PHONE :</div>
                                                    <div class="col font-weight-500 text-muted"
                                                        style="padding-left: 23px;">{{popup_details.phone_number}}</div>
                                                </div>
                                                <div class="pt-4 pb-4 align-items-center" style="display: -webkit-box;">
                                                    <div class="col-auto text-bold">EMAIL :</div>
                                                    <div class="col font-weight-500 text-muted"
                                                        style="padding-left: 30px;">{{popup_details.email}}</div>
                                                </div>
                                                <div class="pt-4 pb-4 align-items-center" style="display: -webkit-box;">
                                                    <div class="col-auto text-bold">WEBSITE :</div>
                                                    <div class="col font-weight-500 text-muted">
                                                        {{popup_details.website}}</div>
                                                </div>
                                                <div class="pt-4 pb-4 align-items-center" style="display: -webkit-box;">
                                                    <div class="col-auto text-bold">Status :</div>
                                                    <div class="col font-weight-600 text-secondary"
                                                        v-if="!outlet.details.is_accepted" style="padding-left: 33px;">
                                                        <i class="icon icon-timer text-secondary"
                                                            style="font-size: 18px;padding-right: 5px;"></i>Waiting
                                                    </div>
                                                    <div class="col font-weight-600 text-green"
                                                        v-if="outlet.details.is_accepted" style="padding-left: 33px;">
                                                        <i class="icon icon-check text-green"
                                                            style="font-size: 18px;padding-right: 5px;"></i> Approved
                                                    </div>
                                                    <div class="col font-weight-600 text-red"
                                                        v-if="outlet.details.is_rejected" style="padding-left: 33px;">
                                                        <i class="icon icon-cross text-red"
                                                            style="font-size: 18px;padding-right: 5px;"></i>Rejected
                                                    </div>
                                                </div>
                                                <!-- <el-checkbox v-model="checked" v-if="outlet.details.is_accepted" class="ml-5 pt-2">Make Unavailable</el-checkbox> -->
                                                <div class="mt-3 pl-3" v-if="outlet.details.is_accepted">
                                                    <a class="btn btn-secondary btn-smm"
                                                        @click="unregisterFormPopupStoreForVendor(outlet)">Unregister</a>
                                                </div>
                                                <div class="mt-4 pl-3" v-if="!outlet.details.is_accepted">
                                                    <a class="btn btn-danger btn-smm">Withdraw Request</a>
                                                </div>
                                            </div>
                                            <div class="col-8 settings-cols">
                                                <div class="card-outlet">
                                                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/company-logo.svg"
                                                        class="img-responsive outlet-logo" style="width: 300px;" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="cashRegisterSettings" class="mediaSize" style="padding-top:20px;">
                                    <register-printer :generalDetailsnew="generalDetails"></register-printer>
                                </div>
                                <div v-if="voidCancelled" class="mediaSize" style="padding-top:20px;">
                                    <void-cancelled :voidoutletId="voidoutletId" :delete_void_order="delete_void_order">
                                    </void-cancelled>
                                </div>
                                <div v-if="paymentSettings" style="padding-top: 20px;" class="payment-outlet-tab">
                                    <ul class="nav nav-tabs nav-site" id="myTab" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" data-toggle="tab" href="#card-provider"
                                                role="tab" aria-controls="card-provider" aria-expanded="true">Card
                                                Provider</a>
                                        </li>
                                        <!-- <li class="nav-item">
                                    <a
                                        class="nav-link"
                                        data-toggle="tab"
                                        href="#deposits"
                                        role="tab"
                                        aria-controls="deposits"
                                        aria-expanded="true"
                                    >Deposits</a>
                                    </li>-->
                                        <li class="nav-item">
                                            <a class="nav-link" data-toggle="tab" href="#payment-mode-setting"
                                                role="tab" aria-controls="payment-mode-setting">Payment Mode
                                                Settings</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content mt-6">
                                        <div role="tabpanel" class="tab-pane fade active show" id="card-provider"
                                            aria-labelledby="card-provider">
                                            <div class="col-md-12">
                                                <button type="submit" class="btn btn-secondary btn-smm"
                                                    style="color:#00448b!important;background-color:#fff!important;"
                                                    @click="newAddCash()">+ Add New Card Provider</button>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="table-row">
                                                    <table class="table table-payment table-scroll">
                                                        <thead>
                                                            <tr>
                                                                <th class="whiteSpace">Card Provider Name</th>
                                                                <th class="hidden-sm-down">Card Availability</th>
                                                                <th>Admin</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <!-- get_all_card_providers_api_inprogress -->
                                                            <tr v-if="get_all_card_providers_api_inprogress && card_details.length <= 0">
                                                                <td class="text-center" colspan="3"
                                                                    style="justify-content: center;">
                                                                    <div class="d-flex align-items-center justify-content-center">
                                                                        <div class="digi-table-loader inverse">
                                                                            <div></div>
                                                                            <div></div>
                                                                            <div></div>
                                                                            <div></div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr v-if="!get_all_card_providers_api_inprogress && card_details.length <= 0">
                                                                <td class="text-center" colspan="3"
                                                                    style="justify-content: center;">
                                                                    <strong>No Card Provider</strong>
                                                                </td>
                                                            </tr>
                                                            <tr v-for="(card,index) in card_details" :key="index"
                                                                v-else>
                                                                <td
                                                                    style="width: 100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;">
                                                                    {{card.card_name}}</td>
                                                                <td class="hidden-sm-down">
                                                                    <el-switch v-model="card.status"
                                                                        v-on:change="change_card_status(card.status,card.card_id)">
                                                                    </el-switch>
                                                                </td>
                                                                <td style="padding: 0.6rem 1.2rem!important;">
                                                                    <el-tooltip class="item" effect="dark"
                                                                        content="Edit" placement="top">
                                                                        <button
                                                                            class="btn btn-icon companyactions actionicons"
                                                                            style="padding: 0.4rem .2rem;"
                                                                            @click="editAddCash(card)">
                                                                            <i class="icon icon-pencil"></i>
                                                                        </button>
                                                                    </el-tooltip>
                                                                    <el-tooltip v-if="!card.is_default" class="ite m"
                                                                        effect="dark" content="Delete" placement="top">
                                                                        <button type="button"
                                                                            class="btn btn-icon actionicons"
                                                                            style="padding: 0.4rem .2rem;"
                                                                            @click="deleteCard(card.card_id)">
                                                                            <i class="icon icon-delete"></i>
                                                                        </button>
                                                                    </el-tooltip>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div role="tabpanel" class="tab-pane fade" id="deposits"
                                            aria-labelledby="deposits">
                                            <div class="pl-4 pr-4">
                                                <span class="text-white font-weight-bold">Deposits Settings</span>
                                                <div class="d-flex justify-content-between p-5 mt-3"
                                                    style="background-color:#dee8f2 !important;border-radius:12px !important;">
                                                    <span
                                                        style="font-size: 14px !important;font-weight: 500 !important;">Should
                                                        Customer Order Deposits Be Included In Orders By Default
                                                        ?</span>
                                                    <el-switch active-text="Yes" inactive-text="No"
                                                        v-model="depositEnable"></el-switch>
                                                </div>
                                            </div>
                                        </div>
                                        <div role="tabpanel" class="tab-pane fade" id="payment-mode-setting"
                                            aria-labelledby="payment-mode-setting">
                                            <div class>
                                                <div class="d-flex justify-content-between flex-wrap pl-2 pr-2">
                                                    <span
                                                        class="text-white pl-3 font-weight-bold fs-14 hidden-xs-down">Other
                                                        Payment Modes</span>
                                                    <button type="submit" class="btn btn-secondary btn-smm"
                                                        style="color:#00448b!important;background-color:#fff!important;"
                                                        @click="addPaymnetMode()">+ Add New Other Payment Mode</button>
                                                </div>
                                                <div class="row pl-5 pr-5 mb-5">
                                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                                        <div class="table-row">
                                                            <table class="table table-scroll tableOverflow">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="whiteSpace">Other Payment Modes</th>
                                                                        <th class="hidden-sm-down">Payment Mode Status
                                                                        </th>
                                                                        <th>Admin</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody v-if="payment_mode_settings_api_inprogress && payment_details.length < 1">
                                                                    <tr>
                                                                        <td></td>
                                                                        <td class="text-center font-weight-bold">
                                                                            <div class="d-flex align-items-center justify-content-center">
                                                                                <div class="digi-table-loader inverse">
                                                                                    <div></div>
                                                                                    <div></div>
                                                                                    <div></div>
                                                                                    <div></div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody v-if="!payment_mode_settings_api_inprogress && payment_details.length < 1">
                                                                    <tr>
                                                                        <td></td>
                                                                        <td class="text-center font-weight-bold">No
                                                                            Other Payment Mode</td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody v-else>
                                                                    <!-- payment_mode_settings_api_inprogress -->
                                                                    
                                                                    <tr v-for="(payment,index) in payment_details"
                                                                        :key="index">
                                                                        <td
                                                                            style="padding: 1.6rem 1.2rem!important;width: 220px;overflow: hidden;text-overflow: ellipsis;display: block;">
                                                                            {{payment.name}}</td>
                                                                        <td style="padding: 1.6rem 1.2rem!important;"
                                                                            class="hidden-sm-down">
                                                                            <el-switch v-model="payment.status"
                                                                                v-on:change="change_payment_method_status(payment.status,payment.payment_id)">
                                                                            </el-switch>
                                                                        </td>
                                                                        <td style="padding: 1.6rem 1.2rem!important;">
                                                                            <el-tooltip class="item" effect="dark"
                                                                                content="Edit" placement="top">
                                                                                <button
                                                                                    class="btn btn-icon companyactions actionicons"
                                                                                    style="padding: 0.4rem .2rem;"
                                                                                    @click="editNewPayment(payment.payment_id)">
                                                                                    <i class="icon icon-pencil"></i>
                                                                                </button>
                                                                            </el-tooltip>
                                                                            <el-tooltip class="ite m" effect="dark"
                                                                                content="Delete" placement="top">
                                                                                <button type="button"
                                                                                    class="btn btn-icon actionicons"
                                                                                    style="padding: 0.4rem .2rem;"
                                                                                    @click="deletePayment(payment.payment_id)">
                                                                                    <i class="icon icon-delete"></i>
                                                                                </button>
                                                                            </el-tooltip>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <add-new-payment @PaymentSaved="manage_payment_gateway"
                                                        modal_brand="add-payment-method"></add-new-payment>
                                                    <edit-add-payment @editPayment="manage_payment_gateway"
                                                        modal_brand="edit-payment-method"></edit-add-payment>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="salesData" style="padding-top: 20px;" class="payment-outlet-tab pl-5 pr-5">
                                    <div class="col-lg-12 col-md-12 col-sm-12 pt-3 d-flex res-settings"
                                        style="display: -webkit-inline-box;">
                                        <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/vertical-cash.svg"
                                            alt="Vertical Cash" />
                                        <div class="col-lg-6">
                                            <label class="text-secondary pr-2 fw-600 pb-1">Is waiter able to see sales
                                                data :</label>
                                            <br />
                                            <el-switch active-text="Yes" inactive-text="No"
                                                v-model="otherDetails.can_waiter_see_sales_data"
                                                @change="saveOtherDetailsOutletConfirm(outlet, otherDetails)">
                                            </el-switch>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="pictureSettings" class="mediaSize" style="padding-top:20px;">
                                    <outlet-pictures></outlet-pictures>
                                </div>
                                <div v-if="scmSettings" class="mediaSize" style="padding-top:0px;">
                                    <settings-supply-chain></settings-supply-chain>
                                </div>
                                <add-cash modal_name="add-new-cash" @cardCreated="get_all_card_providers"></add-cash>
                                <edit-cash :card="editCard" modal_name="edit-new-cash"
                                    @cardEdited="get_all_card_providers"></edit-cash>
                                <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
                                <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
                            </el-main>
                        </el-container>
                    </div>
                </div>
            </div>
        </div>
        <view-qr-code v-if="show_qr_code" @hideViewQrCode="hideViewQrCode" :selected_table='selected_table' modal_name="show_qr_code"></view-qr-code>
        <qr-codes v-if="display_qr_codes" @hideShowQrCode="hideShowQrCode" :qr_codes='qr_codes' modal_name="qr_codes"></qr-codes>
    </div>
</template>
<script>
    import axios from 'axios';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import myUpload from 'vue-image-crop-upload'
    import {
        SweetModal
    } from 'sweet-modal-vue'

    import AddCash from './AddCash'
    import EditCash from './EditCash'
    import AddNewPayment from './AddNewPayment'
    import EditAddPayment from './EditAddPayment'

    // import cashRegister from './mixins/cashRegister'
    import chargeServices from './mixins/charges'
    import OutletServices from './mixins/outlets'
    import tableServices from './mixins/tables'
    import taxServices from './mixins/taxes'
    import {
        EventBus
    } from './eventBus/nav-bar-event'
    import Users from './mixins/user'

    import SettingsSupplyChain from './SCM/SettingsSupplyChain'
    import RegisterPrinter from './RegisterPrinter'
    import VoidCancelled from './VoidCancelled'
    import OutletPictures from './OutletPictures'
    const ViewQrCode = () => import("./ViewQrCode")
    const QrCodes = () => import("./QrCodes")
    import moment from 'moment-timezone';
    import global from './global'
    import { HalfCircleSpinner } from "epic-spinners";
    export default {
        data() {
            return {
                // approval_permission: [],
                // toggle_config: false,
                isLoadingPin: false,
                pinCount: 0,
                changePinConfig: false,
                ajaxCallInProgress: false,
                employees_list: [],
                waiters_list :[],
                checkAll: false,
                opening_meridian_monday:'',
                closing_meridian_monday: '',
                prefixlength: 10,
                branchs: [],
                voidoutletId: '',
                delete_void_order: false,
                cop_address_model: false,
                custom_value: '',
                report_email: '',
                showPop: false,
                showCancelPop: false,
                editoutletname: 30,
                editwebsite: 45,
                editemail: 230,
                editstreet: 42,
                editstreetnew: 42,
                editcity: 15,
                edittownship: 21,
                editstate: 15,
                editzipcode: 12,
                editstreetshort: 42,
                editcityshort: 15,
                editstateshort: 15,
                editzipcodeshort: 12,
                edittaxID: 20,
                taxrulename: 30,
                taxrulecode: 30,
                taxrulerate: 4,
                edittaxrulename: 30,
                edittaxrulecode: 30,
                edittaxrulerate: 4,
                chargesname: 30,
                editchargesname: 30,
                chargesrate: 4,
                custom_value_length: 4,
                editchargesrate: 4,
                chargescode: 30,
                editchargescode: 30,
                selected: 'Canada',
                isNewReq: false,
                noStartEndTime: false,
                ifOtherDetailsSubmitted: false,
                isPhoneNumberInvalid: false,
                ifGeneralDetailsSubmitted: false,
                duplicateOutletName: false,
                showOutletPic: false,
                imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/company-logo.svg',
                activeName: 'first',
                generalSettings: true,
                alertSettings: false,
                taxSettings: false,
                approvalPermission: false,
                generatePin: false,
                otherSettings: false,
                voidCancelled: false,
                scmSettings: false,
                manageSettings: false,
                cashRegisterSettings: false,
                paymentSettings: false,
                pictureSettings: false,
                salesData: false,
                isExisting: true,
                contactInfo: 'first',
                customer: {},
                checkedcash: false,
                checkedcard: false,
                checkedothers: false,
                show: false,
                assign_permission: [],
                assign_permission_list: ['Waiter Parami','Waiter 57'],
                params: {
                    token: '123456798',
                    name: 'avatar'
                },
                headers: {
                    smail: '*_~'
                },
                name: '',
                code: '',
                tax_rate: '',
                type: 'PERCENTAGE',
                edit_type: 'PERCENTAGE',
                discount_included: false,
                edit_discount_included: false,
                edit_code: '',
                cashiers: [],
                modal_msg: '',
                card_details: '',
                max: 13,
                editCard: {
                    card_id: '',
                    name: '',
                    status: ''
                },
                service_type: {
                    take_away: false,
                    dine_in: false,
                    deliveries: false,
                    online: false
                },
                receipt_format: '',
                edit_service_type: {
                    take_away: false,
                    dine_in: false,
                    deliveries: false,
                    online: false
                },
                edit_company_deliveries: [],
                edit_company_online: [],
                duplicateTaxCode: false,
                editDuplicateTaxCode: false,
                ifeditTaxRuleRate: false,
                ifnewTaxRuleSubmitted: false,
                newTaxRule: {
                    name: '',
                    code: '',
                    rate: '',
                    service_type: {
                        take_away: false,
                        dine_in: false,
                        deliveries: false,
                        online: false
                    },
                    outlet_id: this.$route.query.outletId,
                    company_deliveries: [],
                    company_online: []
                },
                selectAll: false,
                editTaxRule: {
                    _id: '',
                    name: '',
                    code: '',
                    rate: '',
                    service_type: {
                        take_away: false,
                        dine_in: false,
                        deliveries: false,
                        online: false
                    },
                    outlet_id: this.$route.query.outletId,
                    company_deliveries: [],
                    company_online: []
                },
                duplicatetaxrule: false,
                editduplicatetaxrule: false,
                ifEditTaxRuleSubmitted: false,
                payment_details: [],
                tables: [],
                editTable: {
                    table_id: '',
                    previous_table_id: ''
                },
                edit_details: {},
                stopEditing: false,
                popup_search_key: '',
                popup_stores: [],
                existing_vendors: [],
                new_vendor_requests: [],
                popup_details: {},
                pin_config: false,
                generalDetails: {
                    add_wifi_info: false,
                    guest_wifi_info: {
                        username: '',
                        password: ''
                    },
                    add_tips: false,
                    enable_double_receipts: false,
                    name: '',
                    website: '',
                    tax_id: '',
                    email: '',
                    profile_pic: '',
                    phone_number: '',
                    currency: {
                        name: '',
                        symbol: ''
                    },
                    address: {
                        street: '',
                        street2: '',
                        city: '',
                        state: '',
                        township: '',
                        zipcode: '',
                        country: ''
                    },
                    short_address: {
                        street: '',
                        city: '',
                        state: '',
                        zipcode: '',
                        country: ''
                    },
                    use_short_address_format_for_receipt: false,
                    branch_id: '',
                    branch_type: 'headquarters',
                    is_decimal_points_allowed: false,
                    no_of_decimal_points: 0,
                    round_off_settings: '',
                    outlet_mode: 'cloud',
                },
                taxDetails: {
                    tax_rules: '' //<inclusive> or <exclusive>,
                },
                otherDetails: {
                    report_emails: [],
                    prompt_user_to_print: false,
                    is_round_off_required: false,
                    double_discount: false,
                    additional_charge_with_discount: false,
                    enable_fast_checkout: false,
                    delete_void_order: false,
                    can_waiter_see_sales_data: false,
                    show_loyalty_when_value_zero: false,
                    show_promotion_when_value_zero: false,
                    no_of_tables: '',
                    business_day_start_time: '',
                    table_prefix: '',
                    opening_hours: {
                        monday: {
                            status: false,
                            start: '',
                            end: '',
                            start_meridian: '',
                            end_meridian: ''
                        },
                        tuesday: {
                            status: false,
                            start: '',
                            end: '',
                            start_meridian: '',
                            end_meridian: ''
                        },
                        wednesday: {
                            status: false,
                            start: '',
                            end: '',
                            start_meridian: '',
                            end_meridian: ''
                        },
                        thursday: {
                            status: false,
                            start: '',
                            end: '',
                            start_meridian: '',
                            end_meridian: ''
                        },
                        friday: {
                            status: false,
                            start: '',
                            end: '',
                            start_meridian: '',
                            end_meridian: ''
                        },
                        saturday: {
                            status: false,
                            start: '',
                            end: '',
                            start_meridian: '',
                            end_meridian: ''
                        },
                        sunday: {
                            status: false,
                            start: '',
                            end: '',
                            start_meridian: '',
                            end_meridian: ''
                        }
                    },
                    default_timings: {
                        breakfast: {
                            start_time: '',
                            end_time: '',
                        },
                        lunch: {
                            start_time: '',
                            end_time: '',
                        },
                        dinner: {
                            start_time: '',
                            end_time: '',
                        }
                    },
                },
                outlet: {},
                tax_rules: [],
                other_charges: [],
                edit_name: '',
                edit_tax_rules: '',
                edit_id: '',
                edit_tax_rate: '',
                nameExists: '',
                editNameExists: '',
                editCodeExists: '',
                codeExists: false,
                taxCodeExists: '',
                editTaxCodeExists: '',
                taxNameExists: '',
                editTaxNameExists: '',
                tableLoading: false,
                depositEnable: false,
                courses_settings: {
                    default_deliver_together: false,
                    default_display_status_for_courses: false,
                    default_display_status_for_items: false,
                    enable_courses_firing: false,
                    no_of_courses: 1
                },
                is_applied_on: '',
                is_charge_applied_on_other_charge: false,
                edit_is_applied_on: '',
                edit_is_charge_applied_on_other_charge: false,
                allOrderTypes: [],
                search_key: '',
                searchdata: '',
                allServiceType: [],
                company_deliveries: [],
                company_online: [],
                delivery_company_list: [],
                validServiceType: false,
                validServiceTypeEdit: false,
                validServiceTypeTaxAdd: false,
                validServiceTypeTaxEdit: false,
                selected_table: null,
                show_qr_code: false,
                display_qr_codes: false,
                qr_codes: [],
                meridian_options: ['AM', 'PM'],
                approval_permissions_selected: [],
                auto_logout: false,
                auto_logout_mins: 0,
                auto_prepare_when_placed: false,
                auto_deliver_when_placed: false,
                assign_table_to_waiter: false,
                selected_tables: [],
                waiter_options: [],
                selected_waiter: '',
                waiter_search_key: '',
                waiters_count: 0,
                isVisibleWaiters: false,
                scrollableWaiters: false,
                isLoadingWaiters: false,
                waiter_access: false,
                waiter_approval_permission: false,
                waiter_payload: null,
                assing_tables_to_waiter_popup: false,
                upcharge_for_credit_card_usage: false,
                hide_row_with_value_zero: false,
                is_credit_card_charges_percentage_less_than_zero:false,
                credit_card_charges_percentage: '',
                show_place_order_in_checkout: false,
                isUploadingInProgress: false,
                attachment_file:"",
                check_attachments: false,
                attachments: {},
                hybrid_mode: false,
                is_outlet_hybrid: false,
                settings_save_api_call: false,
                waiter_permission_api_call: false,
                generate_user_pin_api_inprogress: false,
                add_new_tax_rule_api_inprogress: false,
                save_other_charges_api_inprogress: false,
                waiter_selection_save_api_inprogress: false,
                get_all_card_providers_api_inprogress: false,
                payment_mode_settings_api_inprogress: false
            }
        },
        mixins: [OutletServices, chargeServices, taxServices, tableServices, Users],
        components: {
            'my-upload': myUpload,
            SweetModal,
            AddCash,
            EditCash,
            AddNewPayment,
            EditAddPayment,
            VueTimepicker,
            SettingsSupplyChain,
            RegisterPrinter,
            VoidCancelled,
            OutletPictures,
            ViewQrCode,
            QrCodes,
            HalfCircleSpinner
        },
        computed: {
            loggedInUser() {
                return this.$store.state.loggedInUser
            },
            table_update_name: {
                get() {
                    return this.editTable.table_id
                },
                set(newValue) {
                    this.editTable.table_id = newValue
                }
            },
        },
        watch: {
            approvalPermission(newValue){
                if(newValue) {
                    this.taxSettings = false
                    this.otherSettings = false
                    this.manageSettings = false
                    this.paymentSettings = false
                    this.cashRegisterSettings = false
                    this.scmSettings = false
                    this.voidCancelled = false
                    this.pictureSettings = false
                    this.alertSettings = false
                    this.salesData = false
                    this.generalSettings = false
                    this.generatePin = false
                }
            },
            generatePin(newValue) {
                if(newValue){
                    this.taxSettings = false
                    this.otherSettings = false
                    this.manageSettings = false
                    this.paymentSettings = false
                    this.cashRegisterSettings = false
                    this.scmSettings = false
                    this.voidCancelled = false
                    this.pictureSettings = false
                    this.alertSettings = false
                    this.salesData = false
                    this.generalSettings = false
                    this.approvalPermission = false
                }
            },
            generalSettings(newValue) {
                if (newValue) {
                    this.taxSettings = false
                    this.otherSettings = false
                    this.manageSettings = false
                    this.paymentSettings = false
                    this.cashRegisterSettings = false
                    this.scmSettings = false
                    this.voidCancelled = false
                    this.pictureSettings = false
                    this.alertSettings = false
                    this.salesData = false
                    this.approvalPermission = false
                    this.generatePin = false
                }
            },
            alertSettings(newValue) {
                if (newValue) {
                    this.generalSettings = false
                    this.taxSettings = false
                    this.otherSettings = false
                    this.manageSettings = false
                    this.paymentSettings = false
                    this.cashRegisterSettings = false
                    this.scmSettings = false
                    this.voidCancelled = false
                    this.pictureSettings = false
                    this.salesData = false
                    this.approvalPermission = false
                    this.generatePin = false
                }
            },
            taxSettings(newValue) {
                if (newValue) {
                    this.generalSettings = false
                    this.otherSettings = false
                    this.manageSettings = false
                    this.cashRegisterSettings = false
                    this.paymentSettings = false
                    this.scmSettings = false
                    this.voidCancelled = false
                    this.pictureSettings = false
                    this.alertSettings = false
                    this.salesData = false
                    this.approvalPermission = false
                    this.generatePin = false
                }
            },
            otherSettings(newValue) {
                if (newValue) {
                    this.loadOtherSettings()
                    this.taxSettings = false
                    this.generalSettings = false
                    this.manageSettings = false
                    this.paymentSettings = false
                    this.cashRegisterSettings = false
                    this.scmSettings = false
                    this.voidCancelled = false
                    this.pictureSettings = false
                    this.alertSettings = false
                    this.salesData = false
                    this.approvalPermission = false
                    this.generatePin = false
                }
            },
            manageSettings(newValue) {
                if (newValue) {
                    this.otherSettings = false
                    this.taxSettings = false
                    this.generalSettings = false
                    this.paymentSettings = false
                    this.cashRegisterSettings = false
                    this.scmSettings = false
                    this.voidCancelled = false
                    this.pictureSettings = false
                    this.alertSettings = false
                    this.salesData = false
                    this.approvalPermission = false
                    this.generatePin = false
                }
            },
            cashRegisterSettings(newValue) {
                if (newValue) {
                    this.manageSettings = false
                    this.otherSettings = false
                    this.taxSettings = false
                    this.generalSettings = false
                    this.paymentSettings = false
                    this.scmSettings = false
                    this.voidCancelled = false
                    this.pictureSettings = false
                    this.alertSettings = false
                    this.salesData = false
                    this.approvalPermission = false
                    this.generatePin = false
                }
            },
            paymentSettings(newValue) {
                if (newValue) {
                    this.get_all_card_providers()
                    this.manage_payment_gateway()
                    this.cashRegisterSettings = false
                    this.manageSettings = false
                    this.otherSettings = false
                    this.taxSettings = false
                    this.generalSettings = false
                    this.scmSettings = false
                    this.voidCancelled = false
                    this.pictureSettings = false
                    this.alertSettings = false
                    this.salesData = false
                    this.approvalPermission = false
                    this.generatePin = false
                }
            },
            scmSettings(newValue) {
                if (newValue) {
                    this.cashRegisterSettings = false
                    this.manageSettings = false
                    this.otherSettings = false
                    this.paymentSettings = false
                    this.taxSettings = false
                    this.generalSettings = false
                    this.voidCancelled = false
                    this.pictureSettings = false
                    this.alertSettings = false
                    this.salesData = false
                    this.approvalPermission = false
                    this.generatePin = false
                }
            },
            voidCancelled(newValue) {
                if (newValue) {
                    this.voidoutletId = this.$route.query.outletId
                    this.delete_void_order = this.otherDetails.delete_void_order
                    this.cashRegisterSettings = false
                    this.manageSettings = false
                    this.otherSettings = false
                    this.paymentSettings = false
                    this.taxSettings = false
                    this.generalSettings = false
                    this.scmSettings = false
                    this.pictureSettings = false
                    this.alertSettings = false
                    this.salesData = false
                    this.approvalPermission = false
                    this.generatePin = false
                }
            },
            pictureSettings(newValue) {
                if (newValue) {
                    this.cashRegisterSettings = false
                    this.manageSettings = false
                    this.otherSettings = false
                    this.paymentSettings = false
                    this.taxSettings = false
                    this.generalSettings = false
                    this.scmSettings = false
                    this.voidCancelled = false
                    this.alertSettings = false
                    this.salesData = false
                    this.approvalPermission = false
                    this.generatePin = false
                }
            },
            salesData(newValue) {
                if (newValue) {
                    this.cashRegisterSettings = false
                    this.manageSettings = false
                    this.otherSettings = false
                    this.paymentSettings = false
                    this.taxSettings = false
                    this.generalSettings = false
                    this.scmSettings = false
                    this.voidCancelled = false
                    this.alertSettings = false
                    this.pictureSettings = false
                    this.approvalPermission = false
                    this.generatePin = false
                }
            },
        },
        filters: {
            formatServiceType(serviceType) {
                let result = ''
                for (let key in serviceType) {
                    if (serviceType.hasOwnProperty(key) && serviceType[key]) {
                        result += key + ', '
                    }
                }
                if (result !== '') {
                    return result.substring(0, result.length - 2)
                }
                return result
            }
        },
        methods: {
            showPlaceOrderInCheckout(event) {

            },
            upchargeForCreditCardUsage(value) {

            },
            creditCardPercentage(event) {
                if(event.target.value < 0) {
                    this.is_credit_card_charges_percentage_less_than_zero = true
                } else {
                    this.is_credit_card_charges_percentage_less_than_zero = false
                }
            },
            // hybridModeChange() {

            // },
            async toggleApproval(){
                if(this.waiter_approval_permission == false){
                    this.waiters_list.forEach((w, i) => {
                        w.approval_permissions.change_discount = false 
                        w.approval_permissions.remove_item = false 
                    })
                } 
            },
            async toggleAccess(){
                if(this.waiter_access == false){
                    this.waiter_approval_permission = false
                    this.waiters_list.forEach((w, i) => {
                        w.approval_permissions.change_discount = false 
                        w.approval_permissions.remove_item = false 
                        w.approval_permissions.access_change_discount = false 
                        w.approval_permissions.access_remove_item = false 
                    })
                    // this.waiter_approval_permission = false
                    // this.waiters_list.forEach(async (w, i) => {
                    //     this.waiter_payload = {
                    //         waiter_access: true,
                    //         waiter_approval_permission: true,
                    //         user_permissions: [
                    //             {
                    //                 user_id : w.user_id,
                    //                 approval_permissions: {
                    //                     change_discount: false,
                    //                     remove_item: false,
                    //                     access_change_discount: false,
                    //                     access_remove_item: false
                    //                 }
                    //             }
                    //         ]
                    //     }
                    //     let response = await this.savePermissionDetails(this.waiter_payload)
                    //     this.modal_msg = response.message
                    // })
                    // this.$refs.success_modal.open();
                    // setTimeout(() => {
                    //     this.$refs.success_modal.close();
                    // }, 2000)
                }
            },
            async ChangeApprovalAccess(type, user_id, mode){
                this.waiters_list.forEach((ap, i) => {
                    if(ap.user_id == user_id){
                        if(type == 'access_change_discount'){
                            this.waiter_payload = {
                                waiter_access: true,
                                waiter_approval_permission: true,
                                user_permissions: [
                                    {
                                        user_id : user_id,
                                        approval_permissions: {
                                            change_discount: ap.approval_permissions.change_discount,
                                            remove_item: ap.approval_permissions.remove_item,
                                            access_change_discount: mode,
                                            access_remove_item: ap.approval_permissions.access_remove_item
                                        }
                                    }
                                ]
                            }
                        } else if(type == 'change_discount'){
                            this.waiter_payload = {
                                waiter_access: true,
                                waiter_approval_permission: true,
                                user_permissions: [
                                    {
                                        user_id : user_id,
                                        approval_permissions: {
                                            change_discount: mode,
                                            remove_item: ap.approval_permissions.remove_item,
                                            access_change_discount: ap.approval_permissions.access_change_discount,
                                            access_remove_item: ap.approval_permissions.access_remove_item
                                        }
                                    }
                                ]
                            }
                        } else if(type == 'access_remove_item'){
                            this.waiter_payload = {
                                waiter_access: true,
                                waiter_approval_permission: true,
                                user_permissions: [
                                    {
                                        user_id : user_id,
                                        approval_permissions: {
                                            change_discount: ap.approval_permissions.change_discount,
                                            remove_item: ap.approval_permissions.remove_item,
                                            access_change_discount: ap.approval_permissions.access_change_discount,
                                            access_remove_item: mode
                                        }
                                    }
                                ]
                            }
                        } else if(type == 'remove_item'){
                            this.waiter_payload = {
                                waiter_access: true,
                                waiter_approval_permission: true,
                                user_permissions: [
                                    {
                                        user_id : user_id,
                                        approval_permissions: {
                                            change_discount: ap.approval_permissions.change_discount,
                                            remove_item: mode,
                                            access_change_discount: ap.approval_permissions.access_change_discount,
                                            access_remove_item: ap.approval_permissions.access_remove_item
                                        }
                                    }
                                ]
                            }
                        }
                    }
                })
                let response = await this.savePermissionDetails(this.waiter_payload)
                this.modal_msg = response.message
                this.$refs.success_modal.open();
                setTimeout(() => {
                    this.$refs.success_modal.close();
                }, 2000)
            },
            moment(date) {
                return moment(date);
            },
            hideRowWithValueZero(value) {
                this.hide_row_with_value_zero = value;
                this.$forceUpdate();
            },
            alertEmailsChanges(data) {
                let em_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.otherDetails.report_emails = data;
                this.otherDetails.report_emails.forEach(element => {
                    if(String(element.text).toLowerCase().match(em_regex)) {
                        element.text = String(element.text).toLowerCase()
                    } else {
                        this.$swal({
                            title: element.text + ' is not a valid email address',
                            text: '',
                            type: 'warning'
                        })
                        this.otherDetails.report_emails.splice(this.otherDetails.report_emails.indexOf(element), 1)
                    }
                });
            },
            hideViewQrCode(){
                this.show_qr_code = false
            },
            viewQRCode(table){
                this.show_qr_code = true
                this.selected_table = table
                setTimeout(() => {
                    this.$modal.show("show_qr_code")
                }, 3000);
            },
            hideShowQrCode(){
                this.display_qr_codes = false
            },
            showQrCode(){
                this.display_qr_codes = true
                this.qr_codes = this.tables
                setTimeout(() => {
                    this.$modal.show("qr_codes")
                }, 3000);
            },
            isNumber: function (evt, index) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            UpdateDecimal() {
                if (this.generalDetails.is_decimal_points_allowed) {
                    this.generalDetails.no_of_decimal_points = 2
                } else {
                    this.generalDetails.no_of_decimal_points = 0
                }

            },
            checkDp() {
                if (this.generalDetails.no_of_decimal_points == '' || this.generalDetails.no_of_decimal_points ==
                    null) {
                    this.generalDetails.no_of_decimal_points = 0
                }
                if (this.generalDetails.no_of_decimal_points < 2) {
                    this.generalDetails.no_of_decimal_points = 2
                }
            },
            tableNameUpdate() {
                this.stopEditing = false
            },
            handleClickCard(tab, event) {
                console.log(tab, event)
            },
            handleClickTaxes(tab, event) {
                // console.log(tab, event)
            },
            checkCourseSettings() {
                if (this.otherDetails.courses_settings.no_of_courses > 5) {
                    this.otherDetails.courses_settings.no_of_courses = 5
                } else if (this.otherDetails.courses_settings.no_of_courses <= 0) {
                    this.otherDetails.courses_settings.no_of_courses = 1
                }
            },
            copy_address_long_to_short() {
                if (this.cop_address_model) {
                    this.generalDetails.short_address.city = this.generalDetails.address.city
                    this.generalDetails.short_address.street = this.generalDetails.address.street
                    this.generalDetails.short_address.country = this.generalDetails.address.country
                    this.generalDetails.short_address.state = this.generalDetails.address.state
                    this.generalDetails.short_address.zipcode = this.generalDetails.address.zipcode
                } else {
                    this.generalDetails.short_address.city = ''
                    this.generalDetails.short_address.street = ''
                    this.generalDetails.short_address.country = ''
                    this.generalDetails.short_address.state = ''
                    this.generalDetails.short_address.zipcode = ''
                }
            },
            async saveEditTable() {
                try {
                    if (!this.editTable.table_id.trim()) {
                        this.stopEditing = true
                    } else {
                        this.stopEditing = false
                        let params = {
                            outlet_id: this.$route.query.outletId,
                            table_id: this.editTable.table_id
                        }
                        await this.updateTable(this.editTable._id, params)
                        let success = {
                            title: 'Table Updated Successfully',
                            text: '',
                            type: 'success'
                        }
                        this.$swal(success)
                        let params1 = {
                            outlet_id: this.$route.query.outletId
                        }
                        let tablesResponse = await this.getTables(params1)
                        this.tables = tablesResponse.tables
                        this.hideEditTable()
                    }
                } catch (reason) {
                    let options = {
                        title: 'Cannot Update',
                        text: reason || '',
                        type: 'warning'
                    }
                    this.$swal(options)
                }
            },
            CashChecked() {
                this.checkedcash = !this.checkedcash
            },
            CardChecked() {
                this.checkedcard = !this.checkedcard
            },
            OthersChecked() {
                this.checkedothers = !this.checkedothers
            },
            addPaymnetMode() {
                this.$modal.show('add-payment-method')
            },
            editNewPayment(payment_id) {
                EventBus.$emit('editPayment', payment_id)
                this.$modal.show('edit-payment-method')
            },
            CloseeditAddPaymentMode() {
                this.$modal.hide('edit-payment-method')
            },
            closeAddNewPayment() {
                this.$modal.hide('add-payment-method')
            },
            cancelNewRegister() {
                this.$modal.hide('create-cash')
            },
            showAssignTableWaiter(event) {
                if(event == true) {
                    this.$modal.show('assign-table-to-waiter')
                } else {
                    this.$modal.show('unassign-table-to-waiter');
                }
            },
            async assignWaiterApi(params) {
                return new Promise(async (resolve, reject) => {
                    const response = await this.$http.get(global.POS_API+`/tables/enable_assign_waiter?outlet_id=${params.outletId}&is_assigned=${params.assign_table_to_waiter}`)
                    if (response.data.status_id === 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                });
            },
            async assignWaiterApiCall() {
                try {
                    let params = {
                        outletId: this.$route.query.outletId,
                        assign_table_to_waiter: this.assign_table_to_waiter
                    }
                    let resposne = await this.assignWaiterApi(params)
                    console.log(resposne)
                    this.modal_msg = resposne.message
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.$modal.hide('assign-table-to-waiter')
                        this.$modal.hide('unassign-table-to-waiter')
                        this.loadOtherSettings();
                    }, 2000)
                }
                catch(error) {
                    console.log(error)
                    this.modal_msg = error
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 2000)
                }
            },
            yesNoAssignWaiter(type) {
                if(type == 'yes') {
                    this.assignWaiterApiCall();
                } else {
                    this.assign_table_to_waiter = false;
                    this.$modal.hide('assign-table-to-waiter')
                }
            },
            yesNoUnAssignWaiter(type) {
                if(type == 'yes') {
                    this.assignWaiterApiCall();
                } else {
                    this.assign_table_to_waiter = true;
                    this.$modal.hide('unassign-table-to-waiter')
                }
            },
            selectTableForWaiterSelection(table, index) {
                if(this.assign_table_to_waiter) {
                    if(table.assigned_to) {
                    } else {
                        this.tables[index].is_selected = !this.tables[index].is_selected;
                        if(this.tables[index].is_selected == true) {
                            this.selected_tables.push({
                                id: table._id,
                                table_name: table.table_id
                            });
                        } else {
                            this.selected_tables.forEach((item, index) => {
                                if(item.id == table._id) {
                                    this.selected_tables.splice(index, 1);
                                }
                            });
                        }
                    }
                }
                this.$forceUpdate();
            },
            showAssignWaiterPopup() {
                this.selected_waiter = '';
                this.assing_tables_to_waiter_popup = true;
                setTimeout(() => {
                    this.$modal.show('assign-waiter-to-tables')
                }, 500);
                this.$forceUpdate();
            },
            hideAssignTableWaiter() {
                this.$modal.hide('assign-table-to-waiter')
            },
            hideAssignWaiterPopup() {
                this.assing_tables_to_waiter_popup = false;
                setTimeout(() => {
                    this.$modal.hide('assign-waiter-to-tables')
                    this.selected_waiter = '';
                }, 500);
                this.$forceUpdate();
            },
            async fetchWaiters() {
                this.waiter_options = []
                this.isLoadingWaiters = true
                let response = await this.$http.post(global.POS_API +
                    '/employee/get_employees', {
                        for_manage: false,
                        for_waiter_list: true,
                        limit: 10,
                        search_key: '',
                        skip: 0
                    })
                this.waiter_options = response.data.employees
                this.waiters_count = this.waiter_options.length
                this.isLoadingWaiters = false
                this.scrollableWaiters = false
            },
            async searchWaiters(search_key) {
                this.waiter_search_key = search_key
                this.waiter_options = []
                this.isLoadingWaiters = true
                let response = await this.$http.post(global.POS_API +
                    '/employee/get_employees', {
                        for_manage: false,
                        for_waiter_list: true,
                        limit: 10,
                        search_key: this.waiter_search_key,
                        skip: 0
                    })
                this.waiter_options = response.data.employees
                this.waiters_count = this.waiter_options.length
                this.isLoadingWaiters = false
                this.scrollableWaiters = false
            },
            async reachedEndOfListWaiters(reached) {
                this.isVisibleWaiters = reached
                if (reached) {
                    if (this.scrollableWaiters == false) {
                        this.isLoadingWaiters = true
                        let response = await this.$http.post(global.POS_API +
                            '/employee/get_employees', {
                                for_manage: false,
                                for_waiter_list: true,
                                limit: 10,
                                search_key: this.waiter_search_key,
                                skip: this.waiters_count
                            })
                        let temp_waiters = response.data.employees.length;
                        if (response.data.skip == this.waiters_count) {
                            if (temp_waiters > 0) {
                                let tempFiles = response.data.employees
                                tempFiles.forEach(el => {
                                    this.waiter_options.push(el)
                                })
                                this.waiters_count += response.data.employees.length
                                this.isLoadingWaiters = false
                            } else {
                                this.scrollableWaiters = true
                                this.isLoadingWaiters = false
                            }
                        }
                        this.isLoadingWaiters = false
                    }
                }
            },
            async saveWaiterSelection() {
                try {
                    this.waiter_selection_save_api_inprogress = true;
                    let params = {
                        waiter_id: this.selected_waiter._id,
                        tables: this.selected_tables,
                        outlet_id: this.$route.query.outletId
                    };
                    params.tables.forEach((table, tb_index) => {
                        params.tables[tb_index] = table.id
                    });
                    let response = await this.$http.post(global.POS_API +'/tables/assign_waiter', params);
                    if(response.data.status_id == 1){
                        this.modal_msg = response.data.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.assing_tables_to_waiter_popup = false;
                            setTimeout(() => {
                                this.$modal.hide('assign-waiter-to-tables')
                            }, 500);
                            this.selected_tables = [];
                            this.selected_waiter = ''
                            this.loadOtherSettings();
                            this.waiter_selection_save_api_inprogress = false;
                        }, 2000)
                    } else if(response.data.status_id == 0){
                        this.modal_msg = 'Please Assign A Waiter'
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                            this.$modal.hide('assign-waiter-to-tables')
                            this.selected_tables = [];
                            this.selected_waiter = ''
                            this.loadOtherSettings();
                            this.waiter_selection_save_api_inprogress = false;
                        }, 2000)
                    }
                }
                catch(error) {
                    this.modal_msg = error
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                        this.selected_tables = [];
                    }, 2000)
                    this.waiter_selection_save_api_inprogress = false;
                }
            },
            async unAssignWaiter(table) {
                try {
                    let response = await this.$http.get(global.POS_API +'/tables/remove_assigned_waiter?table_id='+table._id);
                    this.modal_msg = response.data.message
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.loadOtherSettings();
                    }, 2000)
                }
                catch(error) {
                    this.modal_msg = error
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                    }, 2000)
                }
            },
            regenerate() {
                this.$validator.validateAll('otherDetails').then(result => {
                    if (result) {
                        this.regenerateConfirm()
                    } else {

                    }
                })
            },
            async regenerateConfirm() {
                try {
                    let options = {
                        title: 'Are you sure?',
                        text: 'All the Tables will be Resetted. Do you wish to continue?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, Re-Generate it!',
                        cancelButtonText: 'No, Cancel it!',
                        showCloseButton: true,
                        showLoaderOnConfirm: true
                    }
                    let result = await this.$swal(options)
                    if (result.value) {
                        this.$swal.fire({
                                title: 'Re-Generating Table Please Wait....',
                                timerProgressBar: true,
                                onBeforeOpen: () => {
                                    this.$swal.showLoading()
                                }
                            })
                            .then(result => {
                                if (result.dismiss === this.$swal.DismissReason.timer) {

                                }
                            })
                        let params = {
                            outlet_id: this.$route.query.outletId,
                            no_of_tables: this.otherDetails.no_of_tables,
                            table_prefix: this.otherDetails.table_prefix
                        }
                        let response = await this.generateTables(params)
                        let success = {
                            title: 'Successfully Generated Tables.',
                            text: '',
                            type: 'success'
                        }
                        this.$swal(success)
                        let tablesResponse = await this.getTables(params)
                        this.tables = tablesResponse.tables
                    }
                } catch (reason) {
                    this.setOtherDetails()
                    if (reason) {
                        let options = {
                            title: 'Cannot Regenerate',
                            text: reason || '',
                            type: 'warning'
                        }
                        this.$swal(options)
                        this.otherDetails.no_of_tables = this.outlet.no_of_tables
                    }
                }
            },
            newAddCash() {
                this.$modal.show('add-new-cash')
            },
            cancelAddCash() {
                this.$modal.hide('add-new-cash')
            },
            editAddCash(editdetails) {
                let name = editdetails.card_name
                let card_id = editdetails.card_id
                let status = editdetails.status
                this.editCard = {
                    name,
                    card_id,
                    status
                }
                this.$modal.show('edit-new-cash')
            },
            async deleteCard(cardId) {
                let result = await this.$swal({
                    title: 'Are you sure?',
                    text: 'Are you sure to delete Card Provider?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                })
                if (result.value) {
                    this.deleteCardDetails(cardId)
                }
            },
            async deleteCardDetails(cardId) {
                try {
                    let params = {
                        card_id: cardId
                    }
                    await this.outletDeleteCard(params)
                    this.$swal({
                        title: 'Card Provider Deleted Successfully',
                        text: '',
                        type: 'success'
                    })
                    this.get_all_card_providers()
                } catch (reason) {
                    this.$swal({
                        title: reason,
                        text: '',
                        type: 'warning'
                    })
                }
            },
            cancelEditAddCash() {
                this.$modal.hide('edit-new-cash')
            },
            showEditTable(index) {
                let table_id = ''
                let _id = this.tables[index]._id
                let previous_table_id = this.tables[index].table_id
                this.editTable = {
                    table_id,
                    _id,
                    previous_table_id
                }
                this.editTable.table_id = this.editTable.previous_table_id
                this.$modal.show('rename-table')
            },
            hideEditTable() {
                this.stopEditing = false
                this.$modal.hide('rename-table')
            },
            editCashRegister(cashRegister) {
                this.editCashRegisterData = cashRegister
                this.$modal.show('edit-cash')
            },
            canceleditCashRegister() {
                this.$modal.hide('edit-cash')
            },
            hideProductVariants() {
                this.$modal.hide('product-details')
            },
            existingVendorsTab() {
                this.isExisting = true
                this.isNewReq = false
            },
            newRequestsTab() {
                this.isExisting = false
                this.isNewReq = true
            },
            /*
                Function is the event of img crop upload
                @imgUploaded: base64 of image uploaded
                @field: field defined for img crop upload component
            */
           uploadAttachment(newFile) {
                try {
                    var file = newFile;
                    if (file) {
                        const CancelToken = axios.CancelToken;
                        const source = CancelToken.source();
                        let attachment = {
                            // id: uuid(),
                            name: file.name,
                            size: JSON.stringify(file.size),
                            source: source,
                            progress: 0,
                            created_time: new Date(),
                            error: ""
                        }
                        this.attachments = attachment;
                        this.getResumableURL(file, this.attachments);
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            getResumableURL(file, attachment) {
                try {
                    this.isUploadingInProgress = true;
                    attachment.uploading = true;
                    let data = {
                        file: attachment.name
                    }
                    data = new FormData()
                    data.append('file',file)
                    this.$http.post('https://cloudapis.digicollect.com/external/upload_file', data,{
                        onUploadProgress: function (progressEvent) {
                            let progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                            if (progress < 100) {
                                attachment.progress = progress;
                            }
                        }.bind(this)
                    })
                    .then(response => {
                        let resumable_url = response.data.url;
                        attachment.url = resumable_url;
                        attachment.progress = 100;
                        attachment.uploading = false;
                        this.isUploadingInProgress = false;
                        delete attachment['source'];

                        this.imgDataUrl = resumable_url
                        this.generalDetails.profile_pic = resumable_url
                        const changes = {
                            profile_pic: resumable_url
                        };
                        changes._id = this.$route.query.outletId
                        this.showOutletPic = false
                        this.editOutletServiceCall(changes)

                    })
                    .catch(error => {
                        console.log(error.data.reason)
                    })
                } catch (error) {
                    console.log(error);
                }
            },
            blobToFile(theBlob, fileName){
                //A Blob() is almost a File() - it's just missing the two properties below which we will add
                theBlob.lastModifiedDate = new Date();
                theBlob.name = fileName;
                const myFile = new File([theBlob], 'captured_image' + (new Date().getHours()  + '' + new Date().getMinutes()  + '' + new Date().getSeconds()) +'.png', {
                    type: theBlob.type,
                });
                return myFile;
            },
            async onOutletPicCrop(imgUploaded, field) {
                const base64 = await fetch(imgUploaded);
                const blob = await base64.blob();
                let fill = this.blobToFile(blob, 'captured_image' + (new Date().getHours()  + '' + new Date().getMinutes()  + '' + new Date().getSeconds()) +'.png');
                this.uploadAttachment(fill)
            },
            toggleShow() {
                this.show = !this.show
            },
            showTax() {
                this.newTaxRule = {
                    name: '',
                    code: '',
                    rate: '',
                    service_type: {
                        take_away: false,
                        dine_in: false,
                        deliveries: false,
                        online: false
                    },
                    outlet_id: this.$route.query.outletId,
                    company_online: [],
                    company_deliveries: []
                }
                this.$modal.show('create-tax')
            },
            async addTaxRule(value) {
                try {
                    this.add_new_tax_rule_api_inprogress = true;
                    const result = await this.$validator.validate('newTaxRule.*')
                    this.ifnewTaxRuleSubmitted = true
                    if (!value.service_type.deliveries && !value.service_type.dine_in && !value.service_type
                        .online && !value.service_type.take_away) {
                        this.validServiceTypeTaxAdd = true
                    } else {
                        this.validServiceTypeTaxAdd = false
                    }
                    if (result && !this.validServiceTypeTaxAdd) {
                        let params = value
                        params.service_type = params.service_type
                        params.company_deliveries = [...new Set(params.company_deliveries.map(x => x._id))]
                        params.company_online = [...new Set(params.company_online.map(x => x._id))]
                        await this.createTax(params)
                        this.ifnewTaxRuleSubmitted = false
                        this.duplicatetaxrule = false
                        this.duplicateTaxCode = false
                        this.$modal.hide('create-tax')
                        this.getOutletFromId()
                        this.$swal({
                            title: 'Tax Rule Added Successfully',
                            text: '',
                            type: 'success'
                        })
                    this.add_new_tax_rule_api_inprogress = false;
                    }
                } catch (reason) {
                    if (reason === 'Duplicate TaxRule Name') {
                        this.duplicatetaxrule = true
                    } else if (reason === 'Duplicate TaxRule Code') {
                        this.duplicateTaxCode = true
                    } else {
                        this.$swal({
                            title: 'Cannot Add',
                            text: reason || '',
                            type: 'warning'
                        })
                    }
                    this.add_new_tax_rule_api_inprogress = false;
                }
            },
            async updateTaxRule(value) {
                try {
                    this.add_new_tax_rule_api_inprogress = true;
                    const result = await this.$validator.validate('editTaxRule.*')
                    this.ifEditTaxRuleSubmitted = true
                    if (!value.service_type.deliveries && !value.service_type.dine_in && !value.service_type
                        .online && !value.service_type.take_away) {
                        this.validServiceTypeTaxEdit = true
                    } else {
                        this.validServiceTypeTaxEdit = false
                    }
                    if (result && !this.validServiceTypeTaxEdit) {
                        let params = value
                        params.service_type = params.service_type
                        params.company_deliveries = [...new Set(params.company_deliveries.map(x => x._id))]
                        params.company_online = [...new Set(params.company_online.map(x => x._id))]
                        await this.updateTax(params)
                        this.ifEditTaxRuleSubmitted = false
                        this.$modal.hide('edit-tax')
                        this.getOutletFromId()
                        this.$swal({
                            title: 'Tax Rule Edited Successfully',
                            text: '',
                            type: 'success'
                        })
                        this.add_new_tax_rule_api_inprogress = false;
                    }
                } catch (reason) {
                    if (reason === 'Duplicate TaxRule Name') {
                        this.editduplicatetaxrule = true
                    } else if (reason === 'Duplicate TaxRule Code') {
                        this.editDuplicateTaxCode = true
                    } else {
                        this.$swal({
                            title: 'Cannot Edit',
                            text: reason || '',
                            type: 'warning'
                        })
                    }
                    this.add_new_tax_rule_api_inprogress = false;
                }
            },
            async deleteTaxRule(taxrule_id) {
                try {
                    let options = {
                        title: 'Are you sure?',
                        text: "You can't revert your action",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes ',
                        cancelButtonText: 'Cancel',
                        showCloseButton: true,
                        showLoaderOnConfirm: true
                    }
                    let result = await this.$swal(options)
                    if (result.value) {
                        let params = {
                            taxrule_id: taxrule_id
                        }
                        await this.deleteTax(params)
                        let success = {
                            title: 'Tax Rule Deleted Successfully',
                            text: '',
                            type: 'success'
                        }
                        this.$swal(success)
                        this.getOutletFromId()
                    }
                } catch (reason) {
                    this.$swal({
                        title: '',
                        text: reason || '',
                        type: 'warning'
                    })
                }
            },
            hideTax() {
                this.$modal.hide('create-tax')
                this.duplicateTaxCode = false
                this.duplicatetaxrule = false
            },
            editTax(tax_rule) {
                this.editTaxRule = JSON.parse(JSON.stringify(tax_rule))
                this.$modal.show('edit-tax')
            },
            hideEditTax() {
                this.$modal.hide('edit-tax')
                this.editDuplicateTaxCode = false
                this.editduplicatetaxrule = false
            },
            createCharges() {
                this.$modal.show('create-charges')
            },
            editCharges(index) {
                let charge = this.other_charges[index]
                this.edit_name = charge.name
                this.edit_code = charge.code
                this.edit_tax_rate = charge.tax_rate
                this.edit_discount_included = charge.discount_included
                this.edit_type = charge.type
                this.edit_service_type = charge.service_type
                this.edit_id = charge._id
                this.edit_is_applied_on = charge.is_applied_on,
                    this.edit_is_charge_applied_on_other_charge = charge.is_charge_applied_on_other_charge
                this.edit_company_online = charge.company_online
                this.edit_company_deliveries = charge.company_deliveries
                this.$modal.show('edit-charges')
            },
            hideCharges() {
                this.$modal.hide('create-charges')
                this.name = ''
                this.code = ''
                this.tax_rate = ''
                this.service_type = {
                    take_away: false,
                    dine_in: false,
                    deliveries: false,
                    online: false
                }
                this.discount_included = false
                this.type = 'PERCENTAGE'
                this.is_applied_on = ''
                this.is_charge_applied_on_other_charge = false
            },
            hideEditCharges() {
                this.$modal.hide('edit-charges')
                this.edit_name = ''
                this.edit_code = ''
                this.edit_tax_rate = ''
                this.edit_discount_included = false
                this.editNameExists = false
                this.edit_type = 'PERCENTAGE'
                this.edit_service_type = {
                    take_away: false,
                    dine_in: false,
                    deliveries: false,
                    online: false
                }
                this.edit_is_applied_on = ''
                this.edit_is_charge_applied_on_other_charge = false
            },
            cropSuccess(imgDataUrl, field) {
                this.imgDataUrl = imgDataUrl
            },
            cropUploadSuccess(jsonData, field) {},
            cropUploadFail(status, field) {},
            handleClick(tab, event) {},
            async loadOtherSettings() {
                try {
                    let params = {
                        outlet_id: this.$route.query.outletId
                    }
                    let response = await this.getTables(params)
                    this.tables = response.tables
                    this.tables.forEach((table, tb_index) => {
                        table['is_selected'] = false
                    })
                    this.assign_table_to_waiter = response.is_waiter_assigned
                } catch (reason) {
                    this.$swal({
                        title: 'Cannot Edit',
                        text: reason || '',
                        type: 'warning'
                    })
                }
            },
            onPhoneNumberInput({
                number,
                isValid,
                country
            }) {
                this.isPhoneNumberInvalid = !isValid
            },
            clearOpeningHours(status, week) {
                if (!status) {
                    this.otherDetails.opening_hours[week].start = ''
                    this.otherDetails.opening_hours[week].end = ''
                }
            },
            checkIfNoStartEndTime() {
                for (let day in this.otherDetails.opening_hours) {
                    if (this.otherDetails.opening_hours[day].status && (!this.otherDetails.opening_hours[day].start | !this.otherDetails.opening_hours[day].end)) {
                        this.noStartEndTime = true
                        break
                    }
                    else{
                        if(this.otherDetails.opening_hours[day].status){
                            this.otherDetails.opening_hours[day]['start_meridian'] = this.otherDetails.opening_hours[day].start.slice(6,8)
                            this.otherDetails.opening_hours[day]['end_meridian'] = this.otherDetails.opening_hours[day].end.slice(6,8)
                            this.otherDetails.opening_hours[day].start = this.otherDetails.opening_hours[day].start.slice(0,5)
                            this.otherDetails.opening_hours[day].end = this.otherDetails.opening_hours[day].end.slice(0,5)
                        }
                    }
                    this.noStartEndTime = false
                }
            },
            async saveEditCharges(name, code, tax_rate, _id, service_type, discount_included, type,
                is_charge_applied_on_other_charge, is_applied_on, company_deliveries, company_online) {
                try {
                    const result = await this.$validator.validateAll()
                    if (!service_type.deliveries && !service_type.dine_in && !service_type.online && !service_type
                        .take_away) {
                        this.validServiceTypeEdit = true
                    } else {
                        this.validServiceTypeEdit = false
                    }
                    if (result === true && !this.validServiceTypeEdit) {
                        this.save_other_charges_api_inprogress = true;
                        let params = {
                            name: name,
                            code: code,
                            tax_rate: parseFloat(tax_rate),
                            _id: _id,
                            outlet_id: this.$route.query.outletId,
                            service_type: service_type,
                            company_deliveries: [...new Set(company_deliveries.map(x => x._id))],
                            company_online: [...new Set(company_online.map(x => x._id))],
                            discount_included: discount_included,
                            type: type,
                            is_charge_applied_on_other_charge: is_charge_applied_on_other_charge,
                            is_applied_on: is_applied_on._id || '',
                        }
                        let response = await this.updateCharge(params)
                        let options = {
                            title: response.message,
                            text: '',
                            type: 'success'
                        }
                        this.save_other_charges_api_inprogress = false;
                        this.$swal(options)
                        this.getOtherCharges()
                        this.hideEditCharges()
                    }
                } catch (error) {
                    if (error) {
                        if (error.response && error.response === 'duplicate') {
                            if (error.value === 'name') {
                                this.editNameExists = true
                            } else {
                                this.editCodeExists = true
                            }
                        } else {
                            let options = {
                                title: 'Cannot Edit',
                                text: error.reason || '',
                                type: 'warning'
                            }
                            this.$swal(options)
                        }
                    } else {
                        this.$log.error(error)
                    }
                    this.save_other_charges_api_inprogress = false;
                }
            },
            async saveCharges(name, code, tax_rate, service_type, discount_included, type,
                is_charge_applied_on_other_charge, is_applied_on, company_deliveries, company_online) {
                try {
                    const result = await this.$validator.validateAll()
                    if (!service_type.deliveries && !service_type.dine_in && !service_type.online && !service_type
                        .take_away) {
                        this.validServiceType = true
                    } else {
                        this.validServiceType = false
                    }
                    if (result && !this.validServiceType) {
                        this.save_other_charges_api_inprogress = true;
                        let params = {
                            name: name,
                            code: code,
                            tax_rate: parseFloat(tax_rate),
                            outlet_id: this.$route.query.outletId,
                            service_type: service_type,
                            discount_included: discount_included,
                            type: type,
                            is_charge_applied_on_other_charge: is_charge_applied_on_other_charge,
                            is_applied_on: is_applied_on._id || '',
                            company_deliveries: [...new Set(company_deliveries.map(x => x._id))],
                            company_online: [...new Set(company_online.map(x => x._id))]
                        }
                        await this.createCharge(params)
                        let success = {
                            title: 'Created Successfully',
                            text: '',
                            type: 'success'
                        }
                        this.save_other_charges_api_inprogress = false;
                        this.$swal(success)
                        this.getOtherCharges()
                        this.hideCharges()
                    }
                } catch (error) {
                    if (error.response && error.response === 'duplicate') {
                        if (error.value === 'name') {
                            this.nameExists = true
                        } else if (error.value === 'code') {
                            this.codeExists = true
                        } else if (error.value === 'tax_code') {
                            this.taxCodeExists = true
                        } else {
                            this.taxNameExists = true
                        }
                    } else if (error.reason) {
                        let options = {
                            title: 'Cannot Create',
                            text: error.reason || '',
                            buttons: ['ok'],
                            type: 'warning'
                        }
                        this.$swal(options)
                    } else {
                        this.$log.error(error)
                    }
                    this.save_other_charges_api_inprogress = false;
                }
            },
            async deleteOtherCharge(charge_id) {
                try {
                    let options = {
                        title: 'Are you sure?',
                        text: 'Are you sure to delete ?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#00448b',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes'
                    }
                    let result = await this.$swal(options)
                    if (result.value) {
                        let params = {
                            _id: charge_id
                        }
                        await this.deleteCharge(params)
                        this.$swal({
                            title: 'Other Charges Deleted Successfully',
                            text: '',
                            type: 'success'
                        })
                        this.getOtherCharges()
                    }
                } catch (error) {
                    this.$log.error(error)
                    this.$swal({
                        title: 'Cannot Delete',
                        text: '',
                        type: 'warning'
                    })
                }
            },
            async getOtherCharges() {
                try {
                    let params = {
                        outlet_id: this.$route.query.outletId
                    }
                    let response = await this.getCharges(params)
                    this.other_charges = response.response
                } catch (error) {
                    this.$log.error(error)
                }
            },
            async change_printing_status(status) {
                try {
                    let params = {
                        outlet_id: this.$route.query.outletId,
                        status: status
                    }
                    await this.outletChangePrintingStatus(params)
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            async get_all_card_providers() {
                try {
                    this.get_all_card_providers_api_inprogress = true;
                    let res = await this.outletManageCardProviders()
                    this.card_details = res.response.card_details
                    this.get_all_card_providers_api_inprogress = false;
                } catch (reason) {
                    //    TODO: Handle Reason
                    this.get_all_card_providers_api_inprogress = false;
                }
            },
            async change_card_status(status, card_id) {
                try {
                    let params = {
                        card_id: card_id,
                        status: status
                    }
                    await this.outletChangeCardProviderStatus(params)
                    this.$toasted.global.success('Card Status Changed Successfully')
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            async manage_payment_gateway() {
                try {
                    this.payment_mode_settings_api_inprogress = true;
                    let params = {
                        outlet_id: this.$route.query.outletId
                    }
                    let res = await this.outletManagePaymentGateway(params)
                    this.payment_details = res.response.payment_details
                    this.payment_mode_settings_api_inprogress = false;
                } catch (reason) {
                    //    TODO: Handle Reason
                    this.payment_mode_settings_api_inprogress = false;
                }
            },
            async change_payment_method_status(status, payment_id) {
                try {
                    let params = {
                        payment_id: payment_id,
                        status: status
                    }
                    await this.outletChangePaymentMethodStatus(params)
                    this.$toasted.global.success('Payment Mode Status Changed Successfully')
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            async deletePaymentMethod(payment_id) {
                try {
                    let params = {
                        payment_id: payment_id
                    }
                    await this.outletDeletePaymentMethod(params)
                    this.$swal({
                        title: 'Payment Method Deleted Successfully',
                        text: '',
                        type: 'success'
                    })
                    this.manage_payment_gateway()
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            getChanges(json1, json2) {
                const result = {}
                for (let elem in json2)
                    if (json2[elem] !== json1[elem]) result[elem] = json2[elem]
                return result
            },
            assignCurrencySymbol() {
                this.generalDetails.currency.symbol = Utils.currency[this.generalDetails.currency.name]
            },
            autoPrepareChange(value) {
                if(value == true) {
                    
                } else {
                    this.auto_deliver_when_placed = false;
                }
            },
            saveEditedOutlet(formScope, oldDetails, editedDetails) {
                this.$validator.validate(formScope + '.*').then(isValid => {
                    this.ifGeneralDetailsSubmitted = true
                    if (this.isPhoneNumberInvalid) {
                        return
                    }
                    if (isValid) {
                        const changes = this.getChanges(oldDetails, editedDetails)
                        changes._id = this.$route.query.outletId
                        this.editOutletServiceCall(changes)
                    } else {
                        //   'error on personal details'
                    }
                })
            },
            saveOtherDetailsOutletConfirm(oldDetails, editedDetails) {
                if (this.otherDetails.default_timings.breakfast.start_time != null && this.otherDetails.default_timings.breakfast.end_time != null && this.otherDetails.default_timings.dinner.start_time != null && this.otherDetails.default_timings.dinner.end_time != null && this.otherDetails.default_timings.lunch.start_time != null && this.otherDetails.default_timings.lunch.end_time != null) {
                    this.otherDetails.default_timings.breakfast['start_meridian'] = this.otherDetails.default_timings.breakfast.start_time.slice(6,8)
                    this.otherDetails.default_timings.breakfast['end_meridian'] = this.otherDetails.default_timings.breakfast.end_time.slice(6,8)
                    this.otherDetails.default_timings.lunch['start_meridian'] = this.otherDetails.default_timings.lunch.start_time.slice(6,8)
                    this.otherDetails.default_timings.lunch['end_meridian'] = this.otherDetails.default_timings.lunch.end_time.slice(6,8)
                    this.otherDetails.default_timings.dinner['start_meridian'] = this.otherDetails.default_timings.dinner.start_time.slice(6,8)
                    this.otherDetails.default_timings.dinner['end_meridian'] = this.otherDetails.default_timings.dinner.end_time.slice(6,8)
                    this.otherDetails.default_timings.breakfast.start_time = this.otherDetails.default_timings.breakfast.start_time.slice(0,5)
                    this.otherDetails.default_timings.breakfast.end_time = this.otherDetails.default_timings.breakfast.end_time.slice(0,5)
                    this.otherDetails.default_timings.lunch.start_time = this.otherDetails.default_timings.lunch.start_time.slice(0,5)
                    this.otherDetails.default_timings.lunch.end_time = this.otherDetails.default_timings.lunch.end_time.slice(0,5)
                    this.otherDetails.default_timings.dinner.start_time = this.otherDetails.default_timings.dinner.start_time.slice(0,5)
                    this.otherDetails.default_timings.dinner.end_time = this.otherDetails.default_timings.dinner.end_time.slice(0,5)
                    this.saveOtherDetailsOutlet(oldDetails, editedDetails)
                } else {
                    this.$toasted.global.error('Please Fill All the Required Fields')
                }
            },
            saveOtherDetailsOutlet(oldDetails, editedDetails) {
                this.$validator.validate('otherDetails.*').then(isValid => {
                    this.checkIfNoStartEndTime()
                    this.otherDetails.report_emails.forEach((element, index, theArray) => {
                        if (typeof element === 'object') {
                            theArray[index] = element.text
                        }
                    })
                    this.ifOtherDetailsSubmitted = true
                    if (isValid && !this.noStartEndTime) {
                        const changes = this.getChanges(oldDetails, editedDetails)
                        let order_settings = {
                            auto_prepare_when_placed: this.auto_prepare_when_placed,
                            auto_deliver_when_placed: this.auto_deliver_when_placed
                        }
                        changes['auto_logout_mins'] = this.auto_logout_mins
                        changes['auto_logout'] = this.auto_logout
                        changes['order_settings'] = order_settings
                        changes['hide_row_with_value_zero'] = this.hide_row_with_value_zero;
                        changes['upcharge_for_credit_card_usage'] = this.upcharge_for_credit_card_usage;
                        changes['credit_card_charges_percentage'] = +this.credit_card_charges_percentage;
                        changes['show_place_order_in_checkout'] = this.show_place_order_in_checkout;
                        changes['is_outlet_hybrid'] = this.is_outlet_hybrid;
                        changes._id = this.$route.query.outletId
                        changes.receipt_format = this.outlet.receipt_format
                        if(changes.default_timings.breakfast.start_time.includes('Z')) {
                            let time_conversion = this.moment(changes.default_timings.breakfast.start_time);
                            changes.default_timings.breakfast.start_time = time_conversion.split(' ')[0];
                            changes.default_timings.breakfast['start_meridian'] = time_conversion.split(' ')[1]
                        } else if(changes.default_timings.breakfast.start_time.includes('AM') || changes.default_timings.breakfast.start_time.includes('PM')) {
                            changes.default_timings.breakfast.start_time = changes.default_timings.breakfast.start_time.split(' ')[0];
                        }
                        if(changes.default_timings.breakfast.end_time.includes('Z')) {
                            let time_conversion = this.moment(changes.default_timings.breakfast.end_time);
                            changes.default_timings.breakfast.end_time = time_conversion.split(' ')[0];
                            changes.default_timings.breakfast['end_meridian'] = time_conversion.split(' ')[1]
                        } else if(changes.default_timings.breakfast.end_time.includes('AM') || changes.default_timings.breakfast.end_time.includes('PM')) {
                            changes.default_timings.breakfast.end_time = changes.default_timings.breakfast.end_time.split(' ')[0];
                        }
                        if(changes.default_timings.lunch.start_time.includes('Z')) {
                            let time_conversion = this.moment(changes.default_timings.lunch.start_time);
                            changes.default_timings.lunch.start_time = time_conversion.split(' ')[0];
                            changes.default_timings.lunch['end_meridian'] = time_conversion.split(' ')[1]
                        } else if(changes.default_timings.lunch.start_time.includes('AM') || changes.default_timings.lunch.start_time.includes('PM')) {
                            changes.default_timings.lunch.start_time = changes.default_timings.lunch.start_time.split(' ')[0];
                        }
                        if(changes.default_timings.lunch.end_time.includes('Z')) {
                            let time_conversion = this.moment(changes.default_timings.lunch.end_time);
                            changes.default_timings.lunch.end_time = time_conversion.split(' ')[0];
                            changes.default_timings.lunch['end_meridian'] = time_conversion.split(' ')[1]
                        } else if(changes.default_timings.lunch.end_time.includes('AM') || changes.default_timings.lunch.end_time.includes('PM')) {
                            changes.default_timings.lunch.end_time = changes.default_timings.lunch.end_time.split(' ')[0];
                        }
                        if(changes.default_timings.dinner.start_time.includes('Z')) {
                            let time_conversion = this.moment(changes.default_timings.dinner.start_time);
                            changes.default_timings.dinner.start_time = time_conversion.split(' ')[0];
                            changes.default_timings.dinner['start_meridian'] = time_conversion.split(' ')[1]
                        } else if(changes.default_timings.dinner.start_time.includes('AM') || changes.default_timings.dinner.start_time.includes('PM')) {
                            changes.default_timings.dinner.start_time = changes.default_timings.dinner.start_time.split(' ')[0];
                        }
                        if(changes.default_timings.dinner.end_time.includes('Z')) {
                            let time_conversion = this.moment(changes.default_timings.dinner.end_time);
                            changes.default_timings.dinner.end_time = time_conversion.split(' ')[0];
                            changes.default_timings.dinner['end_meridian'] = time_conversion.split(' ')[1]
                        } else if(changes.default_timings.dinner.end_time.includes('AM') || changes.default_timings.dinner.end_time.includes('PM')) {
                            changes.default_timings.dinner.end_time = changes.default_timings.dinner.end_time.split(' ')[0];
                        }
                        this.editOutletServiceCall(changes)
                    } else {
                        //   error on personal details
                    }
                })
            },
            async editOutletServiceCall(params) {
                try {
                    this.settings_save_api_call = true;
                    if (typeof params.phone_number == 'object') {
                        params.phone_number = params.phone_number['e164']
                    }
                    let res = await this.outletEdit(params)
                    let response = await this.validateUser()
                    let userDetails = response.user_details
                    let newLoggedInUser = {
                        access_change_discount: userDetails.access_change_discount,
                        access_remove_item: userDetails.access_remove_item,
                        auto_logout: userDetails.auto_logout,
                        auto_logout_mins: userDetails.auto_logout_mins,
                        hide_row_with_value_zero: userDetails.hide_row_with_value_zero || false,
                        show_place_order_in_checkout: userDetails.show_place_order_in_checkout || false,
                        print_receipt_by_default: userDetails.print_receipt_by_default || false,
                        uid: userDetails.user_id,
                        full_name: userDetails.full_name,
                        email: userDetails.email,
                        profile_pic: userDetails.profile_pic,
                        actions: userDetails.actions,
                        is_setup_done: userDetails.is_setup_done,
                        isAdmin: userDetails.user_role === 'default_admin',
                        is_authorized: userDetails.is_authorized,
                        is_loaded: true,
                        has_order: userDetails.has_order,
                        outlet: userDetails.outlet,
                        business_day_start_time:
                        userDetails.business_day_start_time,
                        role: userDetails.user_role,
                        outlet_type: userDetails.outlet_type,
                        pin: userDetails.pin,
                        is_cashier: userDetails.is_cashier,
                        cashier_session: userDetails.cashier_session || null,
                        service_type: userDetails.service_type || null,
                        enable_fast_checkout: userDetails.enable_fast_checkout,
                        enable_double_receipts: userDetails.enable_double_receipts,
                        enable_course_firing: userDetails.enable_course_firing,
                        default_deliver_together: userDetails.default_deliver_together,
                        outlet_id: userDetails.outlet_id,
                        configure_payment_device:userDetails.configure_payment_device,
                    }
                    this.$store.commit('setLoggedInUser', newLoggedInUser)
                    if (userDetails.is_cashier && userDetails.cashier_session)
                        EventBus.$emit('updateBills', true)

                    this.ifGeneralDetailsSubmitted = false
                    this.ifOtherDetailsSubmitted = false
                    this.updateView(res)
                    this.$swal({
                        title: res.message,
                        text: '',
                        type: 'success'
                    })
                    if(this.auto_logout){
                        EventBus.$emit('force_update_auto_logout', true);
                    } else {
                        EventBus.$emit('force_update_auto_logout', false);
                    }
                    this.settings_save_api_call = false;
                } catch (reason) {
                    this.ifGeneralDetailsSubmitted = false
                    this.ifOtherDetailsSubmitted = false
                    this.settings_save_api_call = false;
                    this.$swal({
                        title: 'Cannot Edit',
                        text: reason || '',
                        type: 'warning'
                    })
                }
            },
            async changeTaxRules() {
                let result = await this.$swal({
                    title: 'Are you sure?',
                    text: "You can't revert your action",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes Change it!',
                    cancelButtonText: 'No, Discard changes!',
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                })
                if (result.value) {
                    let changes = {
                        tax_rules: this.taxDetails.tax_rules,
                        _id: this.$route.query.outletId
                    }
                    this.editOutletServiceCall(changes)
                } else {
                    this.setTaxDetails()
                    this.$swal('Cancelled', 'Changes are discarded', 'info')
                }
            },
            setGeneralDetails() {
                this.generalDetails = JSON.parse(
                    JSON.stringify({
                        name: this.outlet.name,
                        website: this.outlet.website,
                        email: this.outlet.email,
                        profile_pic: this.outlet.profile_pic,
                        phone_number: this.outlet.phone_number,
                        tax_id: this.outlet.tax_id,
                        currency: this.outlet.currency,
                        use_short_address_format_for_receipt: this.outlet
                            .use_short_address_format_for_receipt,
                        use_short_address_format_for_invoice: this.outlet
                            .use_short_address_format_for_invoice,
                        address: {
                            street: this.outlet.address.street,
                            street2: this.outlet.address.street2,
                            city: this.outlet.address.city,
                            state: this.outlet.address.state,
                            township: this.outlet.address.township,
                            zipcode: this.outlet.address.zipcode,
                            country: this.outlet.address.country
                        },
                        short_address: {
                            street: this.outlet.short_address.street,
                            city: this.outlet.short_address.city,
                            state: this.outlet.short_address.state,
                            zipcode: this.outlet.short_address.zipcode,
                            country: this.outlet.short_address.country
                        },
                        receipt_format: this.outlet.receipt_format,
                        add_wifi_info: this.outlet.add_wifi_info,
                        guest_wifi_info: this.outlet.guest_wifi_info,
                        add_tips: this.outlet.add_tips,
                        enable_double_receipts: this.outlet.enable_double_receipts,
                        is_round_off_required: this.outlet.is_round_off_required,
                        prompt_user_to_print: this.outlet.prompt_user_to_print,
                        branch_type: this.outlet.branch_type,
                        branch_id: this.outlet.branch_id,
                        no_of_decimal_points: this.outlet.no_of_decimal_points || 0,
                        is_decimal_points_allowed: this.outlet.is_decimal_points_allowed || false,
                        round_off_settings: this.outlet.round_off_settings,
                    })
                )
                if (this.generalDetails.address.street == this.generalDetails.short_address.street) {
                    this.cop_address_model = true
                }
            },
            setTaxDetails() {
                this.taxDetails = JSON.parse(JSON.stringify({
                    tax_rules: this.outlet.tax_rules
                }))
            },
            setOtherDetails() {
                if(this.outlet.opening_hours.status){
                    this.outlet.opening_hours.start = this.outlet.opening_hours.start + ' ' + this.outlet.opening_hours.start_meridian
                    this.outlet.opening_hours.end = this.outlet.opening_hours.end + ' ' + this.outlet.opening_hours.end_meridian
                }
                for (let day in this.outlet.opening_hours) {
                    if(this.outlet.opening_hours[day].status){
                        this.outlet.opening_hours[day].start = this.outlet.opening_hours[day].start + ' ' + this.outlet.opening_hours[day].start_meridian
                        this.outlet.opening_hours[day].end = this.outlet.opening_hours[day].end + ' ' + this.outlet.opening_hours[day].end_meridian
                    }
                }
                this.outlet.default_timings.breakfast.start_time = this.outlet.default_timings.breakfast.start_time + ' ' + this.outlet.default_timings.breakfast.start_meridian
                this.outlet.default_timings.breakfast.end_time = this.outlet.default_timings.breakfast.end_time + ' ' + this.outlet.default_timings.breakfast.end_meridian
                this.outlet.default_timings.lunch.start_time = this.outlet.default_timings.lunch.start_time + ' ' + this.outlet.default_timings.lunch.start_meridian
                this.outlet.default_timings.lunch.end_time = this.outlet.default_timings.lunch.end_time + ' ' + this.outlet.default_timings.lunch.end_meridian
                this.outlet.default_timings.dinner.start_time = this.outlet.default_timings.dinner.start_time + ' ' + this.outlet.default_timings.dinner.start_meridian
                this.outlet.default_timings.dinner.end_time = this.outlet.default_timings.dinner.end_time + ' ' + this.outlet.default_timings.dinner.end_meridian
                console.log("test")
                this.otherDetails = JSON.parse(
                    JSON.stringify({
                        no_of_tables: this.outlet.no_of_tables,
                        report_emails: this.outlet.report_emails,
                        table_prefix: this.outlet.table_prefix,
                        opening_hours: this.outlet.opening_hours,
                        default_timings: this.outlet.default_timings || this.otherDetails.default_timings,
                        prompt_user_to_print: this.outlet.prompt_user_to_print,
                        is_round_off_required: this.outlet.is_round_off_required,
                        double_discount: this.outlet.double_discount,
                        additional_charge_with_discount: this.outlet
                            .additional_charge_with_discount,
                        business_day_start_time: this.outlet.business_day_start_time,
                        enable_fast_checkout: this.outlet.enable_fast_checkout,
                        delete_void_order: this.outlet.delete_void_order || false,
                        can_waiter_see_sales_data: this.outlet.can_waiter_see_sales_data || false,
                        show_loyalty_when_value_zero: this.outlet.show_loyalty_when_value_zero || false,
                        show_promotion_when_value_zero: this.outlet.show_promotion_when_value_zero || false,
                        courses_settings: this.outlet.courses_settings
                    })
                )
            },
            async getOutletFromId() {
                try {
                    let params = {
                        outlet_id: this.$route.query.outletId,
                        with_tax_rules: true
                    }
                    let res = await this.outletGetOutletFromId(params)
                    this.updateView(res)
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            async getPinConfig(){
                try {
                    let params = {
                        outlet_id: this.$route.query.outletId,
                        with_tax_rules: true
                    }
                    let res = await this.outletGetOutletFromId(params)
                    console.log(res)
                    this.pin_config = res.outlet.is_pin_config_active || false;
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            updateView(response) {
                this.outlet = response.outlet
                if (!this.outlet.details) {
                    this.outlet.details = {}
                }
                this.auto_deliver_when_placed = response.outlet.order_settings.auto_deliver_when_placed
                this.auto_prepare_when_placed = response.outlet.order_settings.auto_prepare_when_placed
                this.upcharge_for_credit_card_usage = response.outlet.upcharge_for_credit_card_usage
                this.credit_card_charges_percentage = response.outlet.credit_card_charges_percentage
                this.is_outlet_hybrid = response.outlet.is_outlet_hybrid
                this.tax_rules = response.tax_rules
                this.other_charges = response.other_charges
                this.prompt_user_to_print = this.outlet.prompt_user_to_print
                this.setGeneralDetails()
                this.setTaxDetails()
                this.setOtherDetails()
                this.getManageDetails()
            },
            async getManageDetails() {
                try {
                    if (this.outlet.outlet_type === 'vendor' && !this.outlet.details.popup_store) {
                        this.getPopupstoresAvailable()
                    } else if (this.outlet.outlet_type === 'vendor' && this.outlet.details.popup_store) {
                        let params = {
                            popup_store: this.outlet.details.popup_store,
                            account_id: this.outlet.details.account_id
                        }
                        let res = await this.outletGetPopupStoreDetails(params)
                        this.popup_details = res.popup_details
                    } else if (this.outlet.outlet_type === 'pop-up') {
                        this.getExistingandNewVendors()
                    }
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            async getExistingandNewVendors() {
                try {
                    let params = {
                        outlet_id: this.outlet._id
                    }
                    let res = await this.outletGetExistingAndNewVendorsOfPopupStore(params)
                    this.existing_vendors = res.existing_vendors
                    this.new_vendor_requests = res.new_vendor_requests
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            async getPopupDetails(details) {
                try {
                    let params = {
                        popup_store: details.popup_store,
                        account_id: details.account_id
                    }
                    let res = await this.outletGetPopupStoreDetails(params)
                    this.popup_details = res.popup_details
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            async getPopupstoresAvailable() {
                try {
                    let params = {
                        search_key: this.popup_search_key,
                        limit: 5
                    }
                    let res = await this.outletGetPopupsAvailable(params)
                    this.popup_stores = res.stores_list
                } catch (reason) {
                    //    TODO: Handle Reason
                }
            },
            async sendRequesttoPopupStore(popup_store_id, account_id) {
                try {
                    let params = {
                        vendor_id: this.outlet._id,
                        popup_store_id: popup_store_id,
                        account_id: account_id
                    }
                    let res = await this.outletSendRequestToPopupStore(params)
                    this.$swal({
                        title: res.message,
                        text: '',
                        type: 'success'
                    })
                    this.getOutletFromId()
                } catch (reason) {
                    this.$swal({
                        title: 'Cannot send request',
                        text: reason || '',
                        type: 'warning'
                    })
                }
            },
            async rejectVendorRequest(vendor_request) {
                try {
                    let params = {
                        vendor_id: vendor_request.vendor_id,
                        account_id: vendor_request.account_id,
                        popup_store_id: this.outlet._id
                    }
                    let res = await this.outletDeclineVendorRequestToPopup(params)
                    this.$swal({
                        title: res.message,
                        text: '',
                        type: 'success'
                    })
                    this.getOutletFromId()
                } catch (reason) {
                    this.$swal({
                        title: 'Failed while rejecting the vendor request',
                        text: reason || '',
                        type: 'warning'
                    })
                }
            },
            async acceptVendorRequest(vendor_request) {
                try {
                    let params = {
                        vendor_id: vendor_request.vendor_id,
                        account_id: vendor_request.account_id,
                        popup_store_id: this.outlet._id
                    }
                    let res = await this.outletAcceptVendorRequestToPopup(params)
                    this.$swal({
                        title: res.message,
                        text: '',
                        type: 'success'
                    })
                    this.getOutletFromId()
                } catch (reason) {
                    this.$swal({
                        title: 'Failed while accepting the vendor request',
                        text: reason || '',
                        type: 'warning'
                    })
                }
            },
            async unregisterVendorForPopupStore(details) {
                try {
                    let params = {
                        vendor_id: details._id,
                        vendor_account_id: details.vendor_account_id,
                        popup_store_id: this.outlet._id,
                        from_popup_store: true
                    }
                    let res = await this.outletUnregisterVendorFromPopupStore(params)
                    this.$swal({
                        title: res.message,
                        text: '',
                        type: 'success'
                    })
                    this.getOutletFromId()
                } catch (reason) {
                    this.$swal({
                        title: 'Failed to unregister',
                        text: reason || '',
                        type: 'warning'
                    })
                }
            },
            async unregisterFormPopupStoreForVendor(vendor_doc) {
                try {
                    let params = {
                        vendor_id: vendor_doc._id,
                        popup_store_id: vendor_doc.details.popup_store,
                        popup_store_account_id: vendor_doc.details.account_id,
                        from_vendor: true
                    }
                    let res = await this.outletUnregisterVendorFromPopupStore(params)
                    this.$swal({
                        title: res.response,
                        text: '',
                        type: 'success'
                    })
                    this.getOutletFromId()
                } catch (reason) {
                    this.$swal({
                        title: reason,
                        text: reason || '',
                        type: 'warning'
                    })
                }
            },
            async deletePayment(payment_id) {
                let result = await this.$swal({
                    title: 'Are you sure?',
                    text: 'Are you sure to delete the payment method ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                })
                if (result.value) {
                    this.deletePaymentMethod(payment_id)
                }
            },
            async getBranchs() {
                try {
                    let res = await this.$http.get('/crm/branches')
                    if (res.data.status_id == 1) {
                        this.branchs = res.data.result
                    }
                } catch (reason) {

                }
            },
            async getOrderType(key) {
                try {
                    let params = {
                        search_key: this.search_key,
                        required_id: true
                    }
                    let res = await this.$http.post('/order_type/get_all_order_types_for_dropdown', params)
                    if (res.data.status_id == 1) {
                        this.allOrderTypes = res.data.response.order_types
                    }
                } catch (reason) {

                }
            },
            async getServiceType() {
                try {
                    let params = {
                        limit: 10,
                        skip: 0,
                        searchdata: this.searchdata
                    }
                    let res = await this.$http.post('/outlet/get_other_charges_dropdown', params)
                    if (res.data.status_id == 1) {
                        this.allServiceType = res.data.response.other_charges_list
                    }
                } catch (reason) {

                }
            },
            async getServiceTypeEdit() {
                try {
                    let params = {
                        limit: 10,
                        skip: 0,
                        searchdata: this.searchdata,
                        _id: this.edit_id
                    }
                    let res = await this.$http.post('/outlet/get_other_charges_dropdown', params)
                    if (res.data.status_id == 1) {
                        this.allServiceType = res.data.response.other_charges_list
                    }
                } catch (reason) {

                }
            },
            removecreate(option) {
                this.company_deliveries.forEach((el, index) => {
                    if (el._id == option._id) {
                        this.company_deliveries.splice(index, 1)
                    }
                })
            },
            removecreateedit(option) {
                this.edit_company_deliveries.forEach((el, index) => {
                    if (el._id == option._id) {
                        this.edit_company_deliveries.splice(index, 1)
                    }
                })
            },
            removeOnline(option) {
                this.company_online.forEach((el, index) => {
                    if (el._id == option._id) {
                        this.company_online.splice(index, 1)
                    }
                })
            },
            removeOnlineedit(option) {
                this.edit_company_online.forEach((el, index) => {
                    if (el._id == option._id) {
                        this.edit_company_online.splice(index, 1)
                    }
                })
            },
            removecreateTax(option) {
                this.newTaxRule.company_deliveries.forEach((el, index) => {
                    if (el._id == option._id) {
                        this.newTaxRule.company_deliveries.splice(index, 1)
                    }
                })
            },
            removeeditTax(option) {
                this.editTaxRule.company_deliveries.forEach((el, index) => {
                    if (el._id == option._id) {
                        this.editTaxRule.company_deliveries.splice(index, 1)
                    }
                })
            },
            removeTaxOnline(option) {
                this.newTaxRule.company_online.forEach((el, index) => {
                    if (el._id == option._id) {
                        this.newTaxRule.company_online.splice(index, 1)
                    }
                })
            },
            removeTaxEditOnline(option) {
                this.editTaxRule.company_online.forEach((el, index) => {
                    if (el._id == option._id) {
                        this.editTaxRule.company_online.splice(index, 1)
                    }
                })
            },
            removeEditTax(option) {
                this.editTaxRule.service_type.forEach((el, index) => {
                    if (el._id == option._id) {
                        this.editTaxRule.service_type.splice(index, 1)
                    }
                })
            },
            removeedit(option) {
                this.edit_service_type.forEach((el, index) => {
                    if (el._id == option._id) {
                        this.edit_service_type.splice(index, 1)
                    }
                })
            },
            limitText(count) {
                return `and ${count} Order Type`
            },
            getDeliveryData(key) {
                this.allOrderTypes.forEach((el, index) => {
                    if (el.order_type == key) {
                        this.apigetDeliveryCo(el._id)
                    }
                })
            },
            async apigetDeliveryCo(type) {
                try {
                    let params = {
                        order_type: type,
                        search_key: '',
                        required_id: true
                    }
                    this.delivery_company_list = []
                    let res = await this.getDeliveryByOrder(params)
                    this.delivery_company_list = res.response.delivery_companies
                } catch (reason) {

                }
            },
            clearValues(key) {
                if (key == 'Deliveries') {
                    this.company_deliveries = []
                } else if (key == 'Online') {
                    this.company_online = []
                }
            },
            clearTaxAddValues(key) {
                if (key == 'Deliveries') {
                    this.newTaxRule.company_deliveries = []
                } else if (key == 'Online') {
                    this.newTaxRule.company_online = []
                }
            },
            clearTaxEditValues(key) {
                if (key == 'Deliveries') {
                    this.editTaxRule.company_deliveries = []
                } else if (key == 'Online') {
                    this.editTaxRule.company_online = []
                }
            },
            checkChangeDiscount(discount) {
                return discount = true;
            },
            async WaitersList() {
                this.waiters_list = [];
                this.approval_permissions_selected = [];
                try{
                    let params = {
                        for_manage: true,
                        search_key: this.search_key,
                        skip: 0,
                        limit: 10,
                        sort: {
                            full_name: 1
                        },
                        for_waiter_list: true
                    }
                    let res = await this.getWaitersList(params);
                    this.waiters_list = res.employees
                    this.waiter_access = res.waiter_access
                    this.waiter_approval_permission = res.waiter_approval_permission
                    // this.waiters_list.forEach(async (each_id) => {
                    //     this.approval_permissions_selected.push({
                    //         user_id: each_id.user_id,
                    //         approval_permissions:{
                    //             change_discount: each_id.approval_permissions.change_discount,
                    //             // delete_order: each_id.approval_permissions.delete_order,
                    //             access_change_discount: each_id.access_change_discount,
                    //             access_remove_item: each_id.access_remove_item,
                    //             remove_item: each_id.approval_permissions.remove_item
                    //         }
                    //     })
                    // })
                    // let check_change_discount = this.approval_permissions_selected.map(x => {return x.approval_permissions.change_discount})
                    // let discounts = arr => arr.every(v => v === true);
                    // console.log(discounts(check_change_discount));
                    // let check_remove_item = this.approval_permissions_selected.map(x => {return x.approval_permissions.remove_item})
                    // let remove_item = arr => arr.every(v => v === true);
                    // console.log(remove_item(check_remove_item));
                    // let check_delete_order = this.approval_permissions_selected.map(x => {return x.approval_permissions.delete_order})
                    // let delete_order = arr => arr.every(v => v === true);
                    // console.log(delete_order(check_delete_order));
                    // if(remove_item(check_remove_item) && discounts(check_change_discount)){
                    //     this.checkAll = true
                    // }
                    // this.$forceUpdate()
                    // console.log(this.waiters_list)
                } catch(error) {
                    console.log(error)
                }
            },
            async updatePermission(event, ap, type) {
                if(event) {
                    let index = this.approval_permissions_selected.map(function(e) { return e.user_id; }).indexOf(ap.user_id);
                    if (index > -1) {
                        if(type == 'change_discount') {
                            this.approval_permissions_selected[index].approval_permissions.change_discount = true
                        } else if(type == 'remove_item') {
                            this.approval_permissions_selected[index].approval_permissions.remove_item = true
                        }
                    }
                } else {
                    let index = this.approval_permissions_selected.map(function(e) { return e.user_id; }).indexOf(ap.user_id);
                    if (index > -1) {
                        if(type == 'change_discount') {
                            this.approval_permissions_selected[index].approval_permissions.change_discount = false
                        } else if(type == 'remove_item') {
                            this.approval_permissions_selected[index].approval_permissions.remove_item = false
                        }
                    }
                }
            },
            async selectPermissions() {
                try {
                    localStorage.setItem('waiter_list', JSON.stringify(this.waiters_list))
                    let waiter_list = JSON.parse(localStorage.getItem('waiter_list'))
                    let params = {
                        waiter_access: this.waiter_access,
                        waiter_approval_permission: this.waiter_approval_permission,
                        user_permissions: waiter_list
                    }
                    params.user_permissions.forEach((user, index) => {
                        delete user._id
                        delete user.full_name
                    })
                    let response = await this.savePermissionDetails(params)
                    this.modal_msg = response.message
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        localStorage.removeItem('waiter_list');
                        this.$refs.success_modal.close();
                        this.ajaxCallInProgress = false
                        this.WaitersList();
                    }, 2000)
                }
                catch (error) {
                    console.log(error)
                    this.modal_msg = error
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        localStorage.removeItem('waiter_list');
                        this.$refs.warning_modal.close();
                        this.ajaxCallInProgress = false
                    }, 2000)
                }
            },
            // selectAllItems(){
            //     if(this.checkAll){
            //         this.waiters_list.forEach(x => {
            //             x.approval_permissions.change_discount = true
            //             x.approval_permissions.remove_item = true
            //             x.approval_permissions.delete_order = true
            //         })
            //     }
            //     else{
            //         this.waiters_list.forEach(x => {
            //             x.approval_permissions.change_discount = false
            //             x.approval_permissions.remove_item = false
            //             x.approval_permissions.delete_order = false
            //         })
            //     }
            // },
            // async selectPermissions() {
            //     let check_change_discount = this.approval_permissions_selected.map(x => {return x.approval_permissions.change_discount})
            //     let discounts = arr => arr.every(v => v === true);
            //     console.log(discounts(check_change_discount));
            //     let check_remove_item = this.approval_permissions_selected.map(x => {return x.approval_permissions.remove_item})
            //     let remove_item = arr => arr.every(v => v === true);
            //     console.log(remove_item(check_remove_item));
            //     let check_delete_order = this.approval_permissions_selected.map(x => {return x.approval_permissions.delete_order})
            //     let delete_order = arr => arr.every(v => v === true);
            //     console.log(delete_order(check_delete_order));
            //     if(remove_item(check_remove_item) && discounts(check_change_discount)){
            //         this.checkAll = true
            //     }
            //     else{
            //         this.checkAll = false
            //     }
            //     this.$forceUpdate()
            //     this.ajaxCallInProgress = true
            //         let params = {
            //             waiter_approval_permission: this.checkAll,
            //             user_permissions: this.approval_permissions_selected
            //         }
            //         let response = await this.savePermissionDetails(params)
            //         this.modal_msg = response.message
            //         this.$refs.success_modal.open();
            //         setTimeout(() => {
            //             this.$refs.success_modal.close();
            //             this.ajaxCallInProgress = false
            //             this.WaitersList();
            //         }, 2000)
            // },
            async generateAllUserPins() {
                this.generate_user_pin_api_inprogress = true;
                let params = {
                    outlet_id: this.outlet._id
                }
                let res = await this.generateAllPin(params);
                this.modal_msg = res.message
                this.$refs.success_modal.open();
                setTimeout(() => {
                    this.$refs.success_modal.close();
                    this.employeesList()
                    this.pin_config = true
                    EventBus.$emit('update_user_pin_settings')
                    this.generate_user_pin_api_inprogress = false;
                }, 2000)
            },
            async employeesList() {
                try{
                    let params = {
                        for_manage: false,
                        outlet_id: this.$route.query.outletId,
                        for_this_outlet: true,
                        limit: 10,
                        search_key: "",
                        skip: 0,
                        sort: {
                            full_name: 1
                        },
                    }
                    let res = await this.getWaitersList(params);
                    this.employees_list = res.employees
                    console.log(this.employees_list)
                } catch(error) {
                    console.log(error)
                }
            },
            async pinRegeneration(user_id){
                this.ajaxCallInProgress = true
                this.generate_user_pin_api_inprogress = true;
                let params = {
                    outlet_id: this.outlet._id,
                    user_id: user_id
                }
                let res = await this.regeneratePin(params);
                this.modal_msg = 'PIN Regenerated Successfully'
                this.$refs.success_modal.open();
                setTimeout(() => {
                    this.$refs.success_modal.close();
                    this.employeesList()
                    this.ajaxCallInProgress = false
                    EventBus.$emit('update_user_pin_settings')
                    this.generate_user_pin_api_inprogress = false;
                }, 2000)
            },
            async deleteUserPin(user_id){
                this.ajaxCallInProgress = true
                let params = {
                    user_id: user_id
                }
                let response = await this.deletePin(params);
                this.modal_msg = 'PIN Deleted Successfully'
                this.$refs.success_modal.open();
                setTimeout(() => {
                    this.$refs.success_modal.close();
                    this.employeesList()
                    this.ajaxCallInProgress = false
                    EventBus.$emit('update_user_pin_settings')
                }, 2000)
            },
            async enablePinConfig(){
                this.ajaxCallInProgress = true
                let params = {
                    outlet_id: this.outlet._id
                }
                let res = await this.enablePin(params)
                this.modal_msg = 'PIN Disabled Successfully'
                // this.modal_msg = res.response
                this.$refs.success_modal.open();
                setTimeout(() => {
                    this.$refs.success_modal.close();
                    this.employeesList()
                    this.ajaxCallInProgress = false
                    EventBus.$emit('update_user_pin_settings')
                }, 2000)
            },
            async scrollEnd(e) {
                const {target} = e;
                if (Math.ceil(target.scrollTop) >= target.scrollHeight - target.offsetHeight) {
                    try {
                        this.isLoadingPin = true
                        let params = {
                            for_manage: false,
                            outlet_id: this.$route.query.outletId,
                            for_this_outlet: true,
                            limit: 10,
                            search_key: "",
                            skip: this.pinCount,
                            sort: {
                                full_name: 1
                            },
                        }
                        let result = await this.getWaitersList(params);
                        if (result.status_id == 1) {
                            if (result.skip == this.pinCount) {
                                let tempFiles = result.response
                                tempFiles.forEach(el => {
                                    this.employees_list.push(el)
                                })
                                this.pinCount += result.response.length;
                                this.isLoadingPin = false
                            }
                            if (result.response.length < 10){
                                document.getElementById('spinner').style.display = 'none'
                                this.isLoadingPin = false
                            }
                        }
                    } catch(error){

                    }
                    this.bottom = true;
                }
            }
        },
        mounted() {
            EventBus.$on('updatePINS', () => {
                console.log('reached')
                this.employeesList()
            })
            console.log(this.loggedInUser)
            setTimeout(() => {
                this.auto_logout = this.loggedInUser.auto_logout
                this.auto_logout_mins = this.loggedInUser.auto_logout_mins
                this.hide_row_with_value_zero = this.loggedInUser.hide_row_with_value_zero
                this.show_place_order_in_checkout = this.loggedInUser.show_place_order_in_checkout
            }, 3000);
            $(document).bind('cut copy paste', function (e) {
                $("#preventkeys").fadeIn("slow");
                setTimeout(function () {
                    $("#preventkeys").fadeOut("slow");
                }, 2000);
                return false;
            });
            $(document).on("keydown keyup", "#preventkeys", function (event) {
                if (event.which == 38 || event.which == 40) {
                    event.preventDefault();
                }
            });
            EventBus.$emit('settingsLoad', true)
            EventBus.$emit('customerdetailsLoad', false)
            EventBus.$emit('reportsLoad', false)
            // getEmployeesForPermission();
            this.getPinConfig();
            this.WaitersList();
            this.employeesList();
        },
        created() {
            const dict = {
                custom: {
                    starting_time: {
                        required: () => 'Start Time is Required',
                    },
                    ending_time: {
                        required: () => 'End Time is Required',
                    },
                    starting_time_lunch: {
                        required: () => 'Start Time is Required',
                    },
                    ending_time_lunch: {
                        required: () => 'End Time is Required',
                    },
                    starting_time_dinner: {
                        required: () => 'Start Time is Required',
                    },
                    ending_time_dinner: {
                        required: () => 'End Time is Required',
                    },
                    outletName: {
                        required: () => 'Outlet Name is Required',
                        regex: () => 'Please Enter Valid Outlet Name'
                    },
                    email: {
                        required: () => 'Email ID Is Required',
                        regex: () => 'Please Enter A Valid Email ID'
                    },
                    city: {
                        required: () => 'City is Required',
                        regex: () => 'Please Enter Valid City Name'
                    },
                    shortcity: {
                        required: () => 'City is Required',
                        regex: () => 'Please Enter Valid City Name'
                    },
                    state: {
                        required: () => 'State is Required',
                        regex: () => 'Please Enter Valid State Name'
                    },
                    shortstate: {
                        required: () => 'State is Required',
                        regex: () => 'Please Enter Valid State Name'
                    },
                    sequence: {
                        required: () => 'Table Prefix is Required',
                        alpha_num: () => 'Enter A Valid Table Prefix'
                    },
                    tax_name: {
                        required: () => 'Name is Required',
                        regex: () => 'Enter A Valid Tax Name'
                    },
                    tax_code: {
                        required: () => 'Tax Code is Required',
                        regex: () => 'Enter A Valid Tax Code'
                    },
                    tax_rate: {
                        required: () => 'Tax Rate is Required',
                        regex: () => 'Enter Valid Tax Rate'
                    },
                    edit_tax_name: {
                        required: () => 'Name is Required',
                        regex: () => 'Enter A Valid Tax Name'
                    },
                    edit_tax_code: {
                        required: () => 'Tax Code is Required',
                        regex: () => 'Enter A Valid Tax Code'
                    },
                    edit_tax_rate: {
                        required: () => 'Tax Rate is Required',
                        regex: () => 'Enter A Valid Tax Rate'
                    },
                    zipcode: {
                        required: () => 'Zip Code is Required',
                        numeric: () => 'Zip Code should be numeric'
                    },
                    shortzipcode: {
                        required: () => 'Zip Code is Required',
                        numeric: () => 'Zip Code should be numeric'
                    },
                    rate: {
                        required: () => 'Tax Rate is Required',
                        regex: () => 'Enter A Valid Tax Rate'
                    },
                    outletWebsite: {
                        required: () => 'Outlet Website is Required',
                        regex: () => 'Outlet Website should be URL'
                    },
                    taxId: {
                        required: () => 'Tax Id is Required'
                    },
                    ruletaxname: {
                        required: () => 'Tax Name Is Required',
                        regex: () => 'Please Enter Valid Tax Name'
                    },
                    ruletaxcode: {
                        required: () => 'Tax Code Is Required',
                        regex: () => 'Please Enter Valid Tax Code'
                    },
                    charge_name: {
                        required: () => 'Name is Required',
                        regex: () => 'Enter A Valid Name'
                    },
                    charge_code: {
                        required: () => 'Code is Required',
                        regex: () => 'Enter A Valid Code'
                    },
                    credit_card_charges_percentage: {
                        min: () => 'Enter Positive Number.'
                    },
                }
            }
            this.$validator.localize('en', dict)
            this.getOutletFromId()
            this.getBranchs()
            this.getOrderType()
        }
    }

</script>
<style scoped>
    .borderBottom {
        border-bottom: 1px solid #a8adb4 !important;
    }

    .general-settings-tab-outlet {
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        background-color: #dee8f2;
        margin: 16px;
    }

    .general-settings-tab-outlet .inputContact,
    .general-settings-tab-outlet .digi-form {
        border-bottom: 1px solid #a8adb4 !important;
        background: transparent !important;
    }

    .general-settings-tab-outlet .vue-tel-input {
        background: transparent !important;
        border: transparent !important;
        border-bottom: 1px solid #a8adb4 !important;
    }

    .tax-tab-outlet {
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        background-color: #dee8f2;
        margin: 16px;
        padding-bottom: 7px;
    }

    .mediaSize .other-table-content {
        padding: 20px;
        background-color: #dee8f2 !important;
        padding-bottom: 20px;
        border-radius: 12px;
        margin-bottom: 20px;
    }

    .mediaSize.other-settings-tab-outlet .inputContact {
        border-bottom: solid 1px #a8adb4 !important;
        background: transparent;
    }

    .other-settings-other .vue-tags-input {
        background-color: transparent !important;
    }

    .other-settings-other .vue__time-picker input.display-time {
        background: transparent !important;
    }

    .send-report-div {
        background-color: #dee8f2 !important;
        padding-bottom: 20px;
        border-radius: 12px;
    }

    .send-report-div .vue-tags-input {
        background-color: transparent !important;
    }

    .send-report-div .new-tag-input-wrapper input {
        background-color: transparent !important;
    }

    .business-div {
        background-color: #dee8f2 !important;
        padding-bottom: 20px;
        border-radius: 12px;
        margin-top: 20px;
    }

    .business-div .vue__time-picker input.display-time {
        background: #dee8f2 !important;
    }

    input:required {
        box-shadow: none !important;
    }

    .icon-edit-void,
    .icon-delete-void {
        cursor: pointer;
    }

    .form-check.form-check-inline.void-checkbox {
        border-radius: 20px;
        border: solid 0.5px #c7e1ff;
        background-color: #ebf6ff;
        padding: 7px 7px 6px 11px;
        font-size: 13px;
        margin-bottom: 10px;
    }

    .form-check.form-check-inline.void-checkbox .form-check-label input[type='checkbox']~.checkbox-icon {
        top: -1px !important;
        left: -1px !important;
        font-size: 22px !important;
    }

    .voiddiv {
        border-radius: 5px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        background-color: #dee8f2;
    }

    .voiddiv_cancelled {
        border-radius: 5px;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        background-color: #dee8f2;
    }

    .form-check.form-check-inline.void-checkbox .form-check-label input[type='checkbox']:checked~.checkbox-icon {
        color: #00448b !important;
    }

    .icon-edit-void .icon.icon-pencil {
        color: #00448b !important;
        font-size: 18px !important;
        padding-left: 8px !important;
    }

    .void-pophover {
        position: absolute;
        background-color: #fff;
        z-index: 9999;
        width: 400px;
    }

    .void-pophover .popover {
        max-width: 400px;
    }

    .form-control.void-input-pop {
        border: 1px solid #cdd2da !important;
        border-radius: 24px 0px 0px 24px !important;
        border-right: 0px !important;
    }

    .icon-delete-void .icon.icon-delete {
        color: #ff6565 !important;
        font-size: 18px !important;
    }

    @media (max-width: 1100px) {
        .col-4.settings-cols {
            flex: 0 0 80% !important;
            max-width: 80% !important;
        }
    }

    @media (max-width: 1100px) {
        .col-8.settings-cols {
            flex: 0 0 100% !important;
            max-width: 100% !important;
            margin-bottom: 20px;
        }
    }

    @media (max-width: 760px) {
        .handlecountry {
            margin-top: 30px !important;
        }
    }

    .contactActiveTab {
        color: #fff !important;
        border-top: 18px solid transparent;
        border-bottom: 18px solid transparent;
        border-right: solid 18px #cdd2da;
        background-color: #00448b;
        border-right-color: #00448b;
        overflow: inherit !important;
        padding-left: 42px !important;
        font-weight: 600;
        background: rgba(0, 0, 0, 0.38);
    }

    .supplyactive.contactActiveTab {
        border-top: 26px solid transparent;
        border-bottom: 26px solid transparent;
        border-right: solid 18px #cdd2da;
        background-color: #00448b;
        border-right-color: #00448b;
        overflow: inherit !important;
        padding-left: 42px !important;
        font-weight: 600;
        background: rgba(0, 0, 0, 0.38);
        color: #fff !important;
    }

    .scmminustop {
        margin-top: -15px;
    }

    label[for='taxCode'] {
        display: none;
    }

    .res-settings {
        box-shadow: 0 1px 5px 0 rgba(187, 132, 132, 0.16) !important;
        background-color: #dee8f2 !important;
        margin-bottom: 16px;
        padding-bottom: 6px;
        border-radius: 8px;
    }

    .select-card-checkbox {
        height: 80px;
        line-height: 79px;
        margin-left: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .occupiedTable-regenerate {
        cursor: pointer;
        width: 100px;
        height: 100px;
        font-weight: 600;
        padding-top: 8px !important;
        padding: 10px;
        text-align: center;
        border-radius: 7px;
        margin-left: 18px;
        display: inline-grid;
        margin-top: 18px;
        color: #ffffff;
        -webkit-box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 2px 3px 2px 0 rgba(0, 0, 0, 0.16);
        background-image: -webkit-gradient(linear,
                left top,
                left bottom,
                from(#00448b),
                color-stop(51%, #00448b),
                to(#69b3fc));
        background-image: linear-gradient(to bottom, #00448b, #00448b 51%, #69b3fc);
    }
    .occupiedTable-regenerate-selected {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24);
        border: solid 0.5px #ffa104;
        background-image: linear-gradient(to bottom, #f9a00b, #f9a00b 51%, #ffd184, #ffd184);
    }
    .occupiedTable-regenerate-waiter-assigned {
        background-image: none;
        border: solid 0.5px #0d84f2;
        background-color: #009f51;
        cursor: not-allowed;
    }

    .table-row {
        border: 1px solid #a8adb3;
        border-radius: 18px;
        margin-top: 10px;
        background-color: #dee8f2;
    }
    .table-row-new {
        background-color: #fff;
    }

    #lazy-load-approval2 {
        max-height:331px;
        overflow-y: auto;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .v-select.cash-v-select input[type='search'] {
        position: absolute !important;
    }

    .outletdetails label.digi-label-outlet {
        top: 13px;
        position: absolute;
        font-size: 12px;
        color: #aaa;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    .digi-form:focus~label.digi-label-outlet,
    .digi-form:valid~label.digi-label-outlet {
        top: -15px;
        font-size: 13px;
        color: #00448b;
    }

    .heading-details {
        font-weight: 600;
        /* color: #00448b; */
        color: #00448b;
        font-size: 14px;
    }

    .verticalTab {
        height: 41px;
        display: flex;
        width: 100%;
        text-align: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 24px;
        position: relative;
        justify-content: center;
        color: #f7f6f6 !important;
        font-weight: 600;
    }

    .verticalTab:hover {
        background-color: #eeeeee47;
    }

    .contactSidebar {
        background-image: url('https://cdn.digicollect.com/cdn/posv2/arora1.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: auto;
    }

    .table.table-cash-outlet tbody tr {
        border-bottom: 1px solid #a8adb4;
    }

    .table.table-cash-outlet tbody tr:last-child {
        border-bottom: none !important;
    }

    .table.table-payment tbody tr {
        border-bottom: 1px solid #a8adb4;
    }

    .table.table-payment tbody tr:last-child {
        border-bottom: none !important;
    }

    .contactTitle {
        text-align: center;
        display: block;
        font-size: 14px;
        color: #fff !important;
        font-weight: 600 !important;
        width: 280px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 auto;
    }

    .existingVendors {
        border: 1px solid #00448b;
        height: 34px !important;
        color: #00448b !important;
        background: #fff !important;
        line-height: 32px !important;
        text-transform: none !important;
        padding: 5px 15px 5px 15px;
        font-size: 14px !important;
        font-weight: 500;
        border-left: none !important;
        cursor: pointer;
        border-radius: 20px 0 0 20px !important;
    }

    .newReq {
        border: 1px solid #00448b;
        height: 34px !important;
        color: #00448b !important;
        background: #fff !important;
        line-height: 32px !important;
        text-transform: none !important;
        padding: 5px 15px 5px 15px;
        font-size: 14px !important;
        font-weight: 500;
        cursor: pointer;
        border-left: none !important;
        border-radius: 0 20px 20px 0 !important;
        margin-left: -3px;
    }

    .hotel-website {
        font-size: 14px;
        font-weight: 600;
        color: #00448b !important;
    }

    .table.table-outlet-details td {
        padding: 0.6rem 1.2rem !important;
    }

    .decline {
        font-size: 14px;
        font-weight: 600;
        color: #e82828 !important;
        padding-right: 20px;
    }

    .popTitle {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        display: block;
        padding-top: 6px;
        padding-bottom: 2px;
    }

    .new-count {
        color: #ffa104 !important;
        font-weight: 600;
    }

    .popAvailable {
        font-size: 14px;
        font-weight: bold;
        color: #00448b;
        line-height: 35px;
    }

    .text-bold {
        font-weight: 600;
    }

    .text-muted {
        color: #4e5764 !important;
        font-weight: 600 !important;
    }

    .font-weight-600.text-green,
    .font-weight-600.text-green i {
        color: #1a9347 !important;
    }

    .font-weight-600.text-red,
    .font-weight-600.text-red i {
        color: red !important;
    }

    .font-weight-600 {
        font-weight: 600;
    }

    .card-outlet {
        border-radius: 7px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #cdd2da;
        margin-top: 50px;
        margin-right: 26px;
    }

    .outlet-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
    }

    @media (min-width: 992px) {}

    @media (min-width: 1200px) {}

    @media (max-width: 1100px) {
        .mediaSize {
            padding: 0 0 !important;
        }
    }

    .currentPopup {
        overflow-x: hidden;
    }

    .nav-tabs.nav-site {
        border-bottom: 1px solid #cdd2da;
        padding-left: 14px;
        padding-right: 14px;
    }

    .nav-tabs.nav-site .nav-item {
        margin-bottom: -2px;
        margin-top: 10px !important;
    }

    .nav-tabs.nav-site .nav-link.active {
        box-shadow: 1px -1px 2px 0 rgba(0, 0, 0, 0.16) !important;
        background: #00448b !important;
        color: #fff !important;
        border-bottom: 1px solid transparent;
        font-weight: 600;
    }

    .nav-tabs.nav-site .nav-link {
        height: 35px !important;
        border: 1px solid #cdd2da;
        background-color: #efefef;
        color: #00448b;
        margin-right: 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        margin-bottom: 1px;
    }

    .table td {
        vertical-align: middle;
        color: #4e5764;
        font-weight: 600;
    }

    .nav-tabs.nav-site .nav-link.active:not(.dropdown-toggle):after,
    .nav-tabs.nav-site .nav-item.show .nav-link:not(.dropdown-toggle):after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background-color: transparent;
    }

    .whiteSpace {
        white-space: nowrap;
    }

    @media (max-width: 780px) {
        .outlet-details-c-el-container .el-aside.contactSidebar {
            width: 250px !important;
            overflow: hidden !important;
        }

        .other-settings-other .res-settings {
            overflow: hidden !important;
        }
    }

    @media (max-width: 900px) {
        .displayHidden {
            display: none !important;
        }
    }

    @media (max-width: 1000px) {
        .openingHours {
            padding-left: 0px !important;
        }

        .closingHours {
            padding-left: 0px !important;
        }
    }

    @media screen and (max-width: 1200px) {
        .tableOverflow {
            overflow-x: auto;
            display: block;
        }
    }

    .tableOverflow:hover::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #94bef0;
        border: 4px solid transparent;
        background-clip: content-box;
        /* THIS IS IMPORTANT */
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border: 9px solid #00448b;
    }

    .displayNone {
        display: none !important;
    }

    .btnWhite {
        background-color: #fff !important;
        color: #00448b !important;
    }

    .network-setting-table thead,
    .network-setting-table tr,
    .network-setting-table td,
    .network-setting-table th {
        display: flex;
        flex: 1 1 0%;
    }

    .network-setting-table tbody tr,
    .network-setting-table tbody td {
        align-items: center;
        font-weight: 600;
    }

    .network-setting-table tbody {
        display: block;
        width: 100%;
        max-height: 390px;
        overflow-y: auto;
    }

    .table.network-setting-table tbody tr {
        border-bottom: 1px solid #a8adb4 !important;
    }

    .openingHours {
        padding-left: 1.5rem;
    }

    .closingHours {
        padding-left: 1.5rem;
    }

    .category-multiple-addon .custom__tag {
        background: #00448b;
        color: #fff;
    }

    .category-multiple-addon .multiselect__strong {
        line-height: 26px !important;
    }
    .v-select.cash-v-select input[type='search'] {
        position: absolute !important;
    }
    .form-check-label{
        padding-left: 0px !important;
    }
    .approvalswitch{
        margin-top: 3px;
    }
    .w-25{
        width: 25% !important;
    }
    .disable-check{
        pointer-events: none !important;
        cursor: not-allowed !important;
    }
</style>
