export default{
    methods: {
        async addOrderType(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/order_type/add_order_type', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getAllOrderType(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/order_type/get_all_order_types', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async editOrderType(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/order_type/edit', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async deleteOrderType(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/order_type/remove_order_type', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async UpdateOrderTypeStatus(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/order_type/change_status_of_order_type', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getAllVariantDetailsAPI(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/products/get_product_id_for_pricing', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async addPricingApi(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/products/update_products_pricing', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getAllProductsForYield(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/products/get_all_products', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async deleteProductByID(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/products/delete_product', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
    }
}