<template>
     <div class="pay_div"> 
          <div class="d-flex flex-column flex-root p-sm-10 p-0">
               <div class="d-flex flex-column flex-lg-row flex-column-fluid bg-body rounded-4">
                    <div class="d-flex flex-lg-row-fluid">
                         <div class="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
                              <!-- <h4 class="black-text fs-2qx fw-bold text-center mb-7">
                                   Please do not click back button or close or refresh page
                              </h4> -->
                              <img class="mx-auto mw-100 w-150px w-lg-350px mb-10 mb-lg-20 hideImage" src="https://cdn.digicollect.com/cdn/posv2/payment-img.svg" alt="payment image" />
                         </div>
                    </div>
                    <div class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center m-sm-12 m-5">
                         <div class="bg-body-pay d-flex flex-center rounded-4 w-md-400px">
                              <div class="w-md-380px">
                                   <div class="w-100">
                                        <h2 class="heading h8 mb-0 black-text">DigiCollect POS Payment</h2>
                                        <h1 class="fs-16 mx-2 mt-5 mb-5 d-flex align-items-center flex-wrap">
                                             <span class="mr-auto fw-bold  black-text">Amount to be Paid :</span> 
                                             <span class="amountDiv">{{total_amount}}</span>
                                        </h1>
                                        <h1 id="global_error" class="text-danger fs-14 global-error-class">
                                             <!-- Please check and fill the correct details. -->
                                        </h1>
                                        <div class="mx-2">
                                             <div style="margin-bottom: 30px;">
                                                  <label>
                                                       Name <span class="text-danger">*</span>
                                                  </label>
                                                  <input class="payment-input-field" v-validate="'required'" name="account_holder_name" v-model="account_holder_name" type="text" />
                                                  <span class="invalid-feedback-form" v-show="errors.has('account_holder_name')" style="display:inline;">Name is required</span>
                                             </div>
                                             <div style="margin-bottom: 30px;">
                                                  <label>
                                                       Email ID <span class="text-danger">*</span>
                                                  </label>
                                                  <input class="payment-input-field" v-validate="'required|email'" name="account_holder_email" v-model="account_holder_email" type="email" />
                                                  <span class="invalid-feedback-form" v-show="errors.has('account_holder_email')" style="display:inline;">{{errors.first('account_holder_email')}}</span>
                                             </div>
                                             <div style="margin-bottom: 30px;">
                                                  <label>
                                                       Router Number <span class="text-danger">*</span>
                                                  </label>
                                                  <input class="payment-input-field" v-validate="'required'" name="routing_number" v-model="routing_number" type="text" />
                                                  <span class="invalid-feedback-form" v-show="errors.has('routing_number')" style="display:inline;">Router Number is required</span>
                                             </div>
                                             <div style="margin-bottom: 30px;">
                                                  <label>
                                                       Account Number <span class="text-danger">*</span>
                                                  </label>
                                                  <input class="payment-input-field" v-validate="'required'" name="account_number" v-model="account_number" type="number" />
                                                  <span class="invalid-feedback-form" v-show="errors.has('account_number')" style="display:inline;">Account Number is required</span>
                                             </div>
                                             <div class="d-flex ml-1 mb-7">
                                                  <div class="form-group buttonsBlock">
                                                       <button type="button" @click="$router.go(-1)" class="btnCancel">
                                                            CANCEL
                                                       </button>
                                                       <!-- <button type="submit" :disabled="submit_api_in_progress" id="pay_btn" @click="Pay" class="btnSubmit"> -->
                                                       <button type="submit" :disabled="submit_api_in_progress" @click="paymentCheck" class="btnSubmit">
                                                            <span class="pr-2">
                                                                 <img src="https://cdn.digicollect.com/cdn/posv2/pay-img.svg" />
                                                            </span>
                                                            PAY
                                                       </button>
                                                  </div>
                                             </div>
                                        </div>

                                        <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
                                        <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
                                   </div>
                              </div>
                              <div v-if="api_call_in_progress" class="checkoutLoader">
                                   <div class="LoaderPlacement">
                                        <div class="digi-table-loader inverse">
                                             <div></div>
                                             <div></div>
                                             <div></div>
                                             <div></div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <payment-msg-modal-b-2-c v-if="payment_status_modal" modal_name="paymentMsgModal" :order_id="order_id" :order_type="order_type" @hidePayModal="hidePayModal" ></payment-msg-modal-b-2-c>
          <payment-msg-page-close-warning-modal modal_name="paymentMsgPageCloseWarningPopup" ></payment-msg-page-close-warning-modal>
     </div>
</template>

<script>
import paymentDeviceAPI from './mixins/paymentDeviceAPI'
import PaymentMsgModalB2C from "./PaymentMsgModalB2C";
import PaymentMsgPageCloseWarningModal from "./PaymentMsgPageCloseWarningModal";
import {
     SweetModal
} from 'sweet-modal-vue'
import { EventBus } from './eventBus/nav-bar-event';
import axios from 'axios'
export default {
     mixins: [paymentDeviceAPI],
     data(){
          return{
               order_id: '',
               order_type: '',
               modal_msg: '',
               api_call_in_progress: false,
               payment_status_modal: false,
               total_amount: 0,
               submit_api_in_progress: false,
               account_holder_name: null,
               account_holder_email: null,
               routing_number: null,
               account_number: null,
               token_generated_for_ach: ''
          }
     },
     components: {
          SweetModal,
          PaymentMsgModalB2C,
          PaymentMsgPageCloseWarningModal
     },
     methods:{
          async paySuccess() {
               this.$modal.show('paymentMsgPageCloseWarningPopup')
               setTimeout(() => {
                    this.$modal.hide('paymentMsgPageCloseWarningPopup');
                    this.paySuccessConfirm()
               },2000);
          },
          async paySuccessConfirm() {
               this.payment_status_modal = true;
               setTimeout(() => {
                    this.$modal.show('paymentMsgModal')
               },500);
               try{
                    this.submit_api_in_progress = true;
                    let payload = {
                         order_id: this.order_id,
                         // expiry: exp_date,
                         token: this.token_generated_for_ach,
                         type: "ACH",
                         email_id: this.account_holder_email
                    }
                    let res = await this.payAmount(payload);
                    // console.log(res)
                    setTimeout(() => {
                         EventBus.$emit("payment_status_success_failure", 'success')
                    }, 1000);
               }
			catch(error){
                    setTimeout(() => {
                         EventBus.$emit("payment_status_success_failure", 'failure')
                    }, 1000);
                    this.payment_status_modal = true;
                    setTimeout(() => {
                         this.$modal.show('paymentMsgModal')
                    },500);
               }
          },
          hidePayModal(){
               this.payment_status_modal = false;
               setTimeout(() => {
                    this.$modal.hide('paymentMsgModal')
               },500);
          },
          async paymentPosApiCall(){
               try {
                    this.$http.get("/cashier/is_cashier_session_active").then(res => {
                    this.modal_msg = res.data.reason;
                         if (res.data.status_id == 1) {
                              this.paySuccess();
                         } else {
                              this.$toasted.global.error(res.data.reason);
                              this.$router.go(-1);
                         }
                    });
               } catch (reason) {
                    this.$toasted.global.error(reason);
               }
		},
          async fetchPostApiCall(url = "", data = {}) {
               // Default options are marked with *
               const response = await fetch(url, {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                         "Content-Type": "application/json",
                         // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                    body: JSON.stringify(data), // body data type must match "Content-Type" header
               });
               return response.json(); // parses JSON response into native JavaScript objects
          },
          async paymentGenerateTokenSuccess() {
               try {
                    this.submit_api_in_progress = true;
                    let payload = {
                         account: this.routing_number + '/' + this.account_number
                    };
                    let response = await this.fetchPostApiCall("https://boltgw-uat.cardconnect.com/cardsecure/api/v1/ccn/tokenize", payload)
                    console.log(response)
                    if(response.token) {
                         console.log('success')
                         this.token_generated_for_ach = response.token
                         this.paymentPosApiCall()
                    } else {
                         console.log('error')
                         this.submit_api_in_progress = false;
                    }
               }
               catch(error) {
                    console.log(error)
                    this.submit_api_in_progress = false;
               }
          },
          paymentCheck() {
               this.$validator.validateAll().then(result => {
                    if (result) {
                         this.paymentGenerateTokenSuccess()
                    }
               })
          }
     },
     mounted(){
          this.order_id = this.$route.query.order_id
          this.order_type = this.$route.query.order_type
          this.total_amount = this.$route.query.total_amount
          // var token = document.getElementById('token');
          // let pay_btn = document.getElementById('pay_btn');
          // if(token.value == ''){
          //      pay_btn.disabled = true
          //      pay_btn.style.border = '1px solid #999999'
          //      pay_btn.style.backgroundColor = '#cccccc'
          //      pay_btn.style.cursor = 'not-allowed'
          // }
          // document.cookie = "SameSite=None"
          // window.addEventListener('message', function(event) {
          //      if(!event.data.source){
          //           console.log("Event Triggered!", event.data)
          //           var eventData = JSON.parse(event.data);
          //           var token = document.getElementById('token');
          //           token.value = eventData.message;
          //           var expiry = document.getElementById('expiry');
          //           expiry.value = eventData.expiry;
          //           var cvv = document.getElementById('cvv');
          //           cvv.value = eventData.cvv;
          //           let global_error = document.getElementById('global_error');
          //           let pay_btn = document.getElementById('pay_btn');
          //           if(token.value != '' && expiry.value != 'undefined'){
          //                pay_btn.disabled = false
          //                global_error.style.display = 'none'
          //                global_error.innerText = ''
          //                pay_btn.style.border = '1px solid #00448f'
          //                pay_btn.style.backgroundColor = '#00448f'
          //                pay_btn.style.cursor = 'pointer'
          //           } else {
          //                console.log(event.data.errorMessage)
          //                global_error.style.display = 'block'
          //                global_error.innerText = eventData.errorMessage || ''
          //                pay_btn.disabled = true
          //                pay_btn.style.border = '1px solid #999999'
          //                pay_btn.style.backgroundColor = '#cccccc'
          //                pay_btn.style.cursor = 'not-allowed'
          //           }
          //      } 
          // }, false);
     },
     beforeRouteLeave (to, from, next) {
          let message = "<div class='d-block'>Order is in progress Data would be lost, Do you want to proceed?</div>";
          // <img src='/static/images/exclamation-yellow.svg' class='d-block h-100px mx-auto mb-5'/><br>
          let options = {
               html: true,
               loader: false,
               reverse: false, // switch the button positions (left to right, and vise versa)
               okText: 'Yes',
               cancelText: 'No',
               animation: 'fade',
               type: 'basic',
               verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
               verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
               backdropClose: true,
          };
          this.$dialog.confirm(message, options)
          .then( () => {
               next();
          })
          .catch( ()=> {
               next(false);
          });
     },
     created() {
          const dict = {
               custom: {
               account_holder_email: {
                    required: () => 'Email is Required',
                    email: () => 'Please enter valid email',
               },
               }
          }
          this.$validator.localize('en', dict)
     }
}

</script>

<style scoped>
     .global-error-class {
          display: none;
     }
     .pay_div .error{
          color:#D32F2F;
          display:block;width:360px;height:34px;padding:2px 12px;font-size:14px;line-height:1.42857143;background-color:#fff;background-image:none;border:1px solid #676767;border-radius:4px;
     }
     .pay_div .heading {
        font-size: 20px;
        font-weight: 700;
        margin-top: 20px;
        padding-bottom: 15px;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        text-transform: uppercase;
    }
     .pay_div .form-group {
          margin-bottom: 15px;
     }
     .pay_div .buttonsBlock {
          max-width: 360px;width:100%;margin-top: 4px;text-align: center;
     }
     .pay_div .card {
          position: relative;
          display: flex;
          flex-direction: column;
          min-width: 0;
          word-wrap: break-word;
          background-color: transparent;
          background-clip: border-box;
          border: 0px solid transparent;
          border-radius: 0rem;
          box-shadow:none !important;
     }
     .pay_div .px-4 {
          padding-right: 1.5rem !important;
          padding-left: 1.5rem !important;
     }
     .pay_div .card {
          max-width: 360px;
          margin: auto;
          border-radius: 0px;
          padding: 0px;
     }
     .pay_div .container .h8 {
          font-size: 24px;
          font-weight: 800;
          text-align: center;
     }

    .flex-root {
        flex: 1;
    }
    .flex-column {
        flex-direction: column;
    }
    .w-150px {
        width: 150px !important;
    }
    .fs-2qx {
        font-size: 18px !important;
        padding: 2px 45px;
    }
    .flex-column-fluid {
        flex: 1 0 auto;
    }
    .flex-center {
        justify-content: center;
        align-items: center;
    }
    .mw-100 {
        max-width: 100% !important;
    }
    .black-text {
        color: #020a12;
    }
    .rounded-4 {
        border-radius: 1rem !important;
    }
    .bg-body {
        background-color: #fff !important;
    }
    .bg-body-pay {
        border-radius: 4px;
        box-shadow: 0 1.5px 6px 0 rgba(0, 0, 0, 0.47);
        background-color: #fff;
    }
    .amountDiv {
        background: #f2f2fb;
        border-radius: 2px;
        color: #00448b;
        letter-spacing: 0.18px;
        padding: 6px 8px;
        width: 145px;
        text-align: right;
        font-weight: 700 !important;
    }
    .fw-700 {
        font-weight: 700 !important;
    }
    .buttonsBlock {
          width: 360px;margin-top: 4px;text-align: center;
     }
    .btnCancel {
        display:inline-block;
        margin-right: 20px;
        margin-bottom:0;
        font-weight:600 !important;
        text-align:center !important;
        white-space:nowrap;
        vertical-align:middle;
        -ms-touch-action:manipulation;
        touch-action:manipulation;
        cursor:pointer;
        color: #fff;
        background-color: #d72c34;
        border:1px solid #d72c34;
        padding:10px 24px !important;
        font-size:15px !important;
        line-height:1.42857143;
        border-radius:6px;
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
        width: 170px;
    }
    .btnSubmit {
        display:inline-block;margin-bottom:0;font-weight:600 !important;text-align:center;white-space:nowrap;vertical-align:middle;-ms-touch-action:manipulation;touch-action:manipulation;cursor:pointer;color: #000;background-image: linear-gradient(315deg, #fccb4a -30%, #f0712a 48%);border: 0 !important; outline: 0 none !important; padding:10px 24px !important;font-size:15px;line-height:1.42857143;border-radius:6px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;width: 170px;
    }
    @media (min-width: 992px) {
        .flex-lg-row {
            flex-direction: row !important;
        }
        .flex-lg-row-fluid {
            flex: 1 auto;
            min-width: 0;
        }
        .pb-lg-10 {
            padding-bottom: 2.5rem !important;
        }
        .mb-lg-20 {
            margin-bottom: 5rem !important;
        }
        .w-lg-350px {
            width: 350px !important;
        }
        .flex-lg-row-auto {
            flex: 0 0 auto;
        }
        .justify-content-lg-end {
            justify-content: flex-end !important;
        }
    }
     .w-md-400px {
          width: 450px !important;
     }
     .w-md-380px {
          width: 380px !important;
     }
     .payment-input-field {
          display: block;
          width: 100%;
          height: 34px;
          padding: 2px 12px;
          font-size: 14px;
          line-height: 1.4285715;
          background-color: #ffffff;
          background-image: none;
          border: 1px solid #676767;
          border-radius: 6px;
     }
	@media (max-width: 350px) {
          .fs-2qx {
               font-size: 14px;
               padding: 2px 5px;
          }
          .p-10 {
               padding: 2rem !important;
          }
          .hideImage {
               display: none;
          }
     }
</style>
