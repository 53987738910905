export default {
    methods: {
        async getTables(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.get('/tables', { params })
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async updateTable(id, params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.put(`/tables/${id}`, params)
                if(response == undefined) {
                    reject('There is Ongoing Order. Cannot Update Table.')
                } else {
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
            })
        },
        async generateTables(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/tables/generate', params)
                if(response == undefined) {
                    reject('Cannot Update Tables while Orders in Progress.')
                } else {
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
            })
        }
    }
}
