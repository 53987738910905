import Swal from "sweetalert2";
import { EventBus } from '../eventBus/nav-bar-event.js'
import global from "../global.js";

export default {
	data() {
		return {
			productObj: {
				name: '',
				description: '',
				quantity: 0,
				price: 0,
				measure:'',
				uom:'',
				currency: '',
				product_id: '',
				stock_keeping_unit: '',
				brand: '',
				product_is:'BOTH',
				category_id: '',
				sub_category_id: '',
				product_type_id: '',
				status: true,
				tags: [],
				tax: [],
				variants: [],
				type: '',
				total_tax: 0,
				variant: false,
				image: '',
				gallery: []
			},
			product_details: {},
			tax_name: '',
			tax_percentage: '',
			tax_name_edit: '',
			tax_percentage_edit: '',
			variant_price: '',
			variant_name: '',
			variant_quantity: '',
			// variant_type: '',
			variant_price_edit: '',
			variant_name_edit: '',
			variant_quantity_edit: '',
			// variant_type_edit: '',
			with_variant: '',
			without_variant: '',
			searchkey: '',
			count: 0,
			start: 1,
			end: 0,
			total_products: 0,
			limit: 10,
			skip: 0,
			products: [],
			filter_data: {
				categories: [],
				brands: [],
				subcategories: [],
				ProductType: []
			},
			search: '',
			searchdata: '',
			variant: false,
			tags: [],
			gallery: [],
			passed_key: {
				key: 'name',
				value: 1
			},
			modal_msg: '',
			tag_list: [],
			params: {
				Authorization: this.$cookie.get("Token")
			},
			headers: {
				Authorization: this.$cookie.get("Token"),
			},
			idexists: false,
			exists: false,
			gallerylist: [],
			submitted: false,
			name: '',
			ajaxCallinprogress: false,
			skuexists: false,
			showIllustrator: false,
			fromVariants: false,
			fromSubCategory:false,
			fromproductType:false,
			product_id: "",
		}
	},
	methods: {
		validate_product() {
			this.submitted = true
			this.$validator.validateAll().then(result => {
				if (result) {
					this.saveProduct();
				}
			})
		},
		validate_edited_product(edited_product) {
			this.$validator.validateAll().then(result => {
				if (result) {
					this.saveEditedProduct(edited_product);
				}
			})
		},
		category_edited_product(edited_product) {
			this.$validator.validateAll().then(result => {
				if (result) {
					this.saveEditedProduct(edited_product);
					EventBus.$emit('showProductByCategory', true)
				}
			})
		},

		saveProduct() {
			if(this.productObj.variant){
				this.productObj.stock_keeping_unit = this.productObj.stock_keeping_unit.trim()
				this.productObj.product_id = this.productObj.product_id.trim()
				this.productObj.price = parseFloat(this.productObj.price)
				this.productObj.quantity = parseInt(this.productObj.quantity)
			}
			if (this.tags.length > 0) {
				for (var i = 1; i < this.tags.length; i++) {
					this.productObj.tags.push(this.tags[i]['text'].trim())
				}
			}
			if (this.tax_name.trim() != '' && this.tax_percentage.trim() != '') {
				this.productObj.tax.push({
					tax_name: this.tax_name.trim(),
					tax_percentage: parseFloat(this.tax_percentage)
				})
			}
			if (this.productObj.status === false && this.productObj.variants) {
				this.productObj.status = false
				this.productObj.variants.forEach(element => {
					element.status = false,
						element.variant_price = parseFloat(element.variant_price),
						element.variant_quantity = parseInt(element.variant_quantity)
				});
			}
			else if (this.productObj.status === true && this.productObj.variants) {
				this.productObj.status = true
				this.productObj.variants.forEach(element => {
					element.status = true,
						element.variant_price = parseFloat(element.variant_price),
						element.variant_quantity = parseInt(element.variant_quantity)
				});
			}
			if (this.productObj.variants) {
				if (this.checkDuplicateInObject('variant_name', this.productObj.variants)) {
					this.modal_msg = "Duplicate Variant Name Found";
					this.$refs.warning_modal.open();
					return
				}
				else if(this.checkDuplicateString('product_id',this.productObj.variants)){
					this.modal_msg = "Duplicate Product ID Found";
					this.$refs.warning_modal.open();
					return
				}
				else if(this.checkDuplicateString('stock_keeping_unit',this.productObj.variants)){
					this.modal_msg = "Duplicate SKU Found";
					this.$refs.warning_modal.open();
					return
				}
			}
			if (this.productObj.tax) {
				this.productObj.tax.forEach(element => {
					element.tax_name = element.tax_name.trim(),
						element.tax_percentage = parseFloat(element.tax_percentage)
				})
			}
			if (this.checkDuplicateInObject('tax_name', this.productObj.tax)) {
				this.modal_msg = "Duplicate Tax Name Found";
				this.$refs.warning_modal.open();
				return
			}
			if(this.productObj.variant == true){
				this.productObj.price = 0
				this.productObj.quantity = 0
				this.productObj.type = ''
			}
			if (this.productObj.variant === false) {
				this.productObj.variants = []
			}
			this.apiToAddProduct(this.productObj)
		},
		apiToAddProduct(product_details) {
			this.$http.post('/products/add_product', product_details).then(res => {
				if (res.data.status_id == 1) {
					this.modal_msg = "Product Created Succesfully";
					this.$refs.success_modal_create.open();
					setTimeout(() => {
						this.$refs.success_modal_create.close();
					}, 2000);
					EventBus.$emit('updateProductList', true);
					this.productObj = {
						name: '',
						description: '',
						quantity: 0,
						price: 0,
						measure:'',
						uom:'',
						currency: '',
						product_id: '',
						stock_keeping_unit: '',
						brand: '',
						category_id: '',
						product_is:'BOTH',
						sub_category_id: '',
						product_type_id: '',
						status: true,
						tags: [],
						tax: [],
						variants: [],
						type: '',
						total_tax: 0,
						variant: false,
						image: '',
						gallery: []
					}
					this.tax_name = ""
					this.tax_percentage = 0
					this.variant_name = ""
					this.variant_quantity = ''
					this.variant_price = 0,
					this.variant_type = "",
					this.imgDataUrl = "https://cdn.digicollect.com/cdn/posv2/default-product.svg";
					this.$modal.hide('product-create');

				}
				else if (res.data.response == 'duplicate') {
					if (res.data.value == 'product_id'){
						this.modal_msg = "Product Id Already Exists"
						this.$refs.warning_modal.open();
					}
					else if (res.data.value == 'sku_unit'){
						this.modal_msg = "Stock Keeping Unit Already Exists"
						this.$refs.warning_modal.open();
					}
					else if(res.data.value == 'product_name'){
						this.modal_msg = "Product Name Already Exists"
						this.$refs.warning_modal.open();
					}
				}
				else if(res.data.response == 'duplicate'){
					this.modal_msg = res.data.reason;
					this.$refs.warning_modal.open();
				}
				else {
					this.modal_msg = "Cannot Create Product";
					this.$refs.warning_modal_create.open();
				}
			})
		},
		checkDuplicateInObject(propertyName, inputArray) {
			var seenDuplicate = false,
				testObject = {};

			inputArray.map(function (item) {
				var itemPropertyName = item[propertyName].toUpperCase();
				if (itemPropertyName in testObject) {
					testObject[itemPropertyName].duplicate = true;
					item.duplicate = true;
					seenDuplicate = true;
				}
				else {
					testObject[itemPropertyName] = item;
					delete item.duplicate;
				}
			});
			return seenDuplicate;
		},
		checkDuplicateString(propertyName, inputArray){
			var seenDuplicate = false,
				testObject = {};

			inputArray.map(function (item) {
				var itemPropertyName = item[propertyName]
				if (itemPropertyName in testObject) {
					testObject[itemPropertyName].duplicate = true;
					item.duplicate = true;
					seenDuplicate = true;
				}
				else {
					testObject[itemPropertyName] = item;
					delete item.duplicate;
				}
			});
			return seenDuplicate;

		},
		deleteproduct: function (product) {
			Swal({
				title: 'Are you sure?',
				text: "Are you sure To delete the Product " + product.name + " ?",
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes'
			}).then((result) => {
				if (result.value) {
					this.confirmDelete(product)
				}
			})
		},
		confirmDelete(product) {
			this.$http.post("products/delete_product", { _id: product._id }).then(res => {
				if (res.data.status_id == 1) {
					// Swal("Deleted!", product.name + " Product has been deleted.", "success",showConfirmButton);
					Swal(({
						text: product.name + " Product has been deleted.",
						type:'success',
						showConfirmButton:false,
						timer:1000
					}))
					this.get_all_products();
				} else {
					this.modal_msg = res.data.response.result;
					this.$refs.warning_modal.open();
				}
			});
		},
		showproduct(item_id) {
			this.$http.post('products/get_product_by_id', { _id: item_id }).then(res => {
				if (res.data.status_id == 1) {
					this.product_details = res.data.response.result
					this.gallery = res.data.response.result.gallery
					if (this.gallery) {
						this.create_gallery_json(this.gallery)
					}
				}
			})
		},
		cancelProduct() {
			this.productObj = {}
			this.$modal.hide('product-create');
		},
		saveEditedProduct(item) {
			if (item.variant && item.variants.length == 0) {
				this.modal_msg = "No Variants Added";
				this.$refs.warning_modal.open();
				return
			}
			if(!item.variant){
				item.stock_keeping_unit = item.stock_keeping_unit.trim()
				item.product_id = item.product_id.trim()
				item.price = parseFloat(item.price)
				item.quantity = parseInt(item.quantity)
			}
			if (item.tags) {
				item.tags.splice(0, item.tags.length)
			}
			if (this.tags.length > 0) {
				for (var i = 0; i < this.tags.length; i++) {
					item.tags.push(this.tags[i]['text'].trim())
				}
			}
			if (this.tax_name.trim() != '' && this.tax_percentage.trim() != '') {
				item.tax.push({
					tax_name: this.tax_name.trim(),
					tax_percentage: parseFloat(this.tax_percentage)
				})
			}
			if (this.variant_name.trim() != '' && this.variant_quantity.trim() != '') {
				item.variants.push({
					variant_name: this.variant_name.trim(),
					variant_price: parseFloat(this.variant_price),
					variant_quantity: parseInt(this.variant_quantity)
				})
			}
			if (!item.variant && item.variants.length > 0) {
				item.variants.splice(0, item.variants.length)
			}
			if (item.status === false && item.variants) {
				item.status = false
				item.variants.forEach(element => {
					element.status = false,
						element.variant_price = parseFloat(element.variant_price),
						element.variant_quantity = parseInt(element.variant_quantity)
				});
			}
			else if (item.status === true && item.variants) {
				item.status = true
				item.variants.forEach(element => {
					element.status = true,
						element.variant_price = parseFloat(element.variant_price),
						element.variant_quantity = parseInt(element.variant_quantity)
				});
			}

			if (item.variants) {
				item.variants.forEach(element => {
					element.variant_price = parseFloat(element.variant_price),
						element.variant_quantity = parseInt(element.variant_quantity)
				});
				if (this.checkDuplicateInObject('variant_name', item.variants)) {
					this.modal_msg = "Duplicate Variant Name Found";
					this.$refs.warning_modal.open();
					return
				}
				else if(this.checkDuplicateString('product_id',item.variants)){
					this.modal_msg = "Duplicate Product ID Found";
					this.$refs.warning_modal.open();
					return
				}
				else if(this.checkDuplicateString('stock_keeping_unit',item.variants)){
					this.modal_msg = "Duplicate SKU Found";
					this.$refs.warning_modal.open();
					return
				}
			}
			if (item.tax) {
				item.tax.forEach(element => {
					element.tax_name = element.tax_name.trim(),
						element.tax_percentage = parseFloat(element.tax_percentage)
				});
			}

			if (this.checkDuplicateInObject('tax_name', item.tax)) {
				item.tax.forEach(element => {
					element.tax_percentage = parseFloat(element.tax_percentage)
				});

				this.modal_msg = "Duplicate Tax Name Found";
				this.$refs.warning_modal.open();
				return
			}
			if (item.variant === false) {
				item.variants = []
			}
			if(item.variant == true){
				item.type = ''
			}
			delete item.category
			delete item.brand_name
			delete item.last_modified_time
			delete item.created_time
			delete item.sub_category
			delete item.outlet_id
			delete item.product_type
			this.apiToEditProduct(item)
		},
		apiToEditProduct(item) {
			this.$http.post('/products/update_product', item).then(res => {
				if (res.data.status_id == 1) {
					this.modal_msg = "Product Edited Succesfully";
					this.$modal.hide('product-edit');
					this.$refs.success_modal.open();
					setTimeout(() => {
						this.$refs.success_modal.close();
					}, 2000);
					EventBus.$emit('updateProductList', true);
					if (this.fromVariants == true) {
						EventBus.$emit('viewProductDetails', item)
						setTimeout(() => {
							this.$modal.show('product_info')
							this.fromVariants = false
						}, 2000);
					}else if(this.fromSubCategory == true){
						this.fromSubCategory = false
						this.$emit('save')
					}else if(this.fromproductType == true){
						this.fromproductType = false
						this.$emit('saved')
					}
					else {
						EventBus.$emit('showProductDetails', true)
						this.$modal.hide('product-edit');
					}

				}
				else if (res.data.response == 'duplicate') {
					if (res.data.value == 'product_id'){
						this.modal_msg = "Product Id Already Exists"
						this.$refs.warning_modal.open();
					}
					else if (res.data.value == 'sku_unit'){
						this.modal_msg = "Stock Keeping Unit Already Exists"
						this.$refs.warning_modal.open();
					}
					else if(res.data.value == 'product_name'){
						this.modal_msg = "Product Name Already Exists"
						this.$refs.warning_modal.open();
					}
				}
				else {
					this.modal_msg = res.data.response;
					this.$refs.warning_modal.open();
				}
			})
		},
		get_all_products(param) {
			if (param == 'prev') this.count--;
			else if (param == 'next')
			this.count++;
			else if (param == 'init') {
				this.count = 0;
			} else if (param == 'search') {
				this.count = 0;
			}
			this.ajaxCallinprogress = true;
			this.products = [];
			if (this.$route.path == '/inventorymanage'){
				this.$http.post(global.POS_API_INVENTORY + '/products/get_all_products', {
				    skip: this.count * this.limit,
				    limit: this.limit,
				    search_key: this.searchkey.trim(),
				    sort_key: this.passed_key.key,
				    sort_value: this.passed_key.value,
					ingredients: false,
					category_id:this.category_ids,
					sub_category_id:this.subcategory_ids,
					product_type_id:this.producttype_ids,
					outlet_id: this.loggedInUser.outlet_id
				}).then(res => {
				    if (res.data.status_id == 1) {
				        this.products = res.data.response.result;
				        this.total_products = res.data.response.product_count;
				        this.start = parseInt(this.count * this.limit + 1);
				        this.end = this.count * this.limit + this.limit < this.total_products ?
				            this.count * this.limit + this.limit :
				            this.total_products;
				        if (this.end == 0) {
				            this.no_product = 'No Products'
				        }
				    } else {
				        this.no_product = 'No Products'
				    }
				    this.ajaxCallinprogress = false;
				    if (this.products.length == 0 && !this.ajaxCallinprogress && !this.searchkey) {
				        this.showIllustrator = true
				    } else {
				        this.showIllustrator = false
				    }
				})
			}
			else if (this.$route.path == '/ingredientManage'){
				this.$http.post('products/get_all_products', {
				    skip: this.count * this.limit,
				    limit: this.limit,
				    searchdata: this.searchkey.trim(),
				    sort_key: this.passed_key.key,
				    sort_value: this.passed_key.value,
				    ingredients: true
				}).then(res => {
				    if (res.data.status_id == 1) {
				        this.products = res.data.response.result;
				        this.total_products = res.data.response.product_count;
				        this.start = this.count * this.limit + 1;
				        this.end = this.count * this.limit + this.limit < this.total_products ?
				            this.count * this.limit + this.limit :
				            this.total_products;
				        if (this.end == 0) {
				            this.no_product = 'No Products'
				        }
				    } else {
				        this.no_product = 'No Products'
				    }
				    this.ajaxCallinprogress = false;
				    if (this.products.length == 0 && !this.ajaxCallinprogress && !this.searchkey) {
				        this.showIllustrator = true
				    } else {
				        this.showIllustrator = false
				    }
				})
			}
		},
		cancelEditProduct() {
			if (this.fromVariants == true) {
				// this.edit_product_details = {}
				this.$modal.hide('product-edit');
				this.$modal.show('product_info')
				this.fromVariants = false
				this.skuexists = false
			} else {
				this.edit_product_details = {}
				this.$modal.hide('product-edit');
			}
		},
		cancelCategoryEditProduct() {
			this.edit_product_details = {}
			this.$modal.hide('category-product-edit');
			this.$modal.show('product-details')
		},
		addTax: function (tax_name, tax_percentage) {
			this.productObj.tax.push(Object.assign({}, {
				tax_name: tax_name,
				tax_percentage: parseFloat(tax_percentage)
			}));
		},
		addVariants() {
			this.productObj.variants.push({
				variant_name: '',
				variant_price: 0,
				variant_quantity: '',
				type: '',
				status: true,
				product_id:'',
				stock_keeping_unit:'',
				measure:'',
				uom:''
			})
		},
		editAddVariants() {
			this.edit_product_details.variants.push({
				variant_name: '',
				variant_price: 0,
				variant_quantity: '',
				type: '',
				status: true,
				product_id:'',
				stock_keeping_unit:'',
				measure:'',
				uom:''
			})
		},
		removeTax: function (index) {
			this.productObj.tax.splice(index, 1);
		},
		removeVariants: function (index) {
			this.productObj.variants.splice(index, 1);
		},
		editRemoveVariants: function (index) {
			this.edit_product_details.variants.splice(index, 1);
		},
		editRemoveTax: function (index) {
			this.edit_product_details.tax.splice(index, 1);
		},
		editAddTax: function (tax_name, tax_percentage) {
			var alternateTax = document.getElementById("editAddTaxGroup");
			this.edit_product_details.tax.push(Object.assign({}, {
				tax_name: tax_name,
				tax_percentage: parseFloat(tax_percentage)
			}));
		},
		getproducts(filter = null) {
			return (filter) ? this.products.filter(product => product[filter.key] == filter.value) : this.products;
		},
		onSearchCategory(search, loading) {
			this.get_categories_for_products(loading, search)
		},
		get_categories_for_products(search) {
			this.$http.post('/products/get_categories_for_products', { search: search,limit:100 })
				.then(res => {
					this.filter_data.categories = res.data.response.result

				})
		},
		get_categories_for_products_edit(search) {
			this.$http.post('/products/get_categories_for_products', { search: search,limit:100 })
				.then(res => {
					this.filter_data.categories = res.data.response.result
					let boolResult = false
					this.filter_data.categories.forEach(element => {
						if(element._id == this.selectedCategory._id) {
							boolResult = true
						}
					})
					if(!boolResult) this.filter_data.categories.push(this.selectedCategory)
				})
		},
		getAllSubCategories(searchdata) {
			if(this.productObj.category_id != ""){
				this.$http
				.post('/categories/get_sub_category_for_category', {
					searchdata: searchdata,
					category_id: this.productObj.category_id,
					limit: 100,
					from:'product'
				})
				.then(response => {
					this.filter_data.subcategories = response.data.response.sub_category_list
				})
			}
		},
		getAllSubCategoriesforEdit(searchdata) {
			if(this.edit_product_details.category_id != ""){
				this.$http
				.post('/categories/get_sub_category_for_category', {
					searchdata: searchdata,
					category_id: this.edit_product_details.category_id,
					limit:100,
					from:'product'
				})
				.then(response => {
					this.filter_data.subcategories = response.data.response.sub_category_list
					let boolResult = false
					this.filter_data.subcategories.forEach(element => {
						if(element.sub_category_id == this.selectedSubCategory.sub_category_id) {
							boolResult = true
						}
					})
					if(!boolResult && !this.dontpushArray) this.filter_data.subcategories.push(this.selectedSubCategory)

				})
			}
		},
		getAllProductTypes(searchdata) {
			if(this.productObj.category_id != ""){
				this.$http
				.post('/categories/get_product_types_for_sub_categories', {
					search: searchdata,
					sub_category_id: this.productObj.sub_category_id,
					limit:100
				})
				.then(response => {
					this.filter_data.ProductType = response.data.product_types
				})
			}
		},
		getAllProductTypesforEdit(searchdata) {
			if(this.edit_product_details.sub_category_id != ""){
			this.$http
				.post('/categories/get_product_types_for_sub_categories', {
					search: searchdata,
					sub_category_id: this.edit_product_details.sub_category_id,
					limit:100
				})
				.then(response => {
					if(response.data.status_id == 1){
						this.filter_data.ProductType = response.data.product_types
						let boolResult = false
						this.filter_data.ProductType.forEach(element => {
							if(element._id == this.selectedProductType._id) {
								boolResult = true
							}
						})
						if(!boolResult && !this.dontpushArray && this.selectedProductType._id != '') this.filter_data.ProductType.push(this.selectedProductType)
					}else{

					}
				})
			}
		},
		// onSearchBrands(search, loading) {
		// 	this.get_brands_for_products(loading, search)
		// },
		get_brands_for_products(search) {
			this.$http.post('/products/get_brands_for_products', { search: search ,limit:100})
				.then(res => {
					this.filter_data.brands = res.data.response.result
				})
		},
		updateTags() {
			this.productObj.tags.push(this.tags)
		},
		changestatus(status, product_id) {
			this.$http.post(global.POS_API_INVENTORY + '/products/change_product_status', { status: status, product_id: product_id }).then(res => {
				if (res.data.status_id == 1) {
                    this.$toasted.global.success("Product Status Updated Successfully")
					EventBus.$emit('ProductVariantsDynamicChange', { product_id: product_id, status: res.data.response });
				}else{
					this.$toasted.global.error("Cannot Change Product Status")
				}
			})
		},
		setPasskey: function (newKey) {
			this.$set(this.passed_key, 'key', newKey);
			var value = this.passed_key.value == -1 ? 1 : -1
			this.$set(this.passed_key, 'value', value);
			this.get_all_products();
			// if (this.count == 0) this.get_all_products();
			// else this.count = 0;
		},
		change_variant_status(status, product_id, variant_id) {
			this.$http.post(global.POS_API_INVENTORY + '/products/change_product_variant_status', { status, product_id, variant_id }).then(res => {
				if (res.data.status_id == 1) {
					this.$toasted.global.success("Product Variant Status Updated Successfully")
					EventBus.$emit('ProductStatusChange', { product_id, status: res.data.response });
				}else{
					this.$toasted.global.error("Cannot Change Product Variant Status")
				}
			})
			EventBus.$emit('ProductVariantChange', { product_id, status, variant_id });
		},
		clear_search_key() {
			this.search = null
			this.get_brands_for_products()
		},
		create_gallery_json(gallery) {
			this.gallerylist.splice(0, this.gallerylist.length)
			gallery.forEach(element => {
				var obj = new Object();
				obj.name = 'image.jpg';
				obj.url = element;
				this.gallerylist.push(obj)
			});
		},
		async getProductsForCustomer(params) {
			return new Promise(async (resolve, reject) => {
				let response = await this.$http.post("mobile/get_products_for_customers", params)
				if (response.data.status_id == 1) {
					resolve(response.data)
				} else {
					reject(response.data.reason)
				}
			})
		},
		changeCategory() {
			this.edit_product_details.sub_category_id = ''
			this.edit_product_details.product_type_id = ''
			this.dontpushArray = true
			this.getAllSubCategoriesforEdit()
		},
		changeSubCatgory() {
			this.edit_product_details.product_type_id = ''
			this.dontpushArray = true
			this.getAllProductTypesforEdit()
		},
		getAllTypes(){
            this.$http.post("/add_ons/flavours/get",{
                "flavour_type":"type"
            }).then(res =>{
                this.getallTypes = res.data.result
            })
		},
		async getAllMeasurements(search) {
			let res = await this.$http.post("/scm/get_unit_of_measurements", {search_key: search,limit: 100})
			if(res.data.status_id ==1){
				this.measurements = res.data.unit_of_measurements
			}
        },
	},
	created() {
		const dict = {
			custom: {
				product_id: {
					required: () => "Product ID Is Required"
				},
				product_name: {
					required: () => "Product Name is Required"
				},
				variant_name: {
					required: () => "Variant Name is Required"
				},
				variant_price: {
					required: () => "Variant Price is Required"
				},
				variant_quantity: {
					required: () => "Variant Quantity is Required",
					numeric: () => "Enter a Valid Quantity"
				},
				product_status: {
					required: () => "Product Status is Required"
				},
				category: {
					required: () => "Category is Required"
				},
				subcategory: {
					required: () => "Sub Category is Required"
				},
				product_price: {
					required: () => 'Price is Required and must be Numeric',
					max:() => 'Price should be Maximum 8 Digits'
				},
				product_quantity: {
					required: () => 'Quantity is Required and must be Numeric',
					max: () => 'Quantity should be Maximum 8 Digits'
				},
				product_sku: {
					required: () => 'SKU is Required',
					alpha_num: () => 'Enter a Valid SKU',
				},
				measure:{
					required: () => 'Measure is Required',
					max:() => 'Measure should be Maximum 15 Digits'
				},
				productUom:{
					required: () => "Unit Of Measurement is Required"
				},
				variant_sku: {
				    required: () => 'SKU is Required',
				    alpha_num: () => 'Enter a Valid SKU',
				},
			}
		};
		this.$validator.localize("en", dict);
		// this.productObj = {
		// 	name: '',
		// 	description: '',
		// 	quantity: 0,
		// 	price: 0,
		// 	currency: '',
		// 	product_id: '',
		// 	stock_keeping_unit: '',
		// 	brand_id: '',
		// 	category_id: '',
		// 	sub_category_id: '',
		// 	product_type_id: '',
		// 	status: '',
		// 	tags: [],
		// 	tax: [],
		// 	variants: [],
		// 	type: 'veg',
		// 	total_tax: 0,
		// 	variant: false,
		// 	image: '',
		// 	gallery: []
		// }
		// this.tax_name = ""
		// this.tax_percentage = 0
		// this.variant_name = ""
		// this.variant_quantity = ''
		// this.variant_price = 0
	},
	filters: {
		calculateTax: function (value) {
			var total_tax = 0;
			for (var i = 0; i < value.length; i++) {
				total_tax = total_tax + parseFloat(value[i].tax_percentage)
			}
			return total_tax
		}
	},
	watch: {
		variant() {
			if (this.variant == true) this.variant = true
			else this.variant = false
			this.errors.clear()
			this.$nextTick(() => {
				this.$validator.reset()
			})
		},
		'productObj.brand_id': function () {
			this.get_brands_for_products()
		},
		'productObj.category_id': function () {
			this.get_categories_for_products();
		},
		'edit_product_details.brand_id': function () {
			this.get_brands_for_products()
		},
		// 'edit_product_details.category_id': function () {
		// 	this.get_categories_for_products();
		// },
	}
}