export default {
  methods: {
    async startSession(params) {
      return new Promise(async (resolve, reject) => {
        let response = await this.$http.post("cashier/start_cashier_session", params)
        if (response.data.status_id == 1) {
          resolve(response.data)
        } else {
          reject(response.data.reason)
        }
      })
    },
    async endSession(params) {
      return new Promise(async (resolve, reject) => {
        let response = await this.$http.post("cashier/end_cashier_session", params)
        if (response.data.status_id == 1) {
          resolve(response.data)
        } else {
          reject(response.data.reason)
        }
      })
    },
    async isSessionActive() {
      return new Promise(async (resolve, reject) => {
        let response = await this.$http.get("cashier/is_cashier_session_active")
        if (response.data.status_id == 1) {
          resolve(response.data)
        } else {
          reject(response.data.reason)
        }
      })
    },
    async startOnlineSession(params) {
      return new Promise(async (resolve, reject) => {
        let response = await this.$http.post("cashier/change_online_payment", params)
        if (response.data.status_id == 1) {
          resolve(response.data)
        } else {
          reject(response.data.reason)
        }
      })
    },
    async endOnlineSession(params) {
      return new Promise(async (resolve, reject) => {
        let response = await this.$http.post("cashier/change_online_payment", params)
        if (response.data.status_id == 1) {
          resolve(response.data)
        } else {
          reject(response.data.reason)
        }
      })
    },
  }
}