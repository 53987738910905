<template>
  <div class="page-layout blank setting-div">
    <div class="pb-10">
      <h2 class="headerblock">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <el-tooltip class="item" effect="dark" content="Back to Outlets" placement="top-start">
              <router-link to="/outletmanage">
                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/hide-nav .svg">
              </router-link>
            </el-tooltip>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="breadcome-heading">
              <div class="form-group formsearch has-warning has-feedback">
                <input type="text" class="form-control textremove holder" id="inputWarning2" aria-describedby="inputWarning2Status" placeholder="Search...">
                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
              </div>
            </div>
          </div>
        </div>
      </h2>
      <div class="page-layout carded left-sidebar">
        <div class="content-settings">
          <div class="row first-middle-input">
            <div class="col-lg-3">
              <div class="profile-left pt-4">
                <div class="profile-image pl-3">
                  <my-upload field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail" v-model="show" :width="300" langType="en" :height="300" url="/upload" :params="params" :headers="headers" img-format="png"></my-upload>
                  <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/company-logo.svg" style="width: 90px;">
                </div>
                <div class="mb-4 mt-4 profile-button">
                  <!-- <a class="btn btn-secondary btn-smm" @click="toggleShow">Upload Image</a> -->
                  <a class="btn btn-secondary btn-smm" @click="toggleShow">Upload Picture</a>
                  <!-- <a class="btn btn-secondary btn-smm" @click="showPayBill()">Pay</a> -->
                  <!-- <a href="javascript:void(0)" class="btn btn-secondary btn-sm">Change Picture</a> -->
                </div>
              </div>
            </div>
            <div class="col-md-9 pl-7">
              <div class="row mt-4">
                <div class="col-sm-12 pl-2">
                  <!-- <h6 style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;" class="pt-3">Product Information</h6> -->
                  <div class="form-group contactGroup mb-4">
                    <input class="inputContact" type="text" value="Green View Cafe" required>
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Outlet Name</label>
                  </div>
                  <div class="form-group contactGroup pt-4">
                    <input class="inputContact" type="text" required>
                    <span class="highlightContact"></span>
                    <span class="barContact"></span>
                    <label class="labelContact">Website</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group contactGroup">
                <input class="inputContact" type="text">
                <span class="highlightContact"></span>
                <span class="barContact"></span>
                <label class="labelContact">Email</label>
              </div>
            </div>
            <div class="col-md-12 pt-4">
              <label for="inputSattus" class="col-form-label text-secondary">Phone Number</label>
              <vue-tel-input placeholder="Enter Your Phone Number">
              </vue-tel-input>
            </div>
          </div>
          <!-- <h6 class="heading-details pb-4 pt-4">LOCALE SETTINGS</h6>     -->
          <div class="row pt-4">
            <div class="col-md-12">
              <label class="text-secondary">Currency</label>
              <select class="form-control" name="currency-select" placeholder="Select Currency" hidden value="" style="max-width: 100% !important;">
                <option hidden value="" selected>Select Currency</option>
                <option data-symbol="$">USD ($)</option>
                <option data-symbol="₹">INR (₹)</option>
                <option data-symbol="€">EUR (€)</option>
                <option data-symbol="£">GBP (£)</option>
                <option data-symbol="¥">JPY (¥)</option>
                <option data-symbol="K">MMK (K)</option>
                <option data-symbol="S$">SGD (S$)</option>
                <option data-symbol="₦">NGN (₦)</option>
                <option data-symbol="฿">THB (฿)</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <!-- <h6 style="font-weight: 600;color: #303031;font-size: 14px;line-height: 1.21;letter-spacing: 0.6px;" class="pt-3">Product Information</h6> -->
              <!-- <div class="form-group contactGroup">      
							<input class="inputContact" type="text" value="Service Tax" required >
							<span class="highlightContact"></span>
							<span class="barContact"></span>
							<label class="labelContact">Outlet Name</label>
						</div> -->
              <div class="form-group contactGroup">
                <input class="inputContact" type="text" required>
                <span class="highlightContact"></span>
                <span class="barContact"></span>
                <label class="labelContact">Enter The Number Of Tables</label>
              </div>
              <div class="form-group contactGroup">
                <input class="inputContact" type="text" required>
                <span class="highlightContact"></span>
                <span class="barContact"></span>
                <label class="labelContact">Enter The Table Number Prefix (For Ex. T1, T2 or FF01) </label>
              </div>
            </div>
          </div>
          <h6 class="heading-details pb-1 pt-4">TAX RULES</h6>
          <div class="form-check form-check-inline d-inline-block">
            <label class="form-check-label form-check-tax">
              <input class="form-check-input" type="radio" name="salutation" value="with_variant">
              <span class="radio-icon"></span>
              <span class="form-check-description" style="color: #4e5764;font-size: 12px;line-height: 1.25;font-weight: 500;">Use Tax Inclusive Prices</span>
            </label>
          </div>
          <div class="form-check form-check-inline d-inline-block">
            <label class="form-check-label form-check-tax">
              <input class="form-check-input" type="radio" name="salutation" value="without_variant">
              <span class="radio-icon"></span>
              <span class="form-check-description" style="color: #4e5764;font-size: 12px;line-height: 1.25;font-weight: 500;">Use Tax Exclusive Prices</span>
            </label>
          </div>
          <div class="row pt-4">
            <div class="col-md-12">
              <h6 class="heading-details">ADDRESS</h6>
              <div class="form-group form-bottom">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="group-setup">
                      <input class="digi-form" type="text" required>
                      <span class="highlight digi-highlight"></span>
                      <span class="bar digi-bar"></span>
                      <label class="digi-label">Street</label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="group-setup">
                      <input class="digi-form" type="text" required>
                      <span class="highlight digi-highlight"></span>
                      <span class="bar digi-bar"></span>
                      <label class="digi-label">City </label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="group-setup">
                      <input class="digi-form" type="text" required>
                      <span class="highlight digi-highlight"></span>
                      <span class="bar digi-bar"></span>
                      <label class="digi-label">State</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group form-top row">
                <div class="col-lg-4">
                  <div class="group-setup">
                    <input class="digi-form" type="text" required>
                    <span class="highlight digi-highlight"></span>
                    <span class="bar digi-bar"></span>
                    <label class="digi-label">Zip Code</label>
                  </div>
                </div>
                <div class="col-lg-8 mb-5">
                  <!-- <label class="country-label">Country</label> -->
                  <select class="form-control" name="country-select" placeholder="Select Country" style="max-width: 100% !important;">
                    <option hidden value="" selected>Select Country</option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Åland Island">Åland Islands</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia, Plurinational State of">Bolivia, Plurinational State of</option>
                    <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">Central African Republic</option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo, the Democratic Republic of the">Congo, the Democratic Republic of the</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curaçao">Curaçao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Territories">French Southern Territories</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option>
                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                    <option value="Korea, Republic of">Korea, Republic of</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestine, State of">Palestine, State of</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Réunion">Réunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russian Federation">Russian Federation</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Barthélemy">Saint Barthélemy</option>
                    <option value="Saint Helena, Ascension and Tristan da Cunha">Saint Helena, Ascension and Tristan da Cunha</option>
                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Martin (French part)">Saint Martin (French part)</option>
                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                    <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                    <option value="South Sudan">South Sudan</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                    <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-Leste">Timor-Leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">United Arab Emirates</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela, Bolivarian Republic of">Venezuela, Bolivarian Republic of</option>
                    <option value="Viet Nam">Viet Nam</option>
                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                    <option value="Virgin Islands, U.S">Virgin Islands, U.S.</option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h6 class="heading-details pb-3">OPENING HOURS</h6>
              <div class="contact-item row no-gutters align-items-center multiple-heading py-sm-4 pl-5 pr-3">
                <div class="col multiple-heading-div">Day</div>
                <div class="col multiple-heading-div">Open/Close</div>
                <div class="col multiple-heading-div">Opening Hours</div>
                <div class="col multiple-heading-div"></div>
              </div>
              <div class="add-member-modal pl-5 pr-3">
                <div class="contact-item row no-gutters align-items-center py-sm-4">
                  <div class="col text-truncate font-weight-bold product-name">Monday</div>
                  <div class="col email text-truncate">
                    <el-switch v-model="valueMon"></el-switch>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeStartMon" :picker-options="{
										selectableRange: '09:00:00 - 23:00:00'
										}" placeholder="Select Start Time">
                    </el-time-picker>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeEndMon" :picker-options="{
										selectableRange: '09:00:00 - 23:00:00'
										}" placeholder="Select End Time">
                    </el-time-picker>
                  </div>
                </div>
                <div class="contact-item row no-gutters align-items-center py-sm-4">
                  <div class="col text-truncate font-weight-bold product-name">Tuesday</div>
                  <div class="col email text-truncate">
                    <el-switch v-model="valueTue"></el-switch>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeStartTue" placeholder="Select Start Time">
                    </el-time-picker>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeEndTue" placeholder="Select End Time">
                    </el-time-picker>
                  </div>
                </div>

                <div class="contact-item row no-gutters align-items-center py-sm-4">
                  <div class="col text-truncate font-weight-bold product-name">Wednesday</div>
                  <div class="col email text-truncate">
                    <el-switch v-model="valueWed"></el-switch>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeStartWed" placeholder="Select Start Time">
                    </el-time-picker>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeEndWed" placeholder="Select End Time">
                    </el-time-picker>
                  </div>
                </div>

                <div class="contact-item row no-gutters align-items-center py-sm-4">
                  <div class="col text-truncate font-weight-bold product-name">Thursday</div>
                  <div class="col email text-truncate">
                    <el-switch v-model="valueThr"></el-switch>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeStartThr" placeholder="Select Start Time">
                    </el-time-picker>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeEndThr" placeholder="Select End Time">
                    </el-time-picker>
                  </div>
                </div>

                <div class="contact-item row no-gutters align-items-center py-sm-4">
                  <div class="col text-truncate font-weight-bold product-name">Friday</div>
                  <div class="col email text-truncate">
                    <el-switch v-model="valueTue"></el-switch>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeStartFri" placeholder="Select Start Time">
                    </el-time-picker>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeEndFri" placeholder="Select End Time">
                    </el-time-picker>
                  </div>
                </div>

                <div class="contact-item row no-gutters align-items-center py-sm-4">
                  <div class="col text-truncate font-weight-bold product-name">Saturday</div>
                  <div class="col email text-truncate">
                    <el-switch v-model="valueSat"></el-switch>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeStartSat" placeholder="Select Start Time">
                    </el-time-picker>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeEndSat" placeholder="Select End Time">
                    </el-time-picker>
                  </div>
                </div>

                <div class="contact-item row no-gutters align-items-center py-sm-4">
                  <div class="col text-truncate font-weight-bold product-name">Sunday</div>
                  <div class="col email text-truncate">
                    <el-switch v-model="valueSun"></el-switch>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeStartSun" placeholder="Select Start Time">
                    </el-time-picker>
                  </div>
                  <div class="col time-pick text-truncate">
                    <el-time-picker v-model="valueTimeEndSun" placeholder="Select End Time">
                    </el-time-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-6 text-center">
            <div class="col-lg-12">
              <div class="list-title" style="font-weight: 500;">
                <a class="btn btn-secondary btn-smm" style="text-transform:uppercase!important;" v-on:click="updateSettings()">Save </a>
                <button type="button" class="btn btn-link">Cancel</button>
              </div>
              <p class="text-danger text-center pt-2"> * Please Fill All Required Fields</p>
            </div>
          </div>
        </div>
        <sweet-modal ref="success_modal" icon="success">
          {{modal_msg}}
        </sweet-modal>
      </div>
    </div>
  </div>
</template>
<style>
.heading-details {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: 0.7px;
  text-align: left;
  color: #00448b;
  padding-top: 15px;
}
.group-setup {
  position: relative;
  margin-bottom: 20px;
}
.md-chips-tags .md-ripple {
  color: #303031;
  background: #c7c7c7;
  border-radius: 18px;
  padding-top: 5px !important;
}
.md-chips-tags.md-field {
  border: 1px solid #c7c7c7 !important;
  border-radius: 5px;
}
.md-chips-tags.md-field .md-input {
  border: none !important;
}
.md-modal-body {
  padding-left: 20px;
  padding-right: 20px;
}
.setting-div span {
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #303031;
}
h4#content-types {
  padding-top: 12px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00448b;
  text-align: center;
}
.general-settings {
  padding-top: 12px;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00448b;
}
.content-settings {
  max-width: 960px;
  width: 100%;
  margin: 0 auto 30px;
}
</style>
<script>
import { SweetModal } from "sweet-modal-vue";
import { mapGetters } from "vuex";
import VeeValidate from "vee-validate";
import { Validator } from "vee-validate";
import { EventBus } from "./eventBus/nav-bar-event.js";
import myUpload from "vue-image-crop-upload";
import axios from 'axios';
export default {
  data() {
    return {
      dialogImageUrl: "",
      show: false,
      searchkey: "",
      params: {
        token: "123456798",
        name: "avatar"
      },
      headers: {
        smail: "*_~"
      },
      imgDataUrl: "", // the datebase64 url of created image
      isUploadingInProgress: false
    };
  },
  components: {
    "my-upload": myUpload
  },
  methods: {
    handleRemove(file, fileList) {},
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    toggleShow() {
      this.show = !this.show;
    },
    blobToFile(theBlob, fileName){
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        const myFile = new File([theBlob], 'captured_image' + (new Date().getHours()  + '' + new Date().getMinutes()  + '' + new Date().getSeconds()) +'.png', {
            type: theBlob.type,
        });
        return myFile;
    },
    async cropSuccess(imgData, field){
        // this.imgData = imgData;
        const base64 = await fetch(imgData);
        const blob = await base64.blob();
        let fill = this.blobToFile(blob, 'captured_image' + (new Date().getHours()  + '' + new Date().getMinutes()  + '' + new Date().getSeconds()) +'.png');
        this.uploadAttachment(fill);
    },
    cropUploadSuccess(jsonData, field){
        // TODO:
    },
    cropUploadFail(status, field){
        // TODO:
    },
    uploadAttachment(newFile) {
        try {
            var file = newFile;
            if (file) {
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                let attachment = {
                    // id: uuid(),
                    name: file.name,
                    size: JSON.stringify(file.size),
                    source: source,
                    progress: 0,
                    created_time: new Date(),
                    error: ""
                }
                this.getResumableURL(file, attachment);
            }
        } catch (error) {
            console.log(error);
        }
    },
    getResumableURL(file, attachment) {
        try {
            this.isUploadingInProgress = true;
            attachment.uploading = true;
            let data = {
                file: attachment.name
            }
            data = new FormData()
            data.append('file',file)
            this.$http.post('https://cloudapis.digicollect.com/external/upload_file', data,{
                onUploadProgress: function (progressEvent) {
                    let progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    if (progress < 100) {
                        attachment.progress = progress;
                    }
                }.bind(this)
            })
            .then(response => {
                let resumable_url = response.data.url;
                this.imgDataUrl = resumable_url;
                this.isUploadingInProgress = false;
            })
            .catch(error => {
                console.log(error.data.reason)
            })
        } catch (error) {
            console.log(error);
        }
    },
  }
};
</script>
