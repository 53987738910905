export default {
    methods: {
        async storePromotions(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/promotions/store_promotions', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getAllPromotions(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/promotions/get_all_promotions', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async updatePromotions(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/promotions/update_promotions', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async updateLoyalty(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/promotions/update_loyalty_details', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else{
                    reject(response.data.reason)
                }
            })
        },
        async getLoyalty() {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.get('/promotions/get_loyalty_details_of_outlet')
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else{
                    reject(response.data.reason)
                }
            })
        },
        async getDeliveryByOrder(params) {            
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post(
                    '/order_type/get_all_delivery_companies_for_dropdown',
                    params
                )
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
    }
}
