<template>
    <div style="margin-top: 30px;">
        <div class="footercomponent" v-if="$route.path !== '/'">
            <!-- <div class="bottomfooter"></div> -->
            <div class="page-footer bg-secondary-light-footer d-flex flex-column justify-content-between px-6 pt-4 pb-0">
                <div class="helpdesk-footer d-none d-md-block" :class="{'zIndex': String($route.path).startsWith('/place_order')}" v-if="$route.path == '/takeordernew' || String($route.path).startsWith('/place_order')"
                    style="z-index: 0;">
                    <div class="middle-helpdesk">
                        <div class="toolbar row no-gutters align-items-center px-4 px-sm-6">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <div class>
                                        <a class="text-footer"
                                            href="https://helpdesk.digicollect.com/" target="_blank">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/help-desk.svg"
                                                class="help-desk-image" />HelpDesk
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="helpdesk-footer d-none" :class="{'d-none': String($route.path).startsWith('/place_order'),'d-md-block': loggedInUser.role != 'customer'}" v-else>
                    <div class="middle-helpdesk">
                        <div class="toolbar row no-gutters align-items-center px-4 px-sm-6">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <div class>
                                        <a class="text-footer"
                                            href="https://helpdesk.digicollect.com/" target="_blank">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/help-desk.svg"
                                                class="help-desk-image" />HelpDesk
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters align-items-start justify-content-between flex-nowrap"
                    style="margin-bottom: 10px;">
                    <div>
                        <span class="text-white fs-12" style="white-space: nowrap;font-weight:500 !important;">
                            <span class="hidden-sm-down">App Version (P) :</span>v3.1.9a3</span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-5 textcenter-footer">
                        <span class="h6 text-footer-down fw-500">
                            © <span class="hidden-sm-down">Copyright</span> 2014 - {{ new Date().getFullYear() }}
                            <a class="footertext hidden-sm-down" href="https://www.digicollect.com/" target="_blank">DigiCollect</a> -
                            All Rights Reserved
                        </span>
                    </div>
                    <router-link to="/" class="homeButton" v-if="$route.path != '/' && loggedInUser.role != 'customer'">
                        <el-tooltip class="item" effect="dark" content="Home" placement="top">
                            <img src="https://cdn.digicollect.com/cdn/posv2/images/home-button-bottom.svg" width="36"
                                :class="{'zindexforIcon':this.$route.name != 'BillingManage' && this.$route.name != 'ClosedOrders' && this.$route.name != 'TaxInvoice' && this.$route.name != 'InventoryManage'}"
                            />
                        </el-tooltip>
                    </router-link>
                    <div v-if="onLine">
                        <span class="pull-right isp-class" >
                            <span class="hidden-sm-down">ISP Connection:</span>
                            <img src="https://cdn.digicollect.com/cdn/posv2/images/active-isp.svg" class="img-isp" /> Active
                        </span>
                    </div>
                    <div v-if="!onLine">
                        <span class="pull-right isp-class" >
                            <span class="hidden-sm-down">ISP Connection:</span>
                            <img src="https://cdn.digicollect.com/cdn/posv2/images/inactive-isp.svg" class="img-isp" />
                            <span class="text-danger">Inactive</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="footercomponentHome" v-if="$route.path == '/'">
            <div class="page-footer bg-secondary-light-footer d-flex flex-column justify-content-between px-6 pt-4 pb-0">
                <div class="helpdesk-footer d-none d-md-block fdgdgf" v-if="$route.path == '/takeordernew'" style="z-index: 0;">
                    <div class="middle-helpdesk">
                        <div class="toolbar row no-gutters align-items-center px-4 px-sm-6">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <div class>
                                        <a class="text-footer"
                                            href="https://helpdesk.digicollect.com/" target="_blank">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/help-desk.svg"
                                                class="help-desk-image" />HelpDesk
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="helpdesk-footer d-none d-md-block" v-if="$route.path !== '/takeordernew'">
                    <div class="middle-helpdesk">
                        <div class="toolbar row no-gutters align-items-center px-4 px-sm-6">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <div class>
                                        <a class="text-footer"
                                            href="https://helpdesk.digicollect.com/" target="_blank">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/help-desk.svg"
                                                class="help-desk-image" />HelpDesk
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters align-items-start justify-content-between flex-nowrap"
                    style="margin-bottom: 10px;">
                    <div>
                        <span class="text-white fs-12" style="white-space: nowrap;font-weight:500 !important;">
                            <span class="hidden-sm-down">App Version (P) :</span>v3.1.9a3</span>
                    </div>
                    <div class="col-lg-6 col-md-6 col-5 textcenter-footer">
                        <!-- <span class="h6 text-footer-down">© Copyright {{ new Date().getFullYear() }} -->
                        <span class="h6 text-footer-down fw-500">
                            © <span class="hidden-sm-down">Copyright</span> 2014 - {{ new Date().getFullYear() }}
                            <a class="footertext hidden-sm-down" href="https://www.digicollect.com/" target="_blank">DigiCollect</a> -
                            All Rights Reserved
                        </span>
                    </div>
                    <!-- <router-link to="/" v-if="$route.path != '/takeordernew' || !String($route.path).startsWith('/place_order')">
                        <el-tooltip class="item" effect="dark" content="Home" placement="top">
                            <img v-if="$route.path != '/'" src="https://cdn.digicollect.com/cdn/posv2/images/home-button-bottom.svg" class
                                style="position: absolute;right: 18px;width: 36px;margin-top: -49px;" />
                        </el-tooltip>
                    </router-link>
                    <router-link to="/" style="z-index: 1;" v-else>
                        <el-tooltip class="item" effect="dark" content="Home" placement="top">
                            <img v-if="$route.path != '/'" src="https://cdn.digicollect.com/cdn/posv2/images/home-button-bottom.svg" class
                                style="position: absolute;right: 18px;width: 36px;margin-top: -49px;" />
                        </el-tooltip>
                    </router-link> -->
                    <div v-if="onLine">
                        <span class="pull-right isp-class" >
                            <span class="hidden-sm-down">ISP Connection:</span>
                            <img src="https://cdn.digicollect.com/cdn/posv2/images/active-isp.svg" class="img-isp" /> Active
                        </span>
                    </div>
                    <div v-if="!onLine">
                        <span class="pull-right isp-class" >
                            <span class="hidden-sm-down">ISP Connection:</span>
                            <img src="https://cdn.digicollect.com/cdn/posv2/images/inactive-isp.svg" class="img-isp" />
                            <span class="text-danger">Inactive</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import VOffline from 'v-offline'
    export default {
        data: () => ({
            onLine: false,
            onlineSlot: 'online-isp',
            offlineSlot: 'offline-isp'
        }),
        components: {
            VOffline
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            }
        },
        methods: {
            amIOnline() {
                // setTimeout(function() {
                // this.onLine = navigator.onLine;
                // console.log(this.onLine);
                // }.bind(this), 2000);
                setInterval(() => {
                    this.onLine = navigator.onLine
                    // console.log(this.onLine);
                }, 50)
            }
        },
        created() {
            this.amIOnline()
            // console.log(this.$route)
        }
    }

</script>
<style>
    .Line-7-Copy {
        width: 2px;
        height: 24px;
        border: solid 1px #e0e3e6;
    }

    .footercomponent {
        z-index: 0;
    }

    .footercomponentHome {
        position: relative;
        z-index: 1;
    }

    .footercomponent .offline-isp {
        content: 'Reference ';
        background-color: #fc9842;
        background-image: linear-gradient(315deg, #fc9842 0%, #fe5f75 74%);
        height: 40px;
        width: 40px;
    }

    .footercomponent .online-isp {
        content: 'Reference ';
        background-color: #00b712;
        background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
        height: 40px;
        width: 40px;
    }

    span.text-footer-down {
        font-size: 12px;
        text-align: center !important;
        color: #ffffff !important;
    }

    .footertext {
        color: #ffffff !important;
        text-decoration: none !important;
    }

    .page-footer {
        z-index: 9999;
        /* height: 58px; */
    }

    .isp-class {
        font-size: 12px;
        color: #ffffff !important;
        font-weight: 600 !important;
        /* position: absolute; */
        /* right: 20px; */
    }

    .img-isp {
        width: 15px !important;
        margin-left: 3px !important;
        margin-right: 3px !important;
    }

    .no-gutters .textcenter-footer {
        text-align: center;
    }

    .bg-secondary-light {
        background-image: linear-gradient(to right,
                #00448b,
                #00448b 51%,
                #69b3fc,
                #9fcbff) !important;
    }

    .bg-secondary-light-footer {
        /* background-image: linear-gradient(
    to right,
    #00448b,
    #00448b 51%,
    #69b3fc,
    #9fcbff
  ) !important; */
        background: #00448b;
    }

    .bottomfooter {
        background-color: #ffffff !important;
        height: 30px;
        bottom: 58px;
    }

    .helpdesk-footer {
        height: 38px;
        border-radius: 15px;
        background-color: #ffffff;
        border: solid 2px #00448b;
        margin: -35px auto 0;
        z-index: 0;
    }

    .text-footer {
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: #4e5764;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none !important;
    }

    .text-footer:hover {
        color: #00448b;
        cursor: pointer;
    }

    .help-desk-image {
        width: 17.7px;
        height: 17.7px;
        -o-object-fit: contain;
        object-fit: contain;
        margin-left: 4px;
        margin-right: 8px;
    }

    .middle-helpdesk {
        padding-top: 9px;
    }

    .col-20,
    .col-sm-20,
    .col-md-20,
    .col-lg-20,
    .col-xl-20 {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-20 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    @media (min-width: 576px) {
        .col-sm-20 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    @media (min-width: 768px) {
        .col-md-20 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    @media (min-width: 992px) {
        .col-lg-20 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    @media (min-width: 1200px) {
        .col-xl-20 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    @supports (-ms-ime-align: auto) {
        .helpdesk-footer {
            width: 140px;
        }
    }

    .zindexforIcon {
        z-index: 0 !important;
    }

    .zIndex{
        z-index: 0 !important;
    }
    .homeButton {
        position: absolute;
        right: 18px;
        width: 36px;
        margin-top: -49px;
    }
	@media (max-width: 1024px) {
        .homeButton {
            margin-top: -42px;
        }
    }
</style>
