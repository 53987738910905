export default{
    methods: {
        async createProductAPI(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/products/add_product', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data)
                }
            })
        },
        async getProductDetailsById(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/products/get_product_by_id', params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async editProductAPI(params){
            return new Promise(async(resolve, reject)=>{
                const response = await this.$http.post('/products/update_product',params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data)
                }
            })
        },
        async getAllCustomFields(params){
            return new Promise(async(resolve, reject)=>{
                const response = await this.$http.post('/outlet/get_all_extra_fields',params)
                if (response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data)
                }
            })
        },
        async getCurrentPinConfirmation(params) {
            return new Promise(async (resolve, reject) => {
                const response = await this.$http.post('/outlet/pin/reset', params)
                if(response.data.status_id === 1) {
                    resolve(response.data)
                } else {
                    reject(response.data)
                }
            })
        },
    }
}