export default {
	methods: {
		async getCustomers(params) {
			return new Promise(async (resolve, reject) => {
				let response = await this.$http.post("customers/get_all_customers", params)
				if (response.data.status_id == 1) {
					resolve(response.data)
				} else {
					reject(response.data.reason)
				}
			})
		},
		async addCustomer(params) {
			return new Promise(async (resolve, reject) => {
				let response = await this.$http.post("customers/create_customer", params)
				if (response.data.status_id == 1) {
					resolve(response.data)
				} else {
					reject(response.data)
				}
			})
		},
		async getGuestCustomer() {
			return new Promise(async (resolve, reject) => {
				let response = await this.$http.post("customers/get_guest_customer_id")
				if (response.data.status_id == 1) {
					resolve(response.data)
				} else {
					reject(response.data.reason)
				}
			})
		},
		async removeCustomer(params) {
			return new Promise(async (resolve, reject) => {
				let response = await this.$http.post("customers/delete_customer", params)
				if (response.data.status_id == 1) {
					resolve(response.data)
				} else {
					reject(response.data.reason)
				}
			})
		},
		async customerUpdate(params) {
			return new Promise(async (resolve, reject) => {
				let response = await this.$http.post("customers/update_customer", params)
				if (response.data.status_id == 1) {
					resolve(response.data)
				} else {
					reject(response.data)
				}
			})
		},
		async getCustomerById(params) {
			return new Promise(async (resolve, reject) => {
				let response = await this.$http.post("customers/get_customer_by_id", params)
				if (response.data.status_id == 1) {
					resolve(response.data)
				} else {
					reject(response.data)
				}
			})
		},
		async getCustomerInfo(params) {
			return new Promise(async (resolve, reject) => {
				let response = await this.$http.post("customers/get_customer_info", params)
				if (response.data.status_id == 1) {
					resolve(response.data)
				} else {
					reject(response.data)
				}
			})
		},
		async customerGetCustomerWith(params) {
			return new Promise(async (resolve, reject) => {
				let response = await this.$http.post("customers/get_all_customers_with", params)
				if (response.data.status_id == 1) {
					resolve(response.data)
				} else {
					reject(response.data)
				}
			})
		},
	},
}
