export default {
    methods: {
        async getPosToken() {
            return new Promise((resolve, reject) => {
                this.$http
                    .get('/get_pos_token')
                    .then(response => {
                        if (response.data.status_id == 1) {
                            resolve(response.data)
                        } else {
                            reject(response.data)
                        }
                    })
                    .catch(error => {
                        this.$log.debug(error)
                        reject(error)
                    })
            })
        },
        async validateUser() {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.get("/get_loggedin_user")
                console.log(response)
                try{
                    console.log(response)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        console.log(response)
                        reject(response.data.reason)
                    }
                }
                catch(error){
                    console.log(error.response.data.reason)
                }
            })
        },
        async getWaitersList(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post("/employee/get_employees",params)
                try{
                    if(response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error) {
                    console.log(error.response.data.reason)
                }
            })
        },
        async TransferWaiterOrder(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/transfer_order', params)
                try{
                    if(response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                } 
                catch(error) {
                    console.log(error.response.data.reason)
                }
            })
        },
        async savePermissionDetails(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/employee/update_user_permissions', params)
                try{
                    if(response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                } 
                catch(error) {
                    console.log(error.response.data.reason)
                }
            })
        },
        async generateAllPin(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/outlet/pin/generate/all', params)
                try{
                    if(response.data.status_id == 1){
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error){
                    console.log(error)
                }
            })
        },
        async regeneratePin(params){
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/outlet/pin/update',params)
                try{
                    if(response.data.status_id == 1){
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                } 
                catch(error){
                    console.log(error)
                }
            })
        },
        async deletePin(params){
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/outlet/pin/delete',params)
                try{
                    if(response.data.status_id == 1){
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error){
                    console.log(error)
                }
            })
        },
        async enablePin(params){
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/outlet/pin/enable_pin',params)
                try{
                    if(response.data.status_id == 1){
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                    }
                }
                catch(error){
                    console.log(error)
                }
            })
        }
    }
}
