<template>
     <modal
          :name="modal_name"
          transition="nice-modal-fade"
          class="create-customer-modal final-modal popup-modal-all-width-height-height-430"
     >
          <div class="v-modal-content">
               <div class="v-modal-header align-items-center">
                    <span class="v-modal-dialog-title v-modal-title">RESET PIN</span>
                    <span class="pointer" @click="HideResetModal">
                         <i class="icon icon-close text-white"></i>
                    </span>
               </div>
               <div class="general-section" style="padding: 15px 0px;">
               <div class="v-modal-body pt-4 pb-4">
                    <div class="row">
                         <div class="col-lg-7 col-md-7 col-sm-6 pl-10">
                              <h2 class="text-secondary fs-26">DigiPOS</h2>
                              <div class="d-flex justify-content-start">
                                   <img src="/static/images/reset-pin-bg.png" width="80%" alt="">
                              </div>
                         </div>
                         <div class="col-lg-5 col-md-5 col-sm-6 my-auto">
                              <span class="text-capitalize fs-14">Enter Current Pin</span>
                              <div class="d-flex align-items-center">
                                   <v-otp-input 
                                        ref="otpInputCurrent"
                                        class="pb-3 pt-3"
                                        id="otp-input-current"
                                        :class="status_code == 1 && reached_length == true ? 'border-green' : status_code == 0 && reached_length == true ? 'border-red' : ''"
                                        input-classes='otp-input'
                                        separator=""
                                        :num-inputs="4"
                                        :should-auto-focus="true"
                                        :is-input-num="true"
                                        @on-complete="getCurrentPin"
                                        @on-change="CurrentChangeDetected"
                                   />
                                   <i class="icon-check-circle text-success" v-if="this.status_code == 1 && reached_length == true"></i>
                                   <i class="icon-close-circle text-danger" v-if="this.status_code == 0 && reached_length == true"></i>
                              </div>
                              <span class="text-capitalize fs-14">Enter New pin</span>
                              <v-otp-input 
                                   ref="otpInputNew"
                                   :class="this.new_confirm == false ? 'new-pin-lock' : ''"
                                   class="pb-3 pt-3"
                                   id="otp-input-new"
                                   input-classes='otp-input'
                                   separator=""
                                   :num-inputs="4"
                                   :should-auto-focus="false"
                                   :is-input-num="true"
                                   @on-change="NewPinChangeDetected"
                                   @on-complete="getNewPin"
                              />
                              <span class="text-capitalize fs-14">Confirm New Pin</span>
                              <v-otp-input 
                                   ref="otpInputConfirm"
                                   :class="this.new_confirm == false ? 'confirm-pin-lock' : ''"
                                   class="pt-3"
                                   input-classes='otp-input'
                                   id="otp-input-confirm"
                                   separator=""
                                   :num-inputs="4"
                                   :should-auto-focus="false"
                                   :is-input-num="true"
                                   @on-change="ConfirmChangeDetected"
                                   @on-complete="getConfirmPin"
                              />
                              <span v-if="new_confirm_pin_not_matched" class="fs-14 text-danger">
                                   New Pin and Confirm Pin does not match
                              </span>
                         </div>
                    </div>
               </div>
               </div>
               <div class="d-flex justify-content-center pt-5 pb-5">
                    <button class="btn btn-danger text-white text-capitalize" :disabled="reset_pin_api_inprogress" @click="HideResetModal">Cancel</button>
                    <button class="btn btn-success text-white ml-8 text-capitalize" :disabled="submit_disabled || reset_pin_api_inprogress" @click="savePIN">Save</button>
               </div>
          </div>
          <div v-if="reset_pin_api_inprogress" class="full-page-loader">
               <div class="d-flex align-items-center justify-content-center">
                    <div class="digi-table-loader inverse">
                         <div></div>
                         <div></div>
                         <div></div>
                         <div></div>
                    </div>
               </div>
          </div>
          <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
          <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
     </modal>
</template>

<script>
import {
        EventBus
    } from './eventBus/nav-bar-event.js'
import product from './mixins/product'
import {
        SweetModal
    } from 'sweet-modal-vue'
export default {
     mixins: [product],
     props: ['modal_name'],
     data() {
          return {
               status_code: null,
               new_confirm: false,
               new_pin_length: 0,
               confirm_pin_length: 0,
               new_pin: 0,
               confirm_pin: 0,
               modal_msg: '',
               current_pin_length: 0,
               new_confirm_pin_not_matched: false,
               reached_length: false,
               submit_disabled: true,
               reset_pin_api_inprogress: false
          }
     },
     components: {
          SweetModal
     },
     methods: {
          HideResetModal(){
               this.$emit('hide-reset-modal')
               this.status_code = null
               this.new_confirm = false
          },
          async getCurrentPin(value) {
               this.current_pin_length = value.length
               try{
                    let params = {
                         current_pin: value
                    }
                    let response = await this.getCurrentPinConfirmation(params)
                    console.log(response.status_id)
                    this.status_code = response.status_id
                    this.new_confirm = true
                    this.reached_length = true
                    this.new_confirm_pin_not_matched = false
                    // this.submit_disabled = false
               } catch(error) {
                    this.status_code = error.status_id
                    this.new_confirm = false
                    this.reached_length = true
                    this.submit_disabled = true
                    this.new_confirm_pin_not_matched = false
                    if(this.new_pin_length || this.confirm_pin_length) {
                         this.$refs.otpInputNew.clearInput();
                         this.$refs.otpInputConfirm.clearInput();
                         this.new_pin = '';
                         this.confirm_pin = '';
                         this.new_pin_length = 0
                         this.confirm_pin_length = 0
                         setTimeout(() => {
                              $('#otp-input-current .otp-input').focus();
                              $('#otp-input-confirm .otp-input').blur();
                              // this.$refs.otpInputCurrent.focusInput()
                         }, 500);
                    }
               }
          },
          CurrentChangeDetected(value){
               if(value.length < 4){
                    this.submit_disabled = true
                    this.new_confirm = false
                    this.reached_length = false
                    this.new_confirm_pin_not_matched = false
                    if(this.new_pin_length || this.confirm_pin_length) {
                         this.$refs.otpInputNew.clearInput();
                         this.$refs.otpInputConfirm.clearInput();
                         this.new_pin = '';
                         this.confirm_pin = '';
                         this.new_pin_length = 0
                         this.confirm_pin_length = 0
                         setTimeout(() => {
                              $('#otp-input-current .otp-input').focus();
                              $('#otp-input-confirm .otp-input').blur();
                              // this.$refs.otpInputCurrent.focusInput()
                         }, 500);
                    }
               }
          },
          NewPinChangeDetected(value){
               this.new_pin_length = value.length
               if(this.confirm_pin_length > 0) {
                    // if(value.length == 0){
                         this.new_confirm_pin_not_matched = false
                    // }
               }
               if(value.length < 4){
                    this.submit_disabled = true
                    if(this.confirm_pin_length > 0) {
                         // if(value.length == 0){
                              this.new_confirm_pin_not_matched = true
                         // }
                    }
                    // this.new_confirm_pin_not_matched = false
               //      if(this.new_pin == this.confirm_pin){
               //           this.new_confirm_pin_not_matched = false
               //           this.submit_disabled = true
               //      } else {
               //           this.new_confirm_pin_not_matched = true
               //           this.submit_disabled = false
               //      }
               }
          },
          ConfirmChangeDetected(value){
               this.confirm_pin_length = value.length
               if(value.length < 4){
                    this.submit_disabled = true
                    // if(this.new_pin == this.confirm_pin){
                    //      this.new_confirm_pin_not_matched = false
                    //      // this.submit_disabled = true
                    // } else {
                         this.new_confirm_pin_not_matched = true
                         // this.submit_disabled = false
                    // }
               }
          },
          getNewPin(value) {
               console.log('New pin completed: ', value);
               this.new_pin = value;
               if(this.confirm_pin_length) {
                    if(this.new_pin != this.confirm_pin) {
                         this.new_confirm_pin_not_matched = true
                    } else {
                         this.new_confirm_pin_not_matched = false
                         this.submit_disabled = false
                    }
               }
          },
          getConfirmPin(value) {
               console.log('Confirm pin completed: ', value)
               this.confirm_pin = value;
               if(this.new_pin != this.confirm_pin) {
                    this.new_confirm_pin_not_matched = true
               } else {
                    this.new_confirm_pin_not_matched = false
                    this.submit_disabled = false
               }
          },
          async savePIN(){
               try {
                    if(this.new_pin_length == this.confirm_pin_length && this.new_pin == this.confirm_pin && this.confirm_pin_length == 4 && this.new_pin_length == 4){
                         this.reset_pin_api_inprogress = true;
                         let params = {
                              new_pin: this.confirm_pin
                         }
                         let response = await this.getCurrentPinConfirmation(params)
                         this.modal_msg = response.message
                         this.$refs.success_modal.open();
                         setTimeout(() => {
                              this.$refs.success_modal.close();
                              this.status_code = null
                              this.new_confirm = false
                              this.$refs.otpInputNew.clearInput();
                              this.$refs.otpInputConfirm.clearInput();
                              this.new_pin = '';
                              this.confirm_pin = '';
                              this.new_pin_length = 0
                              this.confirm_pin_length = 0
                              EventBus.$emit('updatePINS')
                              this.HideResetModal();
                              this.reset_pin_api_inprogress = false;
                         }, 2000)
                    } else if(this.current_pin_length < 4){
                         this.modal_msg = 'Enter Current Pin'
                         this.$refs.warning_modal.open();
                         setTimeout(() => {
                              this.$refs.warning_modal.close();
                         }, 2000)
                    } else if(this.new_pin_length < 4 || this.confirm_pin_length < 4){
                         this.modal_msg = 'Enter New Pin and Confirm Pin'
                         this.$refs.warning_modal.open();
                         setTimeout(() => {
                              this.$refs.warning_modal.close();
                         }, 2000)
                    } else {
                         this.modal_msg = 'New PIN and confirm PIN are not matching'
                         this.$refs.warning_modal.open();
                         setTimeout(() => {
                              this.$refs.warning_modal.close();
                         }, 2000)
                    }  
               }
               catch(error) {
                    this.reset_pin_api_inprogress = false;
                    this.modal_msg = error
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                         this.$refs.warning_modal.close();
                    }, 2000)
               }  
          }
     },
     mounted() {
     },
     computed: {
          loggedInUser() {
               return this.$store.getters.loggedInUser;
          },
     }
}

</script>

<style scoped>
     .new-pin .otp-input,
     .confirm-pin .otp-input{
        pointer-events: none !important;
        background-color: #e3e0e0 !important;
     }

     .float-right{
          float: right !important;
     }
</style>