export default {
  methods: {
    async createCard(param) {
      return new Promise(async (resolve, reject) => {
        let response = await this.$http.post("outlet/create_new_atm_card", param)
        if (response.data.status_id === 1) {
          resolve(response.data)
        } else {
          reject(response.data.reason)
        }
      })
    },
    async updateCard(param) {
      return new Promise(async (resolve, reject) => {
        let response = await this.$http.post("outlet/update_atm_card", param)
        if (response.data.status_id == 1) {
          resolve(response.data)
        } else {
          reject(response.data.reason)
        }
      })
    }
  }
}