<template>
  <div class="page-layout blank customerdetails">
    <div class="pb-10">
      <h2 class="headerblock">
        <div class="row">
          <div class="col-lg-7 col-md-7 col-sm-7 col-xs-12">
            <router-link
              style="color: transparent!important;"
              :disabled="!$routerHistory.hasPrevious()"
              :to="{ path: $routerHistory.previous().path }"
            >
              <el-tooltip class="item" effect="dark" content="Click to Go Back" placement="top">
                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                  <i class="icons icon-arrow-left"></i>
                </button>
              </el-tooltip>
            </router-link>
            <router-link
              style="color: transparent!important;"
              :disabled="!$routerHistory.hasForward()"
              :to="{ path: $routerHistory.next().path }"
            >
              <el-tooltip class="item" effect="dark" content="Click to Go Forward" placement="top">
                <button class="btn btn-light-grey btn-fab-dc mr-3" style="margin-top: 1px;">
                  <i class="icons icon-arrow-right"></i>
                </button>
              </el-tooltip>
            </router-link>
            <a
              class="btn btn-secondary btn-smm ml-3"
              @click="CustomerEdit()"
              v-if="customer.full_name != 'Open Guest'"
            >
              <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/Edit_Icon.svg" class="pr-1" style="width: 16px;" /> Edit Customer
            </a>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
            <div class="breadcome-heading" v-if="contactTimeline">
              <div class="form-group formsearch has-warning has-feedback">
                <input
                  type="text"
                  class="form-control textremove holder"
                  id="inputWarning2"
                  aria-describedby="inputWarning2Status"
                  placeholder="Search by order ID"
                  v-model="searchkey"
                  @input="filterResult()"
                />
                <!-- <span v-if="searchkey.length!=0" class="icon-close-circle-outline form-control-feedback-cancel" aria-hidden="true" @click="removeFilter()"></span> -->
                <span class="icon-magnify iconsearch form-control-feedback" aria-hidden="true"></span>
              </div>
            </div>
          </div>
        </div>
      </h2>
      <div class="text-center" v-if="ajaxCallInProgress">
        <div class="digi-table-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="page-layout carded left-sidebar" v-else>
        <div class="page-content-wrapper">
          <div class="page-content">
            <el-container>
              <el-aside class="contactSidebar">
                <div class="header pt-4">
                  <div class="contactInfo mt-8">
                    <div class="text-center mb-5" v-if="!customer.image">
                      <avatar
                        :username="customer.first_name+' '"
                        :size="40"
                        style="margin: 0 auto;"
                      ></avatar>
                    </div>
                    <div class="text-center mb-5" v-else>
                      <img
                        :src="customer.image"
                        style="width:60px;height:60px;border-radius:100%;border:1px solid #00448b;"
                      />
                    </div>
                    <span
                      class="text-white font-weight-bold fw-600 fs-18 contactTitle"
                    >{{customer.full_name}}</span>
                    <div
                      class="mt-4 contactTitle font-weight-bold text-white"
                      v-if="customer.email"
                    >
                      <i class="icon icon-email CompanyContact"></i>
                      <span style="word-break: break-word;padding: 0px 5px;">{{customer.email}}</span>
                    </div>
                    <div class="mt-3 contactTitle font-weight-bold text-white">
                      <i class="icon icon-phone CompanyContact"></i>
                      <span>{{customer.phone_number}}</span>
                    </div>
                    <div class="mt-8">
                      <ul class="nav flex-column" style="padding-bottom: 158px;">
                        <!-- <li class="nav-item" v-if="loggedInUser.actions.orders && loggedInUser.actions.orders.order_history">
													<a class="nav-link verticalTab" :class="{contactActiveTab : contactTimeline == true}" @click="orderInfoTab()">Order History</a>
                        </li>-->
                        <li class="nav-item">
                          <a
                            class="nav-link verticalTab"
                            :class="{contactActiveTab : contactTimeline == true}"
                            @click="orderInfoTab()"
                          >Order History</a>
                        </li>
                        <li class="nav-item pt-4">
                          <a
                            class="nav-link verticalTab"
                            :class="{contactActiveTab : contactAbout == true}"
                            @click="aboutInfoTab()"
                          >About</a>
                        </li>
                        <!-- <li class="nav-item pt-4">
                          <a
                            class="nav-link verticalTab"
                            :class="{contactActiveTab : contactPrintHistory == true}"
                            @click="printHistory()"
                          >Print History</a>
                        </li>-->
                      </ul>
                    </div>
                  </div>
                </div>
              </el-aside>
              <el-main class="row contactTabs" style="background-color: #00448b;">
                <div v-if="contactTimeline" style="padding:16px;">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="pointsAvailable">
                        <span class="pl-5 font-weight-bold">Total Points Available : {{loyaltyDetails.total_points_available}}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="pointsEarned">
                        <span class="pl-5 font-weight-bold">Total Points Earned : {{loyaltyDetails.total_points_earned}}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="pointsSpent">
                        <span class="pl-5 font-weight-bold">Total Points Spent : {{loyaltyDetails.total_points_spent}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="demo-content">
                    <table class="table table-scroll">
                      <thead>
                        <tr>
                          <th>OUTLET</th>
                          <th>ORDER ID</th>
                          <th>Receipt ID</th>
                          <th>DATE / TIME</th>
                          <th>Order Value</th>
                          <th>POINTS EARNED</th>
                          <th>POINTS SPENT</th>
                          <th>Amount Redeemed</th>
                          <!-- <th>ADMIN</th> -->
                        </tr>
                      </thead>
                      <tbody style="height: 476px !important;max-height: 481px !important;">
                        <tr v-for="(order, orderIndex) in order_details" :key="orderIndex">
                          <!-- <tr v-for="(order, orderIndex) in customer.orders" :key="orderIndex" @click="showOrder(order._id)"> -->
                           <td>{{order.outlet_name || '-'}}</td>
                          <td>{{order.order_id || '-' }}</td>
                          <td>{{order.receipt_id || '-' }}</td>
                          <td>{{order.closed_at_formatted || '-' }}</td>
                          <td>{{order.grand_total}}</td>
                          <td>{{order.points_earned}}</td>
                          <td>{{order.points_spent}}</td>
                          <td>{{order.amount_redeemed}}</td>
                          <!-- <td>
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Print History"
                              placement="top"
                            >
                              <img
                                src="https://cdn.digicollect.com/cdn/posv2/images/print-history.svg"
                                style="width: 23px!important;cursor:pointer;"
                              />
                            </el-tooltip>
                          </td>-->
                        </tr>
                        <tr
                          v-if="!filter_details.length"
                          style="background-color: #dee8f2 !important;border-bottom: 1px solid #a8adb400!important;"
                        >
                          <td
                            colspan="7"
                            class="justify-content-center"
                            style="padding: 0px 31px!important;text-align: center;"
                          >
                            <strong v-if="!searchkey">No Order History</strong>
                            <strong v-else>No Search Result Found</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <el-tabs v-if="contactAbout" v-model="contactInfo">
                  <ul class="nav nav-tabs nav-site" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#table-settings"
                        role="tab"
                        aria-controls="table-settings"
                        aria-expanded="true"
                      >Personal</a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#other-settings-tab"
                        role="tab"
                        aria-controls="other-settings-tab"
                      >Analytics</a>
                    </li>
                  </ul>
                  <div class="tab-content pl-5 pr-5 mt-6">
                    <div
                      role="tabpanel"
                      class="tab-pane fade show active"
                      id="table-settings"
                      aria-labelledby="table-settings"
                    >
                      <div class="row contact-details-tab">
                        <div class="col-md-12 col-lg-6 col-sm-12">
                          <div class="row">
                            <div class="col-sm-12 pb-8">
                              <div class="ml-4 col-md-6 col-lg-6 col-sm-12">
                                <div
                                  class="content-left list-title headerabout greyheaderabout"
                                >Personal Information</div>
                              </div>
                              <div class="digiblock-section" style="background: transparent;">
                                <div class="section-container aboutblock a-Zm">
                                  <div class="sections-div">
                                    <div class="div-content">
                                      <div class="content-left spectrans">Customer Name</div>
                                      <div class="content-right">
                                        <span class="right-div">{{ customer.full_name|| '-' }}</span>
                                      </div>
                                    </div>
                                    <div class="div-content">
                                      <div class="content-left spectrans">Customer Since</div>
                                      <div class="content-right">
                                        <span v-if="customer.last_modified_time"
                                          class="right-div"
                                          style="white-space:nowrap"
                                        >{{ moment(customer.last_modified_time).format('L')}}, {{moment(customer.last_modified_time).format('LT')}}</span>
                                        <span v-else
                                          class="right-div"
                                          style="white-space:nowrap"
                                        >-</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-12 col-md-12">
                              <div class="ml-4 col-md-6 col-lg-6 col-sm-12">
                                <div
                                  class="content-left list-title headerabout greyheaderabout"
                                >Communication Information</div>
                              </div>
                              <div class="digiblock-section" style="background: transparent;">
                                <div class="section-container aboutblock a-Zm">
                                  <div class="sections-div">
                                    <div class="div-content">
                                      <div class="content-left spectrans">Phone</div>
                                      <div class="content-right">
                                        <span class="right-div">{{ customer.phone_number || '-' }}</span>
                                      </div>
                                    </div>
                                    <div class="div-content">
                                      <div class="content-left spectrans">Email</div>
                                      <div class="content-right">
                                        <span
                                          class="right-div"
                                        >{{ customer.email ? customer.email: '-' }}</span>
                                      </div>
                                    </div>
                                    <div class="div-content">
                                      <div class="content-left spectrans">Customer Address</div>
                                      <div class="content-right">
                                        <span
                                          class="right-div"
                                        >{{ customer.personal_full_address || '-' }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-sm-12 col-md-12 pb-8">
                              <div class="ml-4 col-md-6 col-lg-6 col-sm-12">
                                <div
                                  class="content-left list-title headerabout greyheaderabout"
                                >Other Information</div>
                              </div>
                              <div class="digiblock-section" style="background: transparent;">
                                <div class="section-container aboutblock a-Zm">
                                  <div class="sections-div">
                                    <div class="div-content">
                                      <div class="content-left spectrans">Company Name</div>
                                      <div class="content-right">
                                        <span class="right-div">{{ customer.company_name || '-'}}</span>
                                      </div>
                                    </div>
                                    <div class="div-content">
                                      <div class="content-left spectrans">Company Address</div>
                                      <div class="content-right">
                                        <span
                                          class="right-div"
                                        >{{ customer.company_full_address || '-'}}</span>
                                      </div>
                                    </div>
                                    <div class="div-content">
                                      <div class="content-left spectrans">Company Tax Code</div>
                                      <div class="content-right">
                                        <span
                                          class="right-div"
                                        >{{ customer.company_tax_code || '-' }}</span>
                                      </div>
                                    </div>
                                    <div class="div-content">
                                      <div class="content-left spectrans">Customer Tax Code</div>
                                      <div class="content-right">
                                        <span class="right-div">{{ customer.tax_code || '-' }}</span>
                                      </div>
                                    </div>
                                    <div class="div-content">
                                      <div class="content-left spectrans">Referred By</div>
                                      <div class="content-right">
                                        <span class="right-div">{{ customer.referred_by || '-' }}</span>
                                      </div>
                                    </div>
                                    <div class="div-content">
                                      <div class="content-left spectrans">Notes</div>
                                      <div class="content-right">
                                        <span class="right-div">{{ customer.notes || '-' }}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="pt-4 pl-3 pb-3">
                                    <i
                                      class="icon-check s-4 selected-cusdetails"
                                      v-if="customer.promotions"
                                    ></i>
                                    <i class="icon-cancel s-4 selected-cusdetails-cancel" v-else></i>
                                    <span
                                      class="pl-2 option-pro"
                                    >Opted for Promotional Emails and Offers Email</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 col-lg-6 col-sm-12">
                          <div class="row">
                            <div class="col-sm-12 pb-8">
                              <div class="ml-4 col-md-6">
                                <div
                                  class="content-left list-title headerabout greyheaderabout"
                                >Likes And Dislikes</div>
                              </div>
                              <div class="digiblock-section" style="background: transparent;">
                                <div class="section-container aboutblock a-Zm">
                                  <div class="sections-div">
                                    <div class="col-lg-12 pt-2">
                                      <label
                                        for="inputSattus"
                                        class="col-form-label text-secondary fs-14"
                                        style="font-size:14px!important;"
                                      >Likes</label>
                                      <v-select
                                        multiple
                                        v-model="customer.liked_products"
                                        label="product_name"
                                        class="likes-vselect vselectedDisabled"
                                        disabled
                                      />
                                    </div>
                                    <div class="col-lg-12 pt-2">
                                      <label
                                        for="inputSattus"
                                        class="col-form-label text-secondary fs-14"
                                        style="font-size:14px!important;"
                                      >Dislikes</label>
                                      <v-select
                                        multiple
                                        v-model="customer.disliked_products"
                                        label="product_name"
                                        class="likes-vselect vselectedDisabled"
                                        disabled
                                      />
                                    </div>
                                    <div
                                      class="col-lg-12 pt-2"
                                      v-if="customer.is_deposit_available"
                                    >
                                      <div
                                        class="content-left list-title headerabout greyheaderabout"
                                      >Deposits</div>
                                      <div
                                        class="div-content"
                                        style="padding:7px 10px 7px 1px !important;"
                                      >
                                        <div class="content-left spectrans">Deposit Amount</div>
                                        <div class="content-right">
                                          <span class="right-div">{{customer.balance || '-'}}</span>
                                        </div>
                                      </div>
                                      <div
                                        class="div-content"
                                        style="padding:7px 10px 7px 1px !important;"
                                      >
                                        <div class="content-left spectrans">Issued By</div>
                                        <div class="content-right">
                                          <span
                                            class="right-div"
                                          >{{customer.deposit.issued_by || '-'}}</span>
                                        </div>
                                      </div>
                                      <div
                                        class="div-content"
                                        style="padding:7px 10px 7px 1px !important;"
                                      >
                                        <div class="content-left spectrans">Date of Issue</div>
                                        <div class="content-right">
                                          <span
                                            class="right-div"
                                          >{{customer.deposit.date_of_issue_formatted || '-'}}</span>
                                        </div>
                                      </div>
                                      <div
                                        class="div-content"
                                        style="padding:7px 10px 7px 1px !important;"
                                      >
                                        <div class="content-left spectrans">Bank Name</div>
                                        <div class="content-right">
                                          <span
                                            class="right-div"
                                          >{{customer.deposit.bank_name || '-'}}</span>
                                        </div>
                                      </div>
                                      <div
                                        class="div-content"
                                        style="padding:7px 10px 7px 1px !important;"
                                      >
                                        <div class="content-left spectrans">Cheque/Transfer No</div>
                                        <div class="content-right">
                                          <span
                                            class="right-div"
                                          >{{customer.deposit.cheque_number || '-'}}</span>
                                        </div>
                                      </div>
                                      <div
                                        class="div-content"
                                        style="padding:7px 10px 7px 1px !important;"
                                      >
                                        <div class="content-left spectrans">Deposit Notes</div>
                                        <div class="content-right">
                                          <span
                                            class="right-div"
                                          >{{customer.deposit.deposit_notes || '-'}}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="tabpanel"
                      class="tab-pane fade"
                      id="other-settings-tab"
                      aria-labelledby="other-settings-tab"
                    >
                      <div class="row contact-details-tab">
                        <div class="col-md-12 col-lg-6 col-sm-12">
                          <div class="row">
                            <div class="col-sm-12 pb-8">
                              <div class="ml-4 col-md-6 col-lg-6 col-sm-12">
                                <div
                                  class="content-left list-title headerabout greyheaderabout"
                                  style="width: 30%"
                                >Analytics</div>
                              </div>
                              <div class="digiblock-section" style="background: transparent;">
                                <div class="section-container aboutblock a-Zm">
                                  <div class="sections-div">
                                    <div class="div-content">
                                      <div class="content-left spectrans">Average Purchase</div>
                                      <div class="content-right">
                                        <span
                                          class="right-div"
                                        >{{customer.analytics.average_purchase}}</span>
                                      </div>
                                    </div>
                                    <div class="div-content">
                                      <div class="content-left spectrans">Total Purchase</div>
                                      <div class="content-right">
                                        <span
                                          class="right-div"
                                        >{{customer.analytics.total_purchase}}</span>
                                      </div>
                                    </div>
                                    <div class="div-content">
                                      <div class="content-left spectrans">Average Monthly Visits</div>
                                      <div class="content-right">
                                        <span
                                          class="right-div"
                                        >{{customer.analytics.average_monthly_visits}}</span>
                                      </div>
                                    </div>
                                    <div class="div-content">
                                      <div class="content-left spectrans">Total Visits</div>
                                      <div class="content-right">
                                        <span class="right-div">{{customer.analytics.total_visits}}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tabs>
                <div v-if="contactPrintHistory" style="padding:16px;">
                  <div class="demo-content">
                    <div class="col-md-12 pt-4 pb-4">
                      <a
                        class="btn btn-yellow btn-smm"
                        style="border-radius: 2.5px!important;box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16)!important;"
                      >No. Of Receipts Reprinted : {{printhistory.receipts_generated}}</a>
                      <a
                        class="btn btn-green btn-smm ml-3"
                        style="border-radius: 2.5px!important;box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16)!important;"
                      >No. Of Tax Invoice Generated : {{printhistory.tax_invoice_generated}}</a>
                    </div>
                    <table class="table table-scroll table-striped">
                      <thead>
                        <tr>
                          <th>ORDER DATE</th>
                          <th>TABLE NO</th>
                          <th>ORDER ID</th>
                          <th>RECEIPT NO</th>
                          <th>PRINT TYPE</th>
                          <th>COMPANY</th>
                          <th>ITEMIZED BILL</th>
                          <th>ADMIN</th>
                        </tr>
                      </thead>
                      <tbody v-if="printhistory.print_history.length <= 0">
                        <tr>
                          <td
                            colspan="8"
                            class="justify-content-center font-weight-bold"
                          >No Print History Found</td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr v-for="print in printhistory.print_history" :key="print._id">
                          <td
                            class="whiteSpace"
                            style="padding: 0px 8px !important;"
                          >{{print.sent_at|| '-'}}</td>
                          <td>{{print.table_number|| '-'}}</td>
                          <td>{{print.invoice_id|| '-'}}</td>
                          <td>{{print.receipt_id|| '-'}}</td>
                          <td>{{print.type|| '-'}}</td>
                          <td>{{print.company_name|| '-'}}</td>
                          <td>{{print.isItem|| '-'}}</td>
                          <td>
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Print History"
                              placement="top"
                            >
                              <img
                                src="https://cdn.digicollect.com/cdn/posv2/images/print-history.svg"
                                style="width: 23px!important;cursor:pointer;"
                              />
                            </el-tooltip>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </el-main>
            </el-container>
          </div>
        </div>
      </div>
    </div>
    <edit-customer modal_name="customer-edit" @close="closeEditCustomer()"></edit-customer>
  </div>
</template>
<script>
import Avatar from 'vue-avatar'
import EditCustomer from './EditCustomer'
import customerServices from './mixins/customer'
import orderServices from './mixins/orders'
import productServices from './mixins/products'
import { EventBus } from './eventBus/nav-bar-event'
import moment from 'moment-timezone';

export default {
  data() {
    return {
      activeName: 'first',
      contactTimeline: true,
      contactAbout: false,
      contactDeals: false,
      contactUpcomingEvents: false,
      contactInfo: 'first',
      customer: {},
      ajaxCallInProgress: false,
      contactPrintHistory: false,
      printhistory: [],
      loyaltyDetails:[],
      searchkey:'',
      filter_details:[],
      order_details:[],
    }
  },
  components: {
    Avatar,
    EditCustomer
  },
  mixins: [customerServices, orderServices, productServices],
  methods: {
    moment(date) {
        return moment(date);
    },
    filterResult(){
        this.filter_details = []
        for (var i = 0; i <= this.order_details.length; i++) {
            if (this.order_details[i] && this.order_details[i].order_id.match(new RegExp(this.searchkey.trim(), 'gi'))) {
                this.filter_details.push(this.order_details[i])
            }
        }
        if (this.searchkey.trim().length == 0)
            this.filter_details = this.order_details

    },
    CustomerEdit() {
      let id = this.customer._id
      EventBus.$emit('editCustomerApi', id)
      this.$modal.show('customer-edit')
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    orderInfoTab() {
      this.contactTimeline = true
      this.contactAbout = false
      this.contactDeals = false
      this.contactUpcomingEvents = false
      this.contactPrintHistory = false
    },
    aboutInfoTab() {
      this.contactAbout = true
      this.contactTimeline = false
      this.contactDeals = false
      this.contactUpcomingEvents = false
      this.contactPrintHistory = false
    },
    closeEditCustomer() {
      this.$modal.hide('customer-edit')
      this.getCustomer(this.$route.query.id)
    },
    dealsInfo() {
      this.contactDeals = true
      this.contactTimeline = false
      this.contactAbout = false
      this.contactUpcomingEvents = false
      this.contactPrintHistory = false
    },
    companyEventsInfo() {
      this.contactUpcomingEvents = true
      this.contactTimeline = false
      this.contactAbout = false
      this.contactDeals = false
      this.contactPrintHistory = false
    },
    printHistory() {
      this.contactPrintHistory = true
      this.contactTimeline = false
      this.contactAbout = false
      this.contactDeals = false
      this.contactUpcomingEvents = false
    },
    // showOrder(order_id) {
    //   this.$modal.show('order-summary')
    // },
    async getCustomer(id) {
      try {
        this.ajaxCallInProgress = true
        let params = { _id: id }
        let res = await this.getCustomerById(params)
        this.ajaxCallInProgress = false
        this.customer = res.response
        console.log(res)
        this.order_details = this.customer.orders
        this.filter_details = this.customer.orders
        this.order_details.forEach((order, index) => {
          let day = order.closed_at.substring(0, 4);
          let time = order.closed_at.substring(16, );
          let year = order.closed_at.substring(11, 16);
          let month = order.closed_at.substring(8, 11);
          let numeric_day = order.closed_at.substring(5, 7);
          const d = new Date(`${month} ${numeric_day}, ${year} 00:00:00`);
          let date_new_format = this.moment(d).format('L');
          console.log(day + ' ' + date_new_format + ' ' + time)
          order['closed_at_formatted'] = day + ' ' + date_new_format + ' ' + time
        })
        this.filter_details.forEach((filter_detail, index) => {
          let day = order.closed_at.substring(0, 4);
          let time = order.closed_at.substring(16, );
          let year = order.closed_at.substring(11, 16);
          let month = order.closed_at.substring(8, 11);
          let numeric_day = order.closed_at.substring(5, 7);
          const d = new Date(`${month} ${numeric_day}, ${year} 00:00:00`);
          let date_new_format = this.moment(d).format('L');
          console.log(day + ' ' + date_new_format + ' ' + time)
          order['closed_at_formatted'] = day + ' ' + date_new_format + ' ' + time
        })
      } catch (reason) {
        this.ajaxCallInProgress = false
        // TODO: show error dialog
      }
    }
  },
  mounted() {
    EventBus.$emit('customerdetailsLoad', true)
    EventBus.$emit('settingsLoad', false)
    EventBus.$emit('reportsLoad', false)
    this.$http
      .post('/customers/get_customer_print_history', {
        customer_id: this.$route.query.id
      })
      .then(res => {
        if (res.data.status_id == 1) {
          this.loyaltyDetails = res.data.response.loyalty_details
          this.printhistory = res.data.response
        }
      })
  },
  created() {
    this.getCustomer(this.$route.query.id)
    // if (this.$route.query.display === 'about') {
    //   this.aboutInfoTab()
    // }
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser
    }
  }
}
</script>
<style scoped>
.customerdetails .sections-div .v-select.likes-vselect .selected-tags .close {
  display: none !important;
}
.nav-tabs.nav-site {
  border-bottom: 1px solid #cdd2da;
  padding-left: 14px;
  padding-right: 14px;
}
.nav-tabs.nav-site .nav-item {
  margin-bottom: -2px;
}
.nav-tabs.nav-site .nav-link.active {
  box-shadow: 1px -1px 2px 0 rgba(0, 0, 0, 0.16) !important;
  background: #00448b !important;
  color: #fff !important;
  border-bottom: 1px solid transparent;
  font-weight: 600;
}
.nav-tabs.nav-site .nav-link {
  height: 35px !important;
  border: 1px solid #cdd2da;
  background-color: #efefef;
  color: #00448b;
  margin-right: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 1px;
}

.nav-tabs.nav-site .nav-link.active:not(.dropdown-toggle):after,
.nav-tabs.nav-site .nav-item.show .nav-link:not(.dropdown-toggle):after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: transparent;
}

.customerdetails .content-right .right-div {
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: 0.6px;
  color: #303031 !important;
  word-break: break-all;
}
.contact-details-tab {
  margin: 0px !important;
  background-color: #dee8f2 !important;
  border-radius: 12px !important;
  padding-top: 20px;
  margin-bottom: 18px !important;
}
@media only screen and (max-width: 899px) {
  .customerdetails .el-aside.contactSidebar {
    width: 234px;
  }
}
.selected-cusdetails {
  color: #ffffff;
  background-color: #46d830;
  border-radius: 100px;
}
.selected-cusdetails-cancel {
  color: #ffffff;
  background-color: red;
  border-radius: 100px;
}
.option-pro {
  color: #4e5764 !important;
  line-height: 1.23;
}
.icon-star.checked {
  color: orange;
}
.icon-star {
  color: #cdd2da;
  cursor: pointer;
}
.section-container.aboutblock {
  padding: 0 0px 0px 18px !important;
}
div.list-title.headerabout {
  padding-left: 16px;
  border-radius: 50px;
  background-color: #00448b;
  line-height: 20px;
  color: #fff;
  font-weight: 600;
  padding-right: 16px;
  white-space: nowrap;
  display: block;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 300px !important;
}
.greyheaderabout {
  color: #00448b !important;
  background-color: #ced5db !important;
}
.contactActiveTab {
  color: #fff !important;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-right: solid 18px #cdd2da;
  background-color: rgba(205, 210, 218, 0.65);
  border-right-color: #00448b;
  overflow: inherit !important;
  padding-left: 42px !important;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.38);
}
.verticalTab {
  height: 41px;
  display: flex;
  width: 100%;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 24px;
  position: relative;
  justify-content: center;
  color: #f7f6f6 !important;
  font-weight: 600;
}
.contactSidebar {
  padding-bottom: 30px;
  padding-bottom: 30px;
  background-image: url('https://cdn.digicollect.com/cdn/posv2/arora1.png');
  background-size: 310px 800px;
  background-repeat: no-repeat;
  height: auto;
}
.contactTitle {
  text-align: center;
  display: block;
  font-size: 14px;
  letter-spacing: 0.3px;
}
.contact-details-tab.el-tabs--border-card > .el-tabs__header {
  background-color: #fff !important;
  border-right: none !important;
  color: #00448b;
}
.contact-details-tab
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item.is-active {
  border-right-color: #ffffff !important;
  border-left-color: #ffffff !important;
  border-bottom-color: #00448b;
}
.contact-details-tab .el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #00448b !important;
}
.contact-details-tab .el-tabs__item {
  color: #00448b !important;
}
.el-main.contactTabs {
  padding-top: 0px;
  padding-left: 0px;
  margin-left: 0px;
  /* border-left: solid 1px #cdd2da; */
}
.defaultBlue {
  font-weight: 600;
  color: #00448b !important;
}
.customerdetails .page-layout.carded.left-sidebar .page-content-wrapper,
.page-layout.carded.right-sidebar .page-content-wrapper {
  padding-right: 16px !important;
}
.customerdetails .el-tabs__content {
  padding-left: 24px;
  padding-top: 14px;
}
.avatar-customerdetails {
  font-size: 2.3rem !important;
  width: 48px;
  min-width: 48px;
  height: 48px;
  line-height: 48px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-weight: 500;
  text-align: center;
  color: #ffffff !important;
  background: #dfdfdf;
  margin: 0 auto;
}
.customer-details .el-main {
  padding: 0px !important;
}
.table-sorting-icon {
  visibility: hidden;
}
th:hover .table-sorting-icon {
  visibility: visible;
  cursor: pointer;
}
.CompanyContact {
  font-size: 18px;
  color: #fff;
}
.whiteSpace {
  white-space: nowrap !important;
}
.el-tabs__nav-wrap::after {
  height: 1px !important;
  background-color: #fff !important;
}
.pointsAvailable{
  background-image: linear-gradient(to top, #16afaf 99%, #41d7f2 1%);
  padding: 15px;
  border-radius: 14px;
  margin-bottom: 10px;
  font-size: 15px !important;
}
.pointsEarned{
  background-image: linear-gradient(to bottom, #a8e349, #569e20);
  padding: 15px;
  border-radius: 14px;
  margin-bottom: 10px;
  font-size: 15px !important;
}
.pointsSpent{
  background-image: linear-gradient(to bottom, #ffb28e, #ed6961);
  padding: 15px;
  border-radius: 14px;
  margin-bottom: 10px;
  font-size: 15px !important;
}
</style>
