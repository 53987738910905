import Vue from 'vue'
import App from './App'
import axios from 'axios'
import router from './router'
import Avatar from 'vue-avatar'
import './registerServiceWorker'
import vSelect from 'vue-select'
import Toasted from 'vue-toasted'
import VModal from 'vue-js-modal'
import VueCookie from 'vue-cookie'
import ElementUI from 'element-ui'
import QrcodeVue from 'qrcode.vue'
import VueSnackbar from 'vue-snack'
import VueVisible from 'vue-visible'
import VueLogger from 'vuejs-logger'
import VueSession from 'vue-session'
import VueScrollTo from 'vue-scrollto'
import VuejsDialog from 'vuejs-dialog'
import Vue2Filters from 'vue2-filters'
import VeeValidate from 'vee-validate'
import VueTelInput from 'vue-tel-input'
import DatePicker from 'vue2-datepicker'
import VueMasonry from 'vue-masonry-css'
import VueClipboard from 'vue-clipboard2'
import VueFloatLabel from 'vue-float-label'
import Notifications from 'vue-notification'
import VueSweetalert2 from 'vue-sweetalert2'
import AutoResponsive from 'autoresponsive-vue'
import flatPickr from 'vue-flatpickr-component'
import VueTagsInput from '@johmun/vue-tags-input'
import locale from 'element-ui/lib/locale/lang/en'
import VueContentLoading from 'vue-content-loading'
import firebase from 'firebase/app';
require("firebase/messaging");
import {SweetModal,SweetModalTab} from 'sweet-modal-vue'
import store from './store/index';
import {LocationPicker} from 'vue2-location-picker'
import {checkPermissions} from './components/mixins/permissions'
import global from "./components/global.js"
import 'flatpickr/dist/flatpickr.css'
import 'vue-snack/dist/vue-snack.min.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'vue-tel-input-fix/dist/vue-tel-input.css'
import StarRating from 'vue-star-rating'
import VueHtmlToPaper from 'vue-html-to-paper'
import VOffline from 'v-offline'
import VueRouterBackButton from 'vue-router-back-button'
import VueTimepicker from 'vue2-timepicker'
import VueOtpInput from "@bachdgvn/vue-otp-input";
import 'vue2-timepicker/dist/VueTimepicker.css'
import Popover from 'vue-js-popover'
import vSelectPage from 'v-selectpage'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {ObserveVisibility} from 'vue-observe-visibility'
import VueCarousel from 'vue-carousel'
import VueMoment from 'vue-moment';
import VueQRCodeComponent from 'vue-qr-generator'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vueDebounce from 'vue-debounce'

const options = {
    name: '_blank',
    specs: ['fullscreen=no', 'titlebar=', 'scrollbars=no'],
    styles: [
        'https://cdn.digicollect.com/cdn/posv2/css/printre.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css'
    ]
}

/* Vue configurations */

Vue.use(VModal)
Vue.use(Avatar)
Vue.use(Toasted)
Vue.use(flatPickr)
Vue.use(VueCookie)
Vue.use(QrcodeVue)
Vue.use(SweetModal)
Vue.use(VueMasonry)
Vue.use(VueSession)
Vue.use(VueVisible)
Vue.use(DatePicker)
Vue.use(VeeValidate)
Vue.use(VueTelInput)
Vue.use(Vue2Filters)
Vue.use(VuejsDialog)
Vue.use(VueScrollTo)
Vue.use(VueSnackbar)
Vue.use(VueClipboard)
Vue.use(VueTagsInput)
Vue.use(SweetModalTab)
Vue.use(Notifications)
Vue.use(VueFloatLabel)
Vue.use(VueSweetalert2)
Vue.use(AutoResponsive)
Vue.use(LocationPicker)
Vue.use(VueContentLoading)
Vue.use(StarRating)
Vue.use(vSelectPage)
Vue.use(VueCarousel)
Vue.use(firebase);
Vue.use(VueMoment)

Vue.use(VueTimepicker)
Vue.use(Popover)
// Vue.use(Print)
Vue.use(VueHtmlToPaper, options)
Vue.use(VueHtmlToPaper)
// Vue.use(html2canvas)
Vue.component('v-select', vSelect)
Vue.component("v-otp-input", VueOtpInput);
Vue.component('VOffline', require('v-offline'))
Vue.component('multiselect', Multiselect)
Vue.use(VueRouterBackButton, {
    router
})
Vue.component('qr-code', VueQRCodeComponent)
Vue.directive('observe-visibility', ObserveVisibility)
Vue.use(ElementUI, {
    locale
})
Vue.use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 100,
    newestOnTop: true,
    type: 'info'
});
Vue.use(vueDebounce);

const isProduction = process.env.NODE_ENV === 'production'
Vue.config.productionTip = false

Vue.prototype.$serverURL = global.POS_API
console.log(Vue.prototype.$serverURL)
Vue.prototype.$http = axios.create({
    baseURL: Vue.prototype.$serverURL
})

// To Reduce Local Error Emails
Vue.prototype.$http.defaults.headers.common.Debug = location.hostname == "0.0.0.0" ? true : false

const loggerOptions = {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true
}

Vue.use(VueLogger, loggerOptions)

// if (VueCookie.get('Token')) {
//     Vue.prototype.$http.interceptors.response.use(undefined, err => {
//         console.log(err.response.status);
//         if (err.response.status == '403') {
//             VueCookie.delete("Token");
//             VueCookie.delete('Token', {
//                 domain: 'digicollect.com'
//             });
//             VueCookie.delete('Token', {
//                 domain: '.digicollect.com'
//             });
//             VueCookie.delete('Token', {
//                 domain: '.digicollect.com',
//                 path: '/'
//             });
//             VueCookie.delete('Token', {
//                 domain:document.location.hostname,
//                 path:document.location.pathname
//             });
//             VueCookie.delete('Token', {
//                 domain:document.location.hostname,
//             });
//             VueCookie.delete('Token', {
//                 path:document.location.pathname
//             });
//             VueCookie.delete('Token', {
//                 path: '/'
//             });
//             var res = document.cookie;
//             var multiple = res.split(";");
//             for (var i = 0; i < multiple.length; i++) {
//                 var key = multiple[i].split("=");
//                 document.cookie = key[0] + " =; expires = Thu, 01 Jan 1970 00:00:00 UTC";
//             }
//             console.log("Interceptor called")
//             setTimeout(() => {
//                 window.location.href = global.POS_URL + '/login';
//             }, 500);
//         } else {
//             sessionStorage.setItem("error",err.response.data.reason)
//             console.log(err.response);
//             return Promise.reject(err);
//         }
//     })
// }

// Vue Toasted
Vue.toasted.register(
    'success',
    message => {
        return message || ''
    }, {
        type: 'success',
        position: 'top-center',
        duration: 1000,
        className: 'success_snack',
        closeOnSwipe: true
    }
)
Vue.toasted.register(
    'error',
    message => {
        return message || ''
    }, {
        type: 'error',
        position: 'top-center',
        duration: 1000,
        className: 'error_snack',
        closeOnSwipe: true
    }
)
/* router conditions */
router.beforeEach((to, from, next) => {
    if (to.fullPath == '/settings') {
        next()
    } else {
        if (checkPermissions(to.fullPath) == true) {
            next()
        } else {
            next(vm => {})
        }
    }
})

new Vue({
    el: "#app",
    router,
    components: {
        App
    },
    created() {
        window.addEventListener('offline', () => {
            store.dispatch('setConnected', false)
        })
        window.addEventListener('online', () => {
            store.dispatch('setConnected', true)
        })
    },
    template: "<App/>",
    store
});