import Swal from "sweetalert2";

import {
    EventBus
} from '../eventBus/nav-bar-event.js'

export default {
    props: ['modal_name'],
    data() {
        return {
            preparedItems: [],
            deletedItems: [],
            order_details: [],
            item_details: [],
            order_status: '',
            order_type: '',
            new_order_type:'',
            customer_name: '',
            available_count: 0,
            occupied_count: 0,
            message: '',
            orders: {},
            payment_mode_error: '',
            ajaxCallinprogress: true,
            orders_amounts: {},
            item_quantity: 0,
            order_status: false,
            showIllustrator: false,
            noSearchResult: false,
            temp_type: false,
            temp_dine_in: false,
            count:0,
            filterData:{
                order_type:[],
                delivery_companies:[],
                online_companies:[],
                payment_status:'',
                categories: []
            },
            showfilterData:{
                order_type:[],
                delivery_companies:[],
                online_companies:[],
                payment_status:'',
                categories: []
            },
        }
    },
    components: {
        Swal,
    },
    methods: {
        ChangeOrderStatusWhenPrepared(item_id, status, status_value, order_id = '', type, index) {
            this.$http.post('orders/_change_order_item_status_when_prepared', {
                item_id: item_id,
                isPrepared: status_value,
                order_id: order_id
            }).then(res => {
                if (res.data.status_id == 1) {
                    status_value ? this.$toasted.global.success("Item Prepared") : this.$toasted.global.error("Item Preparing")
                    if (status_value) {
                        this.showPreparedCard = false
                    }
                    if (this.type.value == 'Dine-In') {
                        this.getTables()
                    } else {
                        this.get_all_orders(false)
                    }
                    if (order_id) {
                        this.get_all_orders_dine_in(order_id)
                    }
                } else {
                    this.$toasted.global.error(res.data.reason)
                }
            })
        },
        ChangeOrderStatusWhenDelivered(item_id, status, status_value, order_id = '') {
            this.$http.post('orders/_change_order_item_status_when_delivered', {
                item_id: item_id,
                isDelivered: status_value,
                order_id: order_id
            }).then(res => {
                if (res.data.status_id == 1) {
                    status_value ? this.$toasted.global.success("Item Delivered") : this.$toasted.global.error("Item Prepared")
                    if (this.type.value == 'Dine-In') {
                        this.getTables()
                    } else {
                        this.get_all_orders(false)
                    }
                    if (order_id) {
                        this.get_all_orders_dine_in(order_id)
                    }
                } else {
                    this.$toasted.global.error(res.data.reason)
                }
            })
        },
        ChangeAvailableStatus(item_id, status_value, order_id = '') {
            this.$http.post('orders/_change_order_item_available_status', {
                item_id: item_id,
                unAvailable: !status_value
            }).then(res => {
                if (res.data.status_id == 1) {
                    status_value ? this.$toasted.global.success("Item Made Available") : this.$toasted.global.success("Item Made Unavailable")
                    if (this.type.value == 'Dine-In') {
                        this.getTables()
                    } else {
                        this.get_all_orders(false)
                    }
                    if (order_id) {
                        this.get_all_orders_dine_in(order_id)
                    }
                } else {
                    this.$toasted.global.error(res.data.reason)
                }
            })
        },
        updateDisplayOrderDetails(table_id) {
            this.$http.post('/orders/_get_all_orders', {
                table_id: table_id
            }).then(res => {
                if (res.data.status_id == 1) {
                    EventBus.$emit('TableView', res.data.response)
                }
            })
        },
        ChangeOrderStatustoHold(item_id, status, order_id = '') {
            this.$http.post('orders/_change_order_item_status_to_hold_or_unhold', {
                item_id: item_id,
                isHold: status,
            }).then(res => {
                if (res.data.status_id == 1) {
                    status ? this.$toasted.global.success("Item Put on Hold") : this.$toasted.global.error("Item Preparing")
                    if (this.type.value == 'Dine-In') {
                        this.getTables()
                    } else {
                        this.get_all_orders(false)
                    }
                    if (order_id) {
                        this.get_all_orders_dine_in(order_id)
                    }
                } else {
                    this.$toasted.global.error(res.data.reason)
                }
            })
        },
        DeleteIteminOrder(delete_quantity, item_id, view_type, type, order_id = "", reason, comments) {
            this.type = type
            this.view_type = view_type
            this.$http.post('mobile/_delete_items_in_order', {
                item_id: item_id,
                delete_quantity: parseInt(delete_quantity),
                reason: reason,
                comments: comments
            }).then(res => {
                if (res.data.status_id == 1) {
                    this.$toasted.global.success("Deleted Successfully")
                    EventBus.$emit('CloseDeleteComponent', true);
                    this.getDetails()
                } else {
                    this.$toasted.global.error(res.data.reason)
                }
            }).catch((err) => {
                console.log(err.response)
            })
        },
        getTables() {
            this.ajaxCallinprogress = true
            this.$http.post('orders/_get_all_tables', {
                table_id: this.searchdata
            }).then(res => {
                if (res.data.status_id == 1) {
                    this.ajaxCallinprogress = false
                    EventBus.$emit('ManageOrderTable', res.data.response);
                    EventBus.$emit('updateBills', true);
                }
            })
        },
        showOrderDetails() {
            this.$modal.show('order-detail');
        },
        get_all_orders_dine_in(order_id) {
            this.$http.post('mobile/_get_order_details', {
                order_id: order_id,
                for: 'web'
            }).then(res => {
                if (res.data.status_id == 1) {
                    EventBus.$emit('TableView', res.data.response)
                    EventBus.$emit('OrderId', order_id)
                }
            }).then(res => {
                this.showOrderDetails();
            })
        },
        deleteOrderConfirm: function (_id) {
            Swal({
                title: 'Are you sure?',
                text: "Are You Sure You Want To Delete The Order?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.value) {
                    this.deleteOrder(_id)
                }
            })
        },
        deleteOrder(_id, reason, comments) {
            this.$http.post('orders/cancel_order', {
                _id: _id,
                reason: reason,
                comments: comments
            }).then(res => {
                if (res.data.status_id == 1) {
                    // Swal("Order Deleted!", "Order Deleted Successfully", "success");
                    EventBus.$emit('updateOrderCardView',_id)
                    EventBus.$emit('CloseModal', "order-detail")
                    EventBus.$emit('CloseDeleteComponent', "order-detail",res.data.response)
                } else if (res.data.status_id == 0) {
                    this.$toasted.global.error(res.data.reason)
                    Swal("Cannot Delete Order", res.data.reason, "warning");
                    this.getDetails()
                    EventBus.$emit('CloseModal', "order-detail")
                    EventBus.$emit('CloseDeleteComponent', "order-detail")
                } else {
                    this.getDetails()
                }
            })
        },
        holdOrder(_id, status) {
            if (status == 'hold') {
                this.order_status = false
            } else {
                this.order_status = true
            }
            this.$http.post('orders/hold_whole_order', {
                order_id: _id,
                isHold: this.order_status
            }).then(res => {
                if (res.data.status_id == 1) {
                    if (this.order_status) {
                        this.get_all_orders_dine_in(_id)
                        Swal("Order Hold", "Order Put on Hold Successfully", "success");
                    } else {
                        this.get_all_orders_dine_in(_id)
                        Swal("Order Unhold", "Order Removed From Hold", "success");
                    }
                    if (this.type.value == 'Dine-In') {
                        this.getTables()
                    } else {
                        this.get_all_orders(false)
                    }
                } else
                    this.$toasted.global.error(res.data.reason)

            })
        },
        hidePayBill(modal_name) {
            EventBus.$emit('ClosePayBill', modal_name);
        },
        async getorderDetails(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/orders/order_items',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async getorderB2CDetails() {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.get('/b2c/order/get')
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async holdWholeOrder(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post(
                    '/orders/hold_whole_order',
                    params
                )
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async fireActiveApiCall(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/active_fire', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async courseHoldApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/course/hold', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async coursePrepareApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/course/prepare', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async courseDeliverApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/course/deliver', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async itemPreparedApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/_change_order_item_status_when_prepared', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async itemDeliveredApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/_change_order_item_status_when_delivered', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async itemAvailableApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/_change_order_item_available_status', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async itemHoldApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/_change_order_item_status_to_hold_or_unhold', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderPrepareApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/prepare', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async orderDeliveApi(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/deliver', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    reject(response.data.reason)
                }
            })
        },
        async deleteOrdernewConfirm(params) {
            return new Promise(async (resolve, reject) => {
                let response = await this.$http.post('/orders/cancel_order', params)
                if (response.data.status_id == 1) {
                    resolve(response.data)
                } else {
                    this.$toasted.global.error(response.data.reason)
                    reject(response.data.reason)
                }
            })
        },
        async deleteItemnewConfirm(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post('/orders/_delete_items_in_order', params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        this.$toasted.global.error(response.data.reason)
                        reject(response.data.reason)
                    }
                }
                catch(error){
                    this.$toasted.global.error(sessionStorage.getItem("error"))
                }
            })
        },
        async deleteOrderClose(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post('/orders/delete_closed_order', params)
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        this.$toasted.global.error(response.data.reason)
                        reject(response.data.reason)
                    }
                }
                catch(error){
                    this.$toasted.global.error(sessionStorage.getItem("error"))
                }
            })
        },
        async getmanageorderApi(params) {
            return new Promise(async (resolve, reject) => {
                try{
                    let response = await this.$http.post('/orders/manage_orders', params)
                    let res = response.data.response
                    if (this.loggedInUser.role == 'default_order_receiver') {
                        this.stateMaintain(res)
                        this.maintainOrderState(res)
                    }
                    if (response.data.status_id == 1) {
                        resolve(response.data)
                    } else {
                        reject(response.data.reason)
                        this.$toasted.global.error(response.data.reason)
                    }
                } catch(error) {
                    this.$toasted.global.error(sessionStorage.getItem("error"))
                    console.log(error)
                }
            })
        },
        stateMaintain(res) {
            let orderData =res.orders
            orderData.forEach((element, cindex) => {
                if (element.display_status) {
                    this.disable_course = element.display_status.courses
                    this.disable_item = element.display_status.items
                    this.orderCourse_display = element.display_status.courses
                    this.orderItem_display = element.display_status.items
                }
            })
        },
        maintainOrderState(res,item_id) {
            console.log(res)
            this.orderDetails = res.orders
            if (this.orderDetails == '' || this.orderDetails == undefined || this.orderDetails == null) {
                if(res.response) {
                    if(res.response.hasOwnProperty('course_details')) {
                        this.courseDetails = res.response.course_details || []
                        for (var course_index in this.courseDetails) {
                            for (var item_index in this.courseDetails[course_index].item_details) {
                                if(this.courseDetails[course_index].item_details[item_index].item_id == item_id){
                                
                                    if (this.courseDetails[course_index].item_details[item_index].item_status == 'prepared') { 
                                        this.courseDetails[course_index].item_details[item_index].preparedCount++
                                        this.courseDetails[course_index].item_details[item_index].isPrepared = true
                                        this.courseDetails[course_index].item_details[item_index].isHold = false
                                        this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                        this.courseDetails[course_index].item_details[item_index].isFired = true
                                        this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                    } else if (this.courseDetails[course_index].item_details[item_index].item_status ==
                                        'placed') { 
                                        this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                        this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                        this.courseDetails[course_index].item_details[item_index].isHold = false
                                        this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                        this.courseDetails[course_index].item_details[item_index].isFired = false
                                        this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                    } else if (
                                        this.courseDetails[course_index].item_details[item_index]
                                        .item_status == 'preparing'
                                    ) { 
                                        this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                        this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                        this.courseDetails[course_index].item_details[item_index].isHold = false
                                        this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                        this.courseDetails[course_index].item_details[item_index].isFired = true
                                        this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                    } else if (
                                        this.orderDetails[course_index].item_details[item_index].item_status == 'delivered'
                                    ) {
                                        this.courseDetails[course_index].item_details[item_index ].isDelivered = true
                                        this.courseDetails[course_index].item_details[item_index].isPrepared = true
                                        this.courseDetails[course_index].item_details[item_index].isHold = false
                                        this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                        this.courseDetails[course_index].item_details[item_index].isFired = true
                                        this.courseDetails[course_index].item_details[item_index].isDelivered = true
                                    } else if (
                                        this.orderDetails[course_index].item_details[item_index].item_status == 'hold'
                                    ) {
                                        this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                        this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                        this.courseDetails[course_index].item_details[item_index].isHold = true
                                        this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                        this.courseDetails[course_index].item_details[item_index].isFired = true
                                        this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                    } else if (
                                        this.orderDetails[course_index].item_details[item_index].item_status == 'unavailable'
                                    ) {
                                        this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                        this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                        this.courseDetails[course_index].item_details[item_index].isHold = false
                                        this.courseDetails[course_index].item_details[item_index].unAvailable = true
                                        this.courseDetails[course_index].item_details[item_index].isFired = true
                                        this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                    } else {
                                        this.courseDetails[course_index].item_details[item_index ].isDelivered = false
                                        this.courseDetails[course_index].item_details[item_index].isPrepared = false
                                        this.courseDetails[course_index].item_details[item_index].isHold = false
                                        this.courseDetails[course_index].item_details[item_index].unAvailable = false
                                        this.courseDetails[course_index].item_details[item_index].isFired = false
                                        this.courseDetails[course_index].item_details[item_index].isDelivered = false
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                // this.orderDetails.forEach((element, orindex) => {
                //     this.courseDetails = element.order_items
                // })
                this.orderDetails.forEach((element, cdindex) => {
                    element.order_items.forEach((order_item, tiindex) => {
                        order_item.item_details.forEach((item_details, otindex) => {
                            if (item_details.item_status == 'prepared') {
                                item_details.preparedCount++
                                item_details.isPrepared = true
                                item_details.isHold = false
                                item_details.unAvailable = false
                                item_details.isFired = true
                                item_details.isDelivered = false
                            } else if (item_details.item_status == 'placed') {
                                item_details.isDelivered = false
                                item_details.isPrepared = false
                                item_details.isHold = false
                                item_details.unAvailable = false
                                item_details.isFired = false
                                item_details.isDelivered = false
                            } else if (item_details.item_status == 'preparing') {
                                item_details.isDelivered = false
                                item_details.isPrepared = false
                                item_details.isHold = false
                                item_details.unAvailable = false
                                item_details.isFired = true
                                item_details.isDelivered = false
                            } else if (item_details.item_status == 'delivered' ) {
                                item_details.isDelivered = true
                                item_details.isPrepared = true
                                item_details.isHold = false
                                item_details.unAvailable = false
                                item_details.isFired = true
                            } else if ( item_details.item_status == 'hold' ) {
                                item_details.isPrepared = false
                                item_details.isHold = true
                                item_details.unAvailable = false
                                item_details.isFired = true
                            } else if ( item_details.item_status == 'unavailable' ) {
                                item_details.isDelivered = false
                                item_details.isPrepared = false
                                item_details.isHold = false
                                item_details.unAvailable = true
                                item_details.isFired = true
                            } else {
                                item_details.isDelivered = false
                                item_details.isPrepared = false
                                item_details.isHold = false
                                item_details.unAvailable = false
                                item_details.isFired = false
                            }
                            this.$forceUpdate();
                        })
                    })
                })
            }
            this.$forceUpdate();
        },
        async getDetails(){
            try {
                this.ajaxCallinprogress = true
                let temp_res = {
                    orders:[]
                }
                EventBus.$emit('UpdateCard',temp_res,this.showfilterData,this.ajaxCallinprogress)
                let params = {
                    skip:this.count,
                    limit:10,
                    searchdata: this.searchdata,
                    order_type: this.filterData ? this.filterData.order_type : [],
                    delivery_companies: this.filterData ? this.filterData.delivery_companies : [],
                    online_companies: this.filterData ? this.filterData.online_companies : [],
                    payment_status: this.filterData ? this.filterData.payment_status : ''
                }
                if(this.loggedInUser.role == 'default_order_receiver') {
                    params['category_list'] = this.filterData ? this.filterData.categories : []
                }
                let res = await this.getmanageorderApi(params)
                this.ajaxCallinprogress = false;
                this.count = res.response.orders.length;
                EventBus.$emit('UpdateCard',res.response,this.showfilterData,this.ajaxCallinprogress)
            } catch (reason) {
                this.ajaxCallinprogress = false;
                this.noSearchResult = false
            }
        },
        updatedManagerOrder(){
            this.filterData = {
                order_type:this.temp_showfilterData.order_type,
                delivery_companies:[...new Set(this.temp_showfilterData.delivery_companies.map(x =>x.name))],
                online_companies:[...new Set(this.temp_showfilterData.online_companies.map(x =>x.name))],
                payment_status:this.temp_showfilterData.payment_status,
                categories:[...new Set(this.temp_showfilterData.categories.map(x =>x.category_id))]
            }
            setTimeout(() => {
                this.getDetails()
            }, 100);
        },
    },
    filters: {
        GetFullName(customer) {
            return customer.first_name + ' ' + customer.middle_name + ' ' + customer.last_name
        }
    },
    mounted() {
        if(this.$route.params.orderType == 'Take Away'){
            this.filterData.order_type.push('Take Away')
            this.showfilterData.order_type.push('Take Away')
        }else if(this.$route.params.orderType == 'Deliveries'){
            this.filterData.order_type.push('Deliveries')
            this.showfilterData.order_type.push('Deliveries')
        }else if(this.$route.params.orderType == 'Online'){
            this.filterData.order_type.push('Online')
            this.showfilterData.order_type.push('Online')
        }
        EventBus.$on('loadOrders', value => {
            this.getDetails(false)
        })
    }
}
